import React from "react";
import FilterSwitches from "./FilterSwitches/FilterSwitches";
import { FiltersContainer } from "./HeatMapFiltersStyles";
import { Title } from "./HeatMapFiltersStyles";

export default function HeatMapFilters() {
  return (
    <FiltersContainer>
      <Title>Filters:</Title>
      <FilterSwitches />
    </FiltersContainer>
  );
}
