import store from "../../../../../redux/store";
import { selectAllSPatterns } from "../../../../../redux/slices/selectedPatterns";
import { selectChartDataById } from "../../../../../redux/slices/chartData";

const convertBars = (bars) =>
  bars.map((bar) => {
    return { ...bar, time: bar.time / 1000 };
  });

const getSelectedBars = () => {
  const selectedPattern = selectAllSPatterns(store.getState())[0];

  const [rangeShape] = selectedPattern.shapes;
  const start = rangeShape.points[0].time * 1000;
  const end = rangeShape.points[1].time * 1000;

  const chartData = selectChartDataById(
    store.getState(),
    selectedPattern.chartId
  );

  if (!chartData) return [];
  const bars = chartData.bars;
  if (!bars?.length) return [];

  const startIndex = bars.findIndex((bar) => bar.time === start);
  const endIndex = bars.findIndex((bar) => bar.time === end);
  const filteredBars = bars.slice(startIndex, endIndex + 1);

  //need to adjust timestamps to be compatible with the chart
  const convertedBars = convertBars(filteredBars);

  return convertedBars;
};

export default getSelectedBars;
