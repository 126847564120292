import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogTitle, DialogActions, Grid } from "@material-ui/core";
import {
  InnerContainer,
  Column,
  Title,
} from "./AddOrEditCustomCollectionStyles";
import CollectionsSelect from "./CollectionsSelect/CollectionsSelect";
import ChartsSelect from "./ChartsSelect/ChartsSelect";
import ManageCustomCollection from "./ManageCustomCollection/ManageCustomCollection";
import {
  createCustomChartCollection,
  updateCustomChartCollection,
  deleteChartCollection,
} from "../../../redux/slices/chartCollections";
import CustomButton from "../../UI/Button/CustomButton";
import selectSingleChart from "./utils/selectSingleChart";
import selectMultipleCharts from "./utils/selectMultipleCharts";
import findNewSelectionRange from "./utils/findNewSelectionRange";
import useGetChartCollection from "./hooks/useGetChartCollection";
import useCustomCollectionToEdit from "./hooks/useCustomCollectionToEdit";
import CustomSpinner from "../../UI/Spinner/CustomSpinner";

export default function AddOrEditCustomCollection({ onClose }) {
  // Redux dispatch function
  const dispatch = useDispatch();

  const mode = useSelector(({ chartCollections }) => chartCollections.mode);

  const [
    selectedCollection,
    setSelectedCollection,
    collections,
    isFetchingCollection,
  ] = useGetChartCollection();
  const [customCollectionToEdit, selectedCharts, setSelectedCharts] =
    useCustomCollectionToEdit();


  const [name, setName] = useState("");
  const [isCommon, setIsCommon] = useState(false);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    if (customCollectionToEdit) {
      setIsCommon(customCollectionToEdit.common);
      
      if (customCollectionToEdit) {
        setName(customCollectionToEdit.name)
      }

    }
  }, [customCollectionToEdit]);

  // Handler functions
  const onCollectionSelect = (collection) => {
    setSelectedCollection(collection);
  };

  const onChartSelect = (e, selected, chart, filteredCharts) => {
    const newSelectionRange = findNewSelectionRange(
      chart,
      filteredCharts,
      selectedCharts
    );

    if (e.shiftKey && newSelectionRange) {
      selectMultipleCharts(
        newSelectionRange,
        filteredCharts,
        selectedCharts,
        setSelectedCharts,
        selectedCollection
      );
    } else {
      selectSingleChart(
        selected,
        chart,
        selectedCharts,
        setSelectedCharts,
        selectedCollection
      );
    }
  };

  const onChartSelectAll = (filteredCharts) => {
    const selectedChartIds = selectedCharts.map((chart) => chart.id);
    const unselectedCharts = filteredCharts.filter(
      (chart) => !selectedChartIds.includes(chart.id)
    );
    setSelectedCharts([
      ...selectedCharts,
      ...unselectedCharts.map((chart) => ({
        ...chart,
        chart_collection_id: selectedCollection.id,
      })),
    ]);
  };

  const saveCustomCollection = () => {
    if (mode === "add") {
      dispatch(
        createCustomChartCollection(
          name,
          isCommon,
          selectedCharts,
          setApiError,
          onClose
        )
      );
    } else {
      dispatch(
        updateCustomChartCollection(
          customCollectionToEdit.id,
          name,
          isCommon,
          selectedCharts,
          setApiError,
          onClose
        )
      );
    }
  };

  const deleteCustomCollection = () => {
    dispatch(
      deleteChartCollection(customCollectionToEdit.id, setApiError, onClose)
    );
  };

  let title = "Add Custom Security List";

  if (mode !== "add" && customCollectionToEdit) {
    title = `Edit: ${customCollectionToEdit.name}`;
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <InnerContainer>
        <Column>
          <Title>Collections</Title>
          <CollectionsSelect
            collections={collections}
            onSelect={onCollectionSelect}
            selectedCollection={selectedCollection}
          />
          
          {isFetchingCollection && (
            <Grid container justify="center">
              <CustomSpinner />
            </Grid>
          )}

          {!isFetchingCollection && (
            <Fragment>
              <Title>Charts</Title>
              <ChartsSelect
                selectedCollection={selectedCollection}
                onSelect={onChartSelect}
                selectedCharts={selectedCharts}
                onSelectAll={onChartSelectAll}
              />
            </Fragment>
          )}
        </Column>
        <Column>
          {apiError && <div className="delete-error">{apiError}</div>}
          <ManageCustomCollection
            mode={mode}
            name={name}
            setName={setName}
            isCommon={isCommon}
            setIsCommon={setIsCommon}
            selectedCharts={selectedCharts}
            setSelectedCharts={setSelectedCharts}
            saveCustomCollection={saveCustomCollection}
            deleteCustomCollection={deleteCustomCollection}
          />
        </Column>
      </InnerContainer>
      <DialogActions>
        <CustomButton variant="outlined" onClick={onClose}>
          Exit
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
