import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, LinearProgress } from "@material-ui/core";
import styled from "styled-components";

const styles = {
  root: {
    backgroundColor: "rgb(19, 23, 34);!important",
    color: "white !important",
    height: "25px",
    lineHeight: 1.25,
    flex: "grow",
    width: "100%",
  },
  label: {
    fontSize: "12px",
    textTransform: "none",
  },
  disabled: {
    opacity: "0.5",
  },
  containedPrimary: {
    backgroundColor: "rgb(19, 23, 34);!important",
  },
};

const linearProgressStyles = {
  root: {
    borderRadius: "5px",
    bottom: 0,
    position: "absolute",
    width: "100%",
  },
};

const Container = styled.div`
  align-items: center;
  display: flex;
  ${({ flex }) => (flex ? "flex: 1" : "")};
  flex-direction: column;
  margin: 0 5px;
  justify-content: center;
  position: relative;
`;

export default ({ onClick, children, loading, disabled, flex, height, id }) => {
  const updatedStyles = height
    ? {
        ...styles,
        root: {
          ...styles.root,
          height: height + "px",
        },
      }
    : styles;

  const StyledButton = withStyles(updatedStyles)(Button);
  const StyledLinearProgress = withStyles(linearProgressStyles)(LinearProgress);

  return (
    <Container flex={flex}>
      <StyledButton
        id={id}
        onClick={onClick}
        disabled={disabled}
        styles={{ height: height + " px" }}
      >
        {children}
      </StyledButton>
      {loading && <StyledLinearProgress color={"secondary"} value={50} />}
    </Container>
  );
};
