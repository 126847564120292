import styled from "styled-components";

export const Container = styled.div`
  align-items: ${({ zeroCharts }) => (zeroCharts ? "center" : "")};
  display: flex;
  color: white;
  flex-direction: ${({ expanded }) => (expanded ? "row" : "column")};
  flex-grow: 1;
  flex-wrap: ${({ expanded }) => (expanded ? "wrap" : "")};
  justify-content: ${({ zeroCharts, expanded }) =>
    zeroCharts || expanded ? "center" : ""};
  overflow-y: scroll;
  padding: 10px;
`;

export const ButtonContainer = styled.div`
  padding: 10px;
  width: 330px;
`;

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
