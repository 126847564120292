export class ColdStartUtils {
  static constrain({ value, max, min }) {
    if (value < min) return min;
    if (value > max) return max;
    return value;
  }
  static normalizeXCoords(pixelX, width) {
    const x = +((100 * pixelX) / width).toFixed(2);
    const constrainedX = this.constrain({ value: x, max: 100, min: 0 });
    return constrainedX;
  }
  static normalizeYCoords(pixelY, height) {
    const y = 100 - ((100 * pixelY) / height).toFixed(2); //y coords are inverted
    const constrainedY = this.constrain({ value: y, max: 100, min: 0 });
    return constrainedY;
  }

  static findSortedPosition(array, x) {
    for (let i = 0; i < array.length; i++) {
      const prevX = array[i - 1] ? array[i - 1].x : 0;
      const nextX = array[i].x;
      if (x > prevX && x <= nextX) {
        return i;
      }
    }
  }

  static arrayAddCoords({ array, coordinates }) {
    const newArray = array.slice();
    const index = this.findSortedPosition(newArray, coordinates.x);
    newArray.splice(index, 0, coordinates);
    return newArray;
  }
  static arraydeleteCoords({ array, index }) {
    const newArray = array.slice();
    newArray.splice(index, 1);
    return newArray;
  }
  static arrayReplaceCoords({ array, index, coordinates }) {
    const newArray = array.slice();
    newArray.splice(index, 1);
    const newIndex = this.findSortedPosition(newArray, coordinates.x);
    newArray.splice(newIndex, 0, coordinates);
    return newArray;
  }
}
