const adjustHeatMapRectangles = (treeData) => {
  if (!treeData) return treeData;

  const { numberOfLeaves, totalValue } = traverseTree(treeData);

  const valueAdjustedTreeData = adjustValues(
    treeData,
    numberOfLeaves,
    totalValue
  );

  return valueAdjustedTreeData;
};

const traverseTree = (node) => {
  if (!node.children)
    return {
      numberOfLeaves: 1,
      totalValue: node.value,
    };

  return node.children.reduce(
    (acc, childNode) => {
      const { numberOfLeaves, totalValue } = acc;

      return {
        numberOfLeaves: numberOfLeaves + traverseTree(childNode).numberOfLeaves,
        totalValue: totalValue + traverseTree(childNode).totalValue,
      };
    },
    { numberOfLeaves: 0, totalValue: 0 }
  );
};

const adjustValues = (treeData, numberOfLeaves, totalValue) => {
  const CONSTANT = totalValue / (numberOfLeaves * 2);

  const valueAdjustedTreeData = adjustLeaves({ ...treeData }, CONSTANT);

  return valueAdjustedTreeData;
};

const adjustLeaves = (node, CONSTANT) => {
  if (!node.children)
    return {
      ...node,
      relativeSize: node.value + CONSTANT,
    };

  return {
    ...node,
    children: node.children.map((childNode) =>
      adjustLeaves(childNode, CONSTANT)
    ),
  };
};

export default adjustHeatMapRectangles;
