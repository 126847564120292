import React from "react";

import { PatternLine } from "./PatternLinesStyles";

import { useGetPatternLines } from "./useGetPatternLines";

export default function PatternLines({ filteredBars, patterns, dimensions }) {
  const patternLines = useGetPatternLines(filteredBars, patterns, dimensions);

  return (
    <>
      {patternLines.map((line, index) => (
        <PatternLine key={index} line={line} marginTop={dimensions.marginTop} />
      ))}
    </>
  );
}
