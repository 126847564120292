import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../UI/Button/CustomButtonWhite";
import { Container } from "./ButtonsStyles";
import LocalStorage from "../../../../utils/localStorage";
import { removeAllChartData } from "../../../../redux/slices/chartData";
import { resetChartLayout } from "../../../../redux/slices/chartLayout";
import { clearSelectedPatterns } from "../../../../redux/slices/selectedPatterns";
import { setSelectedChartIds } from "../../../../redux/slices/leftPanel";

export default function Buttons() {
  const dispatch = useDispatch();

  // Select from redux store
  const mode = useSelector(({ display }) => display.mode);

  return (
    <Container>
      {mode === "selectPatternsForTraining" && (
        <Button onClick={() => dispatch(clearSelectedPatterns())}>
          Remove all selected rectangles
        </Button>
      )}
      <Button
        onClick={() => {
          LocalStorage.resetCharts();
          dispatch(removeAllChartData());
          dispatch(resetChartLayout());
          dispatch(setSelectedChartIds([]));
        }}
      >
        Close all charts
      </Button>
    </Container>
  );
}
