import styled from "styled-components";

const LEFT_MARGIN = 40;

export const NavIcon = styled.img`
  height: 20px;
  width: 20px;
  filter: invert(100%) sepia(0%) saturate(7400%) hue-rotate(166deg)
    brightness(116%) contrast(128%);
`;
export const NavButton = styled.div`
  align-items: center;
  background-color: rgba(128, 128, 128, 0.555);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  opacity: 0.2;
  position: absolute;
  top: calc(50% - 25px);
  transition: box-shadow 0.2s ease;
  width: 50px;
  z-index: 5;
  left: ${(props) => {
    if (props.direction === "left") {
      if (props.isFullScreen) {
        return "65px";
      } else return `${65 - LEFT_MARGIN}px`;
    }
  }};
  right: ${(props) => (props.direction === "right" ? "65px" : "")};
  &:hover {
    opacity: 1;
  }
`;
