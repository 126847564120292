const selectMultipleCharts = (
  newSelectionRange,
  filteredCharts,
  selectedCharts,
  setSelectedCharts,
  selectedCollection
) => {
  const [min, max] = newSelectionRange;
  const newChartsToSelect = filteredCharts.slice(min, max + 1);

  setSelectedCharts([
    ...selectedCharts,
    ...newChartsToSelect.map((chart) => ({
      ...chart,
      chart_collection_id: selectedCollection.id,
    })),
  ]);
};

export default selectMultipleCharts;
