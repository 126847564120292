import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectChartDataById } from "../../../../../redux/slices/chartData";
import { useDPatternsByChart } from "../../../../GlobalHooks/useDPatternsByChart";

const convertInterval = (interval) => {
  const intervalInSeconds = interval * 60;
  const halfInterval = intervalInSeconds / 2;

  return halfInterval;
};
const getCurrentInfo = (visibleRange, interval) => {
  const currentPosition = (visibleRange.from + visibleRange.to) / 2;
  const halfInterval = convertInterval(interval);
  return [currentPosition, halfInterval];
};

const getPreviousTarget = (visibleRange, interval, times) => {
  const [currentPosition, halfInterval] = getCurrentInfo(
    visibleRange,
    interval
  );

  const finalPattern = times[times.length - 1];
  const firstPattern = times[0];
  if (currentPosition < firstPattern) return null;
  if (currentPosition > finalPattern + halfInterval) return finalPattern;

  for (let i = 0; i < times.length; i++) {
    const distanceBetweenPatternAndCurrentPosition = Math.abs(
      times[i] - currentPosition
    );
    //currently viewing this pattern. The current position will not line up exactly with the
    //pattern, so an allowance of a half interval is used
    if (distanceBetweenPatternAndCurrentPosition <= halfInterval)
      return times[i - 1];
    //in between these patterns:
    const currentlyBetweenThisPatternAndPreviousPattern =
      times[i - 1] < currentPosition && currentPosition < times[i];
    if (currentlyBetweenThisPatternAndPreviousPattern) return times[i - 1];
  }
};
const getNextTarget = (visibleRange, interval, times) => {
  const [currentPosition, halfInterval] = getCurrentInfo(
    visibleRange,
    interval
  );

  const finalPattern = times[times.length - 1];
  const firstPattern = times[0];

  if (currentPosition < firstPattern - halfInterval) return firstPattern;
  if (currentPosition > finalPattern) return null;

  for (let i = 0; i < times.length; i++) {
    const distanceBetweenPatternAndCurrentPosition = Math.abs(
      times[i] - currentPosition
    );
    //currently viewing this pattern. The current position will not line up exactly with the
    //pattern, so an allowance of a half interval is used
    if (distanceBetweenPatternAndCurrentPosition <= halfInterval)
      return times[i + 1];
    //in between these patterns:
    const currentlyBetweenThisPatternAndPreviousPattern =
      times[i - 1] < currentPosition && currentPosition < times[i];
    if (currentlyBetweenThisPatternAndPreviousPattern) return times[i];
  }
};

const findIndex = (bars, target) => {
  for (let i = 0; i < bars.length; i++) {
    if (bars[i].time >= target) return i;
  }
};

const getnewRangeIndices = (bars, target, visibleRange) => {
  const currentStartIndex = findIndex(bars, visibleRange.from * 1000);

  const currentEndIndex = findIndex(bars, visibleRange.to * 1000);

  const halfCurrentRange = Math.floor(
    (currentEndIndex - currentStartIndex + 1) / 2
  );

  const indexOfTarget = findIndex(bars, target * 1000);

  const newStartIndex = Math.max(indexOfTarget - halfCurrentRange, 0);
  const newEndIndex = Math.min(
    indexOfTarget + halfCurrentRange,
    bars.length - 1
  );
  return [newStartIndex, newEndIndex];
};

//HOOK
export const useNavigate = ({ chart }) => {
  const DPatterns = useDPatternsByChart(chart.chartId);
  const times = useMemo(
    () => DPatterns.map((pattern) => pattern.midPoint).sort((a, b) => a - b),
    [DPatterns]
  );

  const data = useSelector((state) =>
    selectChartDataById(state, chart.chartId)
  );

  const navigateToTarget = useCallback(
    (target, visibleRange) => {
      const [newStartIndex, newEndIndex] = getnewRangeIndices(
        data.bars,
        target,
        visibleRange
      );
      const newRange = {
        from: data.bars[newStartIndex].time / 1000,
        to: data.bars[newEndIndex].time / 1000,
      };
      chart.setRange(newRange);
    },
    [chart, data]
  );

  const onPrevPattern = useCallback(() => {
    const visibleRange = chart.getRange();
    const target = getPreviousTarget(visibleRange, chart.interval, times);
    if (!target) return;
    navigateToTarget(target, visibleRange);
  }, [chart, navigateToTarget, times]);

  const onNextPattern = useCallback(() => {
    const visibleRange = chart.getRange();
    const target = getNextTarget(visibleRange, chart.interval, times);
    if (!target) return;
    navigateToTarget(target, visibleRange);
  }, [chart, navigateToTarget, times]);

  if (!times.length) return [null, null];
  return [onPrevPattern, onNextPattern];
};
