import React from "react";
import { Container, Tab } from "./TabSelectStyles";

export default function TabSelect({
  selected,
  setSelected,
  detectingPatterns,
}) {
  const tabData = detectingPatterns
    ? ["Detect", "Charts", "Indicators"]
    : ["Train", "Charts"];

  return (
    <Container>
      {tabData.map((tabInfo, index) => (
        <Tab
          key={index}
          selected={selected === index}
          onClick={() => setSelected(index)}
        >
          {tabInfo}
        </Tab>
      ))}
    </Container>
  );
}
