import styled from "styled-components";

export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: ${({ trainModelView }) =>
    trainModelView ? "center" : "space-between"};
  height: 70%;
  max-height: 700px;
  margin: auto;
  padding: 20px;
  width: 1300px;
  opacity: 1;
  transition: opacity ease-in 0.5s;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 90px;
  margin: 0;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  text-shadow: 0 0 80px rgb(192 219 255 / 48%), 0 0 32px rgb(65 120 255 / 24%);
  transition: opacity ease-in 1s;
`;

export const Subtitle = styled.h2`
  font-size: 30px;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  text-shadow: 0 0 80px rgb(192 219 255 / 48%), 0 0 32px rgb(65 120 255 / 24%);
  transition: opacity ease-in 1s;
`;
