import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";

import { updatedSelectedChartIds } from "../../../../redux/slices/leftPanel";

import DeleteChartModal from "./DeleteChart/DeleteChart";
import ChartItem from "./ChartItem/ChartItem";

import { ListContainer, EmptyListText } from "./ChartListStyle";
import { useContainerHeight } from "../../../GlobalHooks/useContainerHeight";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import { filterCharts } from "../../../../utils/utils";

//template for react-window.
const Row = ({ data, index, style }) => {
  const { listData, selectedChartIds, setChartToDelete, onSelectChart } = data;
  const chart = listData[index];
  return (
    <div style={style}>
      <ChartItem
        chart={chart}
        selected={selectedChartIds.includes(chart.id)}
        setChartToDelete={setChartToDelete}
        onSelectChart={onSelectChart}
        sCharts={chart.sCharts}
        dCharts={chart.dCharts}
      />
    </div>
  );
};

export default function ChartList({ selected: tabSelected }) {
  const dispatch = useDispatch();
  const [chartToDelete, setChartToDelete] = useState(false);

  const selectedChartIds = useSelector(
    ({ leftPanel }) => leftPanel.selectedChartIds
  );
  const selectedChartCollection = useSelector(
    ({ leftPanel }) => leftPanel.selectedCollection
  );
  const selectedInterval = useSelector(
    ({ leftPanel }) => leftPanel.selectedInterval
  );

  const searchValue = useSelector(({ leftPanel }) => leftPanel.searchValue);

  const onSelectChart = (chart) => {
    dispatch(updatedSelectedChartIds(chart));
  };

  const height = useContainerHeight("#chart-list", tabSelected);

  const filteredCharts = useMemo(() => {
    let filteredCharts = [];

    if (selectedChartCollection && selectedInterval) {
      filteredCharts = filterCharts(
        selectedChartCollection.charts,
        selectedInterval,
        searchValue
      );
    }

    return filteredCharts;
  }, [selectedChartCollection, selectedInterval, searchValue]);

  const itemData = {
    listData: filteredCharts,
    selectedChartIds,
    setChartToDelete: setChartToDelete,
    onSelectChart: onSelectChart,
  };

  const emptyList =
    !selectedChartCollection ||
    !selectedChartCollection.charts ||
    selectedChartCollection.charts.length === 0;

  return (
    <>
      <ListContainer id="chart-list">
        {!filteredCharts.length && !emptyList && <LoadingAnimation />}
        {emptyList && <EmptyListText>No charts</EmptyListText>}
        <List
          height={height}
          itemCount={filteredCharts.length}
          itemData={itemData}
          itemSize={29} //height of each row
          width="100%"
        >
          {Row}
        </List>
      </ListContainer>
      <DeleteChartModal
        chartToDelete={chartToDelete}
        setChartToDelete={setChartToDelete}
      />
    </>
  );
}
