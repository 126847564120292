import React, { useEffect, useRef, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animateScroll } from "react-scroll";
import Button from "../../../UI/Button/CustomButtonRed";
import {
  Container,
  ButtonContainer,
  TextContainer,
} from "./HeatMapPreviewChartsStyles";
import HeatMapPreviewChart from "../HeatMapPreviewChart/HeatMapPreviewChart";
import {
  removeAllCharts,
  triggerRerender,
  setShowHeatMapChart,
} from "../../../../redux/slices/heatMap";
import {
  selectAllFavourites,
  removeAllFromFavourites,
} from "../../../../redux/slices/heatMapFavourites";

export default function HeatMapPreviewCharts({
  favouritesView,
  expanded,
  allView,
}) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const selectedSignals = useSelector(({ heatMap }) => heatMap.selectedSignals);
  const modelIdToNameMap = useSelector(
    ({ heatMap }) => heatMap.filterValues.modelIdToNameMap
  );
  const inFront = useSelector(({ heatMap }) => heatMap.inFront);
  const favourites = useSelector(selectAllFavourites);
  const allSignals = useSelector(({ heatMap }) => heatMap.signals);
  const heatMapChartPattern = useSelector(
    ({ heatMapChart }) => heatMapChart.pattern
  );

  // useRef hooks
  const prevSelectedChartsLength = useRef(null);

  // Handler functions
  const clearSelectedCharts = () => {
    dispatch(removeAllCharts());
  };

  const clearFavourites = () => {
    dispatch(removeAllFromFavourites());
  };

  const makeHeatMapTransparent = useCallback(() => {
    document.querySelector(`#heat-map-1`) &&
      document.querySelector(`#heat-map-1`).classList.add("transparent");
    document.querySelector(`#heat-map-1`) &&
      document.querySelector(`#heat-map-2`).classList.add("transparent");
  }, []);

  useEffect(() => {
    if (!selectedSignals.length) {
      dispatch(setShowHeatMapChart(false));
      makeHeatMapTransparent();

      setTimeout(() => {
        dispatch(triggerRerender(inFront));
      }, 500);

      setTimeout(() => {
        dispatch(triggerRerender(inFront === 1 ? 2 : 1));
      }, 1000);
    }
  }, [selectedSignals, dispatch, inFront, makeHeatMapTransparent]);

  useEffect(() => {
    // Scroll to bottom if a new chart is selected
    if (prevSelectedChartsLength.current < selectedSignals.length) {
      animateScroll.scrollToBottom({
        containerId: "selected-charts-container",
        duration: 500,
      });
    }

    prevSelectedChartsLength.current = selectedSignals.length;
  }, [selectedSignals]);

  const signalsToShow = favouritesView
    ? favourites
    : allView
    ? allSignals
    : selectedSignals;

  const heatMapPreviewCharts = [];

  signalsToShow.forEach((signal) => {
    if (signal.signal_type !== "missing_signal") {
      heatMapPreviewCharts.push(
        <HeatMapPreviewChart
          favouritesView={favouritesView}
          selected={signal.id === heatMapChartPattern?.id}
          key={signal.id}
          allView={allView}
          signal={signal}
          height={300}
          width={300}
          modelIdToNameMap={modelIdToNameMap}
        />
      );
    }
  });

  return (
    <Fragment>
      <Container
        expanded={expanded}
        id="selected-charts-container"
        zeroCharts={!signalsToShow.length}
      >
        {heatMapPreviewCharts}

        {!signalsToShow.length &&
          !allView &&
          (favouritesView ? (
            <TextContainer>
              <p>
                <b>No favourited signals</b>
              </p>
            </TextContainer>
          ) : (
            <TextContainer>
              <p>
                <b>No selected signals</b>
              </p>

              <p>Please select a signal on the heat map</p>
            </TextContainer>
          ))}
      </Container>

      {!allView && (
        <ButtonContainer>
          <Button
            onClick={favouritesView ? clearFavourites : clearSelectedCharts}
            disabled={!signalsToShow.length}
          >
            {`${favouritesView ? "Unfavorite" : "Unselect"} all`}
          </Button>
        </ButtonContainer>
      )}
    </Fragment>
  );
}
