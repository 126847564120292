import React from "react";
import { CustomFormControlLabel, CustomSwitch } from "./ChartSwitchesStyles";

export default function ChartSwitch({ checked, onChange, label }) {
  return (
    <CustomFormControlLabel
      control={
        <CustomSwitch
          size="small"
          checked={checked}
          onChange={onChange}
          classes={{
            checked: "chart-switch-checked",
            track: "chart-switch-track",
          }}
        />
      }
      label={label}
      labelPlacement="start"
      classes={{
        label: "chart-switch-label",
        root: "chart-switch-form-control",
      }}
    />
  );
}
