import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../../UI/Button/CustomButtonWhite";
import { setDrillDownBy } from "../../../../../redux/slices/heatMap";

export default function AddLayerButton() {
  const drillDownBy = useSelector(({heatMap}) => heatMap.drillDownBy);

  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setDrillDownBy([]));
  };

  return (
    <React.Fragment>
      <Button fullWidth disabled={drillDownBy.length === 0} onClick={onClick}>
        Clear Layers
      </Button>
    </React.Fragment>
  );
}
