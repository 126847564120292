import React from "react";
import { useDispatch } from "react-redux";

import { IconButton, Tooltip } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";

import { resetShape } from "../../../../../redux/slices/coldStart";

export default function ResetButton() {
  const dispatch = useDispatch();

  const onReset = () => {
    dispatch(resetShape());
  };

  return (
    <Tooltip title="Reset pattern">
      <IconButton onClick={onReset}>
        <RefreshIcon color="primary" fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}
