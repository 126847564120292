import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  background-color: rgb(38, 51, 64);
  border-radius: 5px;
  border: 1px solid #222;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  position: relative;
  width: ${({ expanded }) => {
    switch (expanded) {
      case -1:
        return 0;
      case 1:
        return "100%";
      default:
        return "360px";
    }
  }};
`;
