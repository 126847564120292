import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomSlider from "../../UI/Slider/CustomSlider";
import { Container } from "./DrillDownConstraintSliderStyles";
import { setDrillDownConstraint } from "../../../redux/slices/settings";

export default function DrillDownConstraintSlider() {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const drillDownConstraint = useSelector(
    ({ settings }) => settings.drillDownConstraint
  );

  // Hander functions
  const handleSliderChange = (event, newValue) => {
    dispatch(setDrillDownConstraint(newValue));
  };

  return (
    <Container>
      <CustomSlider
        value={drillDownConstraint}
        onChange={handleSliderChange}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        step={1}
        marks
        min={1}
        max={8}
      />
    </Container>
  );
}
