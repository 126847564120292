import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../UI/Button/CustomButtonWhite";
import { Title, TitleContainer } from "../HeatMapControlStyles";
import { Container, Row, EditedSign } from "./ConfigurationsStyles";
import ConfirmDeleteModal from "./Components/ConfirmDeleteModal/ConfirmDeleteModal";
import SaveConfigModal from "./Components/SaveConfigModal/SaveConfigModal";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  selectConfig,
} from "../../../../redux/slices/heatMapConfigs";
import {
  changeFilter,
  setDrillDownBy,
  setDepthConstraint,
} from "../../../../redux/slices/heatMap";
import { useConfigIsEdited } from "../hooks/useIsEdited";

export default function Configurations() {
  // useState hooks
  const [saveModal, setSaveModal] = useState({
    isOpen: false,
    configToEdit: null,
  });
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const {
    isEdited,
    initialFilters,
    initialDepthConstraint,
    initialDrillDownBy,
  } = useConfigIsEdited();

  const configs = useSelector(({ heatMapConfigs }) => heatMapConfigs.configs);

  const selectedConfig = useSelector(
    ({ heatMapConfigs }) => heatMapConfigs.selectedConfig
  );

  const dispatch = useDispatch();

  // MUI getStyles
  const getStyles = (name) => {
    const style = {
      borderRadius: "5px",
      color: "white",
      margin: "5px 5px",
      position: "relative",
    };

    return style;
  };

  const onSelectedConfigChange = (e) => {
    const configId = e.target.value;
    dispatch(selectConfig(configId));
  };

  return (
    <Container>
      {saveModal.isOpen && (
        <SaveConfigModal
          configToEdit={saveModal.configToEdit}
          onClose={() => setSaveModal({ isOpen: false, configToEdit: null })}
        />
      )}

      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          configToDeleteIndex={configs
            .map((c) => c.id)
            .indexOf(selectedConfig.id)}
          configToDelete={selectedConfig}
          onClose={() => setConfirmDeleteModalOpen(false)}
        />
      )}

      <TitleContainer>
        <Title>Custom configurations:</Title>
      </TitleContainer>

      <Row>
        <Select
          fullWidth
          displayEmpty
          value={selectedConfig?.id}
          onChange={onSelectedConfigChange}
          input={<Input />}
          inputProps={{ "aria-label": "Without label" }}
          disabled={!configs || !configs.length}
        >
          {configs.map((config) => (
            <MenuItem key={config.id} value={config.id} style={getStyles()}>
              {config.name}
            </MenuItem>
          ))}
        </Select>
        {!!(configs && configs.length) && selectedConfig !== null && (
          <EditedSign isEdited={isEdited}>
            {isEdited ? "Edited" : "Unedited"}
          </EditedSign>
        )}
      </Row>
      <Row>
        <Button
          height={40}
          flex={true}
          disabled={selectedConfig === null || !isEdited}
          onClick={() => {
            setSaveModal({isOpen: true, configToEdit: selectedConfig});
          }}
        >
          Save changes
        </Button>
        <Button
          height={40}
          flex={true}
          disabled={selectedConfig === null || !isEdited}
          onClick={() => {
            dispatch(changeFilter(initialFilters));
            dispatch(setDepthConstraint(initialDepthConstraint));
            dispatch(setDrillDownBy(initialDrillDownBy));
          }}
        >
          Remove changes
        </Button>

        <Button
          height={40}
          onClick={() => {
            setSaveModal({isOpen: true, configToEdit: null});
          }}
          disabled={!isEdited && selectedConfig !== null}
          flex={true}
        >
          Save as new
        </Button>
        <Button
          height={40}
          disabled={selectedConfig === null}
          onClick={() => {
            setConfirmDeleteModalOpen(true);
          }}
          flex={true}
        >
          Delete configuration
        </Button>
      </Row>
    </Container>
  );
}
