import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../UI/Button/CustomButtonGreen";
import { ApplyFiltersContainer } from "../HeatMapControlStyles";
import { fetchSignals } from "../../../../redux/slices/heatMap";
import { useFiltersIsEdited } from "../hooks/useIsEdited";

export default function ApplyFiltersButton() {
  const dispatch = useDispatch();

  const isEdited = useFiltersIsEdited();

  return (
    <ApplyFiltersContainer>
      <Button
        disabled={!isEdited}
        fullWidth
        onClick={() => dispatch(fetchSignals())}
      >
        Apply Filters
      </Button>
    </ApplyFiltersContainer>
  );
}
