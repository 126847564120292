import { useMemo } from "react";
import { useSelector } from "react-redux";

const DEFAULT_COLUMNS = 6;
const DEFAULT_ROWS = 6;
const FULLSCREEN_ROWS = 50;

const getNumberOfMinimisedCharts = (allCharts, fullScreenChart) => {
  return allCharts.filter((chart) => chart.i !== fullScreenChart).length;
};
const getNumberOfColumns = (numberOfMinimisedCharts) => {
  //Minimimum columns is 6, increase num of columns (reduce column size) to fit in more tabs if needed
  return Math.max(numberOfMinimisedCharts, 6);
};
const getFullScreenParameters = (
  id,
  numberOfColumns,
  numberOfMinimisedCharts
) => {
  return {
    i: id,
    x: 0,
    y: numberOfMinimisedCharts ? 1 : 0,
    w: numberOfColumns,
    h: numberOfMinimisedCharts ? 39 : 40,
    isResizable: false,
    isDraggable: false,
  };
};
const getMinimisedParameters = (id, minimisedTabId) => {
  return {
    i: id,
    x: minimisedTabId,
    y: 0,
    w: 1,
    h: 1,
    isResizable: false,
    isDraggable: false,
  };
};
const getPreviewParameters = (numberOfColumns) => {
  return {
    i: "preview",
    x: 0,
    y: 2,
    w: numberOfColumns,
    h: 10,
    isResizable: false,
    isDraggable: false,
  };
};
const generateFullScreenLayout = (autoLayout, fullScreenChart) => {
  const numberOfMinimisedCharts = getNumberOfMinimisedCharts(
    autoLayout,
    fullScreenChart
  );
  const numberOfColumns = getNumberOfColumns(numberOfMinimisedCharts);

  let minimisedTabId = -1;

  const newLayout = autoLayout.map((chart) => {
    if (chart.i === fullScreenChart)
      return getFullScreenParameters(
        chart.i,
        numberOfColumns,
        numberOfMinimisedCharts
      );
    else {
      minimisedTabId++;
      return getMinimisedParameters(chart.i, minimisedTabId);
    }
  });
  newLayout.push(getPreviewParameters(numberOfColumns));

  return [newLayout, numberOfColumns];
};

export const useFullScreenLayoutGenerator = ({ autoLayout }) => {
  const fullScreenChart = useSelector(
    ({ chartLayout }) => chartLayout.fullScreenChart
  );

  return useMemo(() => {
    if (fullScreenChart) {
      const [newLayout, newNumColumns] = generateFullScreenLayout(
        autoLayout,
        fullScreenChart
      );
      return [newLayout, newNumColumns, FULLSCREEN_ROWS];
    }
    //else return auto layout
    return [autoLayout, DEFAULT_COLUMNS, DEFAULT_ROWS];
  }, [fullScreenChart, autoLayout]);
};
