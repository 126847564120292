import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectFilteredDPatterns } from "../../../../redux/slices/detectedPatterns";

export const useDetectedPatternData = () => {
  const DPatterns = useSelector(selectFilteredDPatterns);

  const detectedPatterns = useMemo(
    () => DPatterns.sort((a, b) => b.confidence - a.confidence),
    [DPatterns]
  );

  return detectedPatterns;
};
