export default class LocalStorage {
  static addChart(chart, collection, interval, id, open) {
    const newChart = { chart, collection, interval, id, open };
    const currentCharts = JSON.parse(localStorage.getItem("savedCharts")) || [];
    const chartAlreadyExists = currentCharts.some((chart) => chart.id === id);
    if (!chartAlreadyExists) currentCharts.push(newChart);
    localStorage.setItem("savedCharts", JSON.stringify(currentCharts));
  }
  static removeChart(id) {
    const currentCharts = JSON.parse(localStorage.getItem("savedCharts")) || [];
    const newCharts = currentCharts.filter((chart) => chart.id !== id);
    localStorage.setItem("savedCharts", JSON.stringify(newCharts));
  }
  static resetCharts() {
    localStorage.setItem("savedCharts", JSON.stringify([]));
  }
  static getCharts() {
    return JSON.parse(localStorage.getItem("savedCharts")) || [];
  }
  static setCustomHeatMapConfigurations(configs) {
    localStorage.setItem("heatMapCustomConfigs", JSON.stringify(configs));
  }
  static getCustomHeatMapConfigurations() {
    const configs = JSON.parse(localStorage.getItem("heatMapCustomConfigs"));
    if (!configs) return [];
    return configs;
  }
  static setCustomLayersFile(customLayersFile) {
    localStorage.setItem("customLayersFile", JSON.stringify(customLayersFile));
  }
  static getCustomLayersFile() {
    return JSON.parse(localStorage.getItem("customLayersFile"));
  }
  static removeCustomLayersFile() {
    return localStorage.removeItem("customLayersFile");
  }
  static getCustomCollections() {
    return JSON.parse(localStorage.getItem("customCollections"));
  }
  static setCustomCollections(customCollections) {
    localStorage.setItem(
      "customCollections",
      JSON.stringify(customCollections)
    );
  }
  static getFavouritedHeatMapSignals() {
    return JSON.parse(localStorage.getItem("favouritedHeatMapSignals"));
  }
  static setFavouritedHeatMapSignals(favouritedHeatMapSignals) {
    localStorage.setItem(
      "favouritedHeatMapSignals",
      JSON.stringify(favouritedHeatMapSignals)
    );
  }
  static getDrillDownConstraint() {
    return JSON.parse(localStorage.getItem("drillDownConstraint"));
  }
  static setDrillDownConstraint(drillDownConstraint) {
    localStorage.setItem(
      "drillDownConstraint",
      JSON.stringify(drillDownConstraint)
    );
  }
  static getBookmarks() {
    return JSON.parse(localStorage.getItem("bookmarks"));
  }
  static setBookmarks(bookmarks) {
    localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
  }
}
