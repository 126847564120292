import styled from "styled-components";

export const PatternLine = styled.div`
  height: 1px;
  background-color: rgb(255, 255, 255);
  line-height: 1px;
  position: absolute;
  z-index: 10;
  left: ${(props) => props.line.centerX}px;
  top: ${(props) => props.marginTop + props.line.centerY}px;
  width: ${(props) => props.line.length}px;
  transform: rotate(${(props) => props.line.angle}deg);
`;
