import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IntervalContainer } from "./IntervalSelectStyle";
import IntervalButton from "./IntervalButton";
import { setSelectedInterval } from "../../../../redux/slices/leftPanel";

export default function IntervalSelect() {
  const dispatch = useDispatch();

  const selectedInterval = useSelector(({ leftPanel }) => leftPanel.selectedInterval);

  const selectedCollection = useSelector(
    ({ leftPanel }) => leftPanel.selectedCollection
  );

  if (selectedCollection) {
    if (!selectedInterval || !selectedCollection.intervals.includes(selectedInterval)) {
      dispatch(setSelectedInterval(selectedCollection.intervals[0]));
    }
  }

  return (
    <IntervalContainer>
      {selectedCollection && selectedCollection.intervals.map((interval, index) => (
        <IntervalButton
          key={index}
          selected={selectedInterval === interval}
          interval={interval}
        />
      ))}
    </IntervalContainer>
  );
}
