import { useEffect, useMemo, useRef } from "react";
import { useSPatternsByChart } from "../../../../GlobalHooks/useSPatternsByChart";

///Hook
export const useDrawS = ({ drawShapes, newVisRange, chartId }) => {
  const visibleSPatterns = useRef([]);

  const storeSPatterns = useSPatternsByChart(chartId);

  const SPatterns = useMemo(() => {
    return storeSPatterns ? Object.values(storeSPatterns) : [];
  }, [storeSPatterns]);

  //Selected  patterns
  useEffect(() => {
    visibleSPatterns.current = drawShapes({
      prevPatterns: visibleSPatterns.current,
      patterns: SPatterns,
      color: "#39ba1f",
    });
  }, [SPatterns, newVisRange, drawShapes]);
};
