import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";

import { StyledCloseButton } from "./ButtonStyles";

import { removeChartData } from "../../../../../../../redux/slices/chartData";
import { clearDetectedPatternsSingleChart } from "../../../../../../../redux/slices/detectedPatterns";
import { clearSelectedPatternsSingleChart } from "../../../../../../../redux/slices/selectedPatterns";
import { clearSelectedDetectedPatternsSingleChart } from "../../../../../../../redux/slices/selectedDetectedPatterns";
import { clearIndicatorsSingleChart } from "../../../../../../../redux/slices/indicators";
import { removeChartIdFromSelected } from "../../../../../../../redux/slices/leftPanel";

export default function CloseButton({ chartId }) {
  const dispatch = useDispatch();

  return (
    <StyledCloseButton
      onClick={() => {
        if (chartId) {
          dispatch(removeChartData({ id: chartId }));
          dispatch(clearDetectedPatternsSingleChart(chartId));
          dispatch(clearSelectedPatternsSingleChart(chartId));
          dispatch(clearSelectedDetectedPatternsSingleChart(chartId));
          dispatch(clearIndicatorsSingleChart(chartId));
          dispatch(removeChartIdFromSelected(chartId));
        }
      }}
      size="small"
    >
      <CloseIcon />
    </StyledCloseButton>
  );
}
