import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(38, 51, 64);
  border-radius: 10px;
  border: 2px solid ${(props) => props.color};
  box-shadow: ${(props) => (props.selected ? "  0 0 5px 3px #fff" : "")};
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height}px;
  justify-content: center;
  margin: 10px;
  min-height: ${(props) => props.height}px;
  min-width: ${(props) => props.width}px;
  position: relative;
  width: ${(props) => props.width}px;
`;
export const ChartContainer = styled.div`
  height: ${(props) => props.height}px;
  overflow: hidden;
  position: relative;
  width: ${(props) => props.width}px;
`;

export const PatternBorder = styled.div`
  position: absolute;
  z-index: 3;
  width: ${(props) => props.line.x2 - props.line.x1}px;
  height: 70%;
  left: ${(props) => props.line.x1}px;
  top: 20%;
  border: 2px dashed rgba(162,162,248,.63);
  border-radius: 5px;
`;

export const EntryLine = styled.div`
  height: 3px;
  background-color: violet;
  line-height: 1px;
  position: absolute;
  z-index: 10;
  left: ${({ x }) => x}px;
  top: ${({ y, marginTop }) => marginTop + y}px;
  width: 20px;
`;
export const ChartInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  position: absolute;
  top: 2px;
  left: 5px;
  z-index: 10;
  p {
    margin: 1px 0;
  }
`;
export const DeleteContainer = styled.div`
  bottom: 0;
  padding: 5px;
  position: absolute !important;
  right: 0;
  z-index: 5;
`;
export const IndicatorArrow = styled.div`
  border-bottom: ${(props) =>
    props.direction === "up" ? "10px solid lime" : ""};
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: ${(props) =>
    props.direction === "down" ? "10px solid red" : ""};
  height: 0;
  position: absolute;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;
  transform: translate(
    -10px,
    ${(props) => (props.direction === "down" ? "-12px" : "-4px")}
  );
  width: 0;
  z-index: 10;
`;

export const SpinnerContainer = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;
