import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { animateScroll } from "react-scroll";
import { FormControlLabel } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import { ListItem } from "../AddOrEditCustomCollectionStyles";
import {
  Title,
  TopContainer,
  BottomContainer,
  SelectedChartsContainer,
  Container,
} from "./ManageCustomCollectionStyles";
import SaveCustomCollection from "./SaveCustomCollection/SaveCustomCollection";
import CustomCollectionOptions from "./CustomCollectionOptions/CustomCollectionOptions";
import { minutesToStrRepr } from "../../../../utils/utils";
import Checkbox from "../../../UI/Checkbox/Checkbox";

export default function ManageCustomCollection({
  mode,
  selectedCharts,
  setSelectedCharts,
  name,
  setName,
  isCommon,
  setIsCommon,
  saveCustomCollection,
  deleteCustomCollection,
}) {
  const removeSelectedChart = (chartToRemove) =>
    setSelectedCharts((prev) =>
      prev.filter((selectedChart) => selectedChart.id !== chartToRemove.id)
    );

  // useRef hooks
  const prevSelectedChartsLength = useRef(null);

  // Scroll to bottom if a new chart is selected
  useEffect(() => {
    if (prevSelectedChartsLength.current < selectedCharts.length) {
      animateScroll.scrollToBottom({
        containerId: "add-custom-collection__selected-charts-container",
        duration: 200,
      });
    }
    prevSelectedChartsLength.current = selectedCharts.length;
  }, [selectedCharts]);

  const isAdmin = useSelector(({ login }) => login.isUserAdmin);

  return (
    <Container>
      <TopContainer>
        <Title>Custom Security List</Title>
        {!!selectedCharts.length && (
          <Title>{`${selectedCharts.length} charts selected`}</Title>
        )}
      </TopContainer>
      <SelectedChartsContainer id="add-custom-collection__selected-charts-container">
        {selectedCharts.map((chart, index) => (
          <ListItem key={index} onClick={() => removeSelectedChart(chart)}>{`${
            chart.name
          } - ${minutesToStrRepr(chart.interval)}`}</ListItem>
        ))}
      </SelectedChartsContainer>
      <BottomContainer>
        <SaveCustomCollection name={name} setName={setName} />

        {isAdmin && (
          <Tooltip title="Is the collection visible to other users">
            <FormControlLabel
              label="Common"
              control={
                <Checkbox
                  checked={isCommon}
                  onChange={(e) => {
                    setIsCommon(e.target.checked);
                  }}
                />
              }
            />
          </Tooltip>
        )}

        <CustomCollectionOptions
          mode={mode}
          saveCustomCollection={saveCustomCollection}
          setSelectedCharts={setSelectedCharts}
          name={name}
          selectedCharts={selectedCharts}
          deleteCustomCollection={deleteCustomCollection}
        />
      </BottomContainer>
    </Container>
  );
}
