import styled from "styled-components";

export const StyledGridControls = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: space-between;
  position: relative;
`;

export const CloseIconContainer = styled.div`
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 50px;
`;
