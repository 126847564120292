import styled from "styled-components";

export const ColdStartContainer = styled.div`
  background: linear-gradient(
    135deg,
    rgba(3, 21, 31, 1) 56%,
    rgba(24, 67, 89, 0.35) 100%
  );
  flex-grow: 1;
  position: relative;
  max-width: calc(100vw - 380px);
  min-width: 580px;
  height: 100%;
`;
export const MainContainer = styled.div`
  height: 80%;
  box-sizing: border-box;
  padding: 50px 50px 25px 50px;
`;
