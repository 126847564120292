import React from "react";
import { Container } from "./CustomCollectionOptionsStyles";
import Button from "../../../../UI/Button/CustomButtonWhite";


export default function CustomCollectionOptions({
  mode,
  setSelectedCharts,
  selectedCharts,
  name,
  saveCustomCollection,
  deleteCustomCollection,
}) {
  // Handler functions
  const removeAll = () => setSelectedCharts([]);

  return (
    <Container>
      <Button
        onClick={saveCustomCollection}
        disabled={(mode === "add" && !name.trim()) || !selectedCharts.length}
      >
        Save
      </Button>
      <Button onClick={removeAll} disabled={!selectedCharts.length}>
        Remove all
      </Button>
      {mode === "edit" && (
        <Button onClick={deleteCustomCollection}>Delete</Button>
      )}
    </Container>
  );
}
