import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  ListContainer,
  ListItem,
  ButtonsContainer,
  LoadingAnimationContainer,
  WarningContainer,
  WarningMessage,
} from "./SandwichFilterStyles";
import Button from "../../../../../UI/Button/CustomButtonWhite";
import LoadingAnimation from "../../../../../LeftPanel/LoadingAnimation/LoadingAnimation";
import { selectLayerFilter } from "../../../../../../redux/slices/heatMap";

export default ({ open, layer }) => {
  const { filterValuesByLayers, filters, filterValues } = useSelector(
    ({ heatMap }) => heatMap
  );

  const dispatch = useDispatch();

  return (
    <Container open={open}>
      {filterValuesByLayers ? (
        filterValuesByLayers[layer] ? (
          <ListContainer>
            {filterValuesByLayers[layer].map((filterValue, index) => {
              let filterCollection;

              if (filterValues.tickerFields.includes(layer)) {
                filterCollection = filters.ticker[layer] || [];
              } else {
                filterCollection = filters[layer];
              }

              const selected = filterCollection.includes(filterValue.value);

              return (
                <ListItem
                  key={index}
                  selected={selected}
                  onClick={() =>
                    dispatch(selectLayerFilter([filterValue], selected, layer))
                  }
                >
                  {filterValue.label}
                </ListItem>
              );
            })}
          </ListContainer>
        ) : (
          <WarningContainer>
            <WarningMessage>Dynamic filtering</WarningMessage>
            <WarningMessage>
              Set valid filters for higher layers first
            </WarningMessage>
          </WarningContainer>
        )
      ) : (
        <LoadingAnimationContainer>
          <LoadingAnimation />
        </LoadingAnimationContainer>
      )}

      {filterValuesByLayers[layer]?.length && (
        <ButtonsContainer>
          <Button
            onClick={() => {
              dispatch(selectLayerFilter(filterValuesByLayers[layer], false, layer)); 
            }}
          >
            Select all
          </Button>
          <Button
            onClick={() => {
              dispatch(selectLayerFilter(filterValuesByLayers[layer], true, layer));
            }}
          >
            Unselect all
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  );
};
