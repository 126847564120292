import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChartVisibleRange } from "../../../../../redux/slices/heatMapChart";

export const useVisibleRangeController = ({ chart, newVisRange }) => {
  const dispatch = useDispatch();
  const storeRange = useSelector(({ heatMapChart }) => heatMapChart.range);
  const visibleRangeExternallyControlled = useRef(false);

  // ─── RESPOND TO NAVIGATION CHANGE ───────────────────────────────────────────────
  // useNavigation(chart);

  //
  // ─── RESPOND TO EXTERNAL VISIBLE RANGE CONTROLS ─────────────────────────────────
  //

  useEffect(() => {
    if (!chart.ready || !storeRange) return;

    const respondToPreview = storeRange.source === "preview";
    const respondToLink = storeRange.source === "link";

    if (respondToPreview || respondToLink) {
      visibleRangeExternallyControlled.current = true; //we set this so that we don't emit the new range
      setTimeout(() => chart.setRange(storeRange), 100);
    }
  }, [storeRange, chart]);

  // ────────────────────────────────────────────────────────────────────────────────

  //
  // ─── VISIBLE RANGE EMITTER ──────────────────────────────────────────────────────
  //

  // Emit visible range to other components e.g. preview panel
  const prevNewVisRange = useRef(null);
  const prevTo = useRef(null);
  useEffect(() => {
    if (!chart.ready) return;
    //only emit if the visible range has changed. i.e. ignore when the active chart changes
    if (newVisRange === prevNewVisRange.current) return;
    prevNewVisRange.current = newVisRange;

    if (visibleRangeExternallyControlled.current) {
      //no need to emit the range
      visibleRangeExternallyControlled.current = false;
      return;
    }
    const currentRange = chart.getRange();

    if (!currentRange.to) {
      currentRange.to = prevTo.current;
    } else {
      prevTo.current = currentRange.to;
    }

    const payload = { ...currentRange, source: chart };
    dispatch(setChartVisibleRange(payload));
  }, [dispatch, chart, newVisRange]);

  // ────────────────────────────────────────────────────────────────────────────────
};
