const findNearestDepthConstraint = (newPosition, openFilter, drillDownBy) => {
  const openIndex = drillDownBy.findIndex((layer) => layer === openFilter);

  const beforeOpenLayerPositions = drillDownBy.map(
    (layer, index) => 3 + 34 * index
  );

  const afterOpenLayerPositions = beforeOpenLayerPositions.map(
    (position, index) =>
      index > openIndex && openIndex > -1 ? position + 300 : position
  );

  const distances = afterOpenLayerPositions.map((position) =>
    Math.abs(newPosition - position)
  );

  const minDistanceIndex = distances.reduce(
    (acc, distance, index) =>
      distance < acc.value
        ? {
            value: distance,
            index,
          }
        : acc,
    {
      value: Infinity,
      index: null,
    }
  ).index;

  return minDistanceIndex + 1;
};

export default findNearestDepthConstraint;
