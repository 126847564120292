import styled, { css } from "styled-components";

export const MenuButtonBurger = styled.div`
  background: ${(props) => (props.open ? "transparent" : "white")};
  border-radius: 5px;
  box-shadow: ${(props) =>
    props.open ? "none" : "0 2px 5px rgba(255, 101, 47, 0.2)"};
  height: 2px;
  transition: all 0.3s ease-in-out;
  width: 30px;
  &::before,
  &::after {
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    content: "";
    height: 2px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 30px;
  }
  &::before {
    transform: translateY(-8px);
    ${(props) =>
      props.open &&
      css`
        transform: rotate(-45deg);
      `}
  }
  &::after {
    transform: translateY(8px);
    ${(props) =>
      props.open &&
      css`
        transform: rotate(45deg);
      `}
  }
`;

export const MenuButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-right: 30px;
  height: 40px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 270px;
`;
