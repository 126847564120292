import React, { useCallback, useRef, useState, useEffect } from "react";
import GridLayout from "react-grid-layout";
import { useDispatch } from "react-redux";
import { Container } from "./LayoutManagerStyles";
import {
  lockAndUpdate,
  setResizing,
} from "../../../../redux/slices/chartLayout";
import { useContainerHeightAndWidth } from "../../../GlobalHooks/useContainerHeightAndWidth";
import { useLayout } from "../../hooks/useLayout";

export default function LayoutManager({ show, children, containerId }) {
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const [containerHeight, containerWidth] = useContainerHeightAndWidth(
    "#" + containerId,
    show
  );
  useEffect(() => {
    if (show && containerHeight > 0) {
      setDimensions({ height: containerHeight, width: containerWidth });
    }
  }, [containerHeight, containerWidth, show]);

  const mostRecentLayout = useRef([]);
  const { layout, numColumns, numRows } = useLayout({ mostRecentLayout });

  return (
    <Container>
      <GridLayout
        width={dimensions.width}
        cols={numColumns}
        rowHeight={dimensions.height / numRows}
        layout={layout}
        margin={[0, 0]}
        compactType={null}
        onLayoutChange={(layout) => {
          mostRecentLayout.current = layout;
        }}
        onResizeStart={useCallback(() => dispatch(setResizing(true)), [
          dispatch,
        ])}
        onResizeStop={useCallback(
          (layout, oldItem) => {
            mostRecentLayout.current = layout;
            dispatch(setResizing(false));
            dispatch(lockAndUpdate(oldItem.i));
          },
          [dispatch]
        )}
        onDragStop={useCallback(
          (layout, oldItem) => {
            mostRecentLayout.current = layout;
            dispatch(lockAndUpdate(oldItem.i));
          },
          [dispatch]
        )}
      >
        {children}
      </GridLayout>
    </Container>
  );
}
