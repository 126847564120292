import { useMemo } from "react";

function useGetEntryLine(
  filteredBars,
  entryPointShape,
  height,
  width,
) {
  return useMemo(() => {
    if (!filteredBars || !entryPointShape) return;
    const entryPointPoints = entryPointShape.points;

    const maxHeight = subtractMargins(height);
    const xRange = filteredBars.length - 1;

    const [maxY, minY] = getMaxMinPrice(filteredBars);
    const yRange = maxY - minY;

    const centerX = getLeftPixels(findIndex(entryPointPoints[0].datetime));
    const centerY = getTopPixels(entryPointPoints[0].price);

    return { centerX, centerY };

    // *************************************

    function getLeftPixels(x) {
      return (width * x) / xRange;
    }

    function getTopPixels(y) {
      return (maxHeight * (maxY - y)) / yRange;
    }

    function findIndex(time) {
      const convertedTime = time / 1000; //need to adjust timestamps to be compatible with the bars
      return filteredBars.findIndex((bar) => bar.time === convertedTime);
    }
  }, [filteredBars, entryPointShape, height, width]);
}

// *************************************

const MARGIN_TOP = 0.2;
const MARGIN_BOTTOM = 0.1;

function subtractMargins(height) {
  return height * (1 - MARGIN_TOP - MARGIN_BOTTOM);
}

function getMaxMinPrice(bars) {
  let maxPrice = -Infinity;
  let minPrice = Infinity;
  for (let i = 0; i < bars.length; i++) {
    if (bars[i].high > maxPrice) maxPrice = bars[i].high;
    if (bars[i].low < minPrice) minPrice = bars[i].low;
  }
  return [maxPrice, minPrice];
}

export default useGetEntryLine;
