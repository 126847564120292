import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import CustomButton from "../../../../../UI/Button/CustomButton";
import {
  deleteBookmark,
  deleteConfig,
} from "../../../../../../redux/slices/heatMapConfigs";

function ConfirmDeleteModal({ configToDelete, onClose, configToDeleteIndex }) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const bookmarks = useSelector(
    ({ heatMapConfigs }) => heatMapConfigs.bookmarks
  );

  // useState hooks
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  // Handler functions
  const onSubmit = () => {
    if (!configToDelete) {
      return;
    }

    const onSuccess = () => {
      const bookmarkIndecesToDelete = bookmarks.reduce(
        (acc, bookmark, index) =>
          bookmark === configToDeleteIndex ? [...acc, index] : acc,
        []
      );

      bookmarkIndecesToDelete.forEach((indexToDelete) =>
        dispatch(deleteBookmark(indexToDelete))
      );

      setSuccess(true);
    };

    const onError = (errMsg) => {
      setSuccess(false);
      setError(errMsg);
    };

    dispatch(deleteConfig(configToDelete, onSuccess, onError));
  };

  return (
    <Dialog open={true} onClose={onClose}>
      {!success && !error ? (
        <Fragment>
          <DialogTitle>Delete {configToDelete.name}</DialogTitle>
          <DialogActions>
            <CustomButton onClick={onSubmit} variant="outlined">
              Confirm
            </CustomButton>
            <CustomButton onClick={onClose} variant="outlined">
              Exit
            </CustomButton>
          </DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          <DialogTitle>
            {!error ? " Configuration deleted successfully " : error}
          </DialogTitle>
          <DialogActions>
            <CustomButton onClick={onClose} variant="outlined">
              Close
            </CustomButton>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}

export default ConfirmDeleteModal;
