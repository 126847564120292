import React from "react";
import { IconButton } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

export default function DeleteChartButton({ setChartToDelete, chart }) {
  return (
    <div style={{ position: "absolute", right: 5 }}>
      <IconButton
        size="small"
        color="primary"
        onClick={() => setChartToDelete(chart)}
      >
        <DeleteForeverIcon fontSize="small" />
      </IconButton>
    </div>
  );
}
