const isHeatMapInvalid = (node) => {
  let invalid = false;
  if ((node.x1 - node.x0 <= 10 || node.y1 - node.y0 <= 10) && node.data.value) {
    return true;
  }
  if (node.children) {
    invalid = node.children
      .map((childNode) => isHeatMapInvalid(childNode))
      .reduce((el, acc) => (el || acc ? true : false), false);
  }
  return invalid;
};

export default isHeatMapInvalid;
