import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
`;

export const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: 20px;
  position: relative;
  width: 75px;
`;

export const Text = styled.p`
  color: white;
  font-size: 12px;
  font-weight: 200;
  margin: 0 10px;
`;

export const NumericValue = styled.p`
  bottom: -3px;
  color: white;
  font-size: 8px;
  font-weight: 200;
  left: ${({ left }) => left + "px"};
  margin: 0;
  position: absolute;
`;
