// Function to determine whether the clicked chart is above or below all selected charts in the filtered charts array
// If so, determine the selection range for multiple chart selection

const findNewSelectionRange = (chart, filteredCharts, selectedCharts) => {
  if (!selectedCharts.length) return null;
  const chartIndex = filteredCharts.findIndex(
    (filteredChart) => filteredChart.id === chart.id
  );

  const [minSelectedIndex, maxSelectedIndex] = selectedCharts
    .map((selectedChart) =>
      filteredCharts.findIndex(
        (filteredChart) => filteredChart.id === selectedChart.id
      )
    )
    .reduce(
      (acc, selectedChartIndex) => {
        let min = acc[0];
        let max = acc[1];

        if (selectedChartIndex < min) min = selectedChartIndex;
        if (selectedChartIndex > max) max = selectedChartIndex;

        return [min, max];
      },
      [Infinity, 0]
    );

  if (chartIndex < minSelectedIndex) return [chartIndex, minSelectedIndex - 1];
  else if (chartIndex > maxSelectedIndex)
    return [maxSelectedIndex + 1, chartIndex];
  else return null;
};

export default findNewSelectionRange;
