import React, { memo } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import {
  ChartContainer,
  PatternBorder,
  TradingViewContainer,
  ChartInfo,
} from "./GridChartStyles";
import { useGetPatternData } from "./hooks/useGetPatternData";
import { useGetFilterBars } from "./hooks/useGetFilterBars";
import { useSetupChart } from "./hooks/useSetupChart";
import { useDimensions } from "./hooks/useDimensions";
import LinkButton from "./LinkButton/LinkButton";
import PatternLines from "./PatternLines/PatternLines";

export default memo(function GridChart({
  type,
  pattern,
  height,
  width,
  onClick,
}) {
  const showLines = useSelector(({ grid }) => grid.showLines);

  const { patternRange, patterns } = useGetPatternData(pattern.shapes);

  const filteredBars = useGetFilterBars(patternRange, pattern.chartId);

  const dimensions = useDimensions(filteredBars, height, width);

  useSetupChart({ filteredBars, id: type + pattern.id, height, width });

  const isSameYear =
    moment(patternRange.start).format("YYYY") ===
    moment(patternRange.end).format("YYYY");
  const formatString = isSameYear ? "Do MMM" : "Do MMM YYYY";

  const startDate = moment(patternRange.start).format(formatString);
  const endDate = moment(patternRange.end).format("Do MMM YYYY");

  return (
    <ChartContainer
      height={height}
      width={width}
      onClick={() => onClick(pattern)}
    >
      <ChartInfo>
        <p>
          {pattern.chartName} - {pattern.interval} - {pattern.model}
        </p>
        <p>
          {startDate} - {endDate}
        </p>
      </ChartInfo>

      <TradingViewContainer id={type + pattern.id} />

      <LinkButton chartId={pattern.chartId} filteredBars={filteredBars} />

      <PatternBorder selected={type === "selected"} />

      {showLines && (
        <PatternLines
          filteredBars={filteredBars}
          patterns={patterns}
          dimensions={dimensions}
        />
      )}
    </ChartContainer>
  );
});
