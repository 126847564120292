import { useState, useCallback } from "react";

import { useWindowResize } from "./useWindowResize";

export const useContainerHeightAndWidth = (selector, ...triggers) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  //Set the height of the list to be equal to the height of the container
  const updateHeightandWidth = useCallback(() => {
    document.querySelector(selector) &&
      setHeight(document.querySelector(selector).offsetHeight - 37);
    document.querySelector(selector) &&
      setWidth(document.querySelector(selector).offsetWidth - 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, ...triggers]);
  useWindowResize(updateHeightandWidth);
  return [height, width];
};
