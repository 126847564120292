import React from "react";
import { Container, Tab } from "./HeatMapTabSelectStyles";

export default function TabSelect({ selected, setSelected }) {
  const tabData = ["Favourites", "Selected", "All"];

  return (
    <Container>
      {tabData.map((tabInfo, index) => (
        <Tab
          key={index}
          selected={selected === index}
          onClick={() => setSelected(index)}
          width={100 / tabData.length + "%"}
        >
          {tabInfo}
        </Tab>
      ))}
    </Container>
  );
}
