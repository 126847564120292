import React from "react";
import { Container } from "./ChartInterfaceToolbarStyles";
import ChartSwitches from "./ChartSwitches/ChartSwitches";
import Buttons from "./Buttons/Buttons";

export default function ChartInterfaceToolbar() {
  return (
    <Container>
      <ChartSwitches />
      <Buttons />
    </Container>
  );
}
