import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import AnimatedMessage from "../../../../UI/AnimatedMessage/AnimatedMessage";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

const useStatusFieldValue = () => {
  // Select from redux store
  const signalsCount = useSelector(({ heatMap }) => heatMap.signalsCount);

  if (signalsCount === null) return <AnimatedMessage>Loading</AnimatedMessage>;

  return (
    <Fragment>
      <p> {`Total number of signals: ${signalsCount}`}</p>
    </Fragment>
  );
};

export default useStatusFieldValue;
