import { createSlice } from "@reduxjs/toolkit";

const previewChartSlice = createSlice({
  name: "previewChart",
  initialState: {
    range: null,
  },
  reducers: {
    setChartVisibleRange: (state, action) => {
      state.range = action.payload;
    },
  },
});

export const { setChartVisibleRange } = previewChartSlice.actions;

export default previewChartSlice.reducer;
