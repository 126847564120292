import styled from "styled-components";

export const Container = styled.div`
  height: ${({ height }) => height + "px"};
  margin-right: 10px;
  padding: 0;
  position: relative;
  transition: height
    ${({ transitioningHeight }) => (transitioningHeight ? "0.2s" : "0s")} linear;
`;

export const SelectedLine = styled.div`
  background-color: #1c84ff;
  height: ${({ height }) => height + 3 + "px"};
  left: 8px;
  position: absolute;
  top: 0;
  width: 1px;

  transition: height
    ${({ transitioningHeight }) => (transitioningHeight ? "0.2s" : "0s")} linear;

  z-index: 1;
`;

export const UnselectedLine = styled.div`
  background-color: white;
  bottom: 0;
  height: 100%;
  left: 8px;
  position: absolute;
  width: 1px;
`;

export const Label = styled.p`
  align-self: flex-end;
  font-size: 12px;
  margin: 5px 0 19px;
  transform: translateY: -42px;
  width: 28px;
  white-space: nowrap
`;
