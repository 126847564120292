import React from "react";
import { useSelector } from "react-redux";

import { StyledColdStartControls } from "./ControlsStyle";

import ResetButton from "./Buttons/ResetButton";
import CloneButton from "./Buttons/CloneButton";
import SaveButton from "./Buttons/SaveButton";
import Message from "./Message/Message";
import Help from "./Help/Help";

export default function Controls() {
  const error = useSelector(({ coldStart }) => coldStart.error);

  return (
    <StyledColdStartControls>
      <Help />
      <ResetButton />
      <CloneButton disabled={error} />
      <SaveButton disabled={error} />
      <Message />
    </StyledColdStartControls>
  );
}
