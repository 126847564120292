import React from "react";

import { ColdStartContainer, MainContainer } from "./ColdStartStyles";

import DrawingArea from "./DrawingArea/DrawingArea";
import Header from "./Header/Header";
import SavedPatterns from "./SavedPatterns/SavedPatterns";

function ColdStart() {
  return (
    <ColdStartContainer>
      <Header />
      <MainContainer>
        <DrawingArea />
      </MainContainer>
      <SavedPatterns />
    </ColdStartContainer>
  );
}
export default ColdStart;
