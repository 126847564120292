import { useEffect } from "react";

export const useDrawingMenu = ({ chart }) => {
  useEffect(() => {
    if (!chart.ready) return;

    const menuOpen = chart.getDrawingMenuStatus();

    if (!menuOpen) {
      chart.toggleDrawingMenu();
    }
  }, [chart]);
};
