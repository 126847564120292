import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Utils } from "../../../../../utils/utils";
import { useDPatternsByChart } from "../../../../GlobalHooks/useDPatternsByChart";
import { useSDPatternsByChart } from "../../../../GlobalHooks/useSDPatternsByChart";

const BLUE = "rgba(150, 188, 250, 0.1)";
const ORANGE = "rgba(235, 173, 111, 0.1)";

///Helper Functions///
const patternStartInRange = (visibleFrom, visibleTo, patternStart) => {
  return patternStart > visibleFrom && patternStart < visibleTo;
};
const patternEndInRange = (visibleFrom, visibleTo, patternEnd) => {
  return patternEnd > visibleFrom && patternEnd < visibleTo;
};
const wholePatternInRange = (
  visibleFrom,
  visibleTo,
  patternStart,
  patternEnd
) => {
  return patternStart < visibleFrom && patternEnd > visibleTo;
};

const getYPosition = (pattern) => {
  //return the top of the pattern, with a 5% margin
  const [maxPrice, minPrice] = Utils.maxMin(
    pattern.shapes[0].points[0].price,
    pattern.shapes[0].points[1]
      ? pattern.shapes[0].points[1].price
      : pattern.shapes[0].points[0].price
  );
  const priceRange = maxPrice - minPrice;
  const topSpacing = priceRange * 0.05;
  return maxPrice + topSpacing;
};
const getXPosition = (pattern) => {
  //return the start of the pattern with a 25% margin
  //the position will represent the left edge of the text
  const [minTime, maxTime] = [
    pattern.shapes[0].points[0].time,
    pattern.shapes[0].points[1]
      ? pattern.shapes[0].points[1].time
      : pattern.shapes[0].points[0].time,
  ];
  const timeRange = maxTime - minTime;
  const leftSpacing = timeRange * 0.25;
  return minTime + leftSpacing;
};
const getPosition = (pattern) => {
  return [
    {
      price: getYPosition(pattern),
      time: getXPosition(pattern),
    },
  ];
};

const drawShape = (chart, shape, cfg) => {
  switch (shape.shape_type) {
    case "rectangle":
      return chart.drawRectangle({
        points: shape.points,
        cfg,
      });
    case "trend_line":
      return chart.drawLineWithIntersections({ points: shape.points, cfg });
    case "height":
      return chart.drawHeight({ points: shape.points, cfg });
    case "entry_point_lower":
      return chart.drawEntryPoint({
        points: shape.points,
        height: -cfg.height,
      });
    case "entry_point_higher":
      return chart.drawEntryPoint({ points: shape.points, height: cfg.height });
    case "context_line":
      return chart.drawContextLine({ points: shape.points, cfg });
    default:
      return [];
  }
};
const getPatternHeight = (pattern) => {
  const heightPattern = pattern.shapes.find(
    (shape) => shape.shape_type === "height"
  );
  const height = heightPattern
    ? Math.abs(heightPattern.points[0].price - heightPattern.points[1].price)
    : null;
  return height;
};

///Hook
export const usePatternDrawings = ({
  chart,
  visibleDPatterns,
  visibleSDPatterns,
  newVisRange,
}) => {
  // Select from redux store
  const showBoxes = useSelector(({ settings }) => settings.showBoxes);
  const showLines = useSelector(({ settings }) => settings.showLines);
  const DPatterns = useDPatternsByChart(chart.chartId);
  const SDPatterns = useSDPatternsByChart(chart.chartId);

  // useCallback hooks
  const removePreviousShapes = useCallback(
    (previousPatterns) => {
      if (!chart.ready) return;

      previousPatterns.forEach((pattern) => {
        chart.removeShape(pattern.entityID);
      });
    },
    [chart]
  );
  const patternIsNotInView = useCallback(
    (pattern) => {
      const { from, to } = chart.getRange();

      const patternStart = pattern.shapes[0].points[0].time;
      const patternEnd = pattern.shapes[0].points[1]
        ? pattern.shapes[0].points[1].time
        : pattern.shapes[0].points[0].time;

      if (patternStartInRange(from, to, patternStart)) {
        return false;
      }
      if (patternEndInRange(from, to, patternEnd)) {
        return false;
      }
      if (wholePatternInRange(from, to, patternStart, patternEnd)) {
        return false;
      }

      return true; //pattern is not in view
    },
    [chart]
  );

  const drawShapes = useCallback(
    ({ patterns, cfg }) => {
      if (!chart.ready || !patterns) return [];
      const visiblePatterns = [];

      patterns.forEach((pattern) => {
        if (pattern.selected || patternIsNotInView(pattern)) return;
        cfg.height = getPatternHeight(pattern);
        cfg.id = pattern.id;

        pattern.shapes.forEach((shape) => {
          const newShape = drawShape(chart, shape, cfg);
          visiblePatterns.push(...newShape);
        });

        visiblePatterns.push(
          chart.drawText({
            point: getPosition(pattern),
            text: pattern.model,
          })
        );
      });
      return visiblePatterns;
    },
    [chart, patternIsNotInView]
  );

  //NOTE: The chart can't draw outside the visible range, so we need to redraw every time the view changes
  //we will keep track of the current manual drawings and remove them on each render before redrawing

  //Detected patterns
  useEffect(() => {
    removePreviousShapes(visibleDPatterns.current);

    const cfg = { showBoxes, showLines, lock: true, color: BLUE };
    visibleDPatterns.current = drawShapes({ patterns: DPatterns, cfg });
  }, [
    DPatterns,
    showBoxes,
    showLines,
    visibleDPatterns,
    newVisRange,
    drawShapes,
    removePreviousShapes,
  ]);

  //Selected Detected patterns
  useEffect(() => {
    removePreviousShapes(visibleSDPatterns.current);

    const cfg = { showBoxes, showLines, lock: false, color: ORANGE };

    visibleSDPatterns.current = drawShapes({ patterns: SDPatterns, cfg });
  }, [
    SDPatterns,
    showBoxes,
    showLines,
    visibleSDPatterns,
    newVisRange,
    drawShapes,
    removePreviousShapes,
  ]);
};
