import styled from "styled-components";

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: calc(100vw - 380px);
  min-width: 580px;
`;
export const PatternsContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow-y: scroll;
`;
export const GridText = styled.div`
  color: ${(props) => props.theme.primary};
  font-weight: 600;
  margin-top: 50px;
  white-space: nowrap;
`;
export const PatternHeaderContainer = styled.div`
  text-align: center;
  width: 100%;
`;
export const PatternHeader = styled.h2`
  color: white;
`;
