import {
  zoomIn,
  addToOrRemoveFromHeatMapCharts,
  calculateMeanSignal,
  transformToPercentage,
  textOnClickHandler,
} from "./generalRenderHelperFunctions";
import isSpaceForText from "../../../isSpaceForText";

let render = require("../render");

export function nodeOnClickListeners(nodes) {
  // Add event listeners to leaf node tiles
  nodes
    .filter(
      (d) => (!d.data.aggregate || d.data.oneSignal) && d.data.signal_value
    )
    .style("cursor", "pointer")
    .selectAll("rect")
    .each(function (d) {
      d.rect = this;
    })
    .on("click", addToOrRemoveFromHeatMapCharts);

  // Add event listeners to non-leaf node tiles
  nodes
    .filter((d) => !d.children && d.data.aggregate && !d.data.oneSignal)
    .style("cursor", "pointer")
    .selectAll("rect")
    .each(function (d) {
      d.rect = this;
    })
    .on("click", zoomIn);
}

// Set text for leaf nodes
export function setLeafNodeText(nodes) {
  // Set text (Signal value)
  nodes
    .filter((d) => !d.children)
    .append("text")
    .attr("text-anchor", "middle")
    .attr(
      "y",
      (d) =>
        render.y(d.y1 - d.y0) / 2 +
        (d.data.aggregate && render.y(d.y1 - d.y0) > 40 ? -5 : 5)
    )
    .attr("x", (d) => render.x(d.x1 - d.x0) / 2)
    .attr("class", "label")
    .attr("fill", (d) => {
      const meanSignal = calculateMeanSignal(d);
      return isNaN(meanSignal) || d.data.invalid ? "white" : "black";
    })
    .text((d) => {
      if (render.y(d.y1 - d.y0) > 20 && render.x(d.x1 - d.x0) > 60) {
        if (d.data.signal_value) {
          return transformToPercentage(Number(d.data.signal_value));
        }
        const meanSignal = calculateMeanSignal(d);
        return `${meanSignal > 0 ? "+" : ""}${
          isNaN(meanSignal)
            ? meanSignal
            : transformToPercentage(Number(meanSignal))
        }`;
      }
    })
    .on("click", textOnClickHandler);

  // Set text (Number of securities or signals)
  const isSecuritiesRendered = render.drillDownBy
    .slice(0, render.depthConstraint)
    .includes("security");

  nodes
    .filter((d) => !d.children)
    .append("text")
    .attr("text-anchor", "middle")
    .attr("y", (d) => render.y(d.y1 - d.y0) / 2 + 15)
    .attr("x", (d) => render.x(d.x1 - d.x0) / 2)
    .attr("class", "label")
    .attr("fill", (d) => {
      const meanSignal = calculateMeanSignal(d);
      return isNaN(meanSignal) || d.data.invalid ? "white" : "black";
    })
    .text((d) => {
      if (
        render.y(d.y1 - d.y0) > 40 &&
        render.x(d.x1 - d.x0) > 60 &&
        d.data.aggregate
      ) {
        const numberOfSignals = d.data.numberOfValidSignals;
        return isSecuritiesRendered
          ? `${numberOfSignals} signal${numberOfSignals === 1 ? "" : "s"}`
          : `${d.data.value} securit${d.data.value === 1 ? "y" : "ies"}`;
      }
    })
    .on("click", textOnClickHandler);
}

// Set text for parent nodes
export function setParentNodeText(nodes) {
  nodes
    .filter((d) => d.children && isSpaceForText(d))
    .append("text")
    .attr("fill", (d) => {
      return (
        d.height % 2 === 0
          ? render.treeHeight % 2 !== 0
          : render.treeHeight % 2 === 0
      )
        ? "white"
        : "black";
    })
    .attr("clip-path", (d) => `url(#${d.clipId})`)
    .selectAll("tspan")
    .data((d) => [d.data.nameLabel])
    .join("tspan")
    .text((d) => d);

  // Set position for parent nodes' text
  nodes
    .filter((d) => d.children)
    .selectAll("tspan")
    .attr("dx", 5)
    .attr("y", 15);
}
