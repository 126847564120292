import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  background-color: #090b10;
  border-bottom: 1px solid #222;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 50px;
  min-width: 960px;
  justify-content: center;
  width: 100%;
`;

export const Spacer = styled.div`
  flex: 1;
`;
