import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { Api } from "../../api/api";
import { Utils, minutesToStrRepr } from "../../utils/utils";
import LocalStorage from "../../utils/localStorage";
import { addLayoutChart, removeLayoutChart } from "./chartLayout";

const chartDataAdapter = createEntityAdapter();

const initialState = chartDataAdapter.getInitialState({});

const chartDataSlice = createSlice({
  name: "chartData",
  initialState,
  reducers: {
    removeChartDataReducer: (state, action) => {
      chartDataAdapter.removeOne(state, action.payload);
    },
    addChartDataReducer: (state, action) => {
      chartDataAdapter.upsertOne(state, action.payload);
    },
    addMultipleChartDataReducer: (state, action) => {
      chartDataAdapter.upsertMany(state, action.payload);
    },
    removeAllChartData: (state) => {
      chartDataAdapter.setAll(state, []);
    },
  },
});

export const {
  removeChartDataReducer,
  addChartDataReducer,
  addMultipleChartDataReducer,
  removeAllChartData,
} = chartDataSlice.actions;

export const addChartData =
  (
    chart,
    collection = undefined,
    open = false
    //by default, we will get these values from the store (i.e. when manually selecting a chart)
    //when loading from local storage, these values need to be provided
  ) =>
  async (dispatch, getState) => {
    if (!collection) {
      collection = getState().leftPanel.selectedCollection;
    }

    // check if chart can be added to layout
    if (!dispatch(addLayoutChart(chart.id))) return;

    //dispatch empty chart so we can begin setting up the chart
    dispatch(
      addChartDataReducer({
        id: chart.id,
        chartData: {
          id: chart.id,
          name: chart.name,
          interval: chart.interval,
          intervalString: minutesToStrRepr(chart.interval),
          collection: collection.id,
        },
        bars: [],
        open,
      })
    );

    try {
      const { chartData, bars, previewBars } = await getData(chart.id);
      chartData.collection = collection.id;
      dispatch(
        addChartDataReducer({
          id: chart.id,
          chartData,
          bars,
          previewBars,
          open,
        })
      );
      LocalStorage.addChart(chart, collection, chart.interval, chart.id, open);
    } catch (error) {
      console.log(error);
    }
  };
export const addChartDataWithBars =
  ({ chartData, bars }) =>
  (dispatch) => {
    dispatch(
      addChartDataReducer({
        id: chartData.id,
        chartData,
        bars,
        previewBars: null,
      })
    );
    dispatch(addLayoutChart(chartData.id));
  };

export const removeChartData = (chart) => (dispatch) => {
  dispatch(removeLayoutChart(chart.id));
  dispatch(removeChartDataReducer(chart.id));
  LocalStorage.removeChart(chart.id);
};

export const getAllCharts = () => async (dispatch, getState) => {
  const charts = getState().leftPanel.chartListData;
  //if the list has too many charts, restrict the list so we dont open too many charts
  const restrictedList = Utils.restrictList({ list: charts, max: 9 });

  restrictedList.forEach((chart) => {
    dispatch(addChartData(chart));
  });
};

export const loadSavedCharts = () => async (dispatch) => {
  const savedCharts = LocalStorage.getCharts().length
    ? LocalStorage.getCharts()
    : [];

  savedCharts.forEach((chart) => {
    dispatch(
      addChartData(
        chart.chart,
        chart.collection,
        chart.interval,
        chart.id,
        chart.open
      )
    );
  });
};

export default chartDataSlice.reducer;

// ─── Selectors ──────────────────────────────────────────────────────────

export const {
  selectAll: selectAllChartData,
  selectById: selectChartDataById,
  selectIds: selectChartDataIds,
  selectTotal: selectTotalCharts,
} = chartDataAdapter.getSelectors((state) => state.chartData);

export const selectOpenedCharts = createSelector(
  [selectAllChartData],
  (charts) => {
    return charts.filter((chart) => chart.open);
  }
);

//
// ─── UTILITY FUNCTIONS ──────────────────────────────────────────────────────────
//


const getData = async (id) => {
  try {
    const { chartData, bars } = await Api.getChart(id, {});
    return { chartData, bars };
  } catch (error) {
    console.log(error);
    return;
  }
};
