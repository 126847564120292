import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  editSDPattern,
  deselectPattern,
} from "../../../../../redux/slices/selectedDetectedPatterns";
import { selectPattern } from "../../../../../redux/slices/detectedPatterns";
import { addShape } from "../../../../../redux/slices/selectedPatterns";

const checkPatternInRange = (min, max, position) => {
  if (min < position && max > position) return true;
};
const getMaxMin = (point1, point2) => {
  if (point1 > point2) return [point1, point2];
  return [point2, point1];
};

export const useOnClick = ({
  chart,
  newSelection,
  visibleDPatterns,
  visibleSDPatterns,
  mousePosition,
}) => {
  const dispatch = useDispatch();

  const prevNewSelection = useRef(null);

  useEffect(() => {
    //
    // ─── FUNCTION DECLARATIONS ───────────────────────────────────────
    //
    const handleSDPatternSelection = (SDPatternSelected, entityId) => {
      dispatch(
        editSDPattern({
          id: SDPatternSelected.patternID,
          points: chart.getPoints(entityId),
        })
      );
    };

    const checkIfSDPatternSelected = (entityId) => {
      return visibleSDPatterns.current.find(
        (pattern) => pattern.entityID === entityId
      );
    };
    const isShapeRectangle = (entityId) => {
      let allShapes = chart.getAllShapes();
      const shapeType = allShapes.find((shape) => shape.id === entityId).name;
      return shapeType === "rectangle";
    };

    const handleSPatternSelection = (entityId) => {
      const shapeIsRectangle = isShapeRectangle(entityId);
      if (!shapeIsRectangle) return;
      const payload = {
        points: chart
          .getPoints(entityId)
          .sort((a, b) => (a.time < b.time ? -1 : 1)),
        id: entityId,
        shape_type: "rectangle",
      };
      dispatch(
        addShape({
          chartId: chart.chartId,
          chartName: chart.chartName,
          shapeToAdd: payload,
        })
      );
    };

    const handlePatternChange = (entityId) => {
      try {
        //check for SDP selection
        const SDPatternSelected = checkIfSDPatternSelected(entityId);
        if (SDPatternSelected) {
          handleSDPatternSelection(SDPatternSelected, entityId);
          return;
        }

        handleSPatternSelection(entityId);
      } catch (e) {}
    };

    const checkIfPatternSelected = (patterns) => {
      for (let i = 0; i < patterns.length; i++) {
        const pattern = patterns[i];
        if (!pattern.patternID) continue; //ignore shapes like trendlines/ text
        const points = chart.getPoints(pattern.entityID);
        const minTime = points[0].time;
        const maxTime = points[1].time;
        const clickPositionX = mousePosition.time;

        const patternXInRange = checkPatternInRange(
          minTime,
          maxTime,
          clickPositionX
        );
        if (!patternXInRange) continue;

        const [maxPrice, minPrice] = getMaxMin(
          points[0].price,
          points[1].price
        );
        const clickPositionY = mousePosition.price;

        const patternYInRange = checkPatternInRange(
          minPrice,
          maxPrice,
          clickPositionY
        );
        if (patternYInRange) return pattern.patternID;
      }
    };

    const handlePatternClick = () => {
      const SDPatternSelected = checkIfPatternSelected(
        visibleSDPatterns.current
      );
      if (SDPatternSelected) {
        dispatch(deselectPattern(SDPatternSelected));
        return;
      }
      const DPatternSelected = checkIfPatternSelected(visibleDPatterns.current);
      if (DPatternSelected) {
        dispatch(selectPattern(DPatternSelected));
      }
    };

    //
    // ───────────────────────────────── END FUNCTION DECLARATIONS ─────
    //

    if (!chart.ready) return;
    if (newSelection === prevNewSelection.current) return;
    prevNewSelection.current = newSelection;

    const selection = chart.getSelection();

    if (selection.length === 1) {
      const entityId = selection[0];
      //USE THIS IF YOU NEED TO GET THE DETAILS OF A SHAPE E.G. SHAPE NAME:
      // try {
      //   console.log(chart.getShape(entityId));
      // } catch (error) {}
      handlePatternChange(entityId);
      return;
    }

    //if length = 0, the chart has been selected. Check if the user has clicked inside a pattern area
    if (selection.length === 0) {
      handlePatternClick();
    }
  }, [
    chart,
    dispatch,
    mousePosition,
    newSelection,
    visibleDPatterns,
    visibleSDPatterns,
  ]);
};
