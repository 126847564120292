import styled from "styled-components";
import defaultTheme from "../../../styles/defaultTheme";

const LoadingAnimation = styled.div`
  align-self: center;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${defaultTheme.primary};
  color: #9880ff;
  margin-top: 20px;
  animation: dotElastic 1s infinite linear;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    background-color: ${defaultTheme.primary};
    animation: dotElasticBefore 1s infinite linear;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    background-color: ${defaultTheme.primary};
    animation: dotElasticAfter 1s infinite linear;
  }

  @keyframes dotElasticBefore {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dotElastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dotElasticAfter {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

export default LoadingAnimation;
