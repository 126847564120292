import { Utils } from "../../utils/utils";

const convertPoint = (point) => {
  return {
    ...point,
    datetime: Utils.convertDatetimeToTimestamp(point.datetime),
  };
};

export const convertShapePoints = (shape) => {
  if (!shape) return;
  const points = shape.points.map((point) => convertPoint(point));
  return {...shape, points};
};

