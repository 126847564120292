import { useEffect, useRef } from "react";
import { useDPatternsByChart } from "../../../../GlobalHooks/useDPatternsByChart";

///Hook
export const useDrawD = ({ drawShapes, newVisRange, chartId }) => {
  const visibleDPatterns = useRef([]);

  const DPatterns = useDPatternsByChart(chartId);

  useEffect(() => {
    visibleDPatterns.current = drawShapes({
      prevPatterns: visibleDPatterns.current,
      patterns: DPatterns,
      color: "#3c65a7",
    });
  }, [DPatterns, newVisRange, drawShapes]);
};
