import { useMemo } from "react";

const MARGIN_TOP = 0.2;
const MARGIN_BOTTOM = 0.1;

const subtractMargins = (height) => {
  return height * (1 - MARGIN_TOP - MARGIN_BOTTOM);
};

const getMaxMinPrice = (bars) => {
  let maxPrice = -Infinity;
  let minPrice = Infinity;
  for (let i = 0; i < bars.length; i++) {
    if (bars[i].high > maxPrice) maxPrice = bars[i].high;
    if (bars[i].low < minPrice) minPrice = bars[i].low;
  }
  return [maxPrice, minPrice];
};

export const useGetIndicators = (
  filteredBars,
  indicatorShapes,
  height,
  width,
) => {
  return useMemo(() => {
    if (!filteredBars || !indicatorShapes.length) return [];

    const maxHeight = subtractMargins(height);
    const xRange = filteredBars.length;

    const [maxY, minY] = getMaxMinPrice(filteredBars);
    const yRange = maxY - minY;

    const getLeftPixels = (x) => {
      return (width * x) / xRange + width / (2 * filteredBars.length);
    };
    const getTopPixels = (y) => {
      return (maxHeight * (maxY - y)) / yRange + MARGIN_TOP * height;
    };

    const findIndex = (time) => {
      const convertedTime = time / 1000; //need to adjust timestamps to be compatible with the bars
      return filteredBars.findIndex((bar) => bar.time === convertedTime);
    };

    const indicators = [];

    indicatorShapes.forEach((shape) => {
      shape.points.forEach((point) => {
        indicators.push({
          x: getLeftPixels(findIndex(point.datetime)),
          y: getTopPixels(point.price),
          direction: shape.shape_type.toLowerCase().includes("up")
            ? "up"
            : "down",
        });
      });
    });

    return indicators;
  }, [filteredBars, indicatorShapes, height, width]);
};
