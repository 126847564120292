import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  setSelectedShape,
  removeColdStartShape,
  resetShape,
} from "../../../redux/slices/coldStart";

export const usePatternInteractions = () => {
  const dispatch = useDispatch();

  const onClick = useCallback(
    (selected, id) => {
      if (selected) {
        dispatch(setSelectedShape(null));
        dispatch(resetShape());
      } else {
        dispatch(setSelectedShape(id));
      }
    },
    [dispatch]
  );

  const onDelete = useCallback(
    (e, id) => {
      e.stopPropagation();
      dispatch(removeColdStartShape(id));
    },
    [dispatch]
  );

  return { onClick, onDelete };
};
