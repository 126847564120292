import React from "react";
import { Container } from "./FilterSwitchesStyles";
import BuySellSwitch from "./BuySellSwitch/BuySellSwitch";
import ActiveFormedSwitch from "./ActiveFormedSwitch/ActiveFormedSwitch";
import SignalTypeSwitch from "./PatternsIndicatorsSwitch/SignalTypeSwitch";

export default function FilterSwitches() {
  return (
    <Container>
      <BuySellSwitch />
      <ActiveFormedSwitch />
      <SignalTypeSwitch />
    </Container>
  );
}
