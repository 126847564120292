import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setHomeDisplay } from "../../../redux/slices/display";

// Styled components
const ToolbarLogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  width: 260px;
`;

const ToolbarLogoText = styled.h2`
  color: white;
  cursor: pointer;
  margin: 0 0 0 10px;
  transform: translateY(-2px);
`;

export default function ToolbarLogo() {
  // Redux dispatch function
  const dispatch = useDispatch();

  return (
    <ToolbarLogoContainer>
      <ToolbarLogoText onClick={() => dispatch(setHomeDisplay())}>
        permutable.ai
      </ToolbarLogoText>
    </ToolbarLogoContainer>
  );
}
