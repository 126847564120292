import React, { useRef, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { useDispatch } from "react-redux";

import GridChart from "../GridChart/GridChart";
import Dragger from "./Dragger/Dragger";
import { SelectedPatternContainer } from "./SelectedPatternsStyles";
import { GridText, PatternHeaderContainer, PatternHeader } from "../GridStyles";
import { useWidth } from "./hooks/useWidth";
import { deselectPattern } from "../../../redux/slices/selectedDetectedPatterns";

export default function SelectedPatterns({ patternData }) {
  const dispatch = useDispatch();

  const width = useWidth();

  // useRef hooks
  const prevSelectedChartsLength = useRef(null);

  // Scroll to bottom if a new chart is selected
  useEffect(() => {
    if (prevSelectedChartsLength.current < patternData.length) {
      animateScroll.scrollToBottom({
        containerId: "s-p-grid",
        duration: 500,
      });
    }
    prevSelectedChartsLength.current = patternData.length;
  }, [patternData]);

  // Handler functions

  const onClick = (pattern) => {
    dispatch(deselectPattern(pattern.id));
  };

  return (
    <SelectedPatternContainer width={width} id="s-p-grid">
      <PatternHeaderContainer>
        <PatternHeader>Selected patterns</PatternHeader>
      </PatternHeaderContainer>
      {width && (
        <>
          {width !== "100%" && <Dragger />}
          {patternData.length ? (
            patternData.map((pattern) => (
              <GridChart
                key={pattern.id}
                pattern={pattern}
                type="selected"
                height={300}
                width={400}
                onClick={onClick}
              />
            ))
          ) : (
            <GridText>No selected patterns</GridText>
          )}
        </>
      )}
    </SelectedPatternContainer>
  );
}
