import { Api } from "../../api/api";
import findIp from "./findIp";

export default async function bloombergAuth(Auth) {
  const authObj = await Auth.currentSession();
  const email = authObj?.idToken?.payload?.email;
  const bloombergUuid = authObj?.idToken?.payload["custom:bloomberg_uuid"];
  const bloombergAuthId = authObj?.idToken?.payload["custom:bloomberg_auth_id"];

  const ip = await findIp();

  const res = await Api.fetchGetRequestBloombergAuth(
    email,
    ip,
    bloombergUuid,
    bloombergAuthId,
  );
  const data = await res.json();

//  if (!data.authenticated) {
//    throw new Error(data.reason);
//  }
}
