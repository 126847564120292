import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 10px;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export default Container;
