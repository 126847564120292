import { useRef } from "react";
import { usePatternDrawings } from "./usePatternDrawings";
import { useIndicatorDrawings } from "./useIndicatorDrawings";

export const usePatternController = ({
  chart,
  newVisRange,
  matchedPattern,
}) => {
  const visiblePattern = useRef([]);

  usePatternDrawings({
    chart,
    visiblePattern,
    newVisRange,
    matchedPattern,
  });

  useIndicatorDrawings({
    chart,
    newVisRange,
  });
};
