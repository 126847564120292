import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CollectionLabel,
  ButtonContainer,
  NoCustomCollectionsMessage,
} from "./CollectionListStyles";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import CollectionItem from "./CollectionItem/CollectionItem";
import AddCustomCollectionButton from "./AddCustomCollectionButton/AddCustomCollectionButton";
import AddCustomCollection from "../../../Modals/AddOrEditCustomCollection/AddOrEditCustomCollection";

import { updateCustomChartCollectionToEdit, setMode } from "../../../../redux/slices/chartCollections";
import { updateCurrenChartCollection } from "../../../../redux/slices/leftPanel";

export default function CollectionList() {
  // Redux dispatch function
  const dispatch = useDispatch();

  // useState hook
  const [modalOpen, setModalOpen] = useState(false);

  const chartCollections = useSelector(
    ({ chartCollections }) => chartCollections.collections
  );

  const customChartCollections = useSelector(
    ({ chartCollections }) => chartCollections.customCollections
  );

  const selectedCollection = useSelector(
    ({ leftPanel }) => leftPanel.selectedCollection
  );

  const onSelect = useCallback(
    (payload) => {
      dispatch(updateCurrenChartCollection(payload));
    },
    [dispatch]
  );

  // Handler functions
  const onClose = () => {
    setModalOpen(false);
    dispatch(updateCustomChartCollectionToEdit(null));
  };

  const onAddCustomCollectionButtonClick = () => {
    setModalOpen(true);
    dispatch(setMode("add"));
  };

  const onEditCustomCollectionClick = (customCollection) => {
    setModalOpen(true);
    dispatch(setMode("edit"));
    dispatch(updateCustomChartCollectionToEdit(customCollection));
  };

  return (
    <>
      {modalOpen && <AddCustomCollection onClose={onClose} />}
      {!chartCollections.length && <LoadingAnimation />}

      {chartCollections.map((collection) => (
        <CollectionItem
          key={collection.id}
          collection={collection}
          selected={
            selectedCollection && selectedCollection.id === collection.id
          }
          onSelect={onSelect}
        />
      ))}

      <CollectionLabel>Custom security lists</CollectionLabel>
      {customChartCollections.length ? (
        customChartCollections.map((collection) => (
          <CollectionItem
            key={collection.id}
            collection={collection}
            selected={
              selectedCollection && selectedCollection.id === collection.id
            }
            onSelect={onSelect}
            custom
            onEditCustomCollectionClick={onEditCustomCollectionClick}
          />
        ))
      ) : (
        <NoCustomCollectionsMessage>
          No custom security lists
        </NoCustomCollectionsMessage>
      )}
      <ButtonContainer>
        <AddCustomCollectionButton onClick={onAddCustomCollectionButtonClick} />
      </ButtonContainer>
    </>
  );
}
