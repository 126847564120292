import React, { useState } from "react";
import { useSelector } from "react-redux";

import ModelSelect from "./ModelSelect/ModelSelect";
import ConfidenceSelect from "./ConfidenceSelect/ConfidenceSelect";
import DetectPatternsButton from "./DetectPatternsButton/DetectPatternsButton";
import ViewPatternsButton from "./ViewPatternsButton/ViewPatternsButton";
import DetectedPatternsCount from "./DetectedPatternsCount/DetectedPatternsCount";
import PatternsTimeRange from "./PatternsTimeRange";

import { Row, DPatternsCountContainer } from "../LeftPanelStyle";
import { selectTotalDPatterns } from "../../../redux/slices/detectedPatterns";
import ChartsToDetectOnSelect from "./ChartsToDetectOnSelect/ChartsToDetectOnSelect";

export default function ModelsDetect({ setMode }) {
  const totalDPatterns = useSelector(selectTotalDPatterns);

  const [patternsTimeRange, setPatternsTimeRange] = useState([]);
  const [timeRangeIsValid, setTimeRangeIsValid] = useState(true);

  return (
    <div>
      <ModelSelect />
      <ChartsToDetectOnSelect />
      <PatternsTimeRange
        setPatternsTimeRange={setPatternsTimeRange}
        setTimeRangeIsValid={setTimeRangeIsValid}
      />
      <Row>
        <DetectPatternsButton
          patternsTimeRange={patternsTimeRange}
          disabled={!timeRangeIsValid}
        />
      </Row>
      {totalDPatterns > 0 && (
        <>
          <Row>
            <DPatternsCountContainer>
              <DetectedPatternsCount />
              <ViewPatternsButton />
            </DPatternsCountContainer>
          </Row>
          <ConfidenceSelect />
        </>
      )}
    </div>
  );
}
