import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../../UI/Button/CustomButtonWhite";
import { updateWarning } from "../../../../../redux/slices/heatMap";
import { selectConfig } from "../../../../../redux/slices/heatMapConfigs";

export default function BookmarkButton({ bookmarkIndex, index }) {
  const configs = useSelector(({ heatMapConfigs }) => heatMapConfigs.configs);

  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => {
        dispatch(selectConfig(configs[bookmarkIndex].id));
        dispatch(
          updateWarning({
            messages: [],
            renderFirstTime: true,
          })
        );
      }}
      disabled={bookmarkIndex === null}
    >
      {bookmarkIndex !== null && bookmarkIndex <= configs.length - 1
        ? configs[bookmarkIndex].name
        : "Bookmark " + (index + 1)}
    </Button>
  );
}
