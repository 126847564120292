import styled from "styled-components";

export const Layer = styled.div`
  align-items: center;
  align-self: center;
  background-color: #090b10;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.noLayers ? "center" : "space-between")};
  margin: 1px 0;
  padding: 4px;
  text-align: center;
  width: 100%;
`;

export const Index = styled.p`
  font-size: 12px;
  margin: 0 5px;
`;

export const Label = styled.p`
  align-self: flex-end;
  font-size: 12px;
  margin: 5px 0;
`;

export const LayersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CentralContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
