import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectChartDataById } from "../../../../redux/slices/chartData";

const MARGIN_RATIO = 0.25;

const convertBars = (bars) =>
  bars.map((bar) => {
    return { ...bar, time: bar.time / 1000 };
  });

const getPaddedIndices = (bars, start, end) => {
  //add some space to either side of the pattern
  const startIndex = bars.findIndex((bar) => bar.time === start);
  const endIndex = bars.findIndex((bar) => bar.time === end);

  const barRange = endIndex - startIndex + 1;
  const barMargin = Math.floor(barRange * MARGIN_RATIO);

  const paddedStartIndex = Math.max(startIndex - barMargin, 0);
  const paddedEndIndex = Math.min(endIndex + barMargin, bars.length - 1);

  return [paddedStartIndex, paddedEndIndex];
};

export const useGetFilterBars = (patternRange, chartId) => {
  const chartData = useSelector((state) => selectChartDataById(state, chartId));

  return useMemo(() => {
    if (!chartData) return;
    const bars = chartData.bars;
    if (!bars?.length) return;
    const { start, end } = patternRange;

    const [paddedStartIndex, paddedEndIndex] = getPaddedIndices(
      bars,
      start,
      end
    );
    const filteredBars = bars.slice(paddedStartIndex, paddedEndIndex + 1);

    //need to adjust timestamps to be compatible with the chart
    const convertedBars = convertBars(filteredBars);

    return convertedBars;
  }, [patternRange, chartData]);
};
