import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.containerBackground1};
  border-bottom: 1px solid #222;
  border-left: 1px solid #222;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: row;
  height: 35px;
  justify-content: space-between;
  padding: 0 20px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;
