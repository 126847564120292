import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Circle, Label } from "./DragCircleStyles";
import findNearestDepthConstraint from "./utils/findNearestDepthConstraint";
import { setDepthConstraint } from "../../../../../../redux/slices/heatMap";

export default function DragCircle({
  transitioningHeight,
  top,
  openFilter,
  setSelectedLineHeight,
}) {
  const dispatch = useDispatch();

  const drillDownBy = useSelector(({ heatMap }) => heatMap.drillDownBy);
  const depthConstraint = useSelector(({ heatMap }) => heatMap.depthConstraint);

  // useState hook
  const [position, setPosition] = useState(top);
  const [dragging, setDragging] = useState(false);
  const [nearestIndex, setNearestIndex] = useState(depthConstraint);

  // useRef hook
  const initialPosition = useRef({ mouse: null, circle: null });

  // Handler functions
  // calculate relative position to the mouse and set dragging=true
  const onMouseDown = useCallback(
    (e) => {
      // only left mouse button
      if (e.button !== 0) return;

      initialPosition.current.mouse = e.pageY;
      initialPosition.current.circle = position;
      setDragging(true);

      e.stopPropagation();
      e.preventDefault();
    },
    [position]
  );

  const onMouseUp = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      const mouseChangeY = e.pageY - initialPosition.current.mouse;
      const initialCirclePosition = initialPosition.current.circle;
      const newPosition = initialCirclePosition + mouseChangeY;

      const nearestDepthConstraint = findNearestDepthConstraint(
        newPosition,
        openFilter,
        drillDownBy
      );

      setPosition(initialCirclePosition);
      setSelectedLineHeight(initialCirclePosition);
      dispatch(setDepthConstraint(nearestDepthConstraint));
      setDragging(false);

      initialPosition.current.mouse = null;
      initialPosition.current.circle = null;
    },
    [setSelectedLineHeight, drillDownBy, openFilter, dispatch]
  );

  const onMouseMove = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      const mouseChangeY = e.pageY - initialPosition.current.mouse;
      const initialCirclePosition = initialPosition.current.circle;
      const newPosition = initialCirclePosition + mouseChangeY;
      const maxY = 171 + (openFilter ? 300 : 0);

      let newConstrainedPosition;

      if (newPosition < 3) {
        newConstrainedPosition = 3;
      } else if (newPosition > maxY) {
        newConstrainedPosition = maxY;
      } else {
        newConstrainedPosition = initialCirclePosition + mouseChangeY;
      }

      const nearestDepthConstraint = findNearestDepthConstraint(
        newPosition,
        openFilter,
        drillDownBy
      );

      setNearestIndex(nearestDepthConstraint);

      setSelectedLineHeight(newConstrainedPosition);
      setPosition(newConstrainedPosition);
    },
    [openFilter, setSelectedLineHeight, drillDownBy]
  );

  // useEffect hooks
  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    } else {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }
  }, [dragging, onMouseMove, onMouseUp]);

  useEffect(() => setPosition(top), [top]);

  return (
    <Circle
      onMouseDown={onMouseDown}
      transitioningHeight={transitioningHeight}
      top={position}
      dragging={dragging}
    >
      {dragging && <Label top={position}>{nearestIndex}</Label>}
    </Circle>
  );
}
