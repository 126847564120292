import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MenuContainer, Title } from "../LeftPanelStyle";
import IndicatorList from "../IndicatorList/IndicatorList";
import Button from "../../UI/Button/CustomButtonLeftPanelBlue";
import { getIndicators } from "../../../redux/slices/indicators";
import { Row } from "./IndicatorsTabStyles";

export default function IndicatorsTab({ selected }) {
  const [selectedIndicators, setSelectedIndicators] = useState([]);

  const indicators = useSelector(({ indicators }) => indicators.indicatorList);
  const loading = useSelector(({ indicators }) => indicators.fetching);

  const dispatch = useDispatch();

  const onDraw = useCallback(() => {
    dispatch(getIndicators(selectedIndicators));
  }, [dispatch, selectedIndicators]);

  return (
    <MenuContainer selected={selected}>
      <Title>Select indicators:</Title>
      <IndicatorList
        indicators={indicators}
        selectedIndicators={selectedIndicators}
        setSelectedIndicators={setSelectedIndicators}
      />
      <Row>
        <Button loading={loading} disabled={loading} onClick={onDraw}>
          Draw Indicators
        </Button>
      </Row>
    </MenuContainer>
  );
}
