import React, { useState, useEffect } from "react";
import {
  Container,
  TopContainer,
  Title,
  Subtitle,
} from "./HomeInterfaceStyles";
import ChooseConfiguration from "../ChooseConfiguration/ChooseConfiguration";
import Fade from "react-reveal/Fade";

export default function HomeInterface() {
  const [titleVisible, setTitleVisible] = useState(false);
  const [subtitleVisible, setSubtitleVisible] = useState(false);

  // useEffect on render
  useEffect(() => {
    setTimeout(() => setTitleVisible(true), 100);
    setTimeout(() => setSubtitleVisible(true), 600);
  }, []);

  return (
    <Container>
      <TopContainer>
        <Title visible={titleVisible}>Welcome to R2</Title>
        <Subtitle visible={subtitleVisible}>
          Trading insights powered by artificial intelligence.
        </Subtitle>
      </TopContainer>
      <Fade up>
        <ChooseConfiguration />
      </Fade>
    </Container>
  );
}
