import React from "react";

import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { StyledLockButton } from "./ButtonStyles";
import { toggleLock } from "../../../../../../../redux/slices/chartLayout";
import { useDispatch, useSelector } from "react-redux";

export default function LockButton({ chartId }) {
  const dispatch = useDispatch();
  const isLocked = useSelector(
    ({ chartLayout }) => chartLayout.entities[chartId]?.locked
  );

  return (
    <StyledLockButton
      onMouseDown={(e) => {
        e.stopPropagation();

        dispatch(toggleLock(chartId));
      }}
      size="small"
    >
      {isLocked ? (
        <LockIcon fontSize="small" />
      ) : (
        <LockOpenIcon fontSize="small" />
      )}
    </StyledLockButton>
  );
}
