import { useCallback } from "react";
import { useDrawSDP } from "./useDrawSDP";
import { useDrawS } from "./useDrawS";
import { useDrawD } from "./useDrawD";

const removePrevShapes = (chart, prevPatterns) => {
  prevPatterns.forEach((pattern) => {
    chart.removeShape(pattern);
  });
};
const drawNewShapes = (chart, patterns, color) => {
  const visiblePatterns = [];
  patterns.forEach((pattern) => {
    if (pattern.selected) return;
    pattern.shapes.forEach((shape) => {
      if (shape.shape_type !== "rectangle") return;
      const shapes = chart.drawPreviewShapes({
        points: shape.points,
        color,
      });
      visiblePatterns.push(...shapes);
    });
  });
  return visiblePatterns;
};
///Hook
export const usePatternDrawings = ({ chart, chartId, newVisRange }) => {
  const drawShapes = useCallback(
    ({ prevPatterns, patterns, color }) => {
      if (!chart.ready) return [];
      removePrevShapes(chart, prevPatterns);

      return drawNewShapes(chart, patterns, color);
    },
    [chart]
  );
  useDrawSDP({ drawShapes, newVisRange, chartId });
  useDrawS({ drawShapes, newVisRange, chartId });
  useDrawD({ drawShapes, newVisRange, chartId });
};
