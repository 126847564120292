import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "@material-ui/core";
import Button from "../../../../UI/Button/CustomButtonWhite";
import AddLayerMenuItem from "../styledComponents/AddLayerMenuItem";
import {
  updatedDrillDownBy,
  setDepthConstraint,
} from "../../../../../redux/slices/heatMap";

export default function AddLayerButton() {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const drillDownBy = useSelector(({ heatMap }) => heatMap.drillDownBy);
  const filtersToLabelMap = useSelector(
    ({ heatMap }) => heatMap.filtersToLabelMap
  );

  const categories = Object.keys(filtersToLabelMap);

  const drillDownConstraint = useSelector(
    ({ settings }) => settings.drillDownConstraint
  );

  const handleModelMenuClick = (event) => {
    if (categories.length > drillDownBy.length) {
      setAnchorEl(document.getElementById("add-layer"));
    } else {
      setAnchorEl(null);
    }
  };

  const handleModelMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div id={"add-layer"}>
        <Button
          fullWidth
          disabled={
            categories.length === drillDownBy.length ||
            drillDownConstraint === drillDownBy.length
          }
          onClick={handleModelMenuClick}
        >
          Add Layer
        </Button>
      </div>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleModelMenuClose}
        classes={{ paper: "model-select-background" }}
      >
        {categories
          .filter((category) => !drillDownBy.includes(category))
          .map((category) => {
            return (
              <AddLayerMenuItem
                key={category}
                onClick={() => {
                  if (
                    categories.length - 1 === drillDownBy.length ||
                    drillDownConstraint - 1 === drillDownBy.length
                  )
                    handleModelMenuClose();
                  if (!drillDownBy.includes(category)) {
                    const drillDownByToSet = [...drillDownBy, category];
                    dispatch(updatedDrillDownBy(drillDownByToSet));

                    if (drillDownConstraint > drillDownByToSet.length) {
                      dispatch(setDepthConstraint(drillDownByToSet.length));
                    }
                  }
                }}
              >
                {filtersToLabelMap[category]}
              </AddLayerMenuItem>
            );
          })}
      </Menu>
    </React.Fragment>
  );
}
