import styled from "styled-components";

export const Container = styled.div`
  align-self: center;
  background-color: rgb(52, 61, 70);
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: column;
  height: ${({ open }) => (open ? "300px" : 0)};
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  transition: height 0.2s linear;
  width: 270px;
`;

export const ListContainer = styled.ul`
  max-height: 240px;
  overflow-y: scroll;
  padding: 0;
`;

export const ListItem = styled.li`
  background-color: ${({ selected }) =>
    selected ? "rgb(104, 174, 30)" : "#090b10"};
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  list-style-type: none;
  margin: 1px auto;
  padding: 10px 16px;
  position: relative;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 95%;

  &:hover {
    background-color: ${({ selected }) =>
      selected ? "rgb(74, 144, 0)" : "rgb(86, 91, 98)"};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
`;

export const LoadingAnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const WarningContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  color: white;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  height: 100%;
  padding: 20px;
  width: 100%;
`;

export const WarningMessage = styled.p``;
