import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

///Helper Functions///
const patternStartInRange = (visibleFrom, visibleTo, patternStart) => {
  return patternStart > visibleFrom && patternStart < visibleTo;
};

const drawParticularShape = (points, drawFunction, chart) => {
  return points.map((point) => drawFunction.bind(chart)({ point: [point] }));
};

const drawShape = (chart, shape) => {
  switch (shape.shape_type) {
    case "arrow_up_starting_at_the_point":
      return drawParticularShape(
        shape.points,
        chart.drawArrowUpStartingAtPoint,
        chart
      );
    case "arrow_down_starting_at_the_point":
      return drawParticularShape(
        shape.points,
        chart.drawArrowDownStartingAtPoint,
        chart
      );
    case "arrow_up_ending_at_the_point":
      return drawParticularShape(
        shape.points,
        chart.drawArrowUpEndingAtPoint,
        chart
      );

    case "arrow_down_ending_at_the_point":
      return drawParticularShape(
        shape.points,
        chart.drawArrowDownEndingAtPoint,
        chart
      );

    default:
      return;
  }
};

///Hook
export const useIndicatorDrawings = ({ chart, newVisRange }) => {
  const indicators = useSelector(({ heatMapChart }) => heatMapChart.pattern);
  const previousPatterns = useRef([]);

  const removePreviousShapes = useCallback(
    (previousPatterns) => {
      if (!chart.ready) return;

      previousPatterns.forEach((pattern) => {
        chart.removeShape(pattern);
      });
    },
    [chart]
  );
  const patternIsNotInView = useCallback(
    (pattern) => {
      const { from, to } = chart.getRange();
      const patternStart = pattern.shapes[0].points[0].time;

      if (patternStartInRange(from, to, patternStart)) {
        return false;
      }

      return true; //pattern is not in view
    },
    [chart]
  );

  const drawShapes = useCallback(
    ({ patterns }) => {
      if (!chart.ready || !patterns) return [];
      const visiblePatterns = [];

      patterns.forEach((pattern) => {
        if (!pattern || patternIsNotInView(pattern)) return;
        pattern.shapes.forEach((shape) => {
          const newShapes = drawShape(chart, shape);
          if (newShapes) {
            for (let shape of newShapes) {
              visiblePatterns.push(shape);
            }
          }
        });
      });
      return visiblePatterns;
    },
    [chart, patternIsNotInView]
  );

  //NOTE: The chart can't draw outside the visible range, so we need to redraw every time the view changes
  //we will keep track of the current manual drawings and remove them on each render before redrawing

  useEffect(() => {
    removePreviousShapes(previousPatterns.current);
    if (!indicators?.indicator) return;
    previousPatterns.current = drawShapes({ patterns: indicators.patterns });
  }, [indicators, newVisRange, drawShapes, removePreviousShapes]);
};
