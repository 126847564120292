import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const StyledLockButton = styled(IconButton)`
  color: rgb(58, 58, 58) !important;
  position: absolute !important;
  right: 62px;
  top: -2px;
  z-index: 5;
`;
export const StyledFullScreenButton = styled(IconButton)`
  color: rgb(58, 58, 58) !important;
  position: absolute !important;
  right: 30px;
  top: -4px;
  z-index: 5;
`;
export const StyledCloseButton = styled(IconButton)`
  color: rgb(58, 58, 58) !important;
  position: absolute !important;
  right: 0;
  top: -4px;
  z-index: 5;
`;
