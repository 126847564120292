const findLineSegments = (optima, bars, upperOrLower) => {
  if (optima.length <= 1) return [];

  const firstBar = optima[0];
  const otherOptima = optima.slice(1);
  const result = [];

  const isValidLineSegment = (bars, firstBar, lastBar, upperOrLower) => {
    const y = upperOrLower === "upper" ? "high" : "low";

    const grad = (lastBar[y] - firstBar[y]) / (lastBar.time - firstBar.time);
    const intermediateBars = bars.filter(
      ({ time }) => time > firstBar.time && time < lastBar.time
    );
    let result = true;

    for (let bar of intermediateBars) {
      const xDiff = bar.time - firstBar.time;
      const lineY = firstBar[y] + grad * xDiff;
      if (
        (upperOrLower === "upper" && lineY < bar[y]) ||
        (upperOrLower === "lower" && lineY > bar[y])
      )
        result = false;
    }

    return result;
  };

  for (let lastBar of otherOptima) {
    if (isValidLineSegment(bars, firstBar, lastBar, upperOrLower)) {
      result.push([firstBar, lastBar]);
    }
  }

  return [...result, ...findLineSegments(otherOptima, bars, upperOrLower)];
};

export default findLineSegments;
