import { createSlice } from "@reduxjs/toolkit";
import LocalStorage from "../../utils/localStorage";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    showBoxes: true,
    sync: false,
    showLines: false,
    drillDownConstraint:
      typeof LocalStorage.getDrillDownConstraint() === "number"
        ? LocalStorage.getDrillDownConstraint()
        : 8,
  },
  reducers: {
    toggleShowBoxes: (state) => {
      state.showBoxes = !state.showBoxes;
    },
    toggleSync: (state) => {
      state.sync = !state.sync;
    },
    toggleShowLines: (state) => {
      state.showLines = !state.showLines;
    },
    setDrillDownConstraint: (state, action) => {
      state.drillDownConstraint = action.payload;
      LocalStorage.setDrillDownConstraint(action.payload);
    },
  },
});

export const {
  toggleShowBoxes,
  toggleSync,
  toggleShowLines,
  setDrillDownConstraint,
} = settingsSlice.actions;

export default settingsSlice.reducer;
