import React from "react";
import { useSelector } from "react-redux";

import SelectedPatterns from "./SelectedPatterns/SelectedPatterns";
import GridControls from "./GridControls/GridControls";

import { GridContainer, PatternsContainer } from "./GridStyles";
import DetectedPatterns from "./DetectedPatterns/DetectedPatterns";
import { useSelectedPatternData } from "./hooks/useSelectedPatternData";

export default function Grid() {
  const selectedPatternData = useSelectedPatternData();

  const mode = useSelector(({ display }) => display.mode);
  const training =
    mode === "drawModelForTraining" || mode === "selectPatternsForTraining";

  return (
    <GridContainer>
      <GridControls />
      <PatternsContainer>
        {training && selectedPatternData.length && (
          <SelectedPatterns patternData={selectedPatternData} />
        )}

        <DetectedPatterns
          training={training}
          selectedPatternData={selectedPatternData}
        />
      </PatternsContainer>
    </GridContainer>
  );
}
