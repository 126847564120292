import React from "react";

import { TileChartContainer, StyledTileChart } from "./TileChartStyles";
import Toolbar from "./Components/Toolbar/Toolbar";
import PatternNavButtons from "./Components/PatternNavButtons/PatternNavButtons";
import { useSetupChart } from "./hooks/useSetupChart";
import { useDrawingMenu } from "./hooks/useDrawingMenu";
import { useVisibleRangeController } from "./hooks/useVisibleRangeController";
import { useNavigationController } from "./hooks/useNavigationController";
import { usePatternController } from "./hooks/usePatternController";

export default function TileChart({
  chartId,
  isFullScreen,
  isMinimized,
  prefix,
  mode,
}) {
  const { chart, newVisRange, newSelection, mousePosition } = useSetupChart({
    chartId,
    prefix,
  });

  useDrawingMenu({ chart, isFullScreen });

  useVisibleRangeController({ chart, isFullScreen, newVisRange });

  usePatternController({ chart, newVisRange, newSelection, mousePosition });

  const [onPrevPattern, onNextPattern] = useNavigationController({
    chart,
    isFullScreen,
  });

  return (
    <TileChartContainer>
      <Toolbar
        chart={chart}
        isMinimized={isMinimized}
        isFullScreen={isFullScreen}
        mode={mode}
      />
      <PatternNavButtons
        isMinimized={isMinimized}
        isFullScreen={isFullScreen}
        onNextPattern={onNextPattern}
        onPrevPattern={onPrevPattern}
      />

      <StyledTileChart isFullScreen={isFullScreen} id={prefix + chartId} />
    </TileChartContainer>
  );
}
