import { createMuiTheme } from "@material-ui/core/styles";
import defaultTheme from "./defaultTheme";

export default createMuiTheme({
  palette: {
    primary: {
      main: defaultTheme.primary,
    },
    secondary: {
      main: defaultTheme.containerBackground1,
    },
  },
  // typography: {
  // },
  overrides: {
    MuiMenu: {
      paper: {
        backgroundColor: defaultTheme.containerBackground1,
      },
    },
    MuiListItem: {
      root: {
        backgroundColor: defaultTheme.containerBackground2,
        "&:hover": {
          backgroundColor: `${defaultTheme.buttonHover}!important`,
        },
        "&$selected": {
          backgroundColor: defaultTheme.selected,
          "&:hover": {
            backgroundColor: `${defaultTheme.selected}!important`,
          },
        },
        "&$focusVisible": {
          backgroundColor: "",
        },
        color: "white",
      },
    },
    MuiSelect: {
      root: {
        fontSize: "12px",
        color: defaultTheme.primary,
      },
      icon: {
        color: defaultTheme.primary,
      },
    },
    MuiInput: {
      underline: {
        color: defaultTheme.primary,
        "&:before": {
          borderBottomColor: defaultTheme.buttonHover,
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottomColor: defaultTheme.buttonHover,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: defaultTheme.primary,
        fontSize: "12px",
      },
    },
    MuiDialog: {
      paper: {
        backdropFilter: "blur(0.2rem)",
        background: defaultTheme.containerBackground1,
        borderRadius: 20,
        color: defaultTheme.primary,
        textAlign: "center",
        padding: 20,
      },
    },
    MuiRadio: {
      root: {
        color: defaultTheme.primary,
      },
    },
    MuiFormControlLabel: {
      root: {
        color: defaultTheme.primary,
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: defaultTheme.primary,
        opacity: 0.1,
      },
    },
    MuiLinearProgress: {
      bar1Determinate: {
        background: "linear-gradient(to left, rgb(91, 185, 248),#3498db)",
      },
    },
  },
  MuiTextField: {
    root: {
      color: "white",
    },
  },
  props: {
    MuiPopover: {
      getContentAnchorEl: null,
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      transformOrigin: { vertical: "top", horizontal: "center" },
    },
    MuiDialog: {
      hideBackdrop: true,
    },
    MuiButton: {
      color: "primary",
    },
    MuiRadio: {
      color: "primary",
    },
    MuiSwitch: {
      color: "primary",
    },
  },
});
