import React from "react";
import { useDispatch } from "react-redux";
import { Container, Dot } from "./ExpandTabStyles";
import { setRightPanelExpanded } from "../../../../redux/slices/heatMap";

const DOTS = [-150, 0, 150];

export default function ExpandTab({ expanded }) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Handler functions
  const createOnMouseMove = (originalPosition, expanded) => {
    const onMouseMove = (e) => {
      if (expanded && e.pageX - originalPosition > 20) {
        return dispatch(setRightPanelExpanded(false));
      }
      if (expanded && e.pageX - originalPosition < 20) {
        return dispatch(setRightPanelExpanded(true));
      }
      if (!expanded && e.pageX - originalPosition < -20) {
        return dispatch(setRightPanelExpanded(true));
      }
      if (!expanded && e.pageX - originalPosition > -20) {
        return dispatch(setRightPanelExpanded(false));
      }
    };

    return onMouseMove;
  };

  const onMouseDown = (e) => {
    e.preventDefault();
    const onMouseMove = createOnMouseMove(e.pageX, expanded);
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", onMouseMove);
    });
  };

  return (
    <Container expanded={expanded} onMouseDown={onMouseDown}>
      {DOTS.map((dot, index) => (
        <Dot y={dot} key={index} />
      ))}
    </Container>
  );
}
