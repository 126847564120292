import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllSDPatterns } from "../../../redux/slices/selectedDetectedPatterns";
import { selectAllSPatterns } from "../../../redux/slices/selectedPatterns";

export const useSelectedPatternData = () => {
  const SDPatterns = useSelector(selectAllSDPatterns);
  const SPatterns = useSelector(selectAllSPatterns);

  //Set up selected patterns object
  const combinedPatterns = useMemo(() => {
    return [...SPatterns, ...SDPatterns];
  }, [SPatterns, SDPatterns]);

  return combinedPatterns;
};
