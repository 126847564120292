import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedModel } from "../../../../../redux/slices/toolbar";
import { updateConfidence } from "../../../../../redux/slices/detectedPatterns";

const useModelNames = () => {
  // Retrieve redux dispatch
  const dispatch = useDispatch();

  // Select from redux store
  const models = useSelector(({ models }) => models.data);
  const selectedModel = useSelector(({ toolbar }) => toolbar.selectedModel);

  if (!selectedModel && models.length > 0) {
    dispatch(setSelectedModel(models[0]));
    dispatch(updateConfidence(models[0]));
  }

  // Hander functions
  const onChangeModel = useCallback(
    (e) => {
      dispatch(setSelectedModel(e.target.value));
      dispatch(updateConfidence(e.target.value.confidence_threshold));
    },
    [dispatch]
  );

  return [models, selectedModel, onChangeModel];
};

export default useModelNames;
