import { useEffect, useState } from "react";
import moment from "moment";

export const useGetChartInfo = (signal, filteredBars, modelIdToNameMap) => {
  const [chartInfo, setChartInfo] = useState([]);

  useEffect(() => {
    if (!filteredBars) return;
    const start = filteredBars[0];
    const end = filteredBars[filteredBars.legth - 1];

    const isSameYear =
      moment(start).format("YYYY") ===
      moment(end).format("YYYY");
    const formatString = isSameYear ? "Do MMM" : "Do MMM YYYY";

    const startDate = moment(start).format(formatString);
    const endDate = moment(end).format("Do MMM YYYY");

    try {
      setChartInfo([
        `${signal.ticker.security} - ${signal.interval}`,
        `${startDate} - ${endDate}`,
        modelIdToNameMap[signal.model_id].name,
      ]);
    } catch (error) {
      console.log(error);
    }
  }, [filteredBars, signal, modelIdToNameMap]);

  return chartInfo;
};
