import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectPattern,
  setNumberOfNewDetectedPatterns,
} from "../../../redux/slices/detectedPatterns";

import GridChart from "../GridChart/GridChart";

import { DetectedPatternContainer } from "./DetectedPatternsStyles";
import { GridText, PatternHeaderContainer, PatternHeader } from "../GridStyles";

import { useDetectedPatternData } from "./hooks/useDetectedPatternData";
import AnimatedMessage from "../../UI/AnimatedMessage/AnimatedMessage";
import { deselectPattern } from "../../../redux/slices/selectedDetectedPatterns";

import filterForDuplicatePatterns from "./utils/filterForDuplicatePatterns";

export default function DetectedPatterns({ selectedPatternData, training }) {
  const dispatch = useDispatch();

  const detectingPatterns = useSelector(
    ({ detectedPatterns }) => detectedPatterns.fetching
  );

  const patternData = useDetectedPatternData();

  const filteredPatternData = filterForDuplicatePatterns(
    patternData,
    selectedPatternData
  );

  dispatch(setNumberOfNewDetectedPatterns(filteredPatternData.length));

  const onClick = (pattern) => {
    if (!training) return;
    if (pattern.selected) {
      dispatch(deselectPattern(pattern.id));
    } else {
      dispatch(selectPattern(pattern.id));
    }
  };

  const noDetectedPatternsStatement = detectingPatterns ? (
    <AnimatedMessage>Detecting</AnimatedMessage>
  ) : (
    "No detected patterns"
  );

  return (
    <DetectedPatternContainer>
      <PatternHeaderContainer>
        <PatternHeader>
          {`Detected Patterns${
            training ? " - select to add to training set" : ""
          }`}
        </PatternHeader>
      </PatternHeaderContainer>
      <>
        {filteredPatternData.length ? (
          filteredPatternData.map((pattern, index) => (
            <GridChart
              training={training}
              key={pattern.id}
              pattern={pattern}
              type="detected"
              height={300}
              width={400}
              onClick={onClick}
            />
          ))
        ) : (
          <GridText>{noDetectedPatternsStatement}</GridText>
        )}
      </>
    </DetectedPatternContainer>
  );
}
