import styled from "styled-components";
import { ListItem } from "../../../LeftPanelStyle";

export const ChartListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  width: 235px;
`;
const Indicator = styled.div`
  border-radius: 50%;
  height: 10px;
  margin-left: 5px;
  width: 10px;
`;
export const SelectedIndicator = styled(Indicator)`
  background-color: rgb(36, 238, 86);
`;
export const DetectedIndicator = styled(Indicator)`
  background-color: rgb(66, 66, 247);
`;
