import { Utils } from "../../../../utils/utils";
import findClosestMatchedPattern from "../utils/findClosestMatchedPattern";
import processMatch from "../utils/processMatch";

const useMatchedPattern = (pattern, populatedSignals) => {
  if (!pattern || !populatedSignals) return;

  const possibleMatches = populatedSignals
    .filter(
      (signal) =>
        signal.chart_id === pattern.chartId &&
        !!signal.indicator === !!pattern.indicator
    )
    .map((signal) => ({
      ...signal,
      shapes: signal.shapes.map((shape) => ({
        ...shape,
        points: shape.points.map((point) => ({
          price: point.price,
          time: Utils.convertDatetimeToTimestamp(point.datetime) / 1000,
        })),
      })),
    }));

  const matchedArray = possibleMatches.filter((signal) =>
    isSufficientPatternSimilarity(signal.shapes, pattern.shapes)
  );

  let match;

  if (!matchedArray.length) match = null;
  else if (matchedArray.length > 1)
    match = findClosestMatchedPattern(pattern, matchedArray);
  else match = matchedArray[0];

  return processMatch(match);
};

export default useMatchedPattern;

const isSufficientPatternSimilarity = (shapes1, shapes2) => {
  const rectangle1 = shapes1.find((shape) => shape.shape_type === "rectangle");
  const rectangle2 = shapes2.find((shape) => shape.shape_type === "rectangle");

  const xIntersection = findIntersection(
    rectangle1.points,
    rectangle2.points,
    "price"
  );

  const yIntersection = findIntersection(
    rectangle1.points,
    rectangle2.points,
    "time"
  );

  const intersectionArea = xIntersection * yIntersection;

  const unionArea =
    findTotalArea([rectangle1.points, rectangle2.points], ["price", "time"]) -
    intersectionArea;

  return intersectionArea / unionArea > 0.12;
};

const findIntersection = (points1, points2, key) => {
  const relevantPoints1 = points1.map((point) => point[key]).sort();
  const relevantPoints2 = points2.map((point) => point[key]).sort();

  if (
    relevantPoints1[0] <= relevantPoints2[0] &&
    relevantPoints1[1] >= relevantPoints2[1]
  )
    return relevantPoints2[1] - relevantPoints2[0];
  if (
    relevantPoints1[0] >= relevantPoints2[0] &&
    relevantPoints1[1] <= relevantPoints2[1]
  )
    return relevantPoints1[1] - relevantPoints1[0];
  if (
    relevantPoints1[0] <= relevantPoints2[0] &&
    relevantPoints1[1] <= relevantPoints2[1]
  ) {
    if (relevantPoints1[1] >= relevantPoints2[0])
      return relevantPoints1[1] - relevantPoints2[0];
    else return 0;
  }
  if (
    relevantPoints1[0] >= relevantPoints2[0] &&
    relevantPoints1[1] >= relevantPoints2[1]
  ) {
    if (relevantPoints1[0] <= relevantPoints2[1])
      return relevantPoints2[1] - relevantPoints1[0];
    else return 0;
  }
};

const findTotalArea = (shapePointsArray, keysArray) => {
  let total = 0;

  shapePointsArray.forEach((pointsArray) => {
    const xlength = pointsArray.reduce(
      (acc, point) => Math.abs(point[keysArray[0]] - acc),
      0
    );
    const ylength = pointsArray.reduce(
      (acc, point) => Math.abs(point[keysArray[1]] - acc),
      0
    );

    const shapeArea = xlength * ylength;

    total += shapeArea;
  });

  return total;
};
