import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChartUtils } from "../../../../../utils/chartUtils";
import WidgetClass from "../../Classes/WidgetClass";
import { removeShape } from "../../../../../redux/slices/selectedPatterns";
import { useUpdateBars } from "./useUpdateBars";
import { selectChartDataById } from "../../../../../redux/slices/chartData";

const getChartName = (data) => {
  const interval =
    data.chartData.collection === "Training Charts"
      ? ""
      : data.chartData.intervalString?.toUpperCase();
  return `${data.chartData.name} ${interval || ""}`;
};
const DISABLED_FEATURES = [
  "header_symbol_search",
  "header_undo_redo",
  "header_compare",
  "header_fullscreen_button",
  "header_resolutions",
  "legend_widget",
  "timeframes_toolbar",
];
const WIDGET_OPTIONS = {
  symbol: "preload",
  datafeed: {
    onReady: ChartUtils.onReady,
    searchSymbols: ChartUtils.searchSymbols,
    resolveSymbol: ChartUtils.resolveSymbol(60),
    getBars: ChartUtils.getBars("tile"),
    subscribeBars: ChartUtils.subscribeBars,
    unsubscribeBars: ChartUtils.unsubscribeBars,
  },
  interval: 60,
  library_path: "/charting_library/",
  autosize: true,
  disabled_features: DISABLED_FEATURES,
  theme: "Dark",
};

export const useSetupChart = ({ chartId, prefix }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectChartDataById(state, chartId));
  const [chart, setChart] = useState({});

  //Triggers to allow us to respond to chart events
  const [newVisRange, setNewVisRange] = useState(false);
  const [newSelection, setNewSelection] = useState(false);

  const mousePosition = useRef(null);

  //set up widget
  useEffect(() => {
    const widgetOptions = {
      ...WIDGET_OPTIONS,
      container_id: prefix + chartId,
    };
    const widgetApp = new window.TradingView.widget(widgetOptions);
    widgetApp.onChartReady(() => {
      const newChart = new WidgetClass({
        instance: widgetApp,
        chartId: chartId,
        chartName: getChartName(data),
      });

      newChart.onMouseMove(({ time, price }) => {
        mousePosition.current = { time, price };
      });

      newChart.onMouseClick(() => setNewSelection([true])); //array used to ensure equality checks fail

      newChart.onRemoveShape((shapeId) => {
        dispatch(removeShape(shapeId));
      });

      newChart.onVisibleRangeChange(() => {
        setNewVisRange([true]);
      });

      newChart.toggleDrawingMenu(); //remove on setup
      setChart(newChart);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateBars({ chart, data });

  return {
    chart,
    newVisRange,
    newSelection,
    mousePosition: mousePosition.current,
  };
};
