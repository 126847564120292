import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Switch } from "@material-ui/core";

export const Container = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 18px 0 2px;
  width: 300px;
`;

const FormControlLabelStyles = {
  label: {
    fontSize: "12px !important",
  },
  root: {
    marginLeft: 0,
    whiteSpace: "nowrap",
  },
};

const switchStyles = {
  checked: {
    color: "rgb(104, 174, 30) !important",
  },
  track: {
    backgroundColor: "rgb(104, 174, 30) !important",
  },
};

export const CustomFormControlLabel = withStyles(FormControlLabelStyles)(
  FormControlLabel
);

export const CustomSwitch = withStyles(switchStyles)(Switch);
