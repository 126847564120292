import React from "react";
import { useDispatch } from "react-redux";
import { ToolbarLogo, ToolbarLogoContainer } from "./ModeSelectStyles";
import { setHomeDisplay } from "../../../redux/slices/display";

import icon from "../../../assets/icon.png";

export default function DisplaySelect() {
  // Redux dispatch function
  const dispatch = useDispatch();

  return (
    <ToolbarLogoContainer>
      <ToolbarLogo
        onClick={() => dispatch(setHomeDisplay())}
        src={icon}
        alt="logo"
      />
    </ToolbarLogoContainer>
  );
}
