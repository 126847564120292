import React from "react";
import { useSelector } from "react-redux";

import Pattern from "./Pattern/Pattern";

import { selectPatternIds } from "../../../redux/slices/coldStart";

import { useAutoScrollOnChange } from "../hooks/useAutoScrollOnChange";
import { usePatternInteractions } from "../hooks/usePatternInteraction";
import { useContainerHeight } from "../../GlobalHooks/useContainerHeight";
import { StyledSavedPatterns } from "./SavedPatternsStyle";

const PADDING_BORDERS_MARGINS = 26;

export default function SavedPatterns() {
  const patternsIds = useSelector(selectPatternIds);
  const selected = useSelector(({ coldStart }) => coldStart.selected);

  useAutoScrollOnChange({
    elementId: "coldStartSavedPatterns",
    changeValue: patternsIds,
  });

  const height = useContainerHeight("#coldStartSavedPatterns");

  const { onClick, onDelete } = usePatternInteractions();

  return (
    <StyledSavedPatterns id="coldStartSavedPatterns">
      {patternsIds.map((patternId) => (
        <Pattern
          key={patternId}
          id={patternId}
          height={height - PADDING_BORDERS_MARGINS}
          selected={patternId === selected}
          onClick={onClick}
          onDelete={onDelete}
        />
      ))}
    </StyledSavedPatterns>
  );
}
