import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { Api } from "../../api/api";
import { minutesToStrRepr } from "../../utils/utils";

const API_LOADING_PROCESS_STEP = 25;

const heatMapSlice = createSlice({
  name: "heatMap",
  initialState: {
    filtersIsChanged: false,
    filterValuesByLayers: {},
    filtersToLabelMap: {},
    filterValues: {
      intervals: [],
      modelIdToNameMap: {},
      tickers: [],
      tickerFields: [],
    },
    initialFilters: {},
    initialDrillDownBy: [],
    initialDepthConstraint: 1,
    filters: {
      signalType: null,
      status: null,
      entryDirection: null,
      modelIds: [],
      intervals: [],
      ticker: {},
      minSignalValue: 0.88,
      maxSignalValue: 1.0,
      firstBar: 0,
      lastBar: 10,
      signalsOnChartCount: 0,
      showMissingSignals: false,
    },
    drillDownBy: [],
    depthConstraint: 1,
    signals: [],
    signalsCount: null,
    inFront: 1,
    zoomInTileData: null,
    zoomOut: null,
    triggerRerender: {
      heatMap1: { count: 0 },
      heatMap2: { count: 0 },
    },
    selectedSignals: [],
    warningMessage: null,
    warning: {
      messages: [],
      renderFirstTime: true,
    },
    showHeatMapChart: false,
    apiLoadingProgress: 100,
    leftPanelExpanded: 0,
    rightPanelExpanded: true,
    transitioning: false,
    allChartDetails: {},
    updatePattern: false,
  },
  reducers: {
    setFiltersIsChanged: (state, action) => {
      state.filtersIsChanged = action.payload;
    },
    setFilterValuesByLayers: (state, action) => {
      state.filterValuesByLayers = action.payload;
    },
    setFiltersToLabelMap: (state, action) => {
      state.filtersToLabelMap = action.payload;
    },
    setFilterValues: (state, action) => {
      state.filterValues = action.payload;
    },
    setFilters: (state, action) => {
      const filtersToSet = action.payload;
      state.filters = mergeFilters(filtersToSet, state.filters);
    },
    setInFront: (state, action) => {
      state.inFront = action.payload;
    },
    setZoomOut: (state, action) => {
      state.zoomOut = action.payload;
    },
    setZoomInTileData: (state, action) => {
      state.zoomInTileData = action.payload;
    },
    triggerRerender: (state, action) => {
      const index = action.payload;
      state.triggerRerender[`heatMap${index}`] = {
        count: state.triggerRerender[`heatMap${index}`].count++,
      };
    },
    addSignal: (state, action) => {
      const duplicate = state.selectedSignals.filter((selectedSignal) =>
        _.isEqual(selectedSignal, action.payload)
      );
      if (!duplicate.length) {
        state.selectedSignals.push(action.payload);
      }
    },
    removeSignal: (state, action) => {
      const indexToRemove = state.selectedSignals.findIndex((signal) =>
        _.isEqual(signal, action.payload)
      );
      state.selectedSignals.splice(indexToRemove, 1);
      if (state.selectedSignals.length) {
        state.triggerRerender[`heatMap${state.inFront}`] = {
          count: state.triggerRerender[`heatMap${state.inFront}`].count++,
        };
      }
    },
    removeAllCharts: (state) => {
      state.selectedSignals = [];
    },
    setCustomLayers: (state, action) => {
      state.customLayers = action.payload;
    },
    setWarningMessage: (state, action) => {
      state.warningMessage = action.payload;
    },
    upOneLayer: (state) => {
      state.depthConstraint = state.depthConstraint - 1;
    },
    updateWarning: (state, action) => {
      state.warning = { ...state.warning, ...action.payload };
    },
    setShowHeatMapChart: (state, action) => {
      state.showHeatMapChart = action.payload;
    },
    setApiLoadingProgress: (state, action) => {
      if (action.payload.action === "reset") {
        state.apiLoadingProgress = 0;
      } else if (action.payload.action === "increment") {
        state.apiLoadingProgress = Math.min(
          state.apiLoadingProgress + action.payload.value,
          100
        );
        if (Math.round(state.apiLoadingProgress) === 100)
          state.apiLoadingProgress = 100;
      }
    },
    setTransitioning: (state, action) => {
      state.transitioning = action.payload;
    },
    setLeftPanelExpanded: (state, action) => {
      state.leftPanelExpanded = action.payload;
    },
    setRightPanelExpanded: (state, action) => {
      state.rightPanelExpanded = action.payload;
    },
    setDrillDownBy: (state, action) => {
      state.drillDownBy = action.payload;
    },
    setDepthConstraint: (state, action) => {
      state.depthConstraint = action.payload;
    },
    setUpdatePattern: (state, action) => {
      state.updatePattern = action.payload;
    },
    setSignals: (state, action) => {
      const { signals, signalsCount } = action.payload;
      state.signals = signals;
      state.signalsCount = signalsCount;
    },
    setHeatmapData: (state, action) => {
      const dataKeys = [
        "initialFilters",
        "initialDrillDownBy",
        "initialDepthConstraint",
        "filterValues",
        "filters",
        "filterValuesByLayers",
        "filtersToLabelMap",
        "signals",
        "signalsCount",
        "drillDownBy",
        "depthConstraint",
      ];

      for (let key of dataKeys) {
        const keyData = action.payload[key];

        if (keyData !== undefined) {
          state[key] = keyData;
        }
      }
    },
  },
});

export const {
  setInFront,
  setZoomInTileData,
  triggerRerender,
  addSignal,
  removeSignal,
  removeAllCharts,
  setCustomLayers,
  setWarningMessage,
  upOneLayer,
  updateWarning,
  setShowHeatMapChart,
  setApiLoadingProgress,
  setApiLastSyncTime,
  setTransitioning,
  setLeftPanelExpanded,
  setRightPanelExpanded,
  setDrillDownBy,
  setZoomOut,
  setFilterValues,
  setFilters,
  setDepthConstraint,
  setUpdatePattern,
  setSignals,
  setHeatmapData,
  setFilterValuesByLayers,
  setFiltersIsChanged,
} = heatMapSlice.actions;

export default heatMapSlice.reducer;

export const SignalType = {
  PATTERN: "pattern",
  INDICATOR: "indicator",
  ALL: null,
};

export const changeFilter = (filterObj) => (dispatch, getState) => {
  dispatch(setFilters(filterObj));

  if (filterObj.signalType !== undefined) {
    const selectedTypes = [];
    if (filterObj.signalType === SignalType.ALL) {
      selectedTypes.push(...[SignalType.INDICATOR, SignalType.PATTERN]);
    } else {
      selectedTypes.push(filterObj.signalType);
    }

    const { filters, filterValuesByLayers, filterValues } = getState().heatMap;

    let newModelIdsFilters;

    if (filterObj.signalType === SignalType.ALL) {
      newModelIdsFilters = filters.modelIds;
    } else {
      newModelIdsFilters = filters.modelIds.filter((mId) => {
        return filterValues.modelIdToNameMap[mId].type === filterObj.signalType;
      });
    }

    const newFilters = mergeFilters({ modelIds: newModelIdsFilters }, filters);

    const modelIdsFilterLayerValues = buildModelIdFilterLayer(
      filterValues.modelIdToNameMap,
      filterObj.signalType
    );

    dispatch(
      setHeatmapData({
        filters: newFilters,
        filterValuesByLayers: {
          ...filterValuesByLayers,
          modelIds: modelIdsFilterLayerValues,
        },
      })
    );
  }
};

export const updatedDrillDownBy = (drillDownBy) => (dispatch, getState) => {
  const { filters, filterValues } = getState().heatMap;

  const filterValuesByLayers = buildFilterByLayers(
    drillDownBy,
    filters,
    filterValues
  );

  dispatch(setHeatmapData({ drillDownBy, filterValuesByLayers }));
};

export const selectLayerFilter =
  (valuesToSelect, isSelectedNow, layer) => (dispatch, getState) => {
    const { filters, filterValues, drillDownBy } = getState().heatMap;

    let layerFilters;

    const isTickerFilter = filterValues.tickerFields.includes(layer);

    if (isTickerFilter) {
      layerFilters = [...(filters.ticker[layer] || [])];
    } else {
      layerFilters = [...filters[layer]];
    }

    for (let filterValue of valuesToSelect) {
      if (!isSelectedNow) {
        if (!layerFilters.includes(filterValue.value)) {
          layerFilters.push(filterValue.value);
        }
      } else {
        const index = layerFilters.indexOf(filterValue.value);
        if (index > -1) {
          layerFilters.splice(index, 1);
        }
      }
    }

    const filterObToSet = {};

    if (isTickerFilter) {
      const tickersToSet = { ...filters.ticker };
      tickersToSet[layer] = layerFilters;
      filterObToSet.ticker = tickersToSet;
    } else {
      filterObToSet[layer] = layerFilters;
    }

    const filtersToSet = mergeFilters(filterObToSet, filters);
    const filterValuesByLayers = buildFilterByLayers(
      drillDownBy,
      filtersToSet,
      filterValues
    );

    dispatch(
      setHeatmapData({
        filters: filtersToSet,
        filterValuesByLayers: filterValuesByLayers,
      })
    );
  };

export const populateHeatMap = (config) => async (dispatch, getState) => {
  const heatMap = getState().heatMap;

  let filtersFromConfig = {};

  const models = await Api.getHeatMapModels();
  incrementApiLoadingProcess(dispatch);

  const availbaleModelIds = models.models.map((m) => m.id);

  if (config) {
    filtersFromConfig = {
      signalType: config.filters.signal_type,
      status: config.filters.status,
      entryDirection: config.filters.entry_direction,
      modelIds: config.filters.model_ids?.filter((mId) => availbaleModelIds.includes(mId)),
      intervals: config.filters.intervals,
      ticker: JSON.parse(config.filters.ticker),
      minSignalValue: config.filters.min_signal_value,
      maxSignalValue: config.filters.max_signal_value,
      firstBar: config.filters.first_bar,
      lastBar: config.filters.last_bar,
      signalsOnChartCount: config.filters.signals_on_chart_count,
      showMissingSignals: config.filters.show_missing_signals,
    };
  }

  const filters = mergeFilters(filtersFromConfig, heatMap.filters);
  const initialFilters = { ...filters };

  const { items: signals, count: signalsCount } = await Api.getSignals(filters);
  const drillDownBy = config?.drill_down_by || heatMap.drillDownBy;
  const depthConstraint = config?.depth_constraint || heatMap.depthConstraint;

  const { filterValues, filtersToLabelMap, filterValuesByLayers } =
    await buildFilterValues(filters, drillDownBy, models, dispatch);

  dispatch(
    setHeatmapData({
      initialDepthConstraint: depthConstraint,
      initialDrillDownBy: drillDownBy,
      initialFilters,
      filterValues,
      filters,
      filtersToLabelMap,
      filterValuesByLayers,
      signals,
      signalsCount,
      drillDownBy,
      depthConstraint,
    })
  );
  dispatch(setFiltersIsChanged(false));
  incrementApiLoadingProcess(dispatch);
};

export const fetchSignals = () => async (dispatch, getState) => {
  dispatch(setApiLoadingProgress({ action: "reset" }));

  const filters = getState().heatMap.filters;

  const signalsResponse = await Api.getSignals(filters);

  dispatch(
    setSignals({
      signals: signalsResponse.items,
      signalsCount: signalsResponse.count,
    })
  );

  incrementApiLoadingProcess(dispatch, 100);
};

const buildFilterValues = async (filters, drillDownBy, models, dispatch) => {
  const tickers = await Api.getTickers();
  incrementApiLoadingProcess(dispatch);

  const indicators = await Api.getIndicatorList();
  incrementApiLoadingProcess(dispatch);

  const filtersToLabelMap = {
    intervals: "Interval",
    modelIds: "Signal Type",
  };

  for (let tickerField of tickers.ticker_fields) {
    filtersToLabelMap[tickerField] = _.startCase(tickerField);
  }

  const modelIdToNameMap = {};

  for (let model of models.models) {
    modelIdToNameMap[model.id] = {
      name: model.name,
      signalType: SignalType.PATTERN,
    };
  }

  for (let indicatorId of indicators.indicator_ids) {
    modelIdToNameMap[indicatorId] = {
      name: indicatorId,
      signalType: SignalType.INDICATOR,
    };
  }

  const filterValues = {
    intervals: tickers.intervals,
    modelIdToNameMap: modelIdToNameMap,
    tickers: tickers.items,
    tickerFields: tickers.ticker_fields,
  };

  const filterValuesByLayers = buildFilterByLayers(
    drillDownBy,
    filters,
    filterValues
  );

  return { filterValues, filtersToLabelMap, filterValuesByLayers };
};

const buildFilterByLayers = (drillDownBy, filters, filterValues) => {
  const filterValuesByLayers = {};

  let previousTickerLayers = [];

  for (let layer of drillDownBy) {
    if (filterValues.tickerFields.includes(layer)) {
      let filteredTickers = filterValues.tickers;

      for (let previousTickerLayer of previousTickerLayers) {
        const previousFilters = filters.ticker[previousTickerLayer] || [];
        if (previousFilters.length > 0) {
          filteredTickers = filteredTickers.filter((t) => {
            return previousFilters.includes(t[previousTickerLayer]);
          });
          break;
        }
      }

      const uniqueTickerLayerValues = new Set(
        filteredTickers.map((ticker) => ticker[layer])
      );

      filterValuesByLayers[layer] = [...uniqueTickerLayerValues].map((v) => {
        return { label: v, value: v };
      });

      previousTickerLayers.unshift(layer);
    } else if (layer === "intervals") {
      filterValuesByLayers[layer] = filterValues.intervals.map((interval) => {
        return {
          label: minutesToStrRepr(interval),
          value: interval,
        };
      });
    } else if (layer === "modelIds") {
      filterValuesByLayers[layer] = buildModelIdFilterLayer(
        filterValues.modelIdToNameMap,
        filters.signalType
      );
    }
  }

  return filterValuesByLayers;
};

const buildModelIdFilterLayer = (modelIdToNameMap, selectedSignalType) => {
  const modelFilterLayerValues = [];

  Object.entries(modelIdToNameMap).forEach(([id, { name, signalType }]) => {
    if (
      selectedSignalType === SignalType.ALL ||
      selectedSignalType === signalType
    ) {
      modelFilterLayerValues.push({
        label: name,
        value: id,
      });
    }
  });

  return modelFilterLayerValues;
};

export const resetApiLoadingProgress = (dispatch) => {
  dispatch(setApiLoadingProgress({ action: "reset" }));
};

export const incrementApiLoadingProcess = (
  dispatch,
  value = API_LOADING_PROCESS_STEP
) => {
  dispatch(
    setApiLoadingProgress({
      action: "increment",
      value: value,
    })
  );
};

const mergeFilters = (filtersToSet, oldFilters) => {
  const newFilters = { ...oldFilters };

  for (let filterName of Object.keys(oldFilters)) {
    const filterValueToSet = filtersToSet[filterName];

    if (filterValueToSet !== undefined) {
      newFilters[filterName] = filterValueToSet;
    }
  }
  return newFilters;
};
