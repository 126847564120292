import React from "react";
import { useDispatch } from "react-redux";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import { StyledFullScreenButton } from "./ButtonStyles";
import { setFullScreenChart } from "../../../../../../../redux/slices/chartLayout";

export default function FullScreenButton({ isFullScreen, chart }) {
  const dispatch = useDispatch();
  const onClick = () => {
    if (isFullScreen) {
      dispatch(setFullScreenChart(null));
      chart.setCursor();
    } else {
      dispatch(setFullScreenChart(chart.chartId));
    }
  };
  return (
    <>
      <StyledFullScreenButton onClick={onClick} size="small">
        {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </StyledFullScreenButton>
    </>
  );
}
