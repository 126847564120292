import { useMemo } from "react";

const MARGIN_TOP = 0.2;
const MARGIN_BOTTOM = 0.1;

const subtractMargins = (height) => {
  return height * (1 - MARGIN_TOP - MARGIN_BOTTOM);
};

const getMaxMinPrice = (bars) => {
  let maxPrice = -Infinity;
  let minPrice = Infinity;
  for (let i = 0; i < bars.length; i++) {
    if (bars[i].high > maxPrice) maxPrice = bars[i].high;
    if (bars[i].low < minPrice) minPrice = bars[i].low;
  }
  return [maxPrice, minPrice];
};

export const useDimensions = (filteredBars, height, width) => {
  return useMemo(() => {
    if (!filteredBars) return;
    const maxHeight = subtractMargins(height);
    const xRange = filteredBars.length - 1;
    const [maxY, minY] = getMaxMinPrice(filteredBars);
    const yRange = maxY - minY;

    return {
      height: maxHeight,
      maxY,
      width,
      xRange,
      yRange,
      marginTop: 0.2 * height,
    };
  }, [filteredBars, height, width]);
};
