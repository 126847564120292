import styled from "styled-components";

export const TileChartContainer = styled.div`
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
`;
export const StyledTileChart = styled.div`
  box-sizing: border-box;
  padding: 5px;
  padding-top: 25px;
  //hide indicator header if not fullscreen:
  margin-top: ${(props) => (props.isFullScreen ? 0 : "-40px")};
  height: ${(props) => (props.isFullScreen ? "100%" : "calc(100% + 40px)")};
  width: 100%;
  position: relative;

  z-index: 1;
`;
