import LocalStorage from "../localStorage";

const retrieveBookmarks = () => {
  const localStorageBookmarks = LocalStorage.getBookmarks();

  return localStorageBookmarks
    ? localStorageBookmarks
    : [null, null, null, null];
};

export default retrieveBookmarks;
