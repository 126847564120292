import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";

import CustomButton from "../../UI/Button/CustomButton";
import CustomSpinner from "../../UI/Spinner/CustomSpinner";
import { onSaveModel } from "../../../redux/slices/models";

function SaveModel(props) {
  const dispatch = useDispatch();

  const modelToRetrain = useSelector(({ models }) => models.retrainModel);

  let initialName = "";

  if (modelToRetrain) {
    initialName = modelToRetrain.name;
  }

  const [name, setName] = useState(initialName);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [apiError, setApiError] = useState(null);

  const handleChange = (e) => {
    setName(e.target.value);
    setError(false);
  };

  const handleSave = () => {
    if (name.length === 0) {
      setError(true);
    } else {
      setWaiting(true);
      dispatch(onSaveModel(name, setApiError, setSuccess));
      setWaiting(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };
  return (
    <Dialog
      classes={{ paper: "save-model-modal " }}
      open={true}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      onKeyDown={handleKeyDown}
    >
      <DialogTitle id="form-dialog-title">Save Model</DialogTitle>
      <DialogContent classes={{ root: "save-model-modal-content" }}>
        <TextField
          classes={{ root: "custom-input" }}
          error={error}
          helperText={error ? "Please, fill out this field" : ""}
          label="Model name"
          value={name}
          onChange={handleChange}
        />
      </DialogContent>
      <div className="delete-container">
        {success && (
          <div className="delete-success">Model saved successfully!</div>
        )}
        {apiError && (
          <div className="delete-error">{apiError}</div>
        )}
        {waiting && <CustomSpinner />}
      </div>
      {!success && (
        <DialogActions>
          <CustomButton variant="outlined" onClick={props.onClose}>
            Cancel
          </CustomButton>
          <CustomButton variant="outlined" onClick={handleSave}>
            Save model
          </CustomButton>
        </DialogActions>
      )}
      {success && (
        <DialogActions>
          <CustomButton variant="outlined" onClick={props.onClose}>
            OK
          </CustomButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
export default SaveModel;
