import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSlider from "../../../UI/Slider/CustomSlider";
import { changeFilter } from "../../../../redux/slices/heatMap";

export default function ConfidenceRangeSlider({ resetTrigger }) {
  const minSignalValue = useSelector(({heatMap}) => heatMap.filters.minSignalValue) 
  
  const dispatch = useDispatch();

  // Hander functions
  const handleSliderChange = (event, newValue) => {
    dispatch(changeFilter({ minSignalValue: newValue }));
  };

  const valuetext = (value) => value / 200 + 0.5;

  return (
    <CustomSlider
      value={minSignalValue}
      onChange={handleSliderChange}
      valueLabelDisplay="on"
      aria-labelledby="range-slider"
      getAriaValueText={valuetext}
      step={0.01}
      min={0.5}
      max={1}
    />
  );
}
