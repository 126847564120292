import React from "react";
import { useSelector } from "react-redux";

import ModelsDetect from "./ModelsDetect";
import ModelsTrain from "./ModelsTrain";

import { MenuContainer } from "../LeftPanelStyle";

export default function ModelsTab({ selected }) {
  // Select from redux store
  const reduxMode = useSelector(({ display }) => display.mode);
  const mode = reduxMode === "detectPatterns" ? "detect" : "train";

  return (
    <MenuContainer id="menu-container-1" selected={selected}>
      {
        {
          detect: <ModelsDetect />,
          train: <ModelsTrain />,
        }[mode]
      }
    </MenuContainer>
  );
}
