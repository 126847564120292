import { useMemo } from "react";

export const useGetPatternLines = (filteredBars, patterns, dimensions) => {
  return useMemo(() => {
    if (!filteredBars || !patterns.length) return [];

    const getLeftPixels = (x) => {
      return (dimensions.width * x) / dimensions.xRange;
    };
    const getTopPixels = (y) => {
      return (dimensions.height * (dimensions.maxY - y)) / dimensions.yRange;
    };

    const getLineInfo = (x1, y1, x2, y2) => {
      const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
      const centerX = (x1 + x2) / 2 - length / 2;
      const centerY = (y1 + y2) / 2 - 0.5;
      const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
      return {
        centerX: +centerX.toFixed(2),
        centerY: +centerY.toFixed(2),
        length: +length.toFixed(2),
        angle: +angle.toFixed(4),
      };
    };

    const findIndex = (time) => {
      const convertedTime = time;
      return filteredBars.findIndex((bar) => bar.time === convertedTime);
    };

    const trendLines = patterns
      .filter((pattern) => pattern.shape_type === "trend_line")
      .map((pattern) => {
        return [pattern.points[0], pattern.points[pattern.points.length - 1]];
      });

    return trendLines.map((line) => {
      const x1 = getLeftPixels(findIndex(line[0].time));
      const x2 = getLeftPixels(findIndex(line[1].time));
      const y1 = getTopPixels(line[0].price);
      const y2 = getTopPixels(line[1].price);
      return getLineInfo(x1, y1, x2, y2);
    });
  }, [filteredBars, patterns, dimensions]);
};
