import React, { useState } from "react";
import { Dialog, Grow } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Button from "../../../UI/Button/CustomButtonLeftPanelBlue";
import AwesomeSlider from "react-awesome-slider";
import AwsSliderStylesCore from "react-awesome-slider/src/core/styles.scss";
import AwsSliderStyles from "react-awesome-slider/src/styled/fold-out-animation";

import { HelpContainer } from "./HelpStyles";

import HelpPage1 from "./HelpPage1";
import HelpPage2 from "./HelpPage2";
import HelpPage3 from "./HelpPage3";
import HelpPage4 from "./HelpPage4";
import HelpPage5 from "./HelpPage5";
import HelpPage6 from "./HelpPage6";
import HelpPage7 from "./HelpPage7";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export default function Help({ mode }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <>
          <HelpOutlineIcon />
          <div style={{ marginLeft: 10 }}>Guide</div>
        </>
      </Button>

      <Dialog
        maxWidth={false}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <HelpContainer>
          {open && (
            <AwesomeSlider
              cssModule={[AwsSliderStylesCore, AwsSliderStyles]}
              animation="foldOutAnimation"
            >
              {mode === "drawModelForTraining" ? (
                <div>
                  <HelpPage1 />
                </div>
              ) : (
                <div>
                  <HelpPage2 />
                </div>
              )}

              <div>
                <HelpPage3 />
              </div>
              <div>
                <HelpPage4 />
              </div>
              <div>
                <HelpPage5 />
              </div>
              <div>
                <HelpPage6 />
              </div>
              <div>
                <HelpPage7 />
              </div>
            </AwesomeSlider>
          )}
        </HelpContainer>
      </Dialog>
    </>
  );
}
