import { useEffect, useRef } from "react";
import { useSPatternsByChart } from "../../../../GlobalHooks/useSPatternsByChart";

export const useControlledReset = ({ chart }) => {
  //if user chooses to reset all patterns, all selected patterns need to be removed
  const storeSelectedPatterns = useSPatternsByChart(chart.chartId);
  //we need to keep track of previous patterns so we know what to remove
  const prevPatterns = useRef(storeSelectedPatterns);
  useEffect(() => {
    if (!chart.ready || prevPatterns.current === storeSelectedPatterns) return;

    if (!storeSelectedPatterns.length) {
      //only reset patterns if the store is now empty
      prevPatterns.current.forEach((pattern) => {
        chart.removeShape(pattern.id);
      });
    }

    prevPatterns.current = storeSelectedPatterns;
  }, [storeSelectedPatterns, chart]);
};
