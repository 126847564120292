const findClosestMatchedPattern = (pattern, matchedArray) => {
  for (let signal of matchedArray) {
    if (signal.model_name === pattern.model) return signal;
  }

  return matchedArray.reduce((acc, el) => {
    const confidenceDifference = Math.abs(el.confidence - pattern.confidence);
    const prevMinConfidenceDifference = Math.abs(
      acc.confidence - pattern.confidence
    );

    return confidenceDifference < prevMinConfidenceDifference ? el : acc;
  }, matchedArray[0]);
};

export default findClosestMatchedPattern;
