import React from "react";
import styled from "styled-components";
import useStatusFieldValue from "./hooks/useStatusFieldValue";

const Container = styled.div`
  background-color: rgb(32, 32, 32);
  background: linear-gradient(black 0%, #222222 10%, #333333 50%, #black 100%);
  -moz-box-shadow: 2px 2px 3px #000000;
  -webkit-box-shadow: 2px 2px 3px #000000;
  box-shadow: 2px 2px 3px #000000;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-height: 90px;
  justify-content: space-evenly;
  padding: 10px 30px;
  margin-bottom: 20px;

  p {
    margin: 0;
  }
`;

export default function Terminal() {
  // Custom hooks
  const statusFieldValue = useStatusFieldValue();

  return <Container>{statusFieldValue}</Container>;
}
