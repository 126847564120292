import { createSlice } from "@reduxjs/toolkit";

const toolbarSlice = createSlice({
  name: "toolbar",
  initialState: {
    trained: false,
    selectedModel: false,
    modalWarning: "",
    isWarningModalOpen: false,
    isSaveModelModalOpen: false,
    actionModel: null,
    isEditModalOpen: false,
    isDeleteModelModalOpen: false,
    isDeployModelModalOpen: false,
  },
  reducers: {
    setTrained: (state, action) => {
      state.trained = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setModalWarning: (state, action) => {
      state.modalWarning = action.payload;
    },
    setIsWarningModalOpen: (state, action) => {
      state.isWarningModalOpen = action.payload;
    },
    setIsEditModalOpen: (state, action) => {
      state.isEditModalOpen = action.payload;
    },
    setIsSaveModelModalOpen: (state, action) => {
      state.isSaveModelModalOpen = action.payload;
    },
    setIsDeleteModelModalOpen: (state, action) => {
      state.isDeleteModelModalOpen = action.payload;
    },
    setActionModel: (state, action) => {
      state.actionModel = action.payload;
    },
    setIsDeployModalOpen: (state, action) => {
      state.isDeployModelModalOpen = action.payload;
    },
  },
});

export const {
  setTrained,
  setSelectedModel,
  setModalWarning,
  setIsWarningModalOpen,
  setIsEditModalOpen,
  setIsSaveModelModalOpen,
  setIsDeleteModelModalOpen,
  setActionModel,
  setIsDeployModalOpen,
} = toolbarSlice.actions;

export default toolbarSlice.reducer;
