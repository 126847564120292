import { Utils } from "../../../../utils/utils";
import _ from "lodash";

export default class WidgetClass {
  constructor({ instance, chartId, chartName, interval }) {
    this.instance = instance;
    this.chartId = chartId;
    this.chartName = chartName;
    this.interval = interval;
    this.ready = true;
  }

  //
  // ─── SHAPES ────────────────────────────────────────────────────────────────
  //
  removeShape(id) {
    this.autoRemove = true; //flag to differentiate between auto removal and manual remove
    this.instance.chart().removeEntity(id);
  }

  drawPreviewShapes({ points, color }) {
    const start = this.previewBars
      ? this.findClosestPreviewBar(points[0].time)
      : points[0].time;
    const end = this.previewBars
      ? this.findClosestPreviewBar(points[1].time)
      : points[1].time;
    const midPoint = this.previewBars
      ? this.findClosestPreviewBar((start + end) / 2)
      : (start + end) / 2;
    const rectangle = this.instance.chart().createMultipointShape(
      [
        {
          time: start,
          price: points[0].price,
        },
        {
          time: end,
          price: points[1].price,
        },
      ],
      {
        shape: "rectangle",
        lock: true,
        zOrder: "top",
        disableSelection: true,
        overrides: {
          backgroundColor: color,
          color,
        },
      }
    );
    const noteIcon = this.instance.chart().createMultipointShape(
      [
        {
          time: midPoint,
          price: this.priceRange.top,
        },
      ],
      {
        shape: "note",
        lock: true,
        zOrder: "top",
        disableSelection: true,
        overrides: {
          markerColor: color,
        },
      }
    );
    const line = this.instance.chart().createMultipointShape(
      [
        {
          time: midPoint,
          price: this.priceRange.top,
        },
        {
          time: midPoint,
          price: Math.max(points[0].price, points[1].price),
        },
      ],
      {
        shape: "price_range",
        lock: true,
        zOrder: "top",
        disableSelection: true,
        overrides: {
          fillLabelBackground: false,
          textcolor: "transparent",
        },
      }
    );
    return [rectangle, noteIcon, line];
  }
  drawRectangle({ points, cfg }) {
    if (!cfg.showBoxes) return [];
    const entityID = this.instance.chart().createMultipointShape(points, {
      shape: "rectangle",
      overrides: {
        backgroundColor: cfg.color,
        color: "rgba(0, 0, 0, 0)",
      },
      lock: cfg.lock,
    });
    return [
      {
        entityID,
        patternID: cfg.id,
      },
    ];
  }
  drawLineWithIntersections({ points, cfg }) {
    if (!cfg.showLines) return [];

    const newShapes = [];
    const start = points[0];
    const end = points[points.length - 1];
    const intersections = points.slice(1, points.length - 1);
    const trendLine = this.instance
      .chart()
      .createMultipointShape([start, end], {
        shape: "trend_line",
        overrides: {
          linecolor: "#FFF",
          linestyle: 1,
          linewidth: 4,
        },
        lock: true,
      });
    newShapes.push({
      entityID: trendLine,
      patternID: null,
    });
    intersections.forEach((intersection) => {
      const newIntersection = this.instance
        .chart()
        .createMultipointShape([intersection], {
          shape: "icon",
          overrides: {
            color: "#1E7CFF",
            size: 25,
            icon: 61708,
          },
          lock: true,
        });
      newShapes.push({
        entityID: newIntersection,
        patternID: null,
      });
    });
    return newShapes;
  }
  drawContextLine({ points, cfg }) {
    if (!cfg.showLines) return [];

    const entityID = this.instance.chart().createMultipointShape(points, {
      shape: "trend_line",
      overrides: {
        linecolor: "green",
        linestyle: 1,
        linewidth: 2,
      },
      lock: true,
    });
    return [
      {
        entityID,
        patternID: null,
      },
    ];
  }
  drawHeight({ points, cfg }) {
    if (!cfg.showLines) return [];

    const entityID = this.instance.chart().createMultipointShape(points, {
      shape: "trend_line",
      overrides: {
        linecolor: "#AD5BFF",
        leftEnd: 1,
        rightEnd: 1,
        linestyle: 2,
        linewidth: 2,
      },
      lock: true,
    });
    return [
      {
        entityID,
        patternID: null,
      },
    ];
  }
  drawEntryPoint({ points, height }) {
    // const HEIGHT_RATIO = 0.7; //ratio of entry point line to pattern height
    // const adjustedPriceDiff = height * HEIGHT_RATIO;
    const newShapes = [];
    // const secondPoint = {
    //   time: points[0].time,
    //   price: points[0].price + adjustedPriceDiff,
    // };
    // const verticalLine = this.instance
    //   .chart()
    //   .createMultipointShape([points[0], secondPoint], {
    //     shape: "trend_line",
    //     overrides: {
    //       linecolor: "#AD5BFF",
    //       leftEnd: 1,
    //       rightEnd: 1,
    //       linestyle: 2,
    //       linewidth: 2,
    //     },
    //     lock: true,
    //   });
    // newShapes.push({ entityID: verticalLine, patternID: null });

    // const textPoint = [
    //   {
    //     time: points[0].time + this.interval * 60,
    //     price: points[0].price + adjustedPriceDiff / 2,
    //   },
    // ];

    // const text = this.drawText({ point: textPoint, text: "tp" });
    // newShapes.push(text);

    const horizontalPoints1 = [
      {
        time: points[0].time - this.interval * 300,
        price: points[0].price,
      },
      {
        time: points[0].time + this.interval * 300,
        price: points[0].price,
      },
    ];
    const horizontalLine1 = this.instance
      .chart()
      .createMultipointShape(horizontalPoints1, {
        shape: "trend_line",
        overrides: {
          linecolor: "#AD5BFF",
          linestyle: 0,
          linewidth: 5,
        },
        lock: true,
      });
    newShapes.push({ entityID: horizontalLine1, patternID: null });

    // const horizontalPoints2 = [
    //   {
    //     time: points[0].time - this.interval * 300,
    //     price: secondPoint.price,
    //   },
    //   {
    //     time: points[0].time + this.interval * 300,
    //     price: secondPoint.price,
    //   },
    // ];
    // const horizontalLine2 = this.instance
    //   .chart()
    //   .createMultipointShape(horizontalPoints2, {
    //     shape: "trend_line",
    //     overrides: {
    //       linecolor: "#AD5BFF",
    //       linestyle: 0,
    //       linewidth: 2,
    //     },
    //     lock: true,
    //   });
    // newShapes.push({ entityID: horizontalLine2, patternID: null });

    // const textPoint = [
    //   {
    //     time: points[0].time - this.interval * 60,
    //     price: points[0].price,
    //   },
    // ];

    // const text = this.drawText({ point: textPoint, text: "entry" });
    // newShapes.push(text);

    return newShapes;
  }
  drawText({ point, text }) {
    const entityID = this.instance.chart().createMultipointShape(point, {
      shape: "text",
      text,
      overrides: {
        color: "white",
        fontsize: 12,
      },
      lock: true,
    });
    return { entityID, patternID: null };
  }
  drawArrowUpEndingAtPoint({ point }) {
    return this.instance.chart().createMultipointShape(point, {
      shape: "arrow_up",
      lock: false,
    });
  }
  drawArrowDownEndingAtPoint({ point }) {
    return this.instance.chart().createMultipointShape(point, {
      shape: "arrow_down",
      lock: false,
    });
  }

  drawArrowUpStartingAtPoint({ point }) {
    return this.instance.chart().createMultipointShape(point, {
      shape: "icon",
      overrides: {
        color: "#318757",
        size: 35,
        icon: 61662,
      },
      lock: true,
    });
  }

  drawArrowDownStartingAtPoint({ point }) {
    return this.instance.chart().createMultipointShape(point, {
      shape: "icon",
      overrides: {
        color: "#ef5350",
        size: 35,
        icon: 61661,
      },
      lock: true,
    });
  }

  //
  // ─── PREVIEW CHART FUNCTIONS ────────────────────────────────────────────────────
  //

  findClosestPreviewBar(time) {
    for (let i = 0; i < this.previewBars.length; i++) {
      if (this.previewBars[i].time / 1000 > time) {
        return this.previewBars[i].time / 1000;
      }
    }

    return this.previewBars[this.previewBars.length - 1].time / 1000;
  }
  updateVisibleRange(range) {
    if (range) {
      //if we have preview bars (e.g. training charts), find the closest bar
      const start = this.previewBars
        ? this.findClosestPreviewBar(range.from)
        : range.from;
      const end = this.previewBars
        ? this.findClosestPreviewBar(range.to)
        : range.to;
      this.highlightedRange = { start, end };
    }
  }
  drawRangeIndicator() {
    if (!this.highlightedRange || !this.priceRange || !this.barsReady) return;
    if (this.rangeId) this.removeShape(this.rangeId);
    if (this.highlightedRange.start > this.start) {
      //keep track of range width for when we dispatch mouse clicks
      this.rangeWidth = this.highlightedRange.end - this.highlightedRange.start;
    }
    const points = [
      {
        price: this.priceRange.from,
        time: this.highlightedRange.start,
      },
      {
        price: this.priceRange.to,
        time: this.highlightedRange.end,
      },
    ];
    this.rangeId = this.instance.chart().createMultipointShape(points, {
      shape: "date_range",
      lock: true,
      disableSelection: true,
      zOrder: "bottom",
    });
  }
  getPreviewTargetRange() {
    if (this.mousePosition - this.rangeWidth / 2 < this.start) {
      //prevents chart from going outside data range
      this.mousePosition = this.start + this.rangeWidth / 2;
    }

    return {
      from: this.mousePosition - this.rangeWidth / 2,
      to: this.mousePosition + this.rangeWidth / 2,
      source: "preview",
    };
  }

  //
  // ─── MISC ───────────────────────────────────────────────────────────────────────
  //

  getSelection() {
    return this.instance.chart().selection().allSources();
  }
  setCursor() {
    this.instance.selectLineTool("arrow_cursor");
  }
  getShape(id) {
    return this.instance.chart().getShapeById(id);
  }
  getPoints(id) {
    return this.instance.chart().getShapeById(id).getPoints();
  }
  getAllShapes() {
    return this.instance.chart().getAllShapes();
  }
  getDrawingMenuStatus() {
    return this.instance
      .chart()
      .getCheckableActionState("drawingToolbarAction");
  }
  toggleDrawingMenu() {
    this.instance.chart().executeActionById("drawingToolbarAction");
  }
  getRange() {
    return this.instance.chart().getVisibleRange();
  }
  setRange({ from, to }, first = true) {
    this.instance.chart().setVisibleRange({ from, to });
    if (first) {
      this.setRange({ from, to }, false);
    }
  }
  setDefaultRange() {
    this.instance.chart().setVisibleRange({ from: this.start, to: this.end });
  }
  defaultRangeLast250(bars) {
    const startIndex = bars.length <= 250 ? 0 : bars.length - 251;
    this.start = bars[startIndex].time / 1000;
    this.end = bars[bars.length - 1].time / 1000;
  }
  defaultRangeFull(bars) {
    this.start = bars[0].time / 1000;
    this.end = bars[bars.length - 1].time / 1000;
  }
  updatePriceRange(bars) {
    let max = _.maxBy(bars, (bar) => bar.high).high;
    let min = _.minBy(bars, (bar) => bar.low).low;
    const range = max - min;
    this.priceRange = {
      from: min - range * 0.1,
      to: max + range * 0.2,
      top: max + range * 0.05,
    };
  }
  updatePreviewBars(previewBars) {
    this.previewBars = previewBars;
  }
  updateBars(symbol) {
    this.barsReady = false;
    const resolution = Utils.getResolutionForInterval(this.interval);
    this.instance.setSymbol(symbol, resolution, () => {
      this.setDefaultRange();
      this.barsReady = true;
    });
  }

  //
  // ─── SUBSCRIPTIONS ──────────────────────────────────────────────────────────────
  //

  onMouseClick(callback) {
    this.instance.subscribe("mouse_up", callback);
  }
  onRemoveShape(callback) {
    this.instance.subscribe("drawing_event", (shapeId, event) => {
      if (!this.autoRemove) {
        if (event === "remove") callback(shapeId);
      } else this.autoRemove = false;
    });
  }
  onMouseMove(callback) {
    this.instance.chart().crossHairMoved(callback);
  }
  onVisibleRangeChange(callback) {
    this.instance.chart().onVisibleRangeChanged().subscribe(null, callback);
  }
  onStudyChange(callback) {
    this.instance.subscribe("study", ({ value }) => {
      const newStudies = this.instance
        .chart()
        .getAllStudies()
        .map((study) => study.name);
      callback([...newStudies, value]);
    });
    this.instance.subscribe("study_event", (studyId, event) => {
      if (event === "remove") {
        const newStudies = this.instance
          .chart()
          .getAllStudies()
          .map((study) => study.name);
        callback(newStudies);
      }
    });
  }

  //
  // ─── STUDIES ──────────────────────────────────────────────────────────────
  //

  addStudies(studies) {
    for (let study of studies) {
      if (study !== "Volume") {
        try {
          this.instance.chart().createStudy(study);
        } catch (error) {
          console.log(`Unable to add study: ${study}`);
          console.log(error);
        }
      }
    }
  }
}
