import React from "react";
import { TextField } from "@material-ui/core";

export default function SearchCharts({ searchValue, setSearchValue }) {
  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div style={{ marginBottom: "30px" }}>
      <TextField
        label="Search charts"
        fullWidth
        value={searchValue}
        onChange={onSearch}
      />
    </div>
  );
}
