import styled from "styled-components";

const NoHeatMapContainer = styled.div`
  color: white;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 45%;
  transform: translate(-50%, -50%);
`;

export default NoHeatMapContainer;
