import React from "react";
import {
  Container,
  Title,
  SettingsContainer,
  SettingsTitle,
  Subtitle,
  Column,
} from "./SettingsInterfaceStyles";
import DrillDownConstraintSlider from "../DrillDownConstraintSlider/DrillDownConstraintSlider";

export default function Settingsinterface() {
  return (
    <Container>
      <Title>Settings</Title>
      <Subtitle>Heat map</Subtitle>
      <SettingsContainer>
        <Column left>
          <SettingsTitle>Max number of drill down layers</SettingsTitle>
        </Column>
        <DrillDownConstraintSlider />
      </SettingsContainer>
    </Container>
  );
}
