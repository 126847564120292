import { createSlice } from "@reduxjs/toolkit";
import { Utils } from "../../utils/utils";
import { Api } from "../../api/api";
import { setShowHeatMapChart, setLeftPanelExpanded } from "./heatMap";

const heatMapChartSlice = createSlice({
  name: "heatMapChart",
  initialState: {
    data: null,
    range: null,
    pattern: null,
  },
  reducers: {
    setChartVisibleRange: (state, action) => {
      state.range = action.payload;
    },
    clearHeatMapChart: (state) => {
      state.data = null;
      state.range = null;
      state.pattern = null;
    },
    setHeatMapChart: (state, action) => {
      const { chartData, bars } = action.payload;

      state.data = {
        id: chartData.id,
        chartData,
        bars,
        previewBars: null,
      };
    },
    setHeatMapChartSignal: (state, action) => {
      const { signal, modelName } = action.payload;

      if (signal.signal_type === "indicator") {
        state.pattern = {
          id: signal.id,
          indicator: true,
          patterns: [
            {
              indicator: modelName,
              shapes: signal.shapes.map((shape) => formatShape(shape)),
            },
          ],
        };
      } else {
        state.pattern = {
          id: signal.id,
          indicator: false,
          chartId: signal.chart_id,
          model: modelName,
          confidence: Math.abs(signal.signal),
          shapes: signal.shapes.map((shape) => formatShape(shape)),
          interval: signal.interval,
          midPoint: getMidPoint(signal.shapes[0].points),
          selected: false,
        };
      }
    },
    updateHeatMapChartPattern: (state, action) => {
      state.pattern = action.payload;
    },
  },
});

export const {
  setChartVisibleRange,
  clearHeatMapChart,
  setHeatMapChart,
  setHeatMapChartSignal,
  updateHeatMapChartPattern,
} = heatMapChartSlice.actions;

export default heatMapChartSlice.reducer;

export const showHeatMapChartWithSignal =
  (signal, filteredBars) => async (dispatch, getState) => {
    const chartWithAllBars = await Api.getChart(signal.chart_id, {});

    const { modelIdToNameMap } = getState().heatMap.filterValues;

    const modelName = modelIdToNameMap[signal.model_id].name;

    const range =
      filteredBars[filteredBars.length - 1].time - filteredBars[0].time;
    const navigationObject = {
      from: filteredBars[0].time - range,
      to: filteredBars[filteredBars.length - 1].time + range,
      source: "link",
    };

    dispatch(setHeatMapChart(chartWithAllBars));
    dispatch(setHeatMapChartSignal({signal, modelName}));
    dispatch(setChartVisibleRange(navigationObject));
    dispatch(setShowHeatMapChart(true));
    dispatch(setLeftPanelExpanded(0));
  };

// ────────────────────────────────────────────────────────────────────────────────

//
// ─── HELPER FUNCTIONS ───────────────────────────────────────────────────────────
//

const formatPoint = (point) => {
  return {
    price: point.price,
    time: Utils.convertDatetimeToTimestamp(point.datetime) / 1000,
  };
};

const formatShape = (shape) => {
  return {
    shape_type: shape.shape_type,
    points: shape.points.map((point) => formatPoint(point)),
  };
};

const getMidPoint = (points) => {
  const pointTimes = points.map((point) =>
    point.time ? point.time : Utils.convertDatetimeToTimestamp(point.datetime)
  );
  return pointTimes[1] ? (pointTimes[0] + pointTimes[1]) / 2 : pointTimes[0];
};
