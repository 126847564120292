import { configureStore } from "@reduxjs/toolkit";

import chartLayout from "./slices/chartLayout";
import login from "./slices/login";
import display from "./slices/display";
import models from "./slices/models";
import selectedPatterns from "./slices/selectedPatterns";
import selectedDetectedPatterns from "./slices/selectedDetectedPatterns";
import detectedPatterns from "./slices/detectedPatterns";
import coldStart from "./slices/coldStart";
import previewChart from "./slices/previewChart";
import navigation from "./slices/navigation";
import settings from "./slices/settings";
import chartData from "./slices/chartData";
import toolbar from "./slices/toolbar";
import heatMap from "./slices/heatMap";
import leftPanel from "./slices/leftPanel";
import grid from "./slices/grid";
import indicators from "./slices/indicators";
import heatMapChart from "./slices/heatMapChart";
import heatMapConfigs from "./slices/heatMapConfigs";
import chartCollections from "./slices/chartCollections";
import heatMapFavourites from "./slices/heatMapFavourites";

export default configureStore({
  reducer: {
    chartLayout,
    login,
    display,
    models,
    selectedPatterns,
    selectedDetectedPatterns,
    detectedPatterns,
    coldStart,
    previewChart,
    navigation,
    settings,
    chartData,
    toolbar,
    heatMap,
    leftPanel,
    grid,
    indicators,
    heatMapChart,
    heatMapConfigs,
    chartCollections,
    heatMapFavourites,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
