import styled from "styled-components";

export const NoChartsMessageContainer = styled.div`
  color: ${(props) => props.theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 2rem;
  height: 100%;
  width: 100%;
`;
export const NoChartsText = styled.div`
  margin-left: 20px;
`;
