import styled from "styled-components";
import { FormControl, FormHelperText, MenuItem } from "@material-ui/core";

export const StyledFormControl = styled(FormControl)`
  margin-bottom: 10px;
  padding: 5px;
  flex-grow: 1;
`;
export const StyledFormHelperText = styled(FormHelperText)`
  color: ${(props) => props.theme.primary};
`;
export const StyledMenuItem = styled(MenuItem)`
  border-radius: 5px;
  margin: 5px auto;
  position: relative;
  width: 97%;
  color: ${(props) => props.theme.primary};
`;
