const findLocalOptima = (bars) => {
  const reducer = (acc, bar, index) => {
    const prevBar = bars[index - 1] || null;
    const nextBar = bars[index + 1] || null;

    const isLocalMin = determineLocalOptima(
      bar.low,
      prevBar && prevBar.low,
      nextBar && nextBar.low,
      "min"
    );

    const isLocalMax = determineLocalOptima(
      bar.high,
      prevBar && prevBar.high,
      nextBar && nextBar.high,
      "max"
    );

    const newLocalMaxima = isLocalMax
      ? [...acc.localMaxima, bar]
      : acc.localMaxima;
    const newLocalMinima = isLocalMin
      ? [...acc.localMinima, bar]
      : acc.localMinima;

    return {
      localMaxima: newLocalMaxima,
      localMinima: newLocalMinima,
    };
  };

  const determineLocalOptima = (current, prev, next, maxOrMin) => {
    if (maxOrMin === "max") {
      return !((prev && prev > current) || (next && next > current));
    } else {
      return !((prev && prev < current) || (next && next < current));
    }
  };

  return bars.reduce(reducer, {
    localMaxima: [],
    localMinima: [],
  });
};

export default findLocalOptima;
