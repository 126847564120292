import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { IconButton } from "@material-ui/core";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { StyledLinkButton } from "./LinkButtonStyles";

import { addLayoutChart } from "../../../../redux/slices/chartLayout";
import { setChartDisplay } from "../../../../redux/slices/display";
import { navigate } from "../../../../redux/slices/navigation";

export default function LinkButton({ chartId, filteredBars }) {
  const dispatch = useDispatch();

  const onLinkClick = useCallback(
    (e) => {
      const range = {
        start: filteredBars[0].time * 1000,
        end: filteredBars[filteredBars.length - 1].time * 1000,
      };
      e.stopPropagation();
      if (!dispatch(addLayoutChart(chartId))) return;
      dispatch(setChartDisplay());
      dispatch(navigate(chartId, range));
    },
    [dispatch, chartId, filteredBars]
  );

  return (
    <StyledLinkButton onClick={onLinkClick}>
      <IconButton>
        <FindInPageIcon color="primary" />
      </IconButton>
    </StyledLinkButton>
  );
}
