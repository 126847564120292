import React, {Fragment} from "react";
import { useSelector } from "react-redux";

import IntervalSelect from "./IntervalSelect/IntervalSelect";
import CollectionList from "./CollectionList/CollectionList";
import ChartList from "./ChartList/ChartList";
import SearchBar from "./SearchBar/SearchBar";
import CustomSpinner from "../../UI/Spinner/CustomSpinner";
import { Grid } from "@material-ui/core";

import { ListTitle, MenuContainer } from "../LeftPanelStyle";

export default function ChartsTab({ selected }) {
  const isFetchingCollection = useSelector(
    ({ leftPanel }) => leftPanel.isFetchingCollection
  );

  return (
    <MenuContainer selected={selected}>
      <ListTitle>Security lists</ListTitle>
      <CollectionList />

      {isFetchingCollection && <Grid container justify="center"><CustomSpinner /></Grid>}

      {!isFetchingCollection && (
        <Fragment>
          <ListTitle>Charts</ListTitle>
          <IntervalSelect />
          <SearchBar />
          <ChartList selected={selected} />
        </Fragment>
      )}
    </MenuContainer>
  );
}
