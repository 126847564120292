import { useEffect } from "react";
import { createChart } from "lightweight-charts";

const CHART_OPTIONS = {
  priceScale: { position: "none" },
  timeScale: { visible: false },
  crosshair: {
    vertLine: { visible: false },
    horzLine: { visible: false },
  },
  handleScroll: false,
  handleScale: false,
  grid: {
    vertLines: {
      visible: false,
    },
    horzLines: {
      visible: false,
    },
  },
};
const SERIES_OPTIONS = {
  priceLineVisible: false,
  lastValueVisible: false,
};

function removeAllChildNodes(parent) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

export const useSetupChart = ({ filteredBars, id, height, width }) => {
  useEffect(() => {
    if (!filteredBars) return;
    const div = document.getElementById("heatmap_chart" + id);
    removeAllChildNodes(div);

    const chart = createChart("heatmap_chart" + id, {
      width: width,
      height: height,
    });

    const allChartOptions = {
      ...CHART_OPTIONS,
      layout: { backgroundColor: "rgb(38, 51, 64)" },
    };

    const candleSeries = chart.addCandlestickSeries();
    chart.applyOptions(allChartOptions);
    candleSeries.applyOptions(SERIES_OPTIONS);
    candleSeries.setData(filteredBars);
    chart.timeScale().fitContent();
  }, [filteredBars, id, height, width]);
};
