import _ from "lodash";

export default class layoutHelper {
  constructor(chartList, previousLayout) {
    this.maxSpaceOccupied = 0;
    this.largestMinArea = 0;
    this.minDeviation = 0;
    this.finalResult = null;
    this.defaultSize = 6;
    this.chartList = chartList;
    this.previousLayout = previousLayout;
    this.initialGrid = this.createEmptyLayout(this.defaultSize);
    this.counter = 0;
    this.id = 0;
    this.checkedIds = {};
  }

  createEmptyLayout(size) {
    const emptyLayout = [];
    for (let i = 0; i < size; i++) {
      const newRow = [];
      for (let j = 0; j < size; j++) {
        newRow.push(0);
      }
      emptyLayout.push(newRow);
    }
    return emptyLayout;
  }
  convertResults() {
    return this.finalResult.map((chart, index) =>
      this.convertChartFormat(chart, index)
    );
  }
  convertChartFormat(chart, index) {
    return {
      i: this.unlockedCharts[index], //chart id
      x: chart.column,
      y: chart.row,
      w: chart.width,
      h: chart.height,
    };
  }
  restrictY({ y, h, max }) {
    if (y + h <= max) return y; //chart fits in the grid
    return max - h; // return the y position that will allow the chart to fit on the screen
  }

  populateGridWithLockedCharts() {
    const lockedList = this.chartList
      .filter((chart) => chart.locked === true)
      .map((chart) => chart.id);

    this.gridWithLockedCharts = _.cloneDeep(this.initialGrid);
    const newLayout = [];
    this.previousLayout.forEach((chart) => {
      if (lockedList.includes(chart.i)) {
        const y = this.restrictY({
          y: chart.y,
          h: chart.h,
          max: this.defaultSize,
        });
        const h = Math.min(chart.h, this.defaultSize);
        this.occupySpace(this.gridWithLockedCharts, y, chart.x, chart.w, h);
        newLayout.push({ ...chart, y, h });
      }
    });
    return newLayout;
  }
  setUnlockedCharts() {
    this.unlockedCharts = this.chartList
      .filter((chart) => chart.locked === false)
      .map((chart) => chart.id);
    return this.unlockedCharts.length;
  }
  findLargestUniformSize() {
    //start with 3x3 size and gradually reduce it until all charts fit
    for (let width = 3; width > 1; width--) {
      //prevent height from being greater than width and
      //prevent aspect ratio from being too wide
      for (let height = width; height >= (width * 2) / 3; height--) {
        //see if we can fit all the shapes in:
        let result = this.setupInitialShapes(
          this.gridWithLockedCharts,
          this.unlockedCharts.length,
          width,
          height
        );
        if (result) {
          this.minX = width;
          this.minY = height;
          this.startingCharts = result[0];
          return;
        }
      }
    }
    return "error";
  }
  //checks if the specified space in the array is completely free
  checkAreaForSpace(array, startRow, startCol, width, height) {
    for (let i = startRow; i < startRow + height; i++) {
      for (let j = startCol; j < startCol + width; j++) {
        if (!array[i] || array[i][j] !== 0) return false;
      }
    }
    return true;
  }
  //Occupies the specified space
  occupySpace(array, startRow, startCol, width, height) {
    for (let i = startRow; i < startRow + height; i++) {
      for (let j = startCol; j < startCol + width; j++) {
        array[i][j] = 1;
      }
    }
  }
  //Finds the next available space in the array for a shape with given width and height
  findOpenSpace(array, width, height) {
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (this.checkAreaForSpace(array, i, j, width, height)) return [i, j];
      }
    }
    return false;
  }
  //Add additional shapes to the array
  setupInitialShapes(layoutArray, length, width = 1, height = 1) {
    const newLayoutArray = _.cloneDeep(layoutArray);
    const newCharts = [];
    for (let i = 0; i < length; i++) {
      const coords = this.findOpenSpace(newLayoutArray, width, height);
      if (!coords) return false;
      newCharts[i] = {
        row: coords[0],
        column: coords[1],
        width: width,
        height: height,
      };
      this.occupySpace(newLayoutArray, coords[0], coords[1], width, height);
    }
    return [newCharts, newLayoutArray];
  }

  optimiseStartingPosition() {
    //try to find as many 2x1 charts as possible
    let currentWidth = this.minX;
    let currentHeight = this.minY;
    if (currentHeight + 1 <= currentWidth) {
      currentHeight++;
    } else currentWidth++;

    for (let i = 1; i < this.unlockedCharts.length; i++) {
      let largerShapeResult = this.setupInitialShapes(
        this.gridWithLockedCharts,
        this.unlockedCharts.length - i,
        currentWidth,
        currentHeight
      ); //gradually reduce the number of 2x1 charts until they all fit.
      if (largerShapeResult) {
        const [largerShapeCharts, largerShapeGrid] = largerShapeResult;
        let originalShapeResult = this.setupInitialShapes(
          largerShapeGrid,
          i,
          this.minX,
          this.minY
        ); //check that we still have space for the remaining 1x1 charts
        if (originalShapeResult) {
          const [originalShapeCharts] = originalShapeResult;
          this.startingCharts = [...largerShapeCharts, ...originalShapeCharts];
          //prevent algorithm from adding 1x1 charts which is very inefficient
          if (this.minX === 1 && this.minY === 1) {
            this.minX = currentWidth;
            this.minY = currentHeight;
          }
        }
      }
    }
    return false;
  }
  checkMaxWidth(height, width) {
    if (width <= 2 * height) return true;
  }
  checkMaxHeight(height, width) {
    if (height <= width) return true;
  }
  generateMap(charts) {
    let newGrid = _.cloneDeep(this.gridWithLockedCharts);
    charts.forEach((chart) => {
      this.occupySpace(
        newGrid,
        chart.row,
        chart.column,
        chart.width,
        chart.height
      );
    });
    return newGrid;
  }
  analyzeCharts(charts) {
    let smallestChart = Infinity;
    let spaceOccupiedArray = [];
    let spaceOccupied = 0;
    charts.forEach((chart) => {
      const chartSize = chart.width * chart.height;
      spaceOccupiedArray.push(chartSize);
      spaceOccupied += chartSize;
      if (chartSize < smallestChart) smallestChart = chartSize;
    });
    return [smallestChart, spaceOccupiedArray, spaceOccupied];
  }
  getDeviation(charts, spaceOccupied, spaceOccupiedArray) {
    const mean = spaceOccupied / charts.length;
    const deviation = spaceOccupiedArray.reduce(
      (a, b) => a + Math.abs(mean - b),
      0
    );
    return deviation;
  }
  checkOptimalLayout(charts) {
    //ranking for optimal layout:
    //1. we want the smallest chart to be as big as possible.
    //we don't want to maximise space occupied if it means having 1 large chart and 2 tiny charts
    //2. space occupied.
    //3. deviation from the mean. We want to charts to be about the same size as each other

    const [smallestChart, spaceOccupiedArray, spaceOccupied] =
      this.analyzeCharts(charts);
    const totalDeviationFromMean = this.getDeviation(
      charts,
      spaceOccupied,
      spaceOccupiedArray
    );
    const updateGlobalVariables = () => {
      this.largestMinArea = smallestChart;
      this.maxSpaceOccupied = spaceOccupied;
      this.minDeviation = totalDeviationFromMean;
      this.finalResult = charts;
    };
    //1. smallest chart check
    if (smallestChart > this.largestMinArea) {
      updateGlobalVariables();
      return;
    }
    if (smallestChart < this.largestMinArea) return;
    //2.space occupied check
    if (spaceOccupied > this.maxSpaceOccupied) {
      updateGlobalVariables();
      return;
    }
    if (spaceOccupied < this.maxSpaceOccupied) return;
    //3. deviation check
    if (totalDeviationFromMean < this.minDeviation) {
      updateGlobalVariables();
    }
  }
  checkNewChart(chart, newCharts, newGrid, selectedIndex) {
    const openSpaceFound = this.findOpenSpace(
      newGrid,
      chart.width,
      chart.height
    );
    if (!openSpaceFound) return false;
    const mutatedGrid = _.cloneDeep(newGrid);
    chart.row = openSpaceFound[0];
    chart.column = openSpaceFound[1];
    this.occupySpace(
      mutatedGrid,
      chart.row,
      chart.column,
      chart.width,
      chart.height
    );
    //repopulate with the charts we sliced off earlier
    const chartResult = this.setupInitialShapes(
      mutatedGrid,
      this.unlockedCharts.length - 1 - selectedIndex,
      this.minX,
      this.minY
    );
    //check to make sure all the charts still fit
    if (!chartResult) return false;
    const remainingCharts = chartResult[0];
    const mutatedCharts = [...newCharts, chart, ...remainingCharts];
    return mutatedCharts;
  }
  checkRight(charts, newCharts, newGrid, selectedIndex) {
    const chart = _.cloneDeep(charts[selectedIndex]);
    chart.width++;

    if (!this.checkMaxWidth(chart.height, chart.width)) return false;
    return this.checkNewChart(chart, newCharts, newGrid, selectedIndex);
  }
  checkDown(charts, newCharts, newGrid, selectedIndex) {
    const chart = _.cloneDeep(charts[selectedIndex]);
    chart.height++;

    if (!this.checkMaxHeight(chart.height, chart.width)) return false;
    return this.checkNewChart(chart, newCharts, newGrid, selectedIndex);
  }
  mutate(charts, selectedIndex, id = 0) {
    this.counter++;
    //remove all shapes further down the list to open up space lower down
    let newCharts = charts.slice(0, selectedIndex);

    //generate current grid state
    const newGrid = this.generateMap(newCharts);

    //check if we can mutate right, then update the chart array and grid array
    const mutatedChartsRight = this.checkRight(
      charts,
      newCharts,
      newGrid,
      selectedIndex
    );
    if (mutatedChartsRight) {
      this.id++;
      for (let i = selectedIndex; i < this.unlockedCharts.length; i++) {
        this.mutate(mutatedChartsRight, i, this.id);
      }
    }
    // check if we can mutate down, then update the chart array and space array
    const mutatedChartsDown = this.checkDown(
      charts,
      newCharts,
      newGrid,
      selectedIndex
    );
    if (mutatedChartsDown) {
      this.id++;
      for (let i = selectedIndex; i < this.unlockedCharts.length; i++) {
        this.mutate(mutatedChartsDown, i, this.id);
      }
    }

    if (!mutatedChartsRight && !mutatedChartsDown) {
      //no more mutation possible determine if this layout is optimal
      if (this.checkedIds[id]) return;
      this.checkedIds[id] = true;
      this.checkOptimalLayout(charts);
    }
  }
}
