import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  Container,
  ConfigurationsContainer,
  Title,
  ConfigurationCell,
  ConfigurationText,
  ConfigurationIndex,
} from "./ChooseConfigurationStyles.js";
import CustomSpinner from "../../UI/Spinner/CustomSpinner.js";
import { setHeatMapDisplay } from "../../../redux/slices/display";
import {
  fetchConfigs,
  selectConfig,
} from "../../../redux/slices/heatMapConfigs";

export default function ChooseConfiguration() {
  const { configs, isFetching } = useSelector(
    ({ heatMapConfigs }) => heatMapConfigs
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  const initateConfig = (config) => {
    dispatch(selectConfig(config.id));
    dispatch(setHeatMapDisplay());
  };

  return (
    <Container>
      <Title>Select a configuration</Title>
      {isFetching && (
        <Grid container justify="center">
          <CustomSpinner />
        </Grid>
      )}
      {!isFetching && (
        <ConfigurationsContainer>
          {configs.map((config, index) => (
            <ConfigurationCell
              key={index}
              onClick={() => initateConfig(config)}
            >
              <ConfigurationIndex>{index + 1}</ConfigurationIndex>
              <ConfigurationText>{config.name}</ConfigurationText>
              <ConfigurationText>
                <i>{config.preset ? "preset" : "custom"}</i>
              </ConfigurationText>
            </ConfigurationCell>
          ))}
        </ConfigurationsContainer>
      )}
    </Container>
  );
}
