import React from "react";
import { useDispatch } from "react-redux";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { setGridDisplay } from "../../../../redux/slices/display";
import { IconButton } from "@material-ui/core";

export default function ViewPatternsButton() {
  const dispatch = useDispatch();

  return (
    <IconButton
      color="primary"
      size="small"
      onClick={() => dispatch(setGridDisplay())}
    >
      <ExitToAppIcon fontSize="large" />
    </IconButton>
  );
}
