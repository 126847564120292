import * as d3 from "d3";
import _ from "lodash";
import randomColor from "randomcolor";
import {
  updateWarning,
  setTransitioning,
  setZoomInTileData,
  setInFront,
  triggerRerender,
  addSignal,
  removeSignal,
} from "../../../../../redux/slices/heatMap";
import isColorsTooSimilar from "../isColorsTooSimilar";
import store from "../../../../../redux/store";
import findPathToNode from "../../../findPathToNode";
import { addNotification } from "../../../notifications/notifications";

let render = require("../render");

// Recursively counts size of tree
function countLeaves(data) {
  if (data.children) {
    return data.children
      .map((el) => countLeaves(el))
      .reduce((a, b) => a + b, 0);
  }
  return 1;
}
export const checkDataSize = (data) => countLeaves(data) > 500;

// Show warning
export function upOneDrillDownLayer(warning) {
  let newMessages;

  newMessages = [
    `Too many data points to visualise.`,
    `Some data points may be obscured.`,
  ];

  const messagesUnchanged = _.isEqual(warning.messages, newMessages);

  store.dispatch(
    updateWarning({
      messages: newMessages,
      renderFirstTime: messagesUnchanged ? warning.renderFirstTime : true,
    })
  );
}

// Apply d3 treemap algorithm to tree data
export function treemap(data) {
  return d3
    .treemap()
    .size([render.width, render.height])
    .paddingOuter(5)
    .paddingTop(20)
    .paddingInner(1)
    .round(true)(
    d3
      .hierarchy(data)
      .sum((d) => {
        return d.relativeSize;
      })
      .sort((a, b) => {
        return b.relativeSize - a.relativeSize;
      })
  );
}

// Defines sequence of colors for the background of each heatmap level
export function color(treeHeight, treeHeightMinusHeight) {
  const colorFunction = d3
    .scaleOrdinal()
    .domain([...Array(treeHeight + 2).keys()])
    .range(["#E0E2E4", " #484F4F", "#B7D7E8", "#8CA3A3"]);

  return colorFunction(treeHeightMinusHeight);
}

// Color functions calculating color based on linear scale
export const colorRed = d3
  .scaleLinear()
  .domain([0.5, 1])
  .range(["white", "red"]);
export const colorGreen = d3
  .scaleLinear()
  .domain([0.5, 1])
  .range(["white", "green"]);

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function zoomIn(d) {
  store.dispatch(setTransitioning(true));
  if (d.data.aggregate && !d.data.oneSignal) {
    render.x.domain([d.x0, d.x1]);
    render.y.domain([d.y0, d.y1]);

    const t = d3.transition().duration(150).ease(d3.easeLinear);

    const path = findPathToNode(d.parent);
    const { x0, x1, y0, y1 } = d.parent;
    store.dispatch(setZoomInTileData({ path, x0, x1, y0, y1 }));

    d3.select(d.rect)
      .transition(t)
      .attr("width", (d) =>
        render.x(d.x1) - render.x(d.x0) > 0
          ? render.x(d.x1) - render.x(d.x0)
          : 0
      )
      .attr("height", (d) =>
        render.y(d.y1) - render.y(d.y0) > 0
          ? render.y(d.y1) - render.y(d.y0)
          : 0
      );

    d3.select(d.rect.parentNode)
      .moveToFront()
      .transition(t)
      .attr("transform", (d) => `translate(0,0)`)
      .selectAll("text")
      .attr("y", (d, i) => (render.y(d.y1) - render.y(d.y0)) / 2 + 20 * i)
      .attr("x", (d) => (render.x(d.x1) - render.x(d.x0)) / 2);

    render.nodes
      .filter((data) => data !== d)
      .transition()
      .delay(150)
      .style("opacity", "0");

    render.nodes
      .transition()
      .delay(500)
      .duration(500)
      .ease(d3.easeLinear)
      .style("opacity", "0");

    render.toolTip.style("visibility", "hidden");
    render.toolTip.transition().delay(750).style("visibility", "visible");

    setTimeout(() => {
      store.dispatch(setInFront(2));
      store.dispatch(setTransitioning(false));
    }, 1000);
  }
}

export function zoomOut(data) {
  if (store.getState().heatMap.inFront === 1) return;
  store.dispatch(setTransitioning(true));
  render.x.range([data.x0, data.x1]);
  render.y.range([data.y0, data.y1]);

  const t1 = d3.transition().duration(250).ease(d3.easeLinear);
  const t2 = d3.transition().duration(50).ease(d3.easeLinear);

  d3.select(this)
    .select("rect")
    .transition(t1)
    .attr("width", data.x1 - data.x0 > 0 ? data.x1 - data.x0 : 0)
    .attr("height", data.y1 - data.y0 > 0 ? data.y1 - data.y0 : 0)
    .attr("transform", (d) => `translate(${data.x0},${data.y0})`);

  store.dispatch(triggerRerender(1));

  render.nodes
    .filter((d, i) => i !== 0)
    .transition(t2)
    .style("opacity", 0);

  render.nodes
    .filter((d, i) => i === 0)
    .selectAll("rect")
    .filter((d, i) => !d.values)
    .transition(t2)
    .style("opacity", 0);

  render.nodes.selectAll("tspan").transition(t2).style("opacity", 0);
  render.nodes.selectAll("text").transition(t2).style("opacity", 0);

  render.nodes.transition(t1).delay(250).style("opacity", 0);

  render.toolTip.style("visibility", "hidden");
  render.toolTip.transition().delay(1000).style("visibility", "visible");

  setTimeout(() => {
    store.dispatch(setInFront(1));
    store.dispatch(setTransitioning(false));
  }, 500);
}

export function addToOrRemoveFromHeatMapCharts(d) {
  const relevantPatternData = d.data.oneSignal || d.data;

  if (!d.data.color) {
    let highlightColor = randomColor();
    while (isColorsTooSimilar(d.data.tileColor, highlightColor)) {
      highlightColor = randomColor();
    }

    d.data.color = highlightColor;
    store.dispatch(
      addSignal({ ...relevantPatternData, color: highlightColor })
    );
    d3.select(this)
      .style("stroke", highlightColor)
      .style("stroke-width", "5px");
  } else {
    d3.select(this).style("stroke", "none");
    store.dispatch(removeSignal({ ...relevantPatternData }));
    delete d.data.color;
  }
}

export function calculateMeanSignal(d) {
  if (!d.children)
    return d.data.signal_type === "missing_signal"
      ? "No Signal"
      : d.data.signal_value;

  return (
    d.children
      .map((child) => calculateMeanSignal(child))
      .filter((meanSignal) => !isNaN(meanSignal))
      .reduce((a, b) => a + b, 0) / d.children.length
  );
}

export function transformToPercentage(decimal) {
  return (decimal * 100).toFixed(1) + "%";
}

export function textOnClickHandler(d) {
  if ((!d.data.aggregate || d.data.oneSignal) && d.data.signal_value) {
    return addToOrRemoveFromHeatMapCharts(d);
  } else if (!d.children && d.data.aggregate && !d.data.oneSignal) {
    return zoomIn(d);
  }
}

// Handler functions
export function findHeight(inputData) {
  let treeNode = inputData;
  let height = 0;
  while (treeNode.children && treeNode.children.length) {
    height++;
    treeNode = treeNode.children[0];
  }
  return height;
}

export function displayRenderWarning() {
  store.dispatch(
    updateWarning({
      renderFirstTime: false,
    })
  );

  addNotification(store.getState().heatMap.warning.messages);
}
