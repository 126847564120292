import React from "react";
import { useDispatch } from "react-redux";
import { StyledHeader, CloseIconContainer } from "./HeaderStyles";
import Controls from "./Controls/Controls";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { setChartDisplay } from "../../../redux/slices/display";

export default function Header() {
  // dispatch function from redux store
  const dispatch = useDispatch();

  // Handler functions
  const onCloseClick = () => dispatch(setChartDisplay());

  return (
    <StyledHeader>
      <CloseIconContainer>
        <BackspaceIcon onClick={onCloseClick} />
      </CloseIconContainer>
      <Controls />
    </StyledHeader>
  );
}
