import React from "react";
import ReactDOM from "react-dom";
import "whatwg-fetch";
import "./index.css";

import { Provider } from "react-redux";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import defaultTheme from "./styles/defaultTheme";
import MuiDefaultTheme from "./styles/defaultMuiTheme";
import { GlobalStyles } from "./styles/GlobalStyles";

import store from "./redux/store";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={MuiDefaultTheme}>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyles />
            <App />
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
