import _ from "lodash";

// Applies a depth constraint to the tree data
const applyDepthConstraint = (treeData, depthConstraint) => {
  // If there is no valid data, return undefined
  if (!treeData) return null;
  if (depthConstraint && depthConstraint + 1 < findHeight(treeData)) {
    const depthConstrainedTreeData = _.cloneDeep(treeData);
    // Aggregates data beyond depth constraint and adds as children to the current leaf nodes of depth constrained tree
    mapNodesOfCertainDepth(depthConstrainedTreeData, depthConstraint, 0);

    return depthConstrainedTreeData;
  }
  return treeData;
};

const mapNodesOfCertainDepth = (inputNode, depthConstraint, currentDepth) => {
  if (currentDepth === depthConstraint) {
    // Destructure desired values from function return
    const {
      signal_value,
      uniqueAssetNames,
      invalid,
      numberOfValidSignals,
    } = averageOfChildren(inputNode);
    const oneSignal = isAggregateOneSignal(inputNode);
    // Append node to nodes of certain depth
    const averageChild = {
      signal_value,
      value: uniqueAssetNames.length,
      aggregate: true,
      invalid,
      oneSignal,
      numberOfValidSignals,
    };
    inputNode.children = [averageChild];
  } else {
    // Iterate down the tree
    if (!inputNode.children) return;
    for (let child of inputNode.children) {
      mapNodesOfCertainDepth(child, depthConstraint, currentDepth + 1);
    }
  }
};

// Find average signal and average value (size of rectangle in heat map) recursively
const averageOfChildren = (node) => {
  
  if (!node.children) {
    const invalid = node.signal_type === "missing_signal" ? true : false;
    return {
      signal_value: node.signal_value,
      invalid: invalid,
      uniqueAssetNames: [node.ticker.security],
      numberOfValidSignals: invalid ? 0 : 1,
    };
  }

  let totalSignal = 0;
  let totalUniqueAssetNames = [];
  let numberOfValidSignalsFromChildren = 0;

  node.children.forEach((child) => {
    const {
      signal_value,
      uniqueAssetNames,
      numberOfValidSignals,
    } = averageOfChildren(child);
    if (!isNaN(signal_value)) {
      totalSignal += signal_value * numberOfValidSignals;
      numberOfValidSignalsFromChildren += numberOfValidSignals;
    }

    for (let assetName of uniqueAssetNames) {
      if (!totalUniqueAssetNames.includes(assetName))
        totalUniqueAssetNames.push(assetName);
    }
  });

  const averageSignal = numberOfValidSignalsFromChildren
    ? totalSignal / numberOfValidSignalsFromChildren
    : undefined;

  const allChildrenInvalid = numberOfValidSignalsFromChildren === 0;

  return {
    signal_value: averageSignal,
    uniqueAssetNames: totalUniqueAssetNames,
    invalid: allChildrenInvalid,
    numberOfValidSignals: numberOfValidSignalsFromChildren,
  };
};

// Find height of tree
const findHeight = (rootNode) => {
  let height = 0;
  let node = rootNode;
  while (node.children && node.children.length) {
    height++;
    node = node.children[0];
  }
  return height;
};

// Is the aggregate tile representing only one underlying signal?

const isAggregateOneSignal = (inputNode) => {
  if (!inputNode.children) return inputNode;

  if (inputNode.children.length > 1) return false;

  return inputNode.children.reduce(
    (acc, value) => acc && isAggregateOneSignal(value),
    true
  );
};

export default applyDepthConstraint;
