import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";
import CustomButton from "../../../../UI/Button/CustomButton";
import { addOrUpdateBookmark } from "../../../../../redux/slices/heatMapConfigs";

function SetBookmarksModal({ onClose, bookmarks }) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const configs = useSelector(({ heatMapConfigs }) => heatMapConfigs.configs);

  // MUI getStyles
  const getStyles = (name) => {
    const style = {
      borderRadius: "5px",
      color: "white",
      margin: "5px 5px",
      position: "relative",
    };

    return style;
  };

  // Handler functions
  const handleChangeGenerator = (bookmarkIndex) => {
    return (e) => {
      dispatch(addOrUpdateBookmark(e.target.value, bookmarkIndex));
    };
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Set bookmarks</DialogTitle>
      {bookmarks.map((bookmark, index) => (
        <Fragment key={index}>
          <DialogContent style={{ marginBottom: "20px" }}>
            Bookmark {index + 1}
          </DialogContent>
          <Select
            style={{ marginBottom: "40px" }}
            fullWidth
            displayEmpty
            value={bookmark}
            onChange={handleChangeGenerator(index)}
            input={<Input />}
            inputProps={{ "aria-label": "Without label" }}
          >
            {configs.map(({ name }, index) => (
              <MenuItem key={name} value={index} style={getStyles()}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Fragment>
      ))}

      <DialogActions>
        <CustomButton onClick={onClose} variant="outlined">
          Exit
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default SetBookmarksModal;
