import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Switch } from "@material-ui/core";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  width: 450px;
`;

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 270px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 15px 0 10px;
  width: 50px;
`;

export const LabelText = styled.p`
  color: white;
  font-size: 12px;
  margin: 0;
`;

const FormControlLabelStyles = {
  label: {
    fontSize: "12px !important",
  },
  root: {
    marginLeft: 0,
    whiteSpace: "nowrap",
  },
};

const switchStyles = {
  checked: {
    color: "rgb(104, 174, 30) !important",
  },
  track: {
    backgroundColor: "rgb(104, 174, 30) !important",
  },
};

export const CustomFormControlLabel = withStyles(FormControlLabelStyles)(
  FormControlLabel
);

export const CustomSwitch = withStyles(switchStyles)(Switch);
