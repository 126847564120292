import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(38, 51, 64);
  border-radius: 5px;
  border: 1px solid #222;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
  position: relative;
  width: 100%;
`;

export const Column = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: ${({ right }) => (right ? "flex-end" : "")};
  flex-grow: 1;
`;

export const ButtonContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;
