import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  width: calc(100vw - 380px);
`;

export const InnerContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;
