import React from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Title,
  SliderContainer,
  RowContainer,
  ButtonContainer,
} from "./RangeSelectStyles";
import ConfidenceRangeSlider from "./ConfidenceRangeSlider";
import BarRangeSlider from "./BarRangeSlider";
import SignalsOnChartRangeSlider from "./SignalsOnChartRangeSlider";
import Button from "../../../UI/Button/CustomButtonWhite";
import { changeFilter } from "../../../../redux/slices/heatMap";

export default function ConfidenceSlider() {
  const dispatch = useDispatch();

  // Handler functions
  const onConfidenceReset = () => {
    dispatch(changeFilter({ minSignalValue: 0.5, maxSignalValue: 0.5 }));
  };

  const onBarsReset = () => {
    dispatch(changeFilter({ firstBar: 0, lastBar: 600 }));
  };

  const onSignlasNumberReset = () => {
    dispatch(changeFilter({ signalsOnChartCount: 0 }));
  };

  return (
    <Container>
      <Title>Min confidence:</Title>
      <RowContainer>
        <SliderContainer>
          <ConfidenceRangeSlider />
        </SliderContainer>

        <ButtonContainer>
          <Button onClick={onConfidenceReset}>Reset</Button>
        </ButtonContainer>
      </RowContainer>

      <Title>Signal range:</Title>
      <RowContainer>
        <SliderContainer>
          <BarRangeSlider />
        </SliderContainer>

        <ButtonContainer>
          <Button onClick={onBarsReset}>Reset</Button>
        </ButtonContainer>
      </RowContainer>

      <Title>Signals number on chart:</Title>
      <RowContainer>
        <SliderContainer>
          <SignalsOnChartRangeSlider />
        </SliderContainer>

        <ButtonContainer>
          <Button onClick={onSignlasNumberReset}>Reset</Button>
        </ButtonContainer>
      </RowContainer>
    </Container>
  );
}
