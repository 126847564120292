import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../UI/Button/CustomButtonLeftPanelBlue";
import CreateIcon from "@material-ui/icons/Create";

import { removeAllDPatterns } from "../../../../redux/slices/detectedPatterns";
import { setDrawModelDisplay } from "../../../../redux/slices/display";
import { clearSelectedPatterns } from "../../../../redux/slices/selectedPatterns";
import { removeAllSDPatterns } from "../../../../redux/slices/selectedDetectedPatterns";
import {
  clearColdStartPatterns,
  clearDrawingArea,
} from "../../../../redux/slices/coldStart";
import { clearTrainingModels } from "../../../../redux/slices/models";

export default function DrawNewModelButton() {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setDrawModelDisplay());
    dispatch(removeAllDPatterns());
    dispatch(clearSelectedPatterns());
    dispatch(removeAllSDPatterns());
    dispatch(clearColdStartPatterns());
    dispatch(clearDrawingArea());
    dispatch(clearTrainingModels());
  };

  return (
    <Button onClick={onClick}>
      <>
        <CreateIcon />
        <div style={{ marginLeft: 10 }}>Draw New Pattern</div>
      </>
    </Button>
  );
}
