import React from "react";
import styled from "styled-components";
import { minutesToStrRepr } from "../../../../utils/utils";

export const IntervalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.containerBackground2};
  border-radius: 5px;
  margin: 10px 0;
`;

export const StyledIntervalButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.selected : props.theme.containerBackground3};
  color: ${(props) => props.theme.primary};
  font-size: 12px;
  font-weight: 500;
  height: 25px;
  margin: 5px 3px;
  width: 50px;
  text-transform: uppercase;
`;

export default function IntervalSelect({
  selectedInterval,
  setSelectedInterval,
  selectedCollection,
}) {
  return (
    <IntervalContainer>
      {selectedCollection &&
        selectedCollection.intervals.map((interval, index) => (
          <StyledIntervalButton
            key={index}
            selected={selectedInterval === interval}
            onClick={() => {
              setSelectedInterval(interval);
            }}
          >
            {minutesToStrRepr(interval)}
          </StyledIntervalButton>
        ))}
    </IntervalContainer>
  );
}
