import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useCustomCollectionToEdit = () => {
  const customCollectionToEdit = useSelector(
    ({ chartCollections }) => chartCollections.customCollectionToEdit
  );

  const [selectedCharts, setSelectedCharts] = useState([]);

  useEffect(() => {
    if (customCollectionToEdit) {
      setSelectedCharts(customCollectionToEdit.charts);
    }
  }, [customCollectionToEdit]);

  return [customCollectionToEdit, selectedCharts, setSelectedCharts];
}

export default useCustomCollectionToEdit;