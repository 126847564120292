import { createSlice } from "@reduxjs/toolkit";
import { removeAllDPatterns } from "./detectedPatterns";
import { clearSelectedPatterns } from "./selectedPatterns";
import { removeAllSDPatterns } from "./selectedDetectedPatterns";
import { clearColdStartPatterns, clearDrawingArea } from "./coldStart";
import { clearTrainingModels, setModelToRetrain } from "./models";

const displaySlice = createSlice({
  name: "display",
  initialState: {
    display: "home",
    mode: null,
  },
  reducers: {
    setGridDisplay: (state) => {
      state.display = "grid";
    },
    toggleGridDisplay: (state) => {
      if (state.display === "grid") state.display = "chart";
      else state.display = "grid";
    },
    setChartDisplay: (state) => {
      state.display = "chart";
    },
    setHomeDisplay: (state) => {
      state.display = "home";
      state.mode = null;
    },
    setHeatMapDisplayReducer: (state) => {
      state.display = "heatMap";
      state.mode = null;
    },
    setDetectPatternsDisplayReducer: (state) => {
      state.display = "chart";
      state.mode = "detectPatterns";
    },
    setDrawModelDisplayReducer: (state) => {
      state.display = "coldStart";
      state.mode = "drawModelForTraining";
    },
    setSelectPatternsDisplayReducer: (state) => {
      state.display = "chart";
      state.mode = "selectPatternsForTraining";
    },
    setSettingsDisplay: (state) => {
      state.display = "settings";
      state.mode = null;
    },
  },
});

export const {
  setHomeDisplay,
  setGridDisplay,
  toggleGridDisplay,
  setChartDisplay,
  setSettingsDisplay,
} = displaySlice.actions;

const {
  setHeatMapDisplayReducer,
  setDetectPatternsDisplayReducer,
  setDrawModelDisplayReducer,
  setSelectPatternsDisplayReducer,
} = displaySlice.actions;

const clearPatterns = (dispatch) => {
  dispatch(removeAllDPatterns());
  dispatch(clearSelectedPatterns());
  dispatch(removeAllSDPatterns());
  dispatch(clearColdStartPatterns());
  dispatch(clearDrawingArea());
  dispatch(clearTrainingModels());
};

export const setHeatMapDisplay = () => (dispatch) => {
  clearPatterns(dispatch);
  dispatch(setHeatMapDisplayReducer());
};

export const setDetectPatternsDisplay = () => (dispatch) => {
  clearPatterns(dispatch);
  dispatch(setDetectPatternsDisplayReducer());
};
export const setDrawModelDisplay = () => (dispatch) => {
  clearPatterns(dispatch);
  dispatch(setDrawModelDisplayReducer());
};
export const setSelectPatternsDisplay = () => (dispatch) => {
  clearPatterns(dispatch);
  dispatch(setSelectPatternsDisplayReducer());
};
export const setRetrainModelDisplay = (model) => (dispatch) => {
  clearPatterns(dispatch);
  dispatch(setSelectPatternsDisplayReducer());
  dispatch(setModelToRetrain(model))
}

export default displaySlice.reducer;
