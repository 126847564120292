import { useRef } from "react";
import { usePatternDrawings } from "./usePatternDrawings";
import { useIndicatorDrawings } from "./useIndicatorDrawings";
import { useOnClick } from "./useOnClick";
import { useControlledReset } from "./useControlledReset";

export const usePatternController = ({
  chart,
  newVisRange,
  newSelection,
  mousePosition,
}) => {
  const visibleDPatterns = useRef([]);
  const visibleSDPatterns = useRef([]);

  usePatternDrawings({
    chart,
    visibleDPatterns,
    visibleSDPatterns,
    newVisRange,
  });

  useOnClick({
    chart,
    mousePosition,
    newSelection,
    visibleDPatterns,
    visibleSDPatterns,
  });

  useIndicatorDrawings({
    chart,
    newVisRange,
  });

  useControlledReset({ chart });
};
