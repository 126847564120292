import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import CustomSpinner from "../../../UI/Spinner/CustomSpinner";
import LinearProgress from "../../../UI/Spinner/LinearProgress";
import { LinearProgressContainer } from "./ApiLoadingProgressStyles";

export default function ApiLoadingProgress() {
  // Select from redux store
  const apiLoadingProgress = useSelector(
    ({ heatMap }) => heatMap.apiLoadingProgress
  );
  return (
    <Fragment>
      <CustomSpinner />
      <LinearProgressContainer>
        <LinearProgress
          style={{ determinate: { color: "#3498db " } }}
          variant="determinate"
          value={apiLoadingProgress}
        />
      </LinearProgressContainer>
    </Fragment>
  );
}
