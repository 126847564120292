import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
}

.react-resizable-handle {
  z-index: 3;
}
.react-grid-item > .react-resizable-handle::after {
  border-right: 2px solid white !important;
  border-bottom: 2px solid white !important;
}

.awssld {
  height: calc(100% - 25px);
  transform-style: preserve-3d;
}
.awssld__content{
  height: calc(75vh - 25px);
  width: 75vw;
  max-width: 1300px;
  align-items: flex-start;
  padding: 25px;
  box-sizing:border-box;
  background-color: #343d46;
  border-radius: 10px;
}
`;
