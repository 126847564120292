import styled from "styled-components";
import { Link } from "react-router-dom";

const color = "rgb(10, 144, 197)";
const bottomShadow = "rgb(39, 73, 87)";
const topShadow = "#1c1d1f";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #03151f;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.43;
  font-weight: 400;
  letter-spacing: 0.01071em;
  font-size: 14px;
`;
export const AuthBackground = styled.div`
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    circle,
    rgba(24, 67, 89, 1) 0%,
    rgba(3, 21, 31, 1) 40%
  );
`;

export const SubContainer = styled.div`
  box-sizing: border-box;
  bottom: ${(props) => (props.slideUp ? "100%" : "0%")};
  height: ${(props) => props.height + "px"};
  width: 400px;
  font-family: Arial;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #2b2c2e;
  border-radius: 40px;
  box-shadow: 13px 13px 20px ${topShadow}, -13px -13px 20px ${bottomShadow};
  overflow: hidden;
  position: relative;
  padding: 20px;
  transition: bottom 750ms cubic-bezier(0.68, -0.55, 0.265, 1.55), height 0.2s;
`;

export const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const AuthMessageBox = styled.div`
  box-sizing: border-box;
  height: 105px;
  margin: 30px 10px;
  box-sizing: border-box;
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  width: 100%;
  font-size: 14px;
  padding: 10px 20px;
  color: #d64958;
`;

export const ConfirmChangeContainer = styled(AuthMessageBox)`
  text-align: center;
  box-shadow: inset 8px 8px 8px ${topShadow},
    inset -8px -8px 8px ${bottomShadow};
  color: ${color};
  height: 75px;
`;

export const AuthErrorBox = styled(AuthMessageBox)`
  align-items: flex-start;
  box-shadow: inset 8px 8px 8px ${topShadow},
    inset -8px -8px 8px ${(props) => (props.pass ? "#30493b" : "#493033")};
`;

export const WelcomeBox = styled(AuthMessageBox)`
  box-sizing: border-box;
  box-shadow: inset 8px 8px 8px ${topShadow},
    inset -8px -8px 8px ${bottomShadow};
  color: ${color};
  font-weight: 500;
  height: 160px;
  padding: 30px;
`;

export const StyledLink = styled(Link)`
  color: ${color};
  cursor: pointer;
  font-size: 14px;
`;

export const ForgotPasswordHeader = styled.div`
  width: 100%;
  text-align: center;
  color: ${color};
  margin-bottom: 30px;
`;

export const AuthForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
`;

export const InputField = styled(Field)`
  box-sizing: border-box;
  margin-bottom: 25px;
  padding: 15px 10px 15px 5px;
  box-shadow: inset 8px 8px 8px ${topShadow},
    inset -8px -8px 8px ${bottomShadow};
`;

export const AuthInput = styled.input`
  border: none;
  outline: none;
  background-color: transparent !important;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  font-weight: 600;
  color: ${color};
`;

export const AuthButton = styled.button`
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 6px 12px;
  background-color: ${(props) => (props.disabled ? "#536a7e" : color)};
  border: none;
  outline: none;
  height: 50px;
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  box-shadow: 5px 5px 5px ${topShadow}, -5px -5px 5px ${bottomShadow};
`;
export const AuthRedirectText = styled.div`
  color: ${color};
  font-size: 14px;
  margin-right: 10px;
`;
export const AuthCheck = styled.div`
  color: ${(props) => (props.success ? "#19bf00" : "#df3312")};
  font-size: 14px;
`;

export const styleLoginIcon = (Component) => {
  return styled(Component)`
    margin: 0 10px;
    color: ${color};
  `;
};

export const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 80px;
  justify-content: center;
`;
