import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Column, ButtonContainer } from "./HeatMapToolbarStyles";
import Button from "../../UI/Button/CustomButtonWhite";
import UpdateSwitch from "./UpdateSwitch/UpdateSwitch";
import { selectFavouritesIds } from "../../../redux/slices/heatMapFavourites";
import { clearHeatMapChart } from "../../../redux/slices/heatMapChart";
import LineSegmentNumberSlider from "./LineSegmentNumberSlider/LineSegmentNumberSlider";
import Bookmarks from "./Bookmarks/Bookmarks";

export default function ({
  setIsPatternUpdated,
  numberOfLineSegments,
  setNumberOfLineSegments,
}) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const inFront = useSelector(({ heatMap }) => heatMap.inFront);
  const transitioning = useSelector(({ heatMap }) => heatMap.transitioning);
  const showHeatMapChart = useSelector(
    ({ heatMap }) => heatMap.showHeatMapChart
  );
  const favouritesIds = useSelector(selectFavouritesIds);
  const heatMapChartPattern = useSelector(
    ({ heatMapChart }) => heatMapChart.pattern
  );

  const isChartInFavourites =
    heatMapChartPattern && favouritesIds.includes(heatMapChartPattern.id);

  // useContext hooks
  const zoomOut = useSelector(({heatMap}) => heatMap.zoomOut);

  return (
    <Container>
      <Column>
        <ButtonContainer>
          {showHeatMapChart ? (
            <Button onClick={() => dispatch(clearHeatMapChart())}>
              Back to heat map
            </Button>
          ) : (
            <Button onClick={zoomOut} disabled={inFront === 1 || transitioning}>
              Return to top level
            </Button>
          )}
        </ButtonContainer>
      </Column>

      <Column>
        {showHeatMapChart && (
          <LineSegmentNumberSlider
            numberOfLineSegments={numberOfLineSegments}
            setNumberOfLineSegments={setNumberOfLineSegments}
          />
        )}
      </Column>

      <Column right>
        {showHeatMapChart && isChartInFavourites && (
          <UpdateSwitch setIsPatternUpdated={setIsPatternUpdated} />
        )}
        {!showHeatMapChart && <Bookmarks />}
      </Column>
    </Container>
  );
}
