import styled from "styled-components";

export const ChartContainer = styled.div`
  position: relative;
  margin: 10px;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  min-height: ${(props) => props.height}px;
  overflow: hidden;
  background-color: rgb(38, 51, 64);
  border-radius: 10px;
`;
export const PatternLine = styled.div`
  height: 1px;
  background-color: rgb(255, 255, 255);
  line-height: 1px;
  position: absolute;
  z-index: 10;
  left: ${(props) => props.line.centerX}px;
  top: ${(props) => props.marginTop + props.line.centerY}px;
  width: ${(props) => props.line.length}px;
  transform: rotate(${(props) => props.line.angle}deg);
`;
export const PatternBorder = styled.div`
  position: absolute;
  z-index: 3;
  width: 66.7%;
  height: 70%;
  left: 16.7%;
  top: 20%;
  border: ${(props) =>
    props.selected ? "2px solid #4cfd4c" : "2px dashed rgba(162,162,248,.63)"};
  border-radius: 5px;
`;

export const ChartInfo = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  p {
    margin: 1px 0;
  }
`;

export const TradingViewContainer = styled.div``;
