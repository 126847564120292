import { createSlice } from "@reduxjs/toolkit";
import { updateChartCollectionToDetectOn } from "./detectedPatterns";
import { Api } from "../../api/api";
import { setSelectedCollection } from "./leftPanel";

const chartCollectionsSlice = createSlice({
  name: "chartCollections",
  initialState: {
    collections: [],
    customCollections: [],
    customCollectionToEdit: null,
    mode: "add",
  },
  reducers: {
    setCollections: (state, action) => {
      state.collections = action.payload.filter((c) => !c.custom);
      state.customCollections = action.payload.filter((c) => c.custom);
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setCustomCollectionToEdit: (state, action) => {
      state.customCollectionToEdit = action.payload;
    },
  },
});

export const {
  setCollections,
  setCustomCollections,
  setCustomCollectionToEdit,
  setMode,
} = chartCollectionsSlice.actions;

export default chartCollectionsSlice.reducer;

export const getChartCollections =
  (isSetDefaultCollecttion = false) =>
  async (dispatch) => {
    const resp = await Api.getChartCollections();
    dispatch(setCollections(resp.chart_collections));

    if (isSetDefaultCollecttion && resp.chart_collections.length > 0) {
      dispatch(updateChartCollectionToDetectOn(resp.chart_collections[0]));
    }
  };

export const updateCustomChartCollectionToEdit =
  (collection) => async (dispatch) => {
    if (!collection) {
      dispatch(setCustomCollectionToEdit(null));
      return;
    }

    const constChartCollectionInfo = await Api.getChartCollection(
      collection.id
    );
    dispatch(setCustomCollectionToEdit(constChartCollectionInfo));
  };

export const createCustomChartCollection =
  (name, isCommon, charts, onError, onSuccess) => async (dispatch) => {
    try {
      await Api.createChartCollection({
        name: name,
        common: isCommon,
        custom: true,
        chart_ids: charts.map((c) => c.id),
      });

      dispatch(getChartCollections());

      onSuccess();
    } catch (error) {
      onError(error.message);
    }
  };

export const updateCustomChartCollection =
  (id, name, isCommon, charts, onError, onSuccess) => async (dispatch) => {
    try {
      await Api.editChartCollection(id, {
        name: name,
        common: isCommon,
        custom: true,
        chart_ids: charts.map((c) => c.id),
      });

      dispatch(getChartCollections());

      onSuccess();
    } catch (error) {
      onError(error.message);
    }
  };

export const deleteChartCollection =
  (id, onError, onSuccess) => async (dispatch, getState) => {
    try {
      await Api.deleleteChartCollection(id);

      dispatch(getChartCollections());

      const selectedChartCollection = getState().leftPanel.selectedCollection;

      if (selectedChartCollection && selectedChartCollection.id === id) {
        dispatch(setSelectedCollection(null));
      }
      onSuccess();
    } catch (error) {
      onError(error.message);
    }
  };
