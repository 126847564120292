import React from "react";
import { ListItem } from "../AddOrEditCustomCollectionStyles";

export default function CollectionsSelect({
  collections,
  onSelect,
  selectedCollection,
}) {
  return collections.map((collection, index) => (
    <ListItem
      key={index}
      selected={selectedCollection?.id === collection?.id}
      onClick={() => onSelect(collection)}
    >
      {collection.name}
    </ListItem>
  ));
}
