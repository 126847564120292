import findFilteredBars from "./utils/findFilteredBars";
import findLocalOptima from "./utils/findLocalOptima";
import findLineSegments from "./utils/findLineSegments";
import findLineSegmentCombinations from "./utils/findLineSegmentCombinations";
import findOptimalLineSegmentCombination from "./utils/findOptimalLineSegmentCombination/findOptimalLineSegmentCombination";
import createNewPatternShapes from "./utils/createNewPatternShapes";

const addUpdateLineDrawings = (chart, pattern, n) => {
  if (!chart || !pattern || pattern.indicator) return;

  const bars = chart.bars;
  const filteredBars = findFilteredBars(bars, pattern);

  const { localMaxima, localMinima } = findLocalOptima(filteredBars);

  const numberOfOptima = Math.max(localMaxima.length, localMinima.length);

  if (numberOfOptima > 40 && n > 2)
    return addUpdateLineDrawings(chart, pattern, 2);
  if (numberOfOptima > 20 && n > 3)
    return addUpdateLineDrawings(chart, pattern, 3);

  const validUpperLineSegments = findLineSegments(
    localMaxima,
    filteredBars,
    "upper"
  );

  const validLowerLineSegments = findLineSegments(
    localMinima,
    filteredBars,
    "lower"
  );

  const optimalUpperLineSegmentCombination = findOptimalLines(
    validUpperLineSegments,
    filteredBars,
    n,
    "upper"
  );
  if (!optimalUpperLineSegmentCombination) return null;

  const optimalLowerLineSegmentCombination = findOptimalLines(
    validLowerLineSegments,
    filteredBars,
    n,
    "lower"
  );

  if (!optimalLowerLineSegmentCombination) return null;

  const newShapes = createNewPatternShapes(
    pattern,
    optimalUpperLineSegmentCombination,
    optimalLowerLineSegmentCombination
  );

  const updatedPattern = {
    ...pattern,
    shapes: newShapes,
  };

  return updatedPattern;
};

const findOptimalLines = (lineSegments, bars, n, upperOrLower) => {
  if (n < 1) return null;

  const lineSegmentCombinations = findLineSegmentCombinations(lineSegments, n);

  const optimalLineSegmentCombination = findOptimalLineSegmentCombination(
    lineSegmentCombinations,
    bars,
    upperOrLower
  );

  if (!optimalLineSegmentCombination) {
    return findOptimalLines(lineSegments, bars, n - 1, upperOrLower);
  }

  return optimalLineSegmentCombination;
};

export default addUpdateLineDrawings;
