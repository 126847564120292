const findIntersectionPoints = (
  lineSegment,
  prevLineSegment,
  nextLineSegment,
  y
) => {
  const result = [];

  if (prevLineSegment) {
    const intersectionPoint = findIntersectionPoint(
      prevLineSegment,
      lineSegment,
      y
    );
    if (intersectionPoint === "invalid") return "invalid";

    result.push(intersectionPoint);
  }

  if (nextLineSegment) {
    const intersectionPoint = findIntersectionPoint(
      lineSegment,
      nextLineSegment,
      y
    );
    if (intersectionPoint === "invalid") return "invalid";

    result.push(intersectionPoint);
  }

  return result;
};

const findIntersectionPoint = (lineSeg1, lineSeg2, y) => {
  const grad1 =
    (lineSeg1[1][y] - lineSeg1[0][y]) / (lineSeg1[1].time - lineSeg1[0].time);
  const grad2 =
    (lineSeg2[1][y] - lineSeg2[0][y]) / (lineSeg2[1].time - lineSeg2[0].time);
  const gradDiff = grad1 - grad2;

  const x1 = lineSeg1[1].time;
  const y1 = lineSeg1[1][y];

  const x2 = lineSeg2[0].time;
  const y2 = lineSeg2[0][y];

  const intersectionX = (grad1 * x1 - grad2 * x2 + y2 - y1) / gradDiff;

  if (intersectionX < lineSeg1[1].time || intersectionX > lineSeg2[0].time)
    return "invalid";

  const intersectionY = grad1 * (intersectionX - x1) + y1;

  return {
    time: +intersectionX.toFixed(),
    [y]: +intersectionY.toFixed(2),
  };
};

export default findIntersectionPoints;
