import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";

const selectedPatternsAdapter = createEntityAdapter();

const selectedPatternsSlice = createSlice({
  name: "selectedPatterns",
  initialState: selectedPatternsAdapter.getInitialState(),
  reducers: {
    clearSelectedPatterns: (state) => {
      selectedPatternsAdapter.setAll(state, []);
    },
    addShape: (state, action) => {
      const { chartId, chartName, shapeToAdd } = action.payload;

      const shapeData = {
        id: shapeToAdd.id,
        chartId,
        chartName: chartName.split("_")[0],
        shapes: [
          {
            points: shapeToAdd.points,
            shape_type: shapeToAdd.shape_type,
          },
        ],
      };

      selectedPatternsAdapter.upsertOne(state, shapeData);
    },
    removeShape: (state, action) => {
      selectedPatternsAdapter.removeOne(state, action.payload);
    },
    removeManyShapes: (state, action) => {
      selectedPatternsAdapter.removeMany(state, action.payload);
    },
  },
});

export const {
  clearSelectedPatterns,
  addShape,
  removeShape,
  removeManyShapes,
} = selectedPatternsSlice.actions;

export const {
  selectAll: selectAllSPatterns,
  selectById: selectSPatternById,
  selectIds: selectSPatternIds,
  selectTotal: selectTotalSPatterns,
} = selectedPatternsAdapter.getSelectors((state) => state.selectedPatterns);

export const clearSelectedPatternsSingleChart = (chartId = undefined) => (
  dispatch,
  getState
) => {
  if (!chartId) chartId = getState().chartLayout.fullScreenChart;
  const allShapes = Object.values(getState().selectedPatterns.entities);
  const selectedChartShapes = allShapes.filter(
    (shape) => shape.chartId === chartId
  );
  dispatch(removeManyShapes(selectedChartShapes.map((pattern) => pattern.id)));
};

export default selectedPatternsSlice.reducer;

export const selectPatternsChartList = createSelector(
  [selectAllSPatterns],
  (patterns) =>
    patterns.map(({ chartName, interval }) => ({
      chartName,
      interval,
    }))
);
