import React, { Fragment } from "react";

import DrillDownBy from "./DrillDownBy/DrillDownBy";
import {
  Container,
  ControlContainer,
  TopContainer,
} from "./HeatMapControlStyles";
import ApplyFiltersButton from "./ApplyFiltersButton/ApplyFiltersButton";
import HeatMapFilters from "./HeatMapFilters/HeatMapFilters";
import RangeSelect from "./RangeSelect/RangeSelect";
import ShowMissingSignals from "./ShowMissingSignals/ShowMissingSignals";
import Configurations from "./Configurations/Configurations";
import Terminal from "./Terminal/Terminal";
import ExpandTab from "./ExpandTab/ExpandTab";

export default function HeatMapControl({ expanded }) {
  return (
    <Container expanded={expanded}>
      {expanded && (
        <Fragment>
          <TopContainer>
            <ApplyFiltersButton />
          </TopContainer>

          <ControlContainer>
            <Terminal />
            <Configurations />
            <HeatMapFilters />
            <DrillDownBy />
            <RangeSelect />
            <ShowMissingSignals />
          </ControlContainer>
        </Fragment>
      )}

      <ExpandTab expanded={expanded} />
    </Container>
  );
}
