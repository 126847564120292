import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleShowBoxes,
  toggleShowLines,
} from "../../../../redux/slices/settings";
import { Container } from "./ChartSwitchesStyles";
import ChartSwitch from "./ChartSwitch";

export default function ChartSwitches() {
  // Retrieve redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const showBoxes = useSelector(({ settings }) => settings.showBoxes);
  const showLines = useSelector(({ settings }) => settings.showLines);

  // Hander functions
  const onShowBoxesToggle = useCallback(() => dispatch(toggleShowBoxes()), [
    dispatch,
  ]);
  const onShowLinesToggle = useCallback(() => dispatch(toggleShowLines()), [
    dispatch,
  ]);

  return (
    <Container>
      <ChartSwitch
        checked={showBoxes}
        onChange={onShowBoxesToggle}
        label="Show pattern boxes"
      />

      <ChartSwitch
        checked={showLines}
        onChange={onShowLinesToggle}
        label="Show pattern lines"
      />
    </Container>
  );
}
