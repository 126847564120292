import {
  resetShape,
  setCurrentShape,
} from "../../../../../redux/slices/coldStart";

const drawNodalRepresentation = (nodalRepresentation, dispatch) => {
  dispatch(resetShape());

  const newShape = [
    ...nodalRepresentation,
    {
      x: 100,
      baseline: 100,
    },
  ];

  dispatch(setCurrentShape(newShape));
};

export default drawNodalRepresentation;
