import { useMemo } from "react";

const convertBars = (bars) =>
  bars.map((bar) => {
    return { ...bar, time: bar.time / 1000 };
  });

export const useGetFilterBars = (chartData) => {
  return useMemo(() => {
    if (!chartData) return;
    //need to adjust timestamps to be compatible with the chart
    const convertedBars = convertBars(chartData.bars);

    return convertedBars;
  }, [chartData]);
};
