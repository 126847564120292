import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const StyledPattern = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  border: 2px solid
    ${(props) => (props.selected ? props.theme.selected : "rgb(146, 146, 146)")};
  margin: 5px;
  width: 400px;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;
