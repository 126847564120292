const createNewPatternShapes = (
  pattern,
  upperLineSegmentCombination,
  lowerLineSegmentCombination
) => {
  const filteredShapes = pattern.shapes.filter(
    (shape) => shape.shape_type !== "trend_line"
  );

  const newUpperTrendLines = upperLineSegmentCombination.map((lineSegment) => ({
    shape_type: "trend_line",
    points: lineSegment.map((bar) => ({
      time: bar.time / 1000,
      price: bar.high,
    })),
  }));

  const newLowerTrendLines = lowerLineSegmentCombination.map((lineSegment) => ({
    shape_type: "trend_line",
    points: lineSegment.map((bar) => ({
      time: bar.time / 1000,
      price: bar.low,
    })),
  }));

  return filteredShapes.concat(newUpperTrendLines, newLowerTrendLines);
};

export default createNewPatternShapes;
