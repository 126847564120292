import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress, Button } from "@material-ui/core";
import styled from "styled-components";

const styles = {
  root: {
    backgroundColor: "#39c416",
    color: "white !important",
    margin: "0 2px",

    width: "100%",
  },
  label: {
    textTransform: "none",
  },
  disabled: {
    opacity: "0.1",
  },
};
const linearProgressStyles = {
  root: {
    borderRadius: "5px",
    bottom: 0,
    position: "absolute",
    width: "97%",
  },
};

const StyledButton = withStyles(styles)(Button);
const StyledLinearProgress = withStyles(linearProgressStyles)(LinearProgress);

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  position: relative;
`;

export default ({ disabled, onClick, children, loading }) => {
  return (
    <Container onClick={onClick}>
      <StyledButton disabled={disabled}>{children}</StyledButton>
      {loading && <StyledLinearProgress color={"secondary"} value={50} />}
    </Container>
  );
};
