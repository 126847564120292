import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { Api } from "../../api/api";
import { Utils } from "../../utils/utils";
import Bottleneck from "bottleneck";

const indicatorsAdapter = createEntityAdapter();

const initialState = indicatorsAdapter.getInitialState({
  fetching: false,
  indicatorList: [],
});

const indicatorsSlice = createSlice({
  name: "indicators",
  initialState: initialState,
  reducers: {
    setIndicatorList: (state, action) => {
      state.indicatorList = action.payload;
    },
    fetching: (state) => {
      state.fetching = true;
    },
    fetchComplete: (state) => {
      state.fetching = false;
    },
    addIndicator: (state, action) => {
      indicatorsAdapter.upsertOne(state, action.payload);
    },
    removeIndicator: (state, action) => {
      indicatorsAdapter.removeOne(state, action.payload);
    },
    removeManyIndicators: (state, action) => {
      indicatorsAdapter.removeMany(state, action.payload);
    },
    removeAllIndicators: (state) => {
      indicatorsAdapter.setAll(state, []);
    },
  },
});

export const {
  setIndicatorList,
  fetching,
  fetchComplete,
  addIndicator,
  removeIndicator,
  removeManyIndicators,
  removeAllIndicators,
} = indicatorsSlice.actions;

export default indicatorsSlice.reducer;

//
// ─── SELECTORS ──────────────────────────────────────────────────────────────────
//

export const {
  selectById: selectIndicatorsById,
} = indicatorsAdapter.getSelectors((state) => state.indicators);

// ────────────────────────────────────────────────────────────────────────────────

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//
export const getIndicatorList = () => async (dispatch) => {
  const indicatorList = await Api.getIndicatorList();
  dispatch(
    setIndicatorList(
      indicatorList.indicator_ids.map((indicator) =>
        indicator.replace("_", " ")
      )
    )
  );
};

export const getIndicators = (indicators) => async (dispatch, getState) => {
  const chartData = getState().chartData.entities; //original charts
  const fullScreenChart = getState().chartLayout.fullScreenChart;
  const charts = fullScreenChart ? [fullScreenChart] : Object.keys(chartData);
  dispatch(removeAllIndicators());
  dispatch(fetching());

  const limiter = new Bottleneck({
    maxConcurrent: 20,
  });

  await Promise.all(
    charts.map(async (chartId) => {
      await limiter.schedule(async () => {
        const res = await Api.getIndicators({
          indicators: indicators.map((indicator) =>
            indicator.replace(" ", "_")
          ),
          chartId,
        });
        if (!res) return;

        const indicatorPatterns = res.indicators.filter(
          (indicator) => indicator.indicator_name
        );

        const formattedPatterns = formatPatterns(indicatorPatterns);
        console.log(indicatorPatterns);
        console.log(formattedPatterns);
        dispatch(addIndicator({ id: chartId, patterns: formattedPatterns }));
      });
    })
  );
  dispatch(fetchComplete());
};
export const addSingleIndicator = (patternData) => (dispatch) => {
  const pattern = {
    id: patternData.chartId,
    patterns: [
      {
        indicator: patternData.name,
        shapes: patternData.shapes.map((shape) => formatShape(shape)),
      },
    ],
  };
  dispatch(addIndicator(pattern));
};

export const clearIndicatorsSingleChart = (chartId) => async (
  dispatch,
  getState
) => {
  if (!chartId) chartId = getState().chartLayout.fullScreenChart;
  dispatch(removeIndicator(chartId));
};

// ────────────────────────────────────────────────────────────────────────────────

//
// ─── HELPER FUNCTIONS ───────────────────────────────────────────────────────────
//

const formatPoint = (point) => {
  if (Array.isArray(point)) {
    point = point[0];
  }
  return {
    price: point.price,
    time: Utils.convertDatetimeToTimestamp(point.datetime) / 1000,
  };
};

const formatShape = (shape) => {
  return {
    shape_type: shape.shape_type,
    points: shape.points.map((point) => formatPoint(point)),
  };
};

const formatPattern = (pattern) => {
  return {
    indicator: pattern.indicator_name,
    shapes: pattern.shapes.map((shape) => formatShape(shape)),
  };
};

const formatPatterns = (indicatorPatterns) => {
  return indicatorPatterns.map((pattern) => formatPattern(pattern));
};
