import {
  createSlice,
  createEntityAdapter,
  nanoid,
  createSelector,
} from "@reduxjs/toolkit";
import { Api } from "../../api/api";
import { Utils } from "../../utils/utils";
import { addSDPattern } from "./selectedDetectedPatterns";
import Bottleneck from "bottleneck";
import { addMultipleChartDataReducer } from "./chartData";

export const MAX_PATTERNS_NUMBER = 100;
const detectedPatternsAdapter = createEntityAdapter();

const initialState = detectedPatternsAdapter.getInitialState({
  fetching: false,
  fetchProgress: 0,
  confidence: 0.75,
  chartCollectionToDetect: null,
  numberOfNewDetectedPatterns: 0,
});

const detectedPatternsSlice = createSlice({
  name: "detectedPatterns",
  initialState,
  reducers: {
    fetchingPatterns: (state) => {
      state.fetching = true;
    },
    fetchDetectPatternsProgress: (state, action) => {
      state.fetchProgress = action.payload;
    },
    fetchComplete: (state) => {
      state.fetching = false;
      state.progress = 0;
    },
    updateConfidence: (state, action) => {
      state.confidence = action.payload;
    },
    setChartCollectionToDetectOn: (state, action) => {
      state.chartCollectionToDetect = action.payload;
    },
    setNumberOfNewDetectedPatterns: (state, action) => {
      state.numberOfNewDetectedPatterns = action.payload;
    },
    addDPattern: (state, action) => {
      detectedPatternsAdapter.upsertOne(state, action.payload);
    },
    addManyDPatterns: (state, action) => {
      detectedPatternsAdapter.upsertMany(state, action.payload);
    },
    removeDPattern: (state, action) => {
      detectedPatternsAdapter.removeOne(state, action.payload);
    },
    removeManyDpatterns: (state, action) => {
      detectedPatternsAdapter.removeMany(state, action.payload);
    },
    removeAllDPatterns: (state) => {
      detectedPatternsAdapter.setAll(state, []);
    },
    updateDPattern: (state, action) => {
      detectedPatternsAdapter.updateOne(state, action.payload);
    },
  },
});

export const {
  fetchingPatterns,
  fetchDetectPatternsProgress,
  fetchComplete,
  updateConfidence,
  setChartCollectionToDetectOn,
  setNumberOfNewDetectedPatterns,
  addDPattern,
  addManyDPatterns,
  removeDPattern,
  removeManyDpatterns,
  removeAllDPatterns,
  updateDPattern,
} = detectedPatternsSlice.actions;

export default detectedPatternsSlice.reducer;

export const updateChartCollectionToDetectOn =
  (collection) => async (dispatch) => {
    if (!collection) {
      dispatch(setChartCollectionToDetectOn(null));
      return;
    }

    const constChartCollectionInfo = await Api.getChartCollection(
      collection.id
    );
    dispatch(setChartCollectionToDetectOn(constChartCollectionInfo));
  };

//
// ─── SELECTORS ──────────────────────────────────────────────────────────────────
//
export const selectConfidence = (state) => state.detectedPatterns.confidence;

export const {
  selectAll: selectAllDPatterns,
  selectIds: selectDPatternIds,
  selectTotal: selectTotalDPatterns,
} = detectedPatternsAdapter.getSelectors((state) => state.detectedPatterns);

export const selectFilteredDPatterns = createSelector(
  [selectAllDPatterns, selectConfidence],
  (patterns, confidence) => {
    return patterns.filter((pattern) => pattern.confidence > confidence);
  }
);
export const selectDChartList = createSelector(
  [selectFilteredDPatterns],
  (patterns) =>
    patterns.map(({ chartName, interval }) => ({
      chartName,
      interval,
    }))
);
export const filteredDPatternsTotal = createSelector(
  [selectFilteredDPatterns],
  (patterns) => patterns.length
);

// ────────────────────────────────────────────────────────────────────────────────

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//
export const detectPatterns =
  ({
    model,
    defaultConfidence: use_model_confidence_threshold,
    maxPatterns: max_number,
    detectionProposals: proposal_time_range,
  }) =>
  async (dispatch, getState) => {
    // ─── GET CURRENT STATE ──────────────────────────────────────────────────────────

    const chartColllectionToDetectOn =
      getState().detectedPatterns.chartCollectionToDetect;
    // ────────────────────────────────────────────────────────────────────────────────

    if (!chartColllectionToDetectOn) {
      return 0;
    }

    dispatch(fetchingPatterns());
    const chartData = await findChartData(chartColllectionToDetectOn, getState);

    dispatch(addMultipleChartDataReducer(chartData));
    dispatch(
      removeExistingPatterns(model.name, chartColllectionToDetectOn.charts)
    );

    let progress = 0; //we will update the progress as each api call is processed
    let numberOfPatternsFound = 0;

    const payload_template = {
      modelId: model.id,
      max_number,
      use_model_confidence_threshold,
      proposal_time_range,
    };

    const limiter = new Bottleneck({
      maxConcurrent: 20,
    });

    await Promise.all(
      chartColllectionToDetectOn.charts.map(async (chart) => {
        await limiter.schedule(async () => {
          const res = await getDetectedPatterns({
            payload_template,
            chart,
          });
          if (!res) return;
          const detectedPatterns = res.patterns;

          const formattedPatterns = formatPatterns(
            detectedPatterns,
            model.name,
            chart
          );

          numberOfPatternsFound += formattedPatterns.length;
          dispatch(addManyDPatterns(formattedPatterns));

          progress++;
          await dispatch(
            fetchDetectPatternsProgress(
              Math.round(
                (100 * progress) / chartColllectionToDetectOn.charts.length
              )
            )
          );
          //end current
        });
      })
    );
    dispatch(fetchComplete());
    return numberOfPatternsFound;
  };

export const removeExistingPatterns =
  (model, charts) => async (dispatch, getState) => {
    const currentPatterns = Object.values(getState().detectedPatterns.entities);
    const patternsToRemove = currentPatterns
      .filter(
        (pattern) =>
          pattern.model === model &&
          charts.find((chart) => chart.id === pattern.chartId)
      )
      .map((pattern) => pattern.id);
    dispatch(removeManyDpatterns(patternsToRemove));
  };

export const detectPatternsForUnsavedModel =
  (patternsTimeRange) => async (dispatch, getState) => {
    const confidence = getState().detectedPatterns.confidence;
    const modelId = getState().models.temporaryModel;
    if (modelId) {
      const model = {
        id: modelId,
        name: "Unsaved model",
        confidence_threshold: confidence,
      };
      const response = await dispatch(
        detectPatterns({
          model,
          defaultConfidence: false,
          maxPatterns: MAX_PATTERNS_NUMBER,
          detectionProposals: patternsTimeRange,
        })
      );
      return response;
    }
  };

export const clearDetectedPatternsSingleChart =
  (chartId) => async (dispatch, getState) => {
    if (!chartId) chartId = getState().chartLayout.fullScreenChart;
    const allPaterns = Object.values(getState().detectedPatterns.entities);
    const chartPatterns = allPaterns.filter(
      (pattern) => pattern.chartId === chartId
    );
    dispatch(removeManyDpatterns(chartPatterns.map((pattern) => pattern.id)));
  };

export const addSinglePattern = (patternData) => (dispatch) => {
  const pattern = {
    id: patternData.id,
    chartId: patternData.chartId,
    model: patternData.otherData.model_name,
    confidence: Math.abs(patternData.signal),
    shapes: patternData.shapes.map((shape) => formatShape(shape)),
    interval: patternData.chartData
      ? patternData.chartData.interval
      : patternData.otherData.interval,
    midPoint: getMidPoint(patternData.shapes[0].points),
    selected: false,
  };
  dispatch(addDPattern(pattern));
};
export const selectPattern = (id) => (dispatch, getState) => {
  const pattern = getState().detectedPatterns.entities[id];
  dispatch(updateDPattern({ id, changes: { selected: true } }));
  dispatch(addSDPattern(pattern));
};
// ────────────────────────────────────────────────────────────────────────────────

//
// ─── HELPER FUNCTIONS ───────────────────────────────────────────────────────────
//

const createPayload = ({ payload_template, chartId }) => {
  const payload = {
    ...payload_template,
    chartId,
  };

  return payload;
};

const getDetectedPatterns = async ({ payload_template, chart }) => {
  const payload = createPayload({
    payload_template,
    chartId: chart.id,
  });

  const patternsPercentage = [1, 0.5];

  for (let i = 0; i < patternsPercentage.length; i++) {
    if (!payload.max_number) {
      payload.max_number = 100;
    }

    payload.max_number = Math.round(payload.max_number * patternsPercentage[i]);
    
    const startTime = new Date().getTime() / 1000;

    try {
      if (payload.data) {
        const result = await Api.detectPatternsWithData(payload);
        return result.data[0];
      } else {
        const result = await Api.detectPatternsWithoutData(payload);
        return result;
      }
    } catch(err) {
      const tookTime = (new Date().getTime() / 1000) - startTime;

      if (tookTime < 20 && err.message === "Internal server error") {
        continue;
      }

      // if operation tooks more than 20 sec it mean that it's lambda timeout failure 
      // and we could skip this chart
      console.log(err);
      return;
    };
  };
};

const getMidPoint = (points) => {
  const pointTimes = points.map((point) =>
    point.time ? point.time : Utils.convertDatetimeToTimestamp(point.datetime)
  );
  return pointTimes[1] ? (pointTimes[0] + pointTimes[1]) / 2 : pointTimes[0];
};

const formatPoint = (point) => {
  return {
    price: point.price,
    time: Utils.convertDatetimeToTimestamp(point.datetime) / 1000,
  };
};

const formatShape = (shape) => {
  return {
    shape_type: shape.shape_type,
    points: shape.points
      .map((point) => formatPoint(point))
      .sort((a, b) => a.time - b.time),
  };
};

const formatPattern = (pattern, modelName, chart) => {
  const shapes = pattern.shapes.map((shape) => formatShape(shape));

  const chartName = chart.name.split("_")[0];

  return {
    id: nanoid(),
    chartId: chart.id,
    chartName,
    model: modelName,
    confidence: pattern.confidence,
    shapes,
    interval: chart.interval,
    midPoint: getMidPoint(shapes[0].points),
    selected: false,
  };
};

const formatPatterns = (detectedPatterns, modelName, chart) => {
  return detectedPatterns.map((pattern) =>
    formatPattern(pattern, modelName, chart)
  );
};

const findChartData = async (chartCollection) => {
  const resultArray = await Promise.all(
    chartCollection.charts.map(async (chart) => {
      const { chartData, bars } = await getChartData(chart.id);
      chartData.collection = chartCollection.id;

      return { id: chart.id, chartData, bars };
    })
  );

  return resultArray;
};

const getChartData = async (id) => {
  try {
    const { chartData, bars } = await Api.getChart(id, {});
    return { chartData, bars };
  } catch (error) {
    console.log(error);
    return;
  }
};
