import { useEffect, useState } from "react";
import { Api } from "../../../../../api/api";

export const useFetchSignalWithChart = (signal) => {
  const [signalData, setSignalData] = useState({ ...signal, shapes: [] });
  const [chartInfo, setChartInfo] = useState(null);

  useEffect(() => {
    const fetchSignalAndChart = async () => {
      const signalData = await Api.getSignal(signal.id);

      const shapeRangePoints = signalData.shapes[0].points;

      let chartDateRange = {};

      if (signal.signal_type === "indicator") {
        chartDateRange = calculateStartEndDatesForIndicator(
          shapeRangePoints[0].datetime,
          signal.interval
        );
      } else {
        const startDatetime = shapeRangePoints[0].datetime;
        let endDatetime;

        if (signal.status === "ACTIVE") {
          endDatetime =
            signalData.shapes[signalData.shapes.length - 1].points[0].datetime;
        } else {
          endDatetime = shapeRangePoints[1].datetime;
        }

        chartDateRange = {
          startDatetime: substractBarsFromDatetime(
            startDatetime,
            signal.interval,
            25
          ),
          endDatetime: addBarsToDatetime(endDatetime, signal.interval, 25),
        };
      }

      const chartData = await Api.getChart(signal.chart_id, chartDateRange);

      setSignalData({ ...signal, ...signalData });
      setChartInfo(chartData);
    };

    fetchSignalAndChart();
  }, [signal]);

  return { signalData, chartInfo };
};

const calculateStartEndDatesForIndicator = (indicatorDatetimeStr, interval) => {
  return {
    startDatetime: substractBarsFromDatetime(
      indicatorDatetimeStr,
      interval,
      25
    ),
    endDatetime: addBarsToDatetime(indicatorDatetimeStr, interval, 5),
  };
};

const addBarsToDatetime = (dateTimeStr, interval, barscount) => {
  const dt = new Date(dateTimeStr);
  dt.setMinutes(dt.getMinutes() + interval * barscount);
  return convertDateToIsoFormat(dt);
};

const substractBarsFromDatetime = (dateTimeStr, interval, barscount) => {
  const dt = new Date(dateTimeStr);
  dt.setMinutes(dt.getMinutes() - interval * barscount);
  return convertDateToIsoFormat(dt);
};

const convertDateToIsoFormat = (date) => {
  const year = date.getFullYear();
  const month = add0IfLessThan10(date.getMonth() + 1);
  const day = add0IfLessThan10(date.getDate());
  const hours = add0IfLessThan10(date.getHours());
  const minutes = add0IfLessThan10(date.getMinutes());
  const seconds = add0IfLessThan10(date.getSeconds());

  const r = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return r;
};

const add0IfLessThan10 = (number) => {
  const s = number < 10 ? `0${number}` : number;
  return s;
};
