import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Label = styled.p`
  align-self: center;
  font-size: 12px;
  margin: 0;
`;
