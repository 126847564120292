import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Label,
  SelectedLine,
  UnselectedLine,
} from "./DepthSliderStyles";
import DragCircle from "./DragCircle/DragCircle";
import { setDepthConstraint } from "../../../../../redux/slices/heatMap";

export default function DepthSlider({ openFilter, transitioningHeight }) {
  const drillDownBy = useSelector(({ heatMap }) => heatMap.drillDownBy);
  const depthConstraint = useSelector(({ heatMap }) => heatMap.depthConstraint);

  const dispatch = useDispatch();

  const [selectedLineHeight, setSelectedLineHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  ///// set SelectedLine and UnselectedLine heights
  useEffect(() => {
    let newSelectedLineHeight = (depthConstraint - 1) * 34;

    const openIndex = drillDownBy.findIndex((layer) => layer === openFilter);

    if (openIndex > -1) {
      if (openIndex < depthConstraint - 1) {
        newSelectedLineHeight += 300;
      }
    }

    setSelectedLineHeight(newSelectedLineHeight);
  }, [depthConstraint, drillDownBy, openFilter]);

  ///// Set Container height
  useEffect(() => {
    const layersNumber = drillDownBy.length;
    const openFilterIncrement = openFilter ? 300 : 0;
    const newHeight = (layersNumber - 1) * 34 + openFilterIncrement;
    setContainerHeight(newHeight);
  }, [drillDownBy, openFilter]);

  ////// Set depthConstraint to never exceed drillDownBy.length
  useEffect(() => {
    if (depthConstraint > drillDownBy.length)
      dispatch(setDepthConstraint(drillDownBy.length));
  }, [depthConstraint, drillDownBy, dispatch]);

  return (
    drillDownBy.length > 1 && (
      <div>
        <Label>Depth Slider</Label>
        <Container
          height={containerHeight}
          transitioningHeight={transitioningHeight}
        >
          <SelectedLine
            height={selectedLineHeight}
            transitioningHeight={transitioningHeight}
          />

          <DragCircle
            top={selectedLineHeight}
            transitioningHeight={transitioningHeight}
            openFilter={openFilter}
            setSelectedLineHeight={setSelectedLineHeight}
          />

          <UnselectedLine transitioningHeight={transitioningHeight} />
        </Container>
      </div>
    )
  );
}
