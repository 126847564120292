import React from "react";
import { useDispatch } from "react-redux";

import { IconButton, Tooltip } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import {
  setSelectedShape,
  saveCurrentShape,
} from "../../../../../redux/slices/coldStart";

export default function CloneButton({ disabled }) {
  const dispatch = useDispatch();

  const onClone = () => {
    dispatch(setSelectedShape(null));
    dispatch(saveCurrentShape());
  };

  return (
    <Tooltip title="Save new pattern without clearing it">
      <span>
        <IconButton disabled={disabled} onClick={onClone}>
          <FileCopyIcon
            color={disabled ? "disabled" : "primary"}
            fontSize="large"
          />
        </IconButton>
      </span>
    </Tooltip>
  );
}
