import React from "react";

import { ListItemContainer } from "../../../LeftPanelStyle";
import {
  ChartListItem,
  SelectedIndicator,
  DetectedIndicator,
} from "./ChartItemStyle";

import DeleteChartButton from "./DeleteChartButton";

function ChartItem({
  chart,
  selected,
  onSelectChart,
  setChartToDelete,
  sCharts,
  dCharts,
}) {
  return (
    <ListItemContainer>
      <ChartListItem
        selected={selected}
        onClick={() => onSelectChart(chart)}
      >
        <div>{chart.name}</div>
      </ChartListItem>
      {sCharts && <SelectedIndicator />}
      {dCharts && <DetectedIndicator />}
      {chart.common === false && (
        <DeleteChartButton setChartToDelete={setChartToDelete} chart={chart} />
      )}
    </ListItemContainer>
  );
}

export default ChartItem;
