import React, { useState, useEffect, useRef } from "react";
import {
  MenuButtonBurger,
  MenuButtonContainer,
  Container,
} from "./ToolbarMenuStyles";
import DropdownMenu from "./DropdownMenu/DropdownMenu";

export default function ToolbarMenu() {
  // useState hooks
  const [open, setOpen] = useState(false);

  // useRef hooks
  const logoRef = useRef(null);

  // useEffect hooks
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        logoRef &&
        logoRef.current &&
        !logoRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [open, setOpen]);

  return (
    <Container ref={logoRef}>
      <MenuButtonContainer onClick={() => setOpen(!open)}>
        <MenuButtonBurger open={open} />
      </MenuButtonContainer>
      <DropdownMenu open={open} setOpen={setOpen} />
    </Container>
  );
}
