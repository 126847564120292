import React from "react";
import { Dot } from "react-animated-dots";

export default function AnimatedMessage({ children }) {
  return (
    <div>
      {children}
      <Dot>.</Dot>
      <Dot>.</Dot>
      <Dot>.</Dot>
    </div>
  );
}
