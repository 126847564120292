import { createSlice } from "@reduxjs/toolkit";
import { setFullScreenChart } from "./chartLayout";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    pattern: null,
  },
  reducers: {
    setPatternNavigation: (state, action) => {
      state.pattern = action.payload;
    },
  },
});

export const { setPatternNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;

export const navigate = (chartId, range) => async (dispatch, getState) => {
  const fullScreenChart = getState().chartLayout.fullScreenChart;

  if (fullScreenChart !== chartId) {
    dispatch(setFullScreenChart(chartId));
  }
  dispatch(setPatternNavigation([range.start / 1000, range.end / 1000]));
};
