import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DetectButtonContainer = styled.div`
  box-sizing: border-box;
  margin-top: 5px;
  padding-right: 4px;
  width: 50%;
`;

export const Title = styled.p`
  font-size: 12px;
  margin: 0;
  margin-bottom: 10px;
`;
