import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import LocalStorage from "../../utils/localStorage";

const heatMapFavouritesAdapter = createEntityAdapter();

const localStorageFavourites = LocalStorage.getFavouritedHeatMapSignals();

const initialStateObject = localStorageFavourites
  ? {
      warningModalOpen: false,
      ids: Object.keys(localStorageFavourites),
      entities: localStorageFavourites,
    }
  : { warningModalOpen: false };

const initialState =
  heatMapFavouritesAdapter.getInitialState(initialStateObject);

const heatMapFavouritesSlice = createSlice({
  name: "heatMapFavourites",
  initialState: initialState,
  reducers: {
    addToFavourites: (state, action) => {
      heatMapFavouritesAdapter.upsertOne(state, action.payload);
      LocalStorage.setFavouritedHeatMapSignals(state.entities);
    },

    removeFromFavourites: (state, action) => {
      heatMapFavouritesAdapter.removeOne(state, action.payload);
      LocalStorage.setFavouritedHeatMapSignals(state.entities);
    },

    removeAllFromFavourites: (state) => {
      heatMapFavouritesAdapter.setAll(state, []);
      LocalStorage.setFavouritedHeatMapSignals(state.entities);
    },
  },
});

export const {
  addToFavourites,
  removeFromFavourites,
  removeAllFromFavourites,
} = heatMapFavouritesSlice.actions;

export const { closeWarningModal } = heatMapFavouritesSlice.actions;

export default heatMapFavouritesSlice.reducer;

//
// ─── SELECTORS ──────────────────────────────────────────────────────────────────
//

export const {
  selectAll: selectAllFavourites,
  selectIds: selectFavouritesIds,
} = heatMapFavouritesAdapter.getSelectors((state) => state.heatMapFavourites);

export const toggleFavourite = (pattern) => (dispatch, getState) => {
  const allFavourites = selectAllFavourites(getState());

  const isPatternInFavourites = allFavourites.reduce(
    (acc, el) => (el.id === pattern.id ? true : acc),
    false
  );

  isPatternInFavourites
    ? dispatch(removeFromFavourites(pattern.id))
    : dispatch(addToFavourites(pattern));
};
