import styled from "styled-components";

export const StyledMainPage = styled.div`
  background-color: ${(props) => props.theme.primaryBackground};
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;
export const MainPageSubContainer = styled.div`
  display: flex;
  height: calc(100vh - 50px);
  overflow: hidden;
`;
