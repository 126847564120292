import styled from "styled-components";

export const StyledSavedPatterns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 10px;
  height: 20%;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
`;
