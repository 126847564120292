import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetClass from "../../../../MainChartContainer/Components/Classes/WidgetClass";
import { ChartUtils } from "../../../../../utils/chartUtils";
import { useUpdateBars } from "./useUpdateBars";
import { setHeatMapChartSignal } from "../../../../../redux/slices/heatMapChart";

const DISABLED_FEATURES = [
  "header_symbol_search",
  "header_undo_redo",
  "header_compare",
  "header_fullscreen_button",
  "header_resolutions",
  "legend_widget",
  "timeframes_toolbar",
];

const WIDGET_OPTIONS = {
  symbol: "preload",
  datafeed: {
    onReady: ChartUtils.onReady,
    searchSymbols: ChartUtils.searchSymbols,
    resolveSymbol: ChartUtils.resolveSymbol(60),
    getBars: ChartUtils.getBars("tile", "heatMap"),
    subscribeBars: ChartUtils.subscribeBars,
    unsubscribeBars: ChartUtils.unsubscribeBars,
  },
  interval: 60,
  library_path: "/charting_library/",
  autosize: true,
  disabled_features: DISABLED_FEATURES,
  studiesOverrides: { showStudiesLastValue: true },
  theme: "Dark",
};

export const useSetupChart = ({ chartId }) => {
  const dispatch = useDispatch();
  const data = useSelector(({ heatMapChart }) => heatMapChart.data);
  const [chart, setChart] = useState({});

  // useState hook
  const [newVisRange, setNewVisRange] = useState(false);

  //set up widget
  useEffect(() => {
    const widgetOptions = {
      ...WIDGET_OPTIONS,
      container_id: "heat-map-full-chart",
    };
    const widgetApp = new window.TradingView.widget(widgetOptions);
    widgetApp.onChartReady(() => {
      const newChart = new WidgetClass({
        instance: widgetApp,
        chartId: chartId,
        chartName: data.chartData.name,
      });

      newChart.onRemoveShape((shapeId) => {
        dispatch(setHeatMapChartSignal({signal: null, modelName: null}));
      });

      newChart.onVisibleRangeChange(() => {
        setNewVisRange([true]);
      });

      newChart.toggleDrawingMenu(); //remove on setup
      setChart(newChart);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateBars({ chart, data });

  return {
    chart,
    newVisRange,
  };
};
