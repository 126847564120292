import { createSlice } from "@reduxjs/toolkit";
import { Api } from "../../api/api";
import isUserAdmin from "../../auth/utils/isUserAdmin"

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isAuthorised: null,
    error: null,
    isUserAdmin: false,
  },
  reducers: {
    onAuthorizeFail: (state) => {
      state.isAuthorised = false;
      state.isUserAdmin = false;
    },
    setAuthorized: (state) => {
      state.isAuthorised = true;
    },
    setIsAdmin: (state, action) => {
      state.isUserAdmin = action.payload;
    }
  },
});

export const { setAuthorized, onAuthorizeFail, setIsAdmin } = loginSlice.actions;

export default loginSlice.reducer;

export const onAuthorizeSuccess = () => async (dispatch) => {
  dispatch(setAuthorized());
  const isAdmin = await isUserAdmin();
  dispatch(setIsAdmin(isAdmin));
}

export const logout = () => async (dispatch) => {
  try {
    await Api.logout();
    dispatch(onAuthorizeFail());
  } catch (error) {
    console.log("logout error");
  }
};
