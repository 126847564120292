import React from "react";
import ModeSelect from "./ModeSelect/ModeSelect";
import ToolbarMenu from "./ToolbarMenu/ToolbarMenu";
import ToolbarLogo from "./ToolbarLogo/ToolbarLogo";
import ToolbarModal from "./ToolbarModal/ToolbarModal";
import { Container, Spacer } from "./ToolbarStyles";

export default function Toolbar() {
  return (
    <Container>
      <ToolbarModal />
      <ToolbarLogo />
      <Spacer />
      <ModeSelect />
      <Spacer />
      <ToolbarMenu />
    </Container>
  );
}
