import styled from "styled-components";

export const SelectedPatternContainer = styled.div`
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  overflow: hidden;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  overflow: scroll;
  align-content: flex-start;
  justify-content: center;
`;
