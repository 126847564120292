import { useMemo } from "react";

export const useGetPatternLines = (
  filteredBars,
  patternShapes,
  width,
) => {
  return useMemo(() => {
    if (!filteredBars || !patternShapes.length) return [];

    const xRange = filteredBars.length - 1;

    const getLeftPixels = (x) => {
      return (width * x) / xRange;
    };

    const findIndex = (time) => {
      const convertedTime = time / 1000; //need to adjust timestamps to be compatible with the bars
      return filteredBars.findIndex((bar) => bar.time === convertedTime);
    };

    return patternShapes.map((shape) => {
      const points = shape.points;

      const x1 = getLeftPixels(findIndex(points[0].datetime));
      const x2 = getLeftPixels(findIndex(points[points.length - 1].datetime));
;
      return {x1, x2};
    });
  }, [filteredBars, patternShapes, width]);
};
