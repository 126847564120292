import React from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import {
  NoChartsMessageContainer,
  NoChartsText,
} from "./NoChartsMessageStyles";

export default function NoChartsMessage() {
  return (
    <NoChartsMessageContainer>
      <ArrowBackIosIcon fontSize="large" />
      <NoChartsText>Use the chart list to get started</NoChartsText>
    </NoChartsMessageContainer>
  );
}
