import findIntersectionPoints from "./utils/findIntersectionPoints";

const extendCombinationLines = (combination, filteredBars, upperOrLower) => {
  const firstTime = filteredBars[0].time;
  const lastTime = filteredBars[filteredBars.length - 1].time;

  const y = upperOrLower === "upper" ? "high" : "low";

  let result = [];

  combination.forEach((lineSegment, index) => {
    if (result === "invalid") return;

    const grad =
      (lineSegment[1][y] - lineSegment[0][y]) /
      (lineSegment[1].time - lineSegment[0].time);
    const newLineSegment = [];

    if (index === 0) {
      const xDiff = firstTime - lineSegment[0].time;
      const newY = xDiff * grad + lineSegment[0][y];
      newLineSegment.push({
        time: firstTime,
        [y]: newY,
      });
    }

    const prevLineSegment = combination[index - 1];
    const nextLineSegment = combination[index + 1];

    const intersectionPoints = findIntersectionPoints(
      lineSegment,
      prevLineSegment,
      nextLineSegment,
      y
    );

    if (intersectionPoints === "invalid") return (result = "invalid");

    newLineSegment.push(...intersectionPoints);

    if (index === combination.length - 1) {
      const xDiff = lastTime - lineSegment[1].time;
      const newY = xDiff * grad + lineSegment[1][y];
      newLineSegment.push({
        time: lastTime,
        [y]: newY,
      });
    }

    result.push(newLineSegment);
  });

  return result;
};

export default extendCombinationLines;
