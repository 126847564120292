import React from "react";

import { TileChartContainer, StyledTileChart } from "./TileChartStyles";
import Toolbar from "./Components/Toolbar/Toolbar";

import { useSetupChart } from "./hooks/useSetupChart";
import { useDrawingMenu } from "./hooks/useDrawingMenu";
import { useVisibleRangeController } from "./hooks/useVisibleRangeController";
import { usePatternController } from "./hooks/usePatternController";

export default function TileChart({ chartId, matchedPattern }) {
  const { chart, newVisRange } = useSetupChart({
    chartId,
  });

  useDrawingMenu({ chart });

  usePatternController({ chart, newVisRange, matchedPattern });

  useVisibleRangeController({ chart, newVisRange });

  return (
    <TileChartContainer>
      <Toolbar />

      <StyledTileChart id={"heat-map-full-chart"} />
    </TileChartContainer>
  );
}
