import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SwitchContainer, Option } from "../FilterSwitchesStyles";
import { changeFilter } from "../../../../../../redux/slices/heatMap";

const Status = { ACTIVE: "ACTIVE", FORMED: "FORMED", ALL: null };

export default function ActiveFormedSwitch() {
  const signalStatus = useSelector(({ heatMap }) => heatMap.filters.status);

  const dispatch = useDispatch();

  const switchStatusHandler = (status) => {
    dispatch(changeFilter({ status: status }));
  };

  return (
    <SwitchContainer side={"left"}>
      <Option
        side={"left"}
        selected={signalStatus === Status.ACTIVE}
        onClick={() => switchStatusHandler(Status.ACTIVE)}
        selectedColor={"#87CEFA"}
      >
        <p>open</p>
      </Option>
      <Option
        selected={signalStatus === Status.ALL}
        selectedColor={"#DCDCDC"}
        onClick={() => switchStatusHandler(Status.ALL)}
      >
        <p>all</p>
      </Option>
      <Option
        side={"right"}
        selected={signalStatus === Status.FORMED}
        onClick={() => switchStatusHandler(Status.FORMED)}
        selectedColor={"#FFA500"}
      >
        <p>emerging</p>
      </Option>
    </SwitchContainer>
  );
}
