import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(38, 51, 64);
  border-top-right-radius: ${({ expanded }) => (expanded ? "" : "15px")};
  border-bottom-right-radius: ${({ expanded }) => (expanded ? "" : "15px")};
  border-top-left-radius: ${({ expanded }) => (expanded ? "15px" : "")};
  border-bottom-left-radius: ${({ expanded }) => (expanded ? "15px" : "")};
  border: ${({ expanded }) => (expanded ? "white 1px solid" : "")};
  border-right: none;
  cursor: grab;
  height: 60px;
  left: ${({ expanded }) => (expanded ? "" : "100%")};
  right: ${({ expanded }) => (expanded ? "0" : "")};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  z-index: 1000;
`;

export const Dot = styled.div`
  background-color: white;
  border-radius: 50%;
  height: 6px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: ${({ y }) => `translate(-55%, ${-50 + y}%)`};
  width: 6px;
`;
