import styled from "styled-components";

export const CollectionLabel = styled.p`
  font-size: 12px;
`;

export const ButtonContainer = styled.div`
  margin: 20px 0;
`;

export const NoCustomCollectionsMessage = styled.p`
  font-size: 12px;
  margin: auto;
`;
