import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DropdownItem from "./DropdownItem/DropdownItem";

import {
  setSelectedModel,
  setActionModel,
  setIsEditModalOpen,
  setIsDeleteModelModalOpen,
  setIsDeployModalOpen,
} from "../../../../redux/slices/toolbar";
import {
  removeAllDPatterns,
  clearDetectedPatternsSingleChart,
} from "../../../../redux/slices/detectedPatterns";
import {
  removeAllSDPatterns,
  clearSelectedDetectedPatternsSingleChart,
} from "../../../../redux/slices/selectedDetectedPatterns";
import {
  clearSelectedPatterns,
  clearSelectedPatternsSingleChart,
} from "../../../../redux/slices/selectedPatterns";
// import { removeAllChartData } from "../../../../redux/slices/chartData";
// import { resetChartLayout } from "../../../../redux/slices/chartLayout";
import { logout } from "../../../../redux/slices/login";
import { clearColdStartPatterns } from "../../../../redux/slices/coldStart";
import {
  setDetectPatternsDisplay,
  setDrawModelDisplay,
  setHeatMapDisplay,
  setSelectPatternsDisplay,
  setRetrainModelDisplay,
  setSettingsDisplay,
} from "../../../../redux/slices/display";

import "./DropdownMenu.css";
// import LocalStorage from "../../../../utils/localStorage";
import {
  clearIndicatorsSingleChart,
  removeAllIndicators,
} from "../../../../redux/slices/indicators";
import { clearHeatMapChart } from "../../../../redux/slices/heatMapChart";
import {
  populateHeatMap,
  resetApiLoadingProgress,
} from "../../../../redux/slices/heatMap";

export default function DropdownMenu({ open, setOpen }) {
  // Retrieve redux dispatch
  const dispatch = useDispatch();

  // useSelector hooks
  const models = useSelector(({ models }) => models.data);

  // useState hooks
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(0);
  const [hidden, setHidden] = useState(true);
  const [reverse, setReverse] = useState(false);

  // useRef hooks
  const dropdownRef = useRef(null);

  // use Effect hooks
  useEffect(() => {
    if (open) {
      setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
      setHidden(false);
    } else {
      setMenuHeight(0);
      setTimeout(() => {
        setHidden(true);
      }, 300);
      setTimeout(() => {
        setActiveMenu("main");
      }, 500);
    }
  }, [open]);

  // Handler functions
  const calcHeight = (el) => {
    const height = el.offsetHeight;
    setMenuHeight(height);
  };
  const onResetPatterns = () => {
    dispatch(removeAllDPatterns());
    dispatch(clearSelectedPatterns());
    dispatch(removeAllSDPatterns());
    dispatch(clearColdStartPatterns());
    dispatch(removeAllIndicators());
  };
  const onResetSelectedPatterns = () => {
    dispatch(clearSelectedPatterns());
    dispatch(removeAllSDPatterns());
  };
  const onResetPatternsCurrent = () => {
    dispatch(clearDetectedPatternsSingleChart());
    dispatch(clearSelectedPatternsSingleChart());
    dispatch(clearSelectedDetectedPatternsSingleChart());
    dispatch(clearIndicatorsSingleChart());
  };
  const onResetSelectedPatternsCurrent = () => {
    dispatch(clearSelectedPatternsSingleChart());
    dispatch(clearSelectedDetectedPatternsSingleChart());
  };

  return (
    <div
      className={`dropdown ${hidden && "dropdown-hidden"}`}
      style={{ height: menuHeight }}
      ref={dropdownRef}
    >
      {/* Main Menu */}

      <CSSTransition
        in={activeMenu === "main"}
        timeout={open ? 500 : 0}
        classNames={reverse ? "menu-reverse" : "menu-forward"}
        unmountOnExit
        onEnter={open ? calcHeight : () => {}}
      >
        <div className="menu">
          <DropdownItem
            onClick={() => {
              resetApiLoadingProgress(dispatch);
              dispatch(setHeatMapDisplay());
              dispatch(clearHeatMapChart());
              dispatch(populateHeatMap());
              setOpen(false);
            }}
          >
            View Signals Heat Map
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setReverse(false);
              setActiveMenu("models");
            }}
            rightIcon={<ArrowForwardIosIcon />}
          >
            Signal Models
          </DropdownItem>
          <DropdownItem onClick={() => dispatch(setSettingsDisplay())}>
            Settings
          </DropdownItem>

          <DropdownItem onClick={() => dispatch(logout())}>
            Log Out
          </DropdownItem>
        </div>
      </CSSTransition>

      {/* Models  Menu */}

      <CSSTransition
        in={activeMenu === "models"}
        timeout={open ? 500 : 0}
        classNames={reverse ? "menu-reverse" : "menu-forward"}
        unmountOnExit
        onEnter={open ? calcHeight : () => {}}
      >
        <div className="menu">
          <DropdownItem
            onClick={() => {
              setReverse(true);
              setActiveMenu("main");
            }}
            backButton
          />

          <DropdownItem
            rightIcon={<ArrowForwardIosIcon />}
            onClick={() => {
              setReverse(false);
              setActiveMenu("models-select");
            }}
          >
            View Models
          </DropdownItem>
          <DropdownItem
            rightIcon={<ArrowForwardIosIcon />}
            onClick={() => {
              setReverse(false);
              setActiveMenu("training");
            }}
          >
            Train New Models
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              dispatch(setDetectPatternsDisplay());
              setOpen(false);
            }}
          >
            Detect Signals
          </DropdownItem>
        </div>
      </CSSTransition>

      {/* Training Menu */}

      <CSSTransition
        in={activeMenu === "training"}
        timeout={open ? 500 : 0}
        classNames={reverse ? "menu-reverse" : "menu-forward"}
        unmountOnExit
        onEnter={open ? calcHeight : () => {}}
      >
        <div className="menu">
          <DropdownItem
            onClick={() => {
              setReverse(true);
              setActiveMenu("models");
            }}
            backButton
          />

          <DropdownItem
            onClick={() => {
              dispatch(setDrawModelDisplay());
              setOpen(false);
            }}
          >
            Draw Chart Pattern
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              dispatch(setSelectPatternsDisplay());
              setOpen(false);
            }}
          >
            Select Patterns
          </DropdownItem>
        </div>
      </CSSTransition>

      {/* Models Select Menu */}
      <CSSTransition
        in={activeMenu === "models-select"}
        timeout={open ? 500 : 0}
        classNames={reverse ? "menu-reverse" : "menu-forward"}
        unmountOnExit
        onEnter={open ? calcHeight : () => {}}
      >
        <div className="menu">
          <DropdownItem
            onClick={() => {
              setReverse(true);
              setActiveMenu("models");
            }}
            backButton
          />
          {models &&
            models.map((model) => (
              <DropdownItem
                key={model.id}
                onClick={() => {
                  setReverse(false);
                  setActiveMenu(model.name);
                }}
              >
                {model.name}
              </DropdownItem>
            ))}
        </div>
      </CSSTransition>

      {models &&
        models.map((model) => (
          <CSSTransition
            in={activeMenu === model.name}
            timeout={open ? 500 : 0}
            classNames={reverse ? "menu-reverse" : "menu-forward"}
            unmountOnExit
            onEnter={open ? calcHeight : () => {}}
            key={model.id}
          >
            <div className="menu">
              <DropdownItem
                onClick={() => {
                  setReverse(true);
                  setActiveMenu("models");
                }}
                backButton
              />
              <DropdownItem
                onClick={() => {
                  dispatch(setSelectedModel(model));
                  setOpen(false);
                }}
              >
                Select
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  dispatch(setActionModel(model));
                  dispatch(setIsDeployModalOpen(true));
                  setOpen(false);
                }}
              >
                {model.deployed ? "Undeploy" : "Deploy"}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  dispatch(setActionModel(model));
                  dispatch(setIsEditModalOpen(true));
                  setOpen(false);
                }}
              >
                Edit
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  dispatch(setRetrainModelDisplay(model));
                  setOpen(false);
                }}
              >
                Retrain
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  dispatch(setActionModel(model));
                  dispatch(setIsDeleteModelModalOpen(true));
                  setOpen(false);
                }}
              >
                Delete
              </DropdownItem>
            </div>
          </CSSTransition>
        ))}

      {/* Display Mode Menu */}

      {/* Clear All Menu */}
      <CSSTransition
        in={activeMenu === "clearAll"}
        timeout={open ? 500 : 0}
        classNames={reverse ? "menu-reverse" : "menu-forward"}
        unmountOnExit
        onEnter={open ? calcHeight : () => {}}
      >
        <div className="menu">
          <DropdownItem
            onClick={() => {
              setReverse(true);
              setActiveMenu("main");
            }}
            backButton
          />
          <DropdownItem onClick={onResetPatterns}>
            Clear All Patterns
          </DropdownItem>
          <DropdownItem onClick={() => dispatch(removeAllDPatterns())}>
            Clear All Detected Patterns
          </DropdownItem>
          <DropdownItem onClick={onResetSelectedPatterns}>
            Clear All Selected Patterns
          </DropdownItem>
        </div>
      </CSSTransition>

      {/* Clear Current Menu */}
      <CSSTransition
        in={activeMenu === "clearCurrent"}
        timeout={open ? 500 : 0}
        classNames={reverse ? "menu-reverse" : "menu-forward"}
        unmountOnExit
        onEnter={open ? calcHeight : () => {}}
      >
        <div className="menu">
          <DropdownItem
            onClick={() => {
              setReverse(true);
              setActiveMenu("main");
            }}
            backButton
          />
          <DropdownItem onClick={onResetPatternsCurrent}>
            Clear All Patterns
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(clearDetectedPatternsSingleChart())}
          >
            Clear All Detected Patterns
          </DropdownItem>
          <DropdownItem onClick={onResetSelectedPatternsCurrent}>
            Clear All Selected Patterns
          </DropdownItem>
        </div>
      </CSSTransition>
    </div>
  );
}
