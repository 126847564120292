import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 330px;
`;

export const Tab = styled.div`
  border: ${(props) => props.selected && "1px solid lightgrey"};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: ${(props) =>
    props.selected ? "none" : "1px solid lightgrey"};
  box-shadow: ${(props) => props.selected && "0px -2px 5px #fff;"};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: ${({ width }) => width};
`;
