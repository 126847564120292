import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { StyledIconButton } from "./PatternStyle";

export default function DeletePattern({ id, onDelete }) {
  return (
    <StyledIconButton size="small" onClick={(e) => onDelete(e, id)}>
      <DeleteForeverIcon color="primary" fontSize="small" />
    </StyledIconButton>
  );
}
