import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  color: white;
  display: flex;
`;

export const Spacer = styled.div`
  width: 5px;
`;
