import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 2px;
  position: relative;
`;

export const ToolbarLogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 49px;
  justify-content: center;
  width: 120px;
`;
export const ToolbarLogo = styled.img`
  border-radius: 50%;
  cursor: pointer;
  height: 45px;
  user-drag: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;
export const ButtonContainer = styled.div`
  align-items: center;
  border-left: 1px solid #222;
  border-right: 1px solid #222;
  border-bottom: ${({ selected }) => (selected ? "1px solid #888" : "")};
  cursor: pointer;
  display: flex;
  height: 49px;
  justify-content: center;
  width: 120px;

  p {
    font-size: 12px;
  }
`;
