import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Label } from "./ShowMissingSignalsStyles";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import { changeFilter } from "../../../../redux/slices/heatMap";

const ShowMissingSignals = () => {
  const showMissingSignals = useSelector(({heatMap}) => heatMap.filters.showMissingSignals);

  const dispatch = useDispatch();

  return (
    <Container>
      <Label>Show missing signals</Label>
      <Checkbox
        checked={showMissingSignals}
        disableRipple
        disableFocusRipple
        onChange={() => dispatch(changeFilter({showMissingSignals: !showMissingSignals}))}
      />
    </Container>
  );
};

export default ShowMissingSignals;
