import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  CustomFormControlLabel,
  CustomSwitch,
  LabelText,
  SwitchContainer,
  InnerContainer,
} from "./UpdateSwitchStyles";
import Button from "../../../UI/Button/CustomButtonWhite";
import Warning from "../../../Modals/Warning/Warning";
import useMatchedPattern from "../../HeatMapFullChart/hooks/useMatchedPattern";
import { updateHeatMapChartPattern } from "../../../../redux/slices/heatMapChart";
import { setUpdatePattern } from "../../../../redux/slices/heatMap";

export default function UpdateSwitch({ setIsPatternUpdated }) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // useState hook
  const [warningModalOpen, setWarningModalOpen] = useState(false);

  // useSelector hook
  const pattern = useSelector(({ heatMapChart }) => heatMapChart.pattern);
  const populatedSignals = useSelector(
    ({ heatMap }) => heatMap.populatedSignals
  );

  const updatePattern = useSelector(({heatMap}) => heatMap.updatePattern);

  // Custom hook
  const matchedPattern = useMatchedPattern(pattern, populatedSignals);

  // Handler functions
  const onChange = () => {
    dispatch(setUpdatePattern((prev) => !prev));
    setIsPatternUpdated(false);
  };

  const handleCloseWarningModal = () => setWarningModalOpen(false);

  const onSaveButtonClick = () => {
    setUpdatePattern(false);
    setWarningModalOpen(true);
    dispatch(updateHeatMapChartPattern({ ...pattern, ...matchedPattern }));
  };

  return (
    <Container>
      {warningModalOpen && (
        <Warning
          onClose={handleCloseWarningModal}
          warning={"Updated pattern saved"}
        />
      )}
      <InnerContainer>
        <LabelText>Original pattern</LabelText>
        <SwitchContainer>
          <CustomFormControlLabel
            control={
              <CustomSwitch
                size="small"
                checked={updatePattern}
                onChange={onChange}
                classes={{
                  checked: "chart-switch-checked",
                  track: "chart-switch-track",
                }}
              />
            }
            labelPlacement="start"
            classes={{
              label: "chart-switch-label",
              root: "chart-switch-form-control",
            }}
          />
        </SwitchContainer>

        <LabelText>Updated pattern</LabelText>
      </InnerContainer>
      <Button disabled={!updatePattern} onClick={onSaveButtonClick}>
        Save updated pattern
      </Button>
    </Container>
  );
}
