import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SwitchContainer, Option } from "../FilterSwitchesStyles";
import { changeFilter } from "../../../../../../redux/slices/heatMap";

const EntryDirection = { BUY: "BUY", SELL: "SELL", ALL: null };

export default function BuySellSwitch() {
  const entryDirection = useSelector(
    ({ heatMap }) => heatMap.filters.entryDirection
  );

  const dispatch = useDispatch();

  const switchEntyDirectionHandler = (entryDirectionValue) => {
    dispatch(changeFilter({ entryDirection: entryDirectionValue }));
  };

  return (
    <SwitchContainer side={"left"}>
      <Option
        side={"left"}
        selected={entryDirection === EntryDirection.BUY}
        onClick={() => switchEntyDirectionHandler(EntryDirection.BUY)}
        selectedColor={"lime"}
      >
        <p>buy</p>
      </Option>
      <Option
        selected={entryDirection === EntryDirection.ALL}
        selectedColor={"#DCDCDC"}
        onClick={() => switchEntyDirectionHandler(EntryDirection.ALL)}
      >
        <p>all</p>
      </Option>
      <Option
        side={"right"}
        selected={entryDirection === EntryDirection.SELL}
        onClick={() => switchEntyDirectionHandler(EntryDirection.SELL)}
        selectedColor={"red"}
      >
        <p>sell</p>
      </Option>
    </SwitchContainer>
  );
}
