import { createSlice } from "@reduxjs/toolkit";
import { addChartData, removeChartData } from "./chartData";
import { Api } from "../../api/api";

const leftPanelSlice = createSlice({
  name: "leftPanel",
  initialState: {
    selectedCollection: null,
    selectedInterval: null,
    searchValue: "",
    isFetchingCollection: false,
    selectedChartIds: [],
  },
  reducers: {
    setSelectedCollection: (state, action) => {
      state.selectedCollection = action.payload;
    },
    setSelectedInterval: (state, action) => {
      state.selectedInterval = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setIsFetchingCollection: (state, action) => {
      state.isFetchingCollection = action.payload;
    },
    setSelectedChartIds: (state, action) => {
      state.selectedChartIds = action.payload;
    },
  },
});

export default leftPanelSlice.reducer;

export const {
  setSelectedCollection,
  setSelectedInterval,
  setSearchValue,
  setIsFetchingCollection,
  setSelectedChartIds,
} = leftPanelSlice.actions;

export const updateCurrenChartCollection = (collection) => async (dispatch) => {
  dispatch(setIsFetchingCollection(true));
  const constChartCollectionInfo = await Api.getChartCollection(collection.id);
  dispatch(setSelectedCollection(constChartCollectionInfo));
  dispatch(setIsFetchingCollection(false));
}

export const updatedSelectedChartIds = (chart, collection) => (dispatch, getState) => {
  const previousSelectedCharts = getState().leftPanel.selectedChartIds;
  
  if (previousSelectedCharts.includes(chart.id)) {
    dispatch(removeChartData(chart));
    dispatch(setSelectedChartIds(previousSelectedCharts.filter((c_id) => c_id !== chart.id)));
  } else {
    dispatch(addChartData(chart, collection, true));
    dispatch(setSelectedChartIds([...previousSelectedCharts, chart.id]));
  }
}

export const removeChartIdFromSelected = (chartId) => (dispatch, getState) => {
  const previousSelectedCharts = getState().leftPanel.selectedChartIds;
  dispatch(setSelectedChartIds(previousSelectedCharts.filter((c_id) => c_id !== chartId)));
}
