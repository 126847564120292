import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const EditedSign = styled.div`
  align-items: center;
  background-color: ${(props) => (props.isEdited ? "lime" : "grey")};
  border-radius: 3px;
  box-sizing: border-box;
  color: ${(props) => (props.isEdited ? "#333" : "white")};
  display: flex;
  font-size: 12px;
  margin: 0 5px;
  height: 32px;
  justify-content: center;
  padding: 2px;
  min-width: 70px;
`;
