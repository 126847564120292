import { useEffect, useRef } from "react";
import { useSDPatternsByChart } from "../../../../GlobalHooks/useSDPatternsByChart";

export const useDrawSDP = ({ drawShapes, newVisRange, chartId }) => {
  const visibleSDPatterns = useRef([]);

  const SDPatterns = useSDPatternsByChart(chartId);

  useEffect(() => {
    visibleSDPatterns.current = drawShapes({
      prevPatterns: visibleSDPatterns.current,
      patterns: SDPatterns,
      color: "#EBAD6F",
    });
  }, [SDPatterns, newVisRange, drawShapes]);
};
