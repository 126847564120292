import * as d3 from "d3";
import store from "../../../../redux/store";
import isHeatMapInvalid from "../../isHeatMapInvalid";
import {
  treemap,
  checkDataSize,
  upOneDrillDownLayer,
  uuidv4,
  zoomOut,
  displayRenderWarning,
} from "./renderHelperFunctions/generalRenderHelperFunctions";
import {
  clearSvg,
  addShadowToSvg,
  mapNodesToSvgGroups,
  createSvgRects,
} from "./renderHelperFunctions/svgHelperFunctions";
import {
  nodeOnClickListeners,
  setLeafNodeText,
  setParentNodeText,
} from "./renderHelperFunctions/nodesHelperFunctions";
import { addText } from "./renderHelperFunctions/toolTipHelperFunctions";
import { findHeight } from "./renderHelperFunctions/generalRenderHelperFunctions";

export let svg;
export let treeHeight;
export let width;
export let height;
export let drillDownBy;
export let filtersToLabelMap;
export let tickerFields = [];
export let depthConstraint;
export let modelIdToNameMap;
export let warning;
export let x;
export let y;
export let root;
export let nodes;
export let toolTip;

const render = (data, index) => {
  console.log("RENDER");

  svg = d3.select(`#heat-map-${index}`);
  svg.selectAll("*").remove();

  treeHeight = findHeight(data);
  width = document.querySelector("#heat-map-constraint").offsetWidth;
  height = document.querySelector("#heat-map-constraint").offsetHeight;

  // Select from redux store
  drillDownBy = store.getState().heatMap.drillDownBy;
  filtersToLabelMap = store.getState().heatMap.filtersToLabelMap;
  tickerFields = store.getState().heatMap.filterValues.tickerFields;
  depthConstraint = store.getState().heatMap.depthConstraint;
  modelIdToNameMap = store.getState().heatMap.filterValues.modelIdToNameMap;
  warning = store.getState().heatMap.warning;
  const leftPanelExpanded = store.getState().heatMap.leftPanelExpanded !== -1;

  d3.selection.prototype.moveToFront = function () {
    return this.each(function () {
      this.parentNode.appendChild(this);
    });
  };

  // Show warning if there are too many data points to show
  if (checkDataSize(data) && depthConstraint > 2) {
    upOneDrillDownLayer(warning);
  }

  // d3 scaleLinear functions
  x = d3.scaleLinear().domain([0, width]).range([0, width]);
  y = d3.scaleLinear().domain([0, height]).range([0, height]);

  root = treemap(data);

  // Clear svg and set height and width
  clearSvg(svg);

  // Create shadow
  addShadowToSvg(svg);

  // Map nodes in tree data to svg groups
  nodes = mapNodesToSvgGroups(svg);

  // Show warning if heatmap is invalid
  if (isHeatMapInvalid(root)) {
    upOneDrillDownLayer(warning);
  }

  // Position all svg rects
  nodes.attr("transform", (d) => `translate(${x(d.x0)},${y(d.y0)})`);

  // Create svg rects for each node in tree data
  createSvgRects(nodes);

  // Create clip path for text
  nodes
    .append("clipPath")
    .attr("id", (d) => {
      return (d.clipId = uuidv4())
    })
    .append("use")
    .attr("href", (d) => {
      return `#${d.nodeId}`
    });

  // Create hover tool tip for leaf nodes
  toolTip = svg.append("g").attr("class", "hidden");

  // Add event listeners to node tiles
  nodeOnClickListeners(nodes);

  // Set text for leaf nodes
  setLeafNodeText(nodes);

  // Set text for parent nodes
  setParentNodeText(nodes);

  toolTip
    .append("rect")
    .attr("x", "2")
    .attr("y", "2")
    .attr("width", "80")
    .attr("height", "24")
    .attr("fill", "black")
    .attr("opacity", "0.4")
    .attr("rx", "2")
    .attr("ry", "2");

  toolTip
    .append("rect")
    .attr("width", "80")
    .attr("height", "24")
    .attr("fill", "white")
    .attr("rx", "2")
    .attr("ry", "2");

  const numberOfText =
    index === 1 && drillDownBy.length > depthConstraint
      ? treeHeight
      : Object.keys(filtersToLabelMap).length + 1;

  // Add text for each level of tree
  addText(numberOfText, leftPanelExpanded);

  //Add conditional text to top level of heat map
  if (index === 2) {
    svg
      .select("g")
      .select("tspan")
      .text((d) => {
        return `${data.path.join(" > ")}`;
      });
  }

  // Display render warning if exists
  if (
    store.getState().heatMap.warning.messages.length &&
    store.getState().heatMap.warning.renderFirstTime
  ) {
    displayRenderWarning();
  }

  return {
    zoomOut: zoomOut,
  };
};

export default render;
