import React, { useCallback, useEffect } from "react";

import IndicatorItem from "./IndicatorItem/IndicatorItem";

export default function IndicatorList({
  indicators,
  setSelectedIndicators,
  selectedIndicators,
}) {
  useEffect(() => {
    if (!indicators.length || selectedIndicators.length) return;
    setSelectedIndicators([indicators[0]]);
  }, [indicators, selectedIndicators, setSelectedIndicators]);

  const onSelect = useCallback(
    (indicator) => {
      const newSelectedIndicators = [...selectedIndicators];
      const index = newSelectedIndicators.indexOf(indicator);
      if (index > -1) {
        if (newSelectedIndicators.length === 1) return; //prevent empty selection
        newSelectedIndicators.splice(index, 1);
      } else {
        newSelectedIndicators.push(indicator);
      }
      setSelectedIndicators(newSelectedIndicators);
    },
    [selectedIndicators, setSelectedIndicators]
  );

  return (
    <>
      {indicators.map((indicator, index) => (
        <IndicatorItem
          key={index}
          indicator={indicator}
          selected={selectedIndicators.includes(indicator)}
          onSelect={onSelect}
        />
      ))}
    </>
  );
}
