import React from "react";
import { useSelector } from "react-redux";
import MainChartContainer from "../MainChartContainer/MainChartContainer";
import Grid from "../Grid/Grid";
import LeftPanel from "../LeftPanel/LeftPanel";
import ColdStart from "../ColdStart/ColdStart";
import { Container } from "./ChartInterfaceStyles";

export default function ChartInterface({ show }) {
  const display = useSelector(({ display }) => display.display);
  return (
    <Container show={show}>
      <LeftPanel />
      <MainChartContainer show={display === "chart"} />
      {display === "grid" && <Grid />}
      {display === "coldStart" && <ColdStart />}
    </Container>
  );
}
