import { useEffect, useRef } from "react";
import { createChart } from "lightweight-charts";

const CHART_OPTIONS = {
  priceScale: { position: "none" },
  layout: { backgroundColor: "rgb(38,51,64)" },
  timeScale: { visible: false },
  crosshair: {
    vertLine: { visible: false },
    horzLine: { visible: false },
  },
  handleScroll: false,
  handleScale: false,
  grid: {
    vertLines: {
      visible: false,
    },
    horzLines: {
      visible: false,
    },
  },
};
const SERIES_OPTIONS = {
  priceLineVisible: false,
  lastValueVisible: false,
};

export const useSetupChart = ({ filteredBars, id, height, width }) => {
  const candleSeries = useRef(null);
  const chart = useRef(null);

  useEffect(() => {
    chart.current = createChart(id, {
      width,
      height,
    });
    candleSeries.current = chart.current.addCandlestickSeries();
    chart.current.applyOptions(CHART_OPTIONS);
    candleSeries.current.applyOptions(SERIES_OPTIONS);
    //only setup chart once, otherwise multiple charts will be created
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filteredBars) return;
    candleSeries.current.setData(filteredBars);
    chart.current.timeScale().fitContent();
  }, [filteredBars]);
};
