import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { Api } from "../../../api/api";
import { getModels } from "../../../redux/slices/models";

import CustomButton from "../../UI/Button/CustomButton";
import CustomSpinner from "../../UI/Spinner/CustomSpinner";

function DeployModel({ model, onClose }) {
  // Redux dispatch functino
  const dispatch = useDispatch();

  // useState hooks
  const [success, setSuccess] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const deployOrUndeployModel = async () => {
    setWaiting(true);
    try {
      if (model.deployed) {
        await Api.undeployModel(model.id);
        dispatch(getModels({ keepSelectedModel: true }));
      } else {
        await Api.deployModel(model.id);
        dispatch(getModels({ keepSelectedModel: true }));
      }
      setWaiting(false);
      setSuccess(true);
    } catch (e) {
      setWaiting(false);
      onClose();
      console.log(e);
    }
  };

  return (
    <Dialog
      classes={{ paper: "edit-model-modal " }}
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {model.deployed ? "Undeploy" : "Deploy"} model
      </DialogTitle>
      <div className="delete-container">
        {success ? (
          <div>
            <p style={{ color: "#00e676" }}>
              Model has been successfully{" "}
              {model.deployed ? "undeployed" : "deployed"}
            </p>

            <p>
              Please note that it may take a few minutes for signals to show on
              the heat map
            </p>
          </div>
        ) : (
          <div>
            Please confirm that you want to{" "}
            {model.deployed ? "undeploy" : "deploy"}:<p>{model.name}</p>
          </div>
        )}

        {waiting && <CustomSpinner />}
      </div>
      {!waiting && !success && (
        <DialogActions>
          <CustomButton variant="outlined" onClick={deployOrUndeployModel}>
            Confirm
          </CustomButton>
          <CustomButton variant="outlined" onClick={onClose}>
            Cancel
          </CustomButton>
        </DialogActions>
      )}
      {!waiting && success && (
        <DialogActions>
          <CustomButton variant="outlined" onClick={onClose}>
            OK
          </CustomButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
export { DeployModel as default };
