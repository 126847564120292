import { createSlice } from "@reduxjs/toolkit";
const DEFAULT_WIDTH = 430;

const gridSlice = createSlice({
  name: "grid",
  initialState: {
    drawerWidth: DEFAULT_WIDTH,
    showLines: false,
  },
  reducers: {
    setDrawerWidth: (state, action) => {
      state.drawerWidth = Math.max(DEFAULT_WIDTH, action.payload);
    },
    toggleShowLines: (state) => {
      state.showLines = !state.showLines;
    },
  },
});

export const { toggleShowLines, setDrawerWidth } = gridSlice.actions;

export default gridSlice.reducer;
