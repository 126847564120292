import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import "./CustomSpinner.css";

class CustomSpinner extends Component {
  render() {
    return <CircularProgress color="primary" thickness={5} />;
  }
}

export default CustomSpinner;
