import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateConfidence } from "../../../../redux/slices/detectedPatterns";
import CustomSlider from "../../../UI/Slider/CustomSlider";
import { Container } from "./ConfidenceSelectStyles";

function valueLabelFormat(value) {
  return `${Math.round(value * 100)}%`;
}

export default function ConfidenceSelect() {
  const [localSliderValue, setLocalSliderValue] = useState(0.5);

  // Retrieve redux dispatch
  const dispatch = useDispatch();

  // Select from redux store
  const storeSliderValue = useSelector(
    ({ detectedPatterns }) => detectedPatterns.confidence
  );
  const training = useSelector(({ models }) => models.training);
  const detectingPatterns = useSelector(
    ({ detectedPatterns }) => detectedPatterns.fetching
  );

  useEffect(() => {
    setLocalSliderValue(storeSliderValue);
  }, [storeSliderValue]);

  return (
    <Container>
      <div style={{ textAlign: "center" }}>Confidence Filter</div>
      <CustomSlider
        value={localSliderValue}
        onMouseDown={(e) => e.stopPropagation()}
        disabled={training || detectingPatterns}
        min={0.1}
        max={1}
        step={0.01}
        onChange={(e, value) => setLocalSliderValue(value)}
        onChangeCommitted={(e, value) => dispatch(updateConfidence(value))}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        track="inverted"
      />
    </Container>
  );
}
