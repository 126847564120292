import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

export const useFiltersIsEdited = () => {
  const { initialFilters, filters, filterValues } = useSelector(
    ({ heatMap }) => heatMap
  );
  const tickerFields = filterValues.tickerFields;

  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setIsEdited(!isFiltersEqual(initialFilters, filters, tickerFields));
  }, [initialFilters, filters, tickerFields]);

  return isEdited;
};

export const useConfigIsEdited = () => {
  const {
    initialFilters,
    initialDepthConstraint,
    initialDrillDownBy,
    filters,
    drillDownBy,
    depthConstraint,
    filterValues,
  } = useSelector(({ heatMap }) => heatMap);

  const tickerFields = filterValues.tickerFields;

  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    let isEditedToSet = false;

    if (initialDepthConstraint !== depthConstraint) {
      isEditedToSet = true;
    } else if (initialDrillDownBy !== drillDownBy) {
      isEditedToSet = true;
    } else if (!isFiltersEqual(initialFilters, filters, tickerFields)) {
      isEditedToSet = true;
    }

    setIsEdited(isEditedToSet);
  }, [
    initialFilters,
    filters,
    tickerFields,
    initialDepthConstraint,
    depthConstraint,
    initialDrillDownBy,
    drillDownBy,
  ]);

  return {
    isEdited,
    initialFilters,
    initialDepthConstraint,
    initialDrillDownBy,
  };
};

const isFiltersEqual = (initialFilters, filters, tickerFields) => {
  const filtersToCompare = { ...filters, ticker: {} };

  if (!_.isEmpty(filters.ticker)) {
    for (let tickerField of tickerFields) {
      if (filters.ticker[tickerField]?.length) {
        filtersToCompare.ticker[tickerField] = filters.ticker[tickerField];
      }
    }
  }
  return _.isEqual(initialFilters, filtersToCompare);
};
