import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Layer,
  LayersContainer,
  Index,
  Label,
  CentralContainer,
} from "./DrillDownByLayersStyles";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SandwichFilter from "./SandwichFilter/SandwichFilter";
import { updatedDrillDownBy } from "../../../../../redux/slices/heatMap";

export default function DrillDownByLayers({
  openFilter,
  setOpenFilter,
  setTransitioningHeight,
}) {
  const { drillDownBy, filtersToLabelMap } = useSelector(
    ({ heatMap }) => heatMap
  );

  const dispatch = useDispatch();

  // Handler function
  const removeLayer = (layer) => {
    dispatch(
      updatedDrillDownBy(drillDownBy.filter((category) => category !== layer))
    );
    if (openFilter === layer) setOpenFilter(null);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(drillDownBy);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(updatedDrillDownBy(items));
  };

  const onLayerClick = (layer) => {
    setTransitioningHeight(true);
    layer === openFilter ? setOpenFilter(null) : setOpenFilter(layer);
    setTimeout(() => setTransitioningHeight(false), 200);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="drill-down-by">
        {(provided) => (
          <LayersContainer {...provided.droppableProps} ref={provided.innerRef}>
            <Label>Outermost layer</Label>
            {drillDownBy.length ? (
              drillDownBy.map((layer, index) => (
                <Draggable key={layer} draggableId={layer} index={index}>
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <Layer onClick={(e) => onLayerClick(layer)}>
                        <Index>{index + 1}</Index>

                        <CentralContainer>
                          {filtersToLabelMap[layer]}
                          <ArrowDropDownIcon
                            style={{
                              color:
                                layer === openFilter
                                  ? "rgb(38,51,64)"
                                  : "white",
                              cursor: "pointer",
                            }}
                          />
                        </CentralContainer>

                        <ClearIcon
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            removeLayer(layer);
                          }}
                          fontSize="small"
                        />
                      </Layer>
                      <SandwichFilter
                        layer={layer}
                        open={layer === openFilter}
                      />
                    </div>
                  )}
                </Draggable>
              ))
            ) : (
              <Layer noLayers>No Layers Selected</Layer>
            )}
            {provided.placeholder}
            <Label>Innermost layer</Label>
          </LayersContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
}
