import React, { useState, useEffect } from "react";
import LoadingAnimation from "../../../LeftPanel/LoadingAnimation/LoadingAnimation";
import {
  Container,
  InnerContainer,
  BottomContainer,
} from "./ChartsSelectStyles";
import { ListItem } from "../AddOrEditCustomCollectionStyles";
import SeachCharts from "./SearchCharts/SearchCharts";
import IntervalSelect from "../IntervalSelect/IntervalSelect";
import Button from "../../../UI/Button/CustomButtonWhite";
import { filterCharts } from "../../../../utils/utils";

export default function Charts({
  selectedCollection,
  onSelect,
  selectedCharts,
  onSelectAll,
}) {
  // useState hooks
  const [searchValue, setSearchValue] = useState("");

  const [selectedInterval, setSelectedInterval] = useState(null);

  useEffect(() => {
    if (selectedCollection) {
      setSelectedInterval(selectedCollection.intervals[0]);
    }
  }, [selectedCollection]);

  const filteredCharts = filterCharts(
    selectedCollection ? selectedCollection.charts : [],
    selectedInterval,
    searchValue
  );

  return (
    <Container>
      <IntervalSelect
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
        selectedCollection={selectedCollection}
      />
      <SeachCharts searchValue={searchValue} setSearchValue={setSearchValue} />

      <InnerContainer>
        {filteredCharts ? (
          filteredCharts.map((chart, index) => {
            const selected = selectedCharts.reduce(
              (acc, selectedChart) =>
                selectedChart.id === chart.id ? true : acc,
              false
            );
            return (
              <ListItem
                key={index}
                selected={selected}
                onClick={(e) => onSelect(e, selected, chart, filteredCharts)}
              >
                {chart.name}
              </ListItem>
            );
          })
        ) : (
          <LoadingAnimation />
        )}
      </InnerContainer>
      <BottomContainer>
        <Button onClick={() => onSelectAll(filteredCharts)}>
          Select all charts
        </Button>
      </BottomContainer>
    </Container>
  );
}
