import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../../UI/Button/CustomButtonLeftPanelBlue";

import {
  detectPatterns,
  removeAllDPatterns,
} from "../../../../redux/slices/detectedPatterns";
import {
  setModalWarning,
  setIsWarningModalOpen,
} from "../../../../redux/slices/toolbar";
import { setGridDisplay } from "../../../../redux/slices/display";

export default function DetectPatternsButton(props) {
  const dispatch = useDispatch();

  const selectedModel = useSelector(({ toolbar }) => toolbar.selectedModel);
  const detectingPatterns = useSelector(
    ({ detectedPatterns }) => detectedPatterns.fetching
  );

  const handleDetectPatterns = async () => {
    dispatch(removeAllDPatterns());

    const numOfPatterns = await dispatch(
      detectPatterns({
        model: selectedModel,
        defaultConfidence: false,
        maxPatterns: 100,
        detectionProposals: props.patternsTimeRange,
      })
    );
    if (numOfPatterns === 0) {
      dispatch(setModalWarning("No patterns have been detected"));
      dispatch(setIsWarningModalOpen(true));
    } else {
      dispatch(setGridDisplay());
    }
  };

  return (
    <Button
      disabled={!selectedModel || detectingPatterns || props.disabled}
      loading={detectingPatterns}
      onClick={handleDetectPatterns}
    >
      <>
        <SearchIcon />
        <div style={{ marginLeft: 10 }}>Detect Patterns</div>
      </>
    </Button>
  );
}
