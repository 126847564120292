import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "../../../../../UI/Checkbox/Checkbox";

import CustomButton from "../../../../../UI/Button/CustomButton";
import CustomSpinner from "../../../../../UI/Spinner/CustomSpinner";
import {
  createConfig,
  updateConfig,
} from "../../../../../../redux/slices/heatMapConfigs";

function SaveConfigModal({ onClose, configToEdit }) {
  // useState hooks
  const [newConfigName, setNewConfigName] = useState(configToEdit?.name || "");
  const [isPreset, setIsPreset] = useState(configToEdit?.preset || false);
  const [isExecuting, setIsExecuting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isAdmin = useSelector(({ login }) => login.isUserAdmin);

  const dispatch = useDispatch();

  const onSuccess = () => {
    setSuccess(true);
    setErrorMessage("");
    setIsExecuting(false);
  };

  const onError = (errMsg) => {
    setSuccess(false);
    setErrorMessage(errMsg);
    setIsExecuting(false);
  };

  // Handler functions
  const onSubmit = () => {
    setIsExecuting(true);

    if (!configToEdit) {
      dispatch(createConfig(newConfigName, isPreset, onError, onSuccess));
    } else {
      dispatch(
        updateConfig(configToEdit, newConfigName, isPreset, onSuccess, onError)
      );
    }
  };

  const onTextFieldChange = (event) => {
    const value = event.target.value;
    setNewConfigName(value);
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Save configuration</DialogTitle>
      {isExecuting && (
        <Grid container justify="center">
          <CustomSpinner />
        </Grid>
      )}

      {!isExecuting && !success && (
        <Fragment>
          <DialogActions>
            <TextField
              error={!!errorMessage}
              helperText={errorMessage}
              autoFocus
              margin="dense"
              id="config-name"
              label="Configuration name"
              value={newConfigName}
              onChange={onTextFieldChange}
              fullWidth
            />
          </DialogActions>
          <DialogActions>
            {isAdmin && (
              <Tooltip title="Is the config visible to other users">
                <FormControlLabel
                  label="Preset"
                  control={
                    <Checkbox
                      checked={isPreset}
                      onChange={(e) => {
                        setIsPreset(e.target.checked);
                      }}
                    />
                  }
                />
              </Tooltip>
            )}

            <CustomButton
              disabled={!newConfigName || newConfigName.trim().length > 32}
              onClick={onSubmit}
              variant="outlined"
            >
              Confirm
            </CustomButton>
            <CustomButton onClick={onClose} variant="outlined">
              Exit
            </CustomButton>
          </DialogActions>
        </Fragment>
      )}

      {!isExecuting && success && (
        <Fragment>
          <DialogTitle> Configuration saved successfully </DialogTitle>
          <DialogActions>
            <CustomButton onClick={onClose} variant="outlined">
              Close
            </CustomButton>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}

export default SaveConfigModal;
