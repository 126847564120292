import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";

import { onAuthorizeSuccess } from "../../../redux/slices/login";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockIcon from "@material-ui/icons/Lock";
import CustomSpinner from "../../UI/Spinner/CustomSpinner";

import FormErrors from "./FormErrors";
import {
  AuthButton,
  AuthForm,
  AuthInput,
  AuthRedirectText,
  Field,
  InputField,
  StyledLink,
  styleLoginIcon,
  SpinnerContainer,
} from "../authStyle";
import bloombergAuth from "../../../auth/utils/bloombergAuth";

const StyledMailOutlineIcon = styleLoginIcon(MailOutlineIcon);
const StyledLockIcon = styleLoginIcon(LockIcon);

export default function Login({ setHeight, onSignIn }) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);

  useEffect(() => {
    setHeight(540);
  }, [setHeight]);

  const onLoginFail = (message) => {
    setLoggingIn(false);
    setErrors({
      message,
    });
    setHeight(650);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(null);
    // Amplify/Cognito
    try {
      setLoggingIn(true);
      await Auth.signIn(username, password);

      await bloombergAuth(Auth);

      onSignIn(); //set animation
      //wait for animation to finish
      setLoggingIn(false);
      setTimeout(() => {
        dispatch(onAuthorizeSuccess());
      }, 750);
    } catch (error) {
      onLoginFail(
        error.message ||
          "Failed to connect to the server. Try to reload the webpage."
      );
    }
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      {errors && <FormErrors formErrors={errors} />}
      <AuthForm onSubmit={handleSubmit}>
        {loggingIn ? (
          <SpinnerContainer>
            <CustomSpinner />
          </SpinnerContainer>
        ) : (
          <Fragment>
            <InputField>
              <StyledMailOutlineIcon fontSize="large" />
              <AuthInput
                type="text"
                id="username"
                placeholder="Email"
                value={username}
                onChange={onUsernameChange}
                required={true}
              />
            </InputField>
            <InputField>
              <StyledLockIcon fontSize="large" />

              <AuthInput
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={onPasswordChange}
                required={true}
              />
            </InputField>
          </Fragment>
        )}

        <StyledLink to="/forgotPassword">Forgot your password?</StyledLink>
        <AuthButton>Sign in</AuthButton>
        <Field>
          <AuthRedirectText>Need an account?</AuthRedirectText>
          <StyledLink to="/register">Sign up</StyledLink>
        </Field>
      </AuthForm>
    </>
  );
}
