import { useState, useCallback, useRef } from "react";
import { useWindowResize } from "../../GlobalHooks/useWindowResize";

export const useGetDimensions = () => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const containerTop = useRef(0);
  const containerLeft = useRef(0);

  //Get dimensions of the container
  const updateDimensions = useCallback(() => {
    const container = document.getElementById("coldStartContainer");
    setHeight(container.offsetHeight);
    setWidth(container.offsetWidth);
    const { top, left } = container.getBoundingClientRect();
    containerTop.current = top;
    containerLeft.current = left;
  }, []);

  useWindowResize(updateDimensions);
  return {
    height,
    width,
    containerTop: containerTop.current,
    containerLeft: containerLeft.current,
  };
};
