import { useAutoLayoutGenerator } from "./useAutoLayoutGenerator";
import { useFullScreenLayoutGenerator } from "./useFullScreenLayoutGenerator";

export const useLayout = ({ mostRecentLayout }) => {
  //
  // ─── AUTO LAYOUT ────────────────────────────────────────────────────────────────
  //
  const autoLayout = useAutoLayoutGenerator({ mostRecentLayout });
  // ────────────────────────────────────────────────────────────────────────────────

  //
  // ─── FULL SCREEN LAYOUT ─────────────────────────────────────────────────────────
  //
  const [finalLayout, numColumns, numRows] = useFullScreenLayoutGenerator({
    autoLayout,
  });
  // ────────────────────────────────────────────────────────────────────────────────
  return { layout: finalLayout, numColumns, numRows };
};
