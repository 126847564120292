const combinationsReducer = (acc, combination, bars, upperOrLower) => {
  const y = upperOrLower === "upper" ? "high" : "low";

  let sum = 0;

  for (let bar of bars) {
    const relevantLineSegment = combination.find(
      (lineSegment) =>
        lineSegment[0].time <= bar.time && lineSegment[1].time >= bar.time
    );

    if (!relevantLineSegment) return { optimalCombination: null };

    const grad =
      (relevantLineSegment[1][y] - relevantLineSegment[0][y]) /
      (relevantLineSegment[1].time - relevantLineSegment[0].time);

    const lineY =
      grad * (bar.time - relevantLineSegment[0].time) +
      relevantLineSegment[0][y];

    const dist = upperOrLower === "upper" ? lineY - bar[y] : bar[y] - lineY;

    if (dist < 0) {
      return acc;
    }

    sum += dist;
  }

  if (sum < acc.value)
    return {
      optimalCombination: combination,
      value: sum,
    };

  return acc;
};

export default combinationsReducer;
