import React, { useRef, useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Container } from "./HeatMapLeftPanelStyles";
import HeatMapPreviewCharts from "./HeatMapPreviewCharts/HeatMapPreviewCharts";
import HeatMapTabSelect from "./HeatMapTabSelect/HeatMapTabSelect";
import ExpandTab from "./ExpandTab/ExpandTab";

export default function HeatMapLeftPanel() {
  // Select  from redux store
  const { selectedSignals, leftPanelExpanded } = useSelector(
    ({ heatMap }) => heatMap
  );

  // useState hook
  const [tabSelected, setTabSelected] = useState(0);

  // useRef hooks
  const prevSelectedChartsLength = useRef(0);

  // useEffect hooks
  useEffect(() => {
    // Set to selected if new selected chart
    if (prevSelectedChartsLength.current < selectedSignals.length)
      setTabSelected(1);
    prevSelectedChartsLength.current = selectedSignals.length;
  }, [selectedSignals, setTabSelected]);

  return (
    <Container expanded={leftPanelExpanded}>
      {leftPanelExpanded !== -1 && (
        <Fragment>
          <HeatMapTabSelect
            selected={tabSelected}
            setSelected={setTabSelected}
          />

          <HeatMapPreviewCharts
            expanded={leftPanelExpanded === 1}
            favouritesView={tabSelected === 0}
            allView={tabSelected === 2}
          />
        </Fragment>
      )}

      <ExpandTab expanded={leftPanelExpanded} />
    </Container>
  );
}
