import React, { Fragment } from "react";

import Overlay from "./Overlay";
import LockButton from "./Buttons/LockButton";
import FullScreenButton from "./Buttons/FullScreenButton";
import CloseButton from "./Buttons/CloseButton";

import { StyledToolbar, TileToolbarTitle } from "./ToolbarStyles";

export default function Toolbar({ chart, isMinimized, isFullScreen, mode }) {
  return (
    <>
      <Overlay />
      <StyledToolbar>
        <TileToolbarTitle textLeft={isMinimized}>
          {chart.chartName}
        </TileToolbarTitle>
      </StyledToolbar>
      {mode !== "heat_map" && (
        <Fragment>
          {!isMinimized && !isFullScreen && (
            <LockButton chartId={chart.chartId} />
          )}
          {chart.barsReady && (
            <FullScreenButton isFullScreen={isFullScreen} chart={chart} />
          )}
        </Fragment>
      )}

      <CloseButton chartId={chart.chartId} />
    </>
  );
}
