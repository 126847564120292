import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { selectTotalColdStartPatterns } from "../../../redux/slices/coldStart";
import { selectTotalSPatterns } from "../../../redux/slices/selectedPatterns";
import { selectTotalSDPatterns } from "../../../redux/slices/selectedDetectedPatterns";
import { selectTotalDPatterns } from "../../../redux/slices/detectedPatterns";

import { Title } from "./ChartsToDetectOnSelect/ChartsToDetectOnStyles";
import SaveModelButton from "./SaveModelButton/SaveModelButton";
import TrainButton from "./TrainButton/TrainButton";
import ConfidenceSelect from "./ConfidenceSelect/ConfidenceSelect";
import ViewPatternsButton from "./ViewPatternsButton/ViewPatternsButton";
import DrawNewModelButton from "./DrawNewModelButton/DrawNewModelButton";
import DrawnPatternsCount from "./DrawnPatternsCount/DrawnPatternsCount";
import SelectedPatternsCount from "./SelectedPatternsCount/SelectedPatternsCount";
import DetectedPatternsCount from "./DetectedPatternsCount/DetectedPatternsCount";
import Help from "./Help/Help";
import ChartsToDetectOnSelect from "./ChartsToDetectOnSelect/ChartsToDetectOnSelect";
import PatternsTimeRange from "./PatternsTimeRange";

import {
  Row,
  PatternsCountContainer,
  DPatternsCountContainer,
} from "../LeftPanelStyle";

export default function ModelsTrain({ setMode }) {
  const drawnCount = useSelector(selectTotalColdStartPatterns);
  const selectedPatternsNumber = useSelector(selectTotalSPatterns);
  const trainableDetectedPatternsNumber = useSelector(selectTotalSDPatterns);
  const mode = useSelector(({ display }) => display.mode);
  const trained = useSelector(({ models }) => models.trainingModelTrained);
  const modelToRetrain = useSelector(({ models }) => models.retrainModel);
  const totalDPatterns = useSelector(selectTotalDPatterns);

  const selectedCount =
    selectedPatternsNumber + trainableDetectedPatternsNumber;

  const [patternsTimeRange, setPatternsTimeRange] = useState([]);
  const [timeRangeIsValid, setTimeRangeIsValid] = useState(true);

  return (
    <>
      {modelToRetrain && (
        <Row>
          <Title>Model to retrain: {modelToRetrain.name}</Title>
        </Row>
      )}
      <Row>
        <Help mode={mode} />
      </Row>
      {mode === "drawModelForTraining" && (
        <Row>
          <DrawNewModelButton />
        </Row>
      )}
      <PatternsCountContainer>
        {mode === "drawModelForTraining" && !trained ? (
          <DrawnPatternsCount count={drawnCount} />
        ) : (
          <SelectedPatternsCount count={selectedCount} />
        )}
      </PatternsCountContainer>

      <Fragment>
        <ChartsToDetectOnSelect />
        <PatternsTimeRange
          setPatternsTimeRange={setPatternsTimeRange}
          setTimeRangeIsValid={setTimeRangeIsValid}
        />
        <Row>
          <TrainButton
            selectedCount={selectedCount}
            patternsTimeRange={patternsTimeRange}
            patternsTimeRangeIsValid={timeRangeIsValid}
            drawnCount={drawnCount}
            trained={trained}
            disabled={
              !timeRangeIsValid || (selectedCount === 0 && drawnCount === 0)
            }
          />
        </Row>
      </Fragment>

      {trained && totalDPatterns > 0 && (
        <>
          <Row>
            <DPatternsCountContainer>
              <DetectedPatternsCount />
              <ViewPatternsButton />
            </DPatternsCountContainer>
          </Row>
          <ConfidenceSelect />
          <Row>
            <SaveModelButton />
          </Row>
        </>
      )}
    </>
  );
}
