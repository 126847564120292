import store from "../../redux/store";

const setTileColors = (data) => {
  if (data.children) {
    return data.children.forEach((child) => setTileColors(child));
  }

  const selectedSignals = store.getState().heatMap.selectedSignals;
  const selectedChartsIds = selectedSignals.map(({ id }) => id);

  const relevantPatternData = data.oneSignal || data;
  if (selectedChartsIds.includes(relevantPatternData.id)) {
    data.color = selectedSignals.find(
      ({ id }) => id === relevantPatternData.id
    ).color;
  } else {
    delete data.color;
  }
};

export default setTileColors;
