import React from "react";
import { TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearchValue,
} from "../../../../redux/slices/leftPanel";

export default function SearchBar() {
  const dispatch = useDispatch();
  const searchValue = useSelector(({ leftPanel }) => leftPanel.searchValue);

  const onSearch = (e) => {
    dispatch(setSearchValue(e.target.value));
  };
  return (
    <div style={{ marginBottom: 10, paddingLeft: 5 }}>
      <TextField
        label="Search charts"
        fullWidth
        value={searchValue}
        onChange={onSearch}
      />
    </div>
  );
}
