import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`;

export const Container = styled.div`
  margin: 30px 0 20px;
`;

export const FiltersContainer = styled.div`
  margin: 50px 0;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
`;
