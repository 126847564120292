import * as d3 from "d3";
import {uuidv4, color, colorGreen, colorRed} from "./generalRenderHelperFunctions";

let render = require('../render');

// Clear svg and set height and width
export function clearSvg(svg){
  svg.attr("width", render.width).attr("height", render.height);
}
  
// Create shadow
export function addShadowToSvg(svg){
  svg
    .append("filter")
    .attr("id", "shadow")
    .append("feDropShadow")
    .attr("flood-opacity", 0.5)
    .attr("dx", 0)
    .attr("dy", 0)
    .attr("stdDeviation", 2);
}
  
// Map nodes in tree data to svg groups
export function mapNodesToSvgGroups(svg){
  return svg
    .selectAll("g")
    .data(
      d3
        .nest()
        .key((d) => d.height)
        .entries(render.root.descendants())
    )
    .join("g")
    .attr("filter", "url(#shadow)")
    .selectAll("g")
    .data((d) => d.values)
    .join("g");
}

export function createSvgRects(nodes){
  nodes
    .append("rect")
    .attr("id", (d) => {
      return (d.nodeId = uuidv4())
    })
    .attr("fill", (d) => {
      let tileColor;
      if (!d.children) {
        if (d.data.signal_value > 0)
          tileColor = colorGreen(Math.pow(Math.abs(d.data.signal_value), 2));
        else if (d.data.signal_value < 0)
          tileColor = colorRed(Math.pow(Math.abs(d.data.signal_value), 2));
        else tileColor = "black";
        d.data.tileColor = tileColor;
        return tileColor;
      }

      return color(render.treeHeight, render.treeHeight - d.height);
    })
    .attr("width", (d) => (render.x(d.x1 - d.x0) > 0 ? render.x(d.x1 - d.x0) : 0))
    .attr("height", (d) => (render.y(d.y1 - d.y0) > 0 ? render.y(d.y1 - d.y0) : 0))
    .attr("rx", 5)
    .attr("ry", 5)
    .filter((d) => d.data.color)
    .style("stroke", (d) => d.data.color)
    .style("stroke-width", "5px");
}

