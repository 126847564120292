import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(38, 51, 64);
  border-radius: 5px;
  border: 1px solid #222;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  position: relative;
  width: ${({ expanded }) => (expanded ? "350px" : "0")};
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
`;

export const ControlContainer = styled.div`
  border-top: 2px solid #222;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 10px;
  padding-bottom: 0;
`;

export const ApplyFiltersContainer = styled.div`
  align-items: center;
  flex-grow: 1;
  justify-content: center;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;
