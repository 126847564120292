import { useEffect } from "react";

export const useVisibleRangeController = ({ chart, newVisRange }) => {
  //preview chart should display all bars
  useEffect(() => {
    if (!chart.ready || !chart.barsReady) return;
    chart.setDefaultRange();
    chart.drawRangeIndicator();
  }, [chart, newVisRange]);
};
