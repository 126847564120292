import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Api } from "../../../../api/api";

const useGetChartCollection = () => {
  const collections = useSelector(
    ({ chartCollections }) => chartCollections.collections
  );

  let defaultCollection = null;

  if (collections.length > 0) {
    defaultCollection = collections[0];
  }

  const [selectedCollection, setSelectedCollection] =
    useState(defaultCollection);

  const [fullSelectedCollection, setFullSelectedCollection] = useState(null);

  const [isFetchingCollection, setIsFetchingCollection] = useState(false);

  useEffect(() => {
    const loadCollection = async () => {
      setIsFetchingCollection(true);

      let fullCollection = null

      if (selectedCollection) {
        fullCollection = await Api.getChartCollection(selectedCollection.id);
      }

      setFullSelectedCollection(fullCollection);

      setIsFetchingCollection(false)
    }

    loadCollection();
  }, [selectedCollection])
  

  return [fullSelectedCollection, setSelectedCollection, collections, isFetchingCollection];
};

export default useGetChartCollection;
