import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import TileChart from "./TileChart/TileChart";
import { Container, InnerContainer } from "./HeatMapFullChartStyles";
import { setShowHeatMapChart } from "../../../redux/slices/heatMap";
import HeatMapToolbar from "../HeatMapToolbar/HeatMapToolbar";
import Warning from "../../Modals/Warning/Warning";
import useMatchedPattern from "./hooks/useMatchedPattern";
import addUpdatedLineDrawings from "../utils/addUpdateLineDrawings/addUpdateLineDrawings";
import { updateHeatMapChartPattern } from "../../../redux/slices/heatMapChart";
import { setUpdatePattern } from "../../../redux/slices/heatMap";

export default function HeatMapFullChart() {
  // useState
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [isPatternUpdated, setIsPatternUpdated] = useState(false);
  const [numberOfLineSegments, setNumberOfLineSegments] = useState(1);

  // Select from redux store
  const updatePattern = useSelector(({heatMap}) => heatMap.updatePattern);
  const chart = useSelector(({ heatMapChart }) => heatMapChart.data);
  const pattern = useSelector(({ heatMapChart }) => heatMapChart.pattern);
  const populatedSignals = useSelector(
    ({ heatMap }) => heatMap.populatedSignals
  );

  const matchedPattern = useMatchedPattern(pattern, populatedSignals);

  // Redux dispatch function
  const dispatch = useDispatch();

  // useRef hooks
  const prevChart = useRef(null);
  const originalPattern = useRef(null);

  // useEffect hook
  useEffect(() => {
    dispatch(setUpdatePattern(false));
    setIsPatternUpdated(false);
    if (!chart) dispatch(setShowHeatMapChart(false));
  }, [chart, dispatch]);

  useEffect(() => {
    if (!_.isEqual(chart, prevChart.current)) {
      setNumberOfLineSegments(1);
      originalPattern.current = pattern;
      prevChart.current = chart;
    }
  }, [chart, pattern]);

  useEffect(() => {
    const isSamePattern = _.isEqual(
      pattern && pattern.shapes,
      matchedPattern && matchedPattern.shapes
    );
    if (updatePattern && isSamePattern && !isPatternUpdated) {
      setWarningModalOpen(true);
    }
  }, [updatePattern, isPatternUpdated, matchedPattern, pattern]);

  //  Add updated line drawings
  useEffect(() => {
    const updatedPattern = addUpdatedLineDrawings(
      chart,
      pattern,
      numberOfLineSegments
    );
    if (!updatedPattern) {
      dispatch(updateHeatMapChartPattern(originalPattern.current));
    } else if (!_.isEqual(pattern, updatedPattern)) {
      dispatch(updateHeatMapChartPattern(updatedPattern));
    }
  }, [chart, pattern, dispatch, numberOfLineSegments, originalPattern]);

  // Handler function
  const handleCloseWarningModal = () => {
    setWarningModalOpen(false);
    setIsPatternUpdated(true);
  };

  return (
    <Container>
      {warningModalOpen && (
        <Warning
          onClose={handleCloseWarningModal}
          warning={"Pattern remains unchanged"}
        />
      )}
      <HeatMapToolbar
        setIsPatternUpdated={setIsPatternUpdated}
        numberOfLineSegments={numberOfLineSegments}
        setNumberOfLineSegments={setNumberOfLineSegments}
      />
      <InnerContainer>
        <TileChart matchedPattern={matchedPattern} />
      </InnerContainer>
    </Container>
  );
}
