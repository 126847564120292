import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setChartVisibleRange } from "../../../../../redux/slices/previewChart";

export const useClickHandler = ({ chart, newSelection }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!chart.ready || !newSelection) return;
    const targetRange = chart.getPreviewTargetRange();
    dispatch(setChartVisibleRange(targetRange));
  }, [dispatch, chart, newSelection]);
};
