import extendCombinationLines from "./utils/extendCombination/extendCombinationLines";
import combinationsReducer from "./utils/combinationsReducer";

const findOptimalLineSegmentCombination = (
  lineSegmentCombinations,
  filteredBars,
  upperOrLower
) => {
  const extendedCombinations = lineSegmentCombinations
    .map((combination) =>
      extendCombinationLines(combination, filteredBars, upperOrLower)
    )
    .filter((combination) => combination !== "invalid");

  const optimalCombination = extendedCombinations.reduce(
    (acc, combination, index) =>
      combinationsReducer(acc, combination, filteredBars, upperOrLower),

    {
      optimalCombination: null,
      value: Infinity,
    }
  ).optimalCombination;

  return optimalCombination;
};

export default findOptimalLineSegmentCombination;
