import React from "react";
import CustomSlider from "../../../UI/Slider/CustomSlider";
import {
  Container,
  SliderContainer,
  Text,
  NumericValue,
} from "./LineSegmentNumberSliderStyles";

export default function LineSegmentNumberSlider({
  numberOfLineSegments,
  setNumberOfLineSegments,
}) {
  return (
    <Container>
      <Text>Trend line segmentation</Text>
      <SliderContainer>
        <CustomSlider
          value={numberOfLineSegments}
          onChange={(e, newValue) => setNumberOfLineSegments(newValue)}
          aria-labelledby="range-slider"
          getAriaValueText={(value) => value}
          step={1}
          min={1}
          max={4}
          marks
        />
        <NumericValue left={0}>1</NumericValue>
        <NumericValue left={24}>2</NumericValue>
        <NumericValue left={49}>3</NumericValue>
        <NumericValue left={74}>4</NumericValue>
      </SliderContainer>
    </Container>
  );
}
