import { createSlice, createEntityAdapter, nanoid } from "@reduxjs/toolkit";

const savedPatternsAdapter = createEntityAdapter();

const initialShape = [
  {
    x: 100,
    baseline: 100,
  },
];

const initialState = savedPatternsAdapter.getInitialState({
  selected: null,
  currentShape: initialShape,
  error: null,
});

const coldStartSlice = createSlice({
  name: "coldStart",
  initialState,
  reducers: {
    clearColdStartPatterns: (state) => {
      state.selected = null;
      savedPatternsAdapter.setAll(state, []);
    },
    saveCurrentShape: {
      reducer(state, action) {
        const id = state.selected || action.payload.id;
        savedPatternsAdapter.upsertOne(state, {
          id,
          shape: state.currentShape,
        });
      },
      prepare() {
        return {
          payload: {
            id: nanoid(),
          },
        };
      },
    },
    removeColdStartShape: (state, action) => {
      if (action.payload === state.selected) state.selected = null;
      savedPatternsAdapter.removeOne(state, action.payload);
    },
    setSelectedShape: (state, action) => {
      state.selected = action.payload;
      if (action.payload) {
        state.currentShape = state.entities[action.payload].shape;
      }
    },
    setCurrentShape: (state, action) => {
      state.currentShape = action.payload;
    },
    resetShape: (state) => {
      state.currentShape = initialShape;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearDrawingArea: (state) => {
      state.currentShape = initialShape;
    },
  },
});

export const {
  selectAll: selectAllPatterns,
  selectById: selectPatternById,
  selectIds: selectPatternIds,
  selectTotal: selectTotalColdStartPatterns,
} = savedPatternsAdapter.getSelectors((state) => state.coldStart);

export const {
  clearColdStartPatterns,
  saveCurrentShape,
  removeColdStartShape,
  setSelectedShape,
  setCurrentShape,
  resetShape,
  setError,
  clearDrawingArea,
} = coldStartSlice.actions;

export default coldStartSlice.reducer;
