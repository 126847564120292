import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectFilteredDPatterns } from "../../redux/slices/detectedPatterns";

export const useDPatternsByChart = (chartId) => {
  const allDPatterns = useSelector((state) => selectFilteredDPatterns(state));
  return useMemo(
    () => allDPatterns.filter((pattern) => pattern.chartId === chartId),
    [chartId, allDPatterns]
  );
};
