import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChartVisibleRange } from "../../../../../redux/slices/previewChart";

export const useVisibleRangeController = ({
  chart,
  isFullScreen,
  newVisRange,
}) => {
  const dispatch = useDispatch();
  const sync = useSelector(({ settings }) => settings.sync);
  const storeRange = useSelector(({ previewChart }) => previewChart.range);
  const visibleRangeExternallyControlled = useRef(false);

  //
  // ─── RESPOND TO EXTERNAL VISIBLE RANGE CONTROLS ─────────────────────────────────
  //

  useEffect(() => {
    if (!chart.ready || !storeRange) return;

    //when user navigates using preview window, we only want to update the component which is in fullscreen mode
    const respondToPreview = storeRange.source === "preview" && isFullScreen;

    //when user navigates with sync turned on (control all windows at the same time)
    // const respondToSync = storeRange.source === "sync";

    if (respondToPreview) {
      visibleRangeExternallyControlled.current = true; //we set this so that we don't emit the new range
      console.log(storeRange);
      chart.setRange(storeRange);
    }
  }, [storeRange, isFullScreen, chart]);

  // ────────────────────────────────────────────────────────────────────────────────

  //
  // ─── VISIBLE RANGE EMITTER ──────────────────────────────────────────────────────
  //

  // Emit visible range to other components e.g. preview panel
  const prevNewVisRange = useRef(null);
  useEffect(() => {
    if (!chart.ready) return;
    //only emit if the visible range has changed. i.e. ignore when the active chart changes
    if (newVisRange === prevNewVisRange.current) return;
    prevNewVisRange.current = newVisRange;

    if (visibleRangeExternallyControlled.current) {
      //no need to emit the range
      visibleRangeExternallyControlled.current = false;
      return;
    }
    let source;
    //in full screen mode, only the preview chart needs to know the current range
    if (isFullScreen) source = "chart";
    //in sync mode, all other charts need to know the current range
    else if (sync) source = "sync";

    if (source) {
      const currentRange = chart.getRange();
      const payload = { ...currentRange, source };
      dispatch(setChartVisibleRange(payload));
    }
  }, [dispatch, chart, newVisRange, isFullScreen, sync]);
  // ────────────────────────────────────────────────────────────────────────────────
};
