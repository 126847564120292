import React, { useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutError } from "../../../../redux/slices/chartLayout";

import Warning from "../../../Modals/Warning";

export default function LayoutWarning() {
  const dispatch = useDispatch();
  const layoutError = useSelector(({ chartLayout }) => chartLayout.layoutError);

  const onClose = useCallback(() => {
    dispatch(setLayoutError(false));
  }, [dispatch]);

  const warningComponent = (
    <Fragment>
      <p>Maximum charts display limit exceeded.</p>
      <p>Please close a chart before opening a new one.</p>
    </Fragment>
  );

  return (
    <>
      {layoutError && <Warning onClose={onClose} warning={warningComponent} />}
    </>
  );
}
