import React, { Fragment } from "react";
import { EntryLine, PatternBorder } from "../HeatMapPreviewChartStyles";
import { useGetPatternLines } from "../hooks/useGetPatternLines";
import useGetEntryLine from "../hooks/useGetEntryLine";
import { convertShapePoints } from "../../../../../utils/heatmap/signals";

export const Pattern = ({
  signalData,
  filteredBars,
  chartWidth,
  chartHeight,
}) => {
  const patternShapes = signalData.shapes
    .filter((shape) => shape.shape_type === "rectangle")
    .map((pattern) => convertShapePoints(pattern));

  const entryPointShape = convertShapePoints(
    signalData.shapes.find((shape) => shape.shape_type.includes("entry_point"))
  );

  const patternLines = useGetPatternLines(
    filteredBars,
    patternShapes,
    chartWidth
  );

  const entryLine = useGetEntryLine(
    filteredBars,
    entryPointShape,
    chartHeight,
    chartWidth
  );

  return (
    <Fragment>
      {patternLines.map((line, index) => (
        <PatternBorder
          line={line}
        />
      ))}
      {entryLine && (
        <EntryLine
          marginTop={chartHeight * 0.2}
          x={entryLine.centerX}
          y={entryLine.centerY}
        />
      )}
    </Fragment>
  );
};
