import React, { memo } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useDispatch } from "react-redux";
import CustomSpinner from "../../../UI/Spinner/CustomSpinner";

import {
  ChartContainer,
  ChartInfo,
  DeleteContainer,
  Container,
  SpinnerContainer,
} from "./HeatMapPreviewChartStyles";
import { Pattern } from "./Pattern/Pattern";
import { Indicator } from "./Indicator/Indicator";

import { useFetchSignalWithChart } from "./hooks/useFetchSignalWithChart";
import { useGetFilterBars } from "./hooks/useGetFilterBars";
import { useSetupChart } from "./hooks/useSetupChart";
import { useGetChartInfo } from "./hooks/useGetChartInfo";
import LinkButton from "./LinkButton/LinkButton";
import FavouriteStar from "./FavouriteStar/FavouriteStar";
import { removeSignal } from "../../../../redux/slices/heatMap";
import { removeFromFavourites } from "../../../../redux/slices/heatMapFavourites";
import stringToHex from "./utils/stringToHex";

export default memo(function HeatMapChart({
  signal,
  height,
  width,
  selected,
  favouritesView,
  allView,
  modelIdToNameMap,
}) {
  const chartHeight = height - 100;
  const {signalData, chartInfo: chartData} = useFetchSignalWithChart(signal);

  const filteredBars = useGetFilterBars(chartData);

  useSetupChart({ filteredBars, id: signal.id, height: chartHeight, width });

  const chartInfo = useGetChartInfo(signal, filteredBars, modelIdToNameMap);

  const dispatch = useDispatch();

  const onDelete = () => {
    favouritesView
      ? dispatch(removeFromFavourites(signal.id))
      : dispatch(removeSignal(signal));
  };

  return (
    <Container
      height={height}
      width={width}
      color={allView ? stringToHex(signal.id) : signal.color}
      selected={selected}
    >
      <ChartInfo>
        {chartInfo.map((data, index) => (
          <p key={index}>{data}</p>
        ))}
      </ChartInfo>
      <ChartContainer height={chartHeight} width={width}>
        {signalData.signal_type === "indicator" && (
          <Indicator
            signalData={signalData}
            filteredBars={filteredBars}
            chartWidth={width}
            chartHeight={chartHeight}
          />
        )}
        {signalData.signal_type === "pattern" && (
          <Pattern
            signalData={signalData}
            filteredBars={filteredBars}
            chartWidth={width}
            chartHeight={chartHeight}
          />
        )}
        <div id={"heatmap_chart" + signal.id} />
      </ChartContainer>
      {!filteredBars && (
        <SpinnerContainer>
          <CustomSpinner />
        </SpinnerContainer>
      )}
      {!favouritesView && (
        <FavouriteStar chartData={chartData} pattern={signal} />
      )}
      <LinkButton
        signal={signalData}
        chartData={chartData}
        filteredBars={filteredBars}
      />
      <DeleteContainer>
        <DeleteForeverIcon style={{ cursor: "pointer" }} onClick={onDelete} />
      </DeleteContainer>
    </Container>
  );
});
