import React from "react";
import { useSelector } from "react-redux";
import CloseButton from "./Buttons/CloseButton";
import { StyledToolbar, TileToolbarTitle, Spacer } from "./ToolbarStyles";

export default function Toolbar() {
  const chart = useSelector(({ heatMapChart }) => heatMapChart.data);

  return (
    <StyledToolbar>
      <Spacer />
      <TileToolbarTitle>{!!chart && chart.chartData.name}</TileToolbarTitle>

      <CloseButton />
    </StyledToolbar>
  );
}
