import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";

import { StyledCloseButton } from "./ButtonStyles";
import { clearHeatMapChart } from "../../../../../../../redux/slices/heatMapChart";

export default function CloseButton() {
  const dispatch = useDispatch();

  return (
    <StyledCloseButton
      onClick={() => {
        dispatch(clearHeatMapChart());
      }}
      size="small"
    >
      <CloseIcon />
    </StyledCloseButton>
  );
}
