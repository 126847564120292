import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomSpinner from "../UI/Spinner/CustomSpinner";

import HeatMapControl from "./HeatMapControl/HeatMapControl";

import HeatMapFullChart from "./HeatMapFullChart/HeatMapFullChart";
import ApiLoadingProgress from "./HeatMapInterface/ApiLoadingProgress/ApiLoadingProgress";
import HeatMapLeftPanel from "./HeatMapLeftPanel/HeatMapLeftPanel";

import {
  setInFront,
  setZoomInTileData,
  triggerRerender,
} from "../../redux/slices/heatMap";
import applyDepthConstraint from "../../utils/heatmap/drawHeatMap/functions/applyDepthConstraint";
import findSubsetTreeData from "../../utils/heatmap/findSubsetTreeData";
import Container from "./styledComponents/Container";
import NoHeatMapContainer from "./styledComponents/NoHeatMapContainer";
import HeatMapConstraint from "./styledComponents/HeatMapConstraint";
import HeatMapSvg from "./HeatMapSvg";
import { HeatMapContainer } from "./HeatMapInterface/HeatMapInterfaceStyles";
import { useContainerHeightAndWidth } from "../GlobalHooks/useContainerHeightAndWidth";
import HeatMapToolbar from "./HeatMapToolbar/HeatMapToolbar";

import "./HeatMap.css";
import adjustHeatMapRectangles from "../../utils/heatmap/processPopulatedSignals/adjustHeatMapRectangles";

import useDrillDownConstraint from "./hooks/useDrillDownConstraint";

import createTreeData from "../../utils/heatmap/processPopulatedSignals/createTreeData";

export default function HeatMapInterface() {
  // Retrieve redux dispatch function
  const dispatch = useDispatch();

  const {
    drillDownBy,
    depthConstraint,
    zoomInTileData,
    warningMessage,
    inFront,
    showHeatMapChart,
    apiLoadingProgress,
    leftPanelExpanded,
    rightPanelExpanded,
    signals,
    filterValues,
  } = useSelector(({ heatMap }) => heatMap);

  const [treeData, setTreeData] = useState(null);
  const [heatMap1Data, setHeatMap1Data] = useState(null);
  const [heatMap2Data, setHeatMap2Data] = useState(null);

  const tickerFields = filterValues.tickerFields;
  const modelIdToNameMap = filterValues.modelIdToNameMap;

  // Custom hook
  const [height, width] = useContainerHeightAndWidth("#heat-map-constraint");

  useEffect(() => {
    dispatch(triggerRerender(inFront));
  }, [height, width, inFront, dispatch]);

  useEffect(() => {
    const newTreeData = createTreeData(signals, drillDownBy, tickerFields, modelIdToNameMap);

    setTreeData(newTreeData);
    const heatMap1Data = applyDepthConstraint(newTreeData, depthConstraint);
    const adjustedHeatMap1Data = adjustHeatMapRectangles(heatMap1Data);
    setHeatMap1Data(adjustedHeatMap1Data);
    dispatch(setZoomInTileData(null));
    dispatch(setInFront(1));
  }, [tickerFields, signals, drillDownBy, depthConstraint, modelIdToNameMap, dispatch]);

  // Re-render heat map 2 whenever input populated data or zoom in tile data changes
  useEffect(() => {
    const heatMap2Data = findSubsetTreeData(treeData, zoomInTileData);
    const adjustedHeatMap2Data = adjustHeatMapRectangles(heatMap2Data);
    setHeatMap2Data(adjustedHeatMap2Data);
  }, [treeData, zoomInTileData]);

  // Trigger re-render whenever left panel expands or contracts
  useEffect(() => {
    dispatch(triggerRerender(inFront));
  }, [dispatch, inFront, leftPanelExpanded, rightPanelExpanded]);

  // Trim drillDownBy layers according to constraint
  useDrillDownConstraint();

  return (
    <Container>
      {apiLoadingProgress < 100 ? (
        <NoHeatMapContainer>
          <ApiLoadingProgress />
        </NoHeatMapContainer>
      ) : (
        <React.Fragment>
          <HeatMapLeftPanel />
          {leftPanelExpanded !== 1 &&
            (showHeatMapChart ? (
              <HeatMapFullChart treeData={treeData} />
            ) : (
              <Fragment>
                <HeatMapContainer>
                  <HeatMapToolbar label={"Signals Heat Map"} />
                  <HeatMapConstraint id="heat-map-constraint">
                    <HeatMapSvg index={1} data={heatMap1Data} />
                    <HeatMapSvg index={2} data={heatMap2Data} />
                    <NoHeatMapContainer>
                      {warningMessage ? (
                        <React.Fragment>
                          <h1>{warningMessage[0]}</h1>
                          <h3>{warningMessage[1]}</h3>
                        </React.Fragment>
                      ) : (
                        <CustomSpinner />
                      )}
                    </NoHeatMapContainer>
                  </HeatMapConstraint>
                </HeatMapContainer>
                <HeatMapControl expanded={rightPanelExpanded} />
              </Fragment>
            ))}
        </React.Fragment>
      )}
    </Container>
  );
}
