import styled from "styled-components";

const AddLayerMenuItem = styled.div`
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin: 15px 0;
  outline: none;
  text-align: center;
  width: 250px;
`;

export default AddLayerMenuItem;
