import { useEffect } from "react";

export const useDrawingMenu = ({ chart, isFullScreen }) => {
  useEffect(() => {
    if (!chart.ready) return;

    const menuOpen = chart.getDrawingMenuStatus();

    if ((isFullScreen && !menuOpen) || (!isFullScreen && menuOpen)) {
      chart.toggleDrawingMenu();
    }
  }, [isFullScreen, chart]);
};
