import React, { useState } from "react";
import { Dialog, Grow, Button } from "@material-ui/core";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

import { StyledHelp, HelpContainer, HelpRow } from "./HelpStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export default function Instructions() {
  const [open, setOpen] = useState(false);

  return (
    <StyledHelp>
      <Button color="primary" onClick={() => setOpen(true)}>
        <ContactSupportIcon />
      </Button>
      <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={() => setOpen(false)}
        onClick={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <HelpContainer>
          <HelpRow> Left click to draw a new point</HelpRow>
          <HelpRow> Drag a point to edit</HelpRow>
          <HelpRow> Right click a point to delete</HelpRow>
        </HelpContainer>
      </Dialog>
    </StyledHelp>
  );
}
