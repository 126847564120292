import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllCharts } from "../../../redux/slices/chartLayout";

export const useChartList = () => {
  const charts = useSelector(selectAllCharts);
  const fullScreenChart = useSelector(
    ({ chartLayout }) => chartLayout.fullScreenChart
  );
  //Set up charts

  return useMemo(() => {
    const chartList = charts.map((chart) => {
      return {
        key: chart.id,
        isFullScreen: fullScreenChart === chart.id,
        isMinimized: fullScreenChart && fullScreenChart !== chart.id,
      };
    });
    if (fullScreenChart) {
      chartList.push({
        key: "preview",
        fullScreenChart,
      });
    }
    return chartList;
  }, [charts, fullScreenChart]);
};
