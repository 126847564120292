import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Toolbar from "../Toolbar/Toolbar";

import ChartInterface from "../ChartInterface/ChartInterface";
import HeatMapInterface from "../HeatMap/HeatMapInterface";
import { getModels } from "../../redux/slices/models";
import { loadSavedCharts } from "../../redux/slices/chartData";
import { getIndicatorList } from "../../redux/slices/indicators";
import { StyledMainPage, MainPageSubContainer } from "./MainPageStyle";
import HomeInterface from "../Home/HomeInterface/HomeInterface";
import SettingsInterface from "../Settings/SettingsInterface/SettingsInterface";

function MainChartPage() {
  const dispatch = useDispatch();
  const display = useSelector(({ display }) => display.display);

  //initial load
  useEffect(() => {
    dispatch(getModels({ keepSelectedModel: false }));
    dispatch(loadSavedCharts());
    dispatch(getIndicatorList());
  }, [dispatch]);

  return (
    <StyledMainPage>
      <Toolbar />
      <MainPageSubContainer>
        <ChartInterface
          show={
            display === "chart" || display === "coldStart" || display === "grid"
          }
        />
        {display === "heatMap" && <HeatMapInterface />}
        {display === "home" && <HomeInterface />}
        {display === "settings" && <SettingsInterface />}
      </MainPageSubContainer>
    </StyledMainPage>
  );
}

export { MainChartPage as default };
