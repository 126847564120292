import React from "react";

import TileChart from "./Components/TileChart/TileChart";
import Preview from "./Components/Preview/Preview";
import LayoutWarning from "./Components/LayoutWarning/LayoutWarning";
import NoChartsMessage from "./Components/NoChartsMessage/NoChartsMessage";

import { StyledMainChartContainer } from "./MainChartContainerStyles";
import { useChartList } from "./hooks/useChartList";
import LayoutManager from "./Components/LayoutManager/LayoutManager";
import ChartInterfaceToolbar from "../ChartInterface/ChartInterfaceToolbar/ChartInterfaceToolbar";

export default function MainChartContainer({ show }) {
  const chartList = useChartList();

  return (
    <StyledMainChartContainer show={show} id="main-chart-container">
      <ChartInterfaceToolbar />
      <LayoutManager show={show} containerId="main-chart-container">
        {chartList.map((chart) => {
          if (chart.key === "preview") {
            return (
              <div key={chart.key}>
                <Preview
                  fullScreenChart={chart.fullScreenChart}
                  containerId={"preview_container"}
                />
              </div>
            );
          } else {
            return (
              <div key={chart.key}>
                <TileChart
                  chartId={chart.key}
                  isFullScreen={chart.isFullScreen}
                  isMinimized={chart.isMinimized}
                  prefix={"tile_chart"}
                />
              </div>
            );
          }
        })}
      </LayoutManager>
      {!chartList.length && <NoChartsMessage />}
      <LayoutWarning />
    </StyledMainChartContainer>
  );
}
