function colorDistance(rgbArray1, rgbArray2) {
  const [r1, g1, b1] = rgbArray1;
  const [r2, g2, b2] = rgbArray2;

  // distance to color
  const diffR = r1 - r2;
  const diffG = g1 - g2;
  const diffB = b1 - b2;

  return Math.sqrt(diffR * diffR + diffG * diffG + diffB * diffB);
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

const isColorsTooSimilar = (rgb, hex) => {
  const rgbArray1 = rgb
    .substring(4, rgb.length - 1)
    .replace(/ /g, "")
    .split(",");
  const rgbArray2 = hexToRgb(hex);

  return colorDistance(rgbArray1, rgbArray2) < 100;
};

export default isColorsTooSimilar;
