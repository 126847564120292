import slayer from "slayer";

/**
 * The function getSpikes takes a list of bars, and returns a list of nodes with x,y in [0,100]
 * The algorithm will use local minima / local maxima detection to return a "smoother", simplified version of the curve,
 * with only a few data points instead of the long list of bars.
 * @param bars A list of objects with the following numbers: {time, open, close, high, low, volume}
 * @param numberOfDots The maximum amount of dots that should be returned. Too high might lead to overfitting.
 * @returns {Promise<*>} A promise of a list of {x: number, y: number} objects
 */
const getNodalRepresentation = async (bars, numberOfDots) => {
  // This boolean, if true, just makes the function return the bars as-is, in the node view
  const RETURN_AS_IS = false;

  // This boolean makes the function use closing prices instead of high/low prices. High/low seems to work better.
  const USE_CLOSE = false;

  const { n, minY, maxY } = findSummaryStatistics(bars, USE_CLOSE);

  let mergedArray;
  let finalArrayLength = Infinity;
  let minPeakDistance = 0;

  if (RETURN_AS_IS) {
    const finalArray = bars.map((a, i) => ({ x: i, y: a.close })); // Change to a list of x,y objects
    // Make sure all the numbers are between 0,100 and return that
    return normaliseToRange(finalArray, n, minY, maxY);
  }

  // Run the algorithm. If it returns too many nodes, run it again with a lower minimum peak distance
  // Not the most efficient solution, I will try to find something else
  while (finalArrayLength > numberOfDots) {
    minPeakDistance += 2;
    const slayerConfig = {
      minPeakDistance: minPeakDistance,
      minPeakHeight: -100,
    };

    // The local maxima are determined by running the "slayer" library, which has a peak detection algorithm,
    // on the bars. If useClose is true, we use the closing prices, else we use high/low.

    // Why do we use "maxY - item.close" (or item.low) for detecting minima?
    // To detect local minima, we run local maxima detection on an inverted version of the bars.
    // Because slayer does not work on negative numbers, we add the maximum price in the list to all of them,
    // so that there are no numbers below 0 when running on the inverted bars.

    const maxima = await slayer(slayerConfig)
      .y((item) => (USE_CLOSE ? item.close : item.high))
      .fromArray(bars);

    const minima = (
      await slayer(slayerConfig)
        .y((item) => maxY - (USE_CLOSE ? item.close : item.low))
        .fromArray(bars)
    )
      // To get the original prices back, subtract maxY from the minima, and invert them again.
      .map((a) => ({ x: a.x, y: -(a.y - maxY) }));

    // Concatenate the minima and maxima arrays.
    mergedArray = minima.concat(maxima);
    finalArrayLength = mergedArray.length;
  }

  // Sort the array by increasing x index so that the minima and maxima are ordered correctly.
  mergedArray.sort((a, b) => a.x - b.x);

  // Map the x,y coordinates to the [0,100] range.
  const normalisedArray = normaliseToRange(mergedArray, n, minY, maxY);

  return normalisedArray;
};

function findSummaryStatistics(bars, USE_CLOSE) {
  const lowPrices = bars.map((a) => (USE_CLOSE ? a.close : a.low));
  const highPrices = bars.map((a) => (USE_CLOSE ? a.close : a.high));

  const n = bars.length;
  const minY = Math.min(...lowPrices);
  const maxY = Math.max(...highPrices);

  return {
    n,
    minY,
    maxY,
  };
}

function normaliseToRange(arrayOfBars, n, minY, maxY) {
  return arrayOfBars.map((a) => ({
    x: (a.x / n) * 100,
    y: ((a.y - minY) / (maxY - minY)) * 100,
  }));
}

export default getNodalRepresentation;
