const selectSingleChart = (
  selected,
  chart,
  selectedCharts,
  setSelectedCharts,
  selectedCollection
) => {
  if (selected) {
    // If selected, remove from selectedCharts array
    const index = selectedCharts.findIndex(
      (selectedChart) => selectedChart.id === chart.id
    );
    if (index > -1) {
      setSelectedCharts(
        selectedCharts
          .slice(0, index)
          .concat(selectedCharts.slice(index + 1, selectedCharts.length))
      );
    }
  } else {
    // Else, add to selectedCharts array
    setSelectedCharts([
      ...selectedCharts,
      { ...chart, chart_collection_id: selectedCollection.id },
    ]);
  }
};

export default selectSingleChart;
