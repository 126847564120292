import { Auth } from "aws-amplify";
import { Utils } from "../utils/utils";
import Binance from "binance-api-node";
const client = Binance();

// const BASE = "https://syxcae0rpi.execute-api.eu-west-1.amazonaws.com/dev";
// const BASE = "https://r8l7gw70c2.execute-api.eu-west-1.amazonaws.com/qa";

const BASE = "https://v4jp0z17dg.execute-api.eu-west-1.amazonaws.com/qa";
const HEAT_MAP_BASE =
  "https://gw0toortuj.execute-api.eu-west-1.amazonaws.com/production";
const BLOOMBERG_AUTH =
  "https://ubr0z3zifj.execute-api.eu-west-1.amazonaws.com/dev";

export class Api {
  static fetchGetRequestBloombergAuth(
    email,
    ip,
    bloomberg_uuid,
    bloomberg_auth_id
  ) {
    console.log(`Detected IP: ${ip}`);

    return fetch(BLOOMBERG_AUTH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ email, ip, bloomberg_uuid, bloomberg_auth_id }),
    });
  }
  // cognito:username
  static fetchGetRequestWithToken(path, URL = BASE) {
    return Auth.currentSession()
      .then((res) => res.getAccessToken())
      .then((token) => {
        return fetch(URL + path, {
          headers: {
            Authorization: "Bearer " + token.getJwtToken(),
            "user-id": token?.payload?.username,
          },
        });
      })
      .then((res) => {
        if (res.status >= 500) {
          return Promise.reject("Internal server error");
        }

        if (res.status >= 400) {
          return res.json().then((json) => {
            return Promise.reject(
              json.message || json.detail || "Request error"
            );
          });
        }

        return res.json();
      })
      .catch((err) => {
        let err_message = err;

        if (err.message && err.message.toLowerCase().includes("failed to fetch")) {
          err_message = "Internal server error"
        }

        else if (typeof err_message === "object") {
          err_message = JSON.stringify(err_message);
        }

        throw new Error(err_message);
      });
  }

  static fetchPostRequestWithToken(path, data, URL = BASE) {
    return Auth.currentSession()
      .then((res) => res.getAccessToken())
      .then((token) => {
        return fetch(URL + path, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.getJwtToken(),
            Accept: "application/json",
            "Content-Type": "application/json",
            "user-id": token?.payload?.username,
          },
          body: JSON.stringify(data),
        });
      })
      .then((res) => {
        if (res.status >= 500) {
          return Promise.reject("Internal server error");
        }

        if (res.status >= 400) {
          return res.json().then((json) => {
            return Promise.reject(
              json.message || json.detail || "Request error"
            );
          });
        }

        return res.json();
      })
      .catch((err) => {
        let err_message = err;

        if (typeof err_message === "object") {
          err_message = JSON.stringify(err_message);
        }

        throw new Error(err_message);
      });
  }

  static fetchFileUploadWithToken(path, data) {
    return Auth.currentSession()
      .then((res) => res.getAccessToken().getJwtToken())
      .then((token) =>
        fetch(BASE + path, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: data,
        })
      )
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }

  static fetchPutRequestWithToken(path, data, URL = BASE) {
    return Auth.currentSession()
      .then((res) => res.getAccessToken())
      .then((token) =>
        fetch(URL + path, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token.getJwtToken(),
            Accept: "application/json",
            "Content-Type": "application/json",
            "user-id": token?.payload?.username,
          },

          body: JSON.stringify(data),
        })
      )
      .then((res) => {
        if (res.status >= 500) {
          return Promise.reject("Internal server error");
        }

        if (res.status >= 400) {
          return res
            .json()
            .then((json) =>
              Promise.reject(json.message || json.detail || "Request error")
            );
        }
      })
      .catch((err) => {
        let err_message = err;

        if (typeof err_message === "object") {
          err_message = JSON.stringify(err_message);
        }

        throw new Error(err_message);
      });
  }

  static fetchPatchRequestWithToken(path, data) {
    return Auth.currentSession()
      .then((res) => res.getAccessToken().getJwtToken())

      .then((token) => {
        return fetch(BASE + path, {
          method: "PATCH",
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",

            _method: "PATCH",
          },
          body: JSON.stringify(data),
        });
      })
      .then((res) => {
        if (res.status >= 500) {
          return Promise.reject("Internal server error");
        }

        if (res.status >= 400) {
          return res
            .json()
            .then((json) => Promise.reject(json.message || "Request error"));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  static fetchDeleteRequestWithToken(path, url = BASE) {
    return Auth.currentSession()
      .then((res) => res.getAccessToken())
      .then((token) =>
        fetch(url + path, {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token.getJwtToken(),
            "user-id": token?.payload?.username,
          },
        })
      )
      .then((res) => {
        if (res.status >= 500) {
          return Promise.reject("Internal server error");
        }

        if (res.status >= 400) {
          return res
            .json()
            .then((json) => Promise.reject(json.message || json.detail || "Request error"));
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  static getCharts() {
    let path = "/financial/v1/charts";

    return this.fetchGetRequestWithToken(path);
  }

  static logout() {
    return Auth.signOut();
  }

  static getChart(id, { resampleSize, startDatetime, endDatetime }, attempt = 1) {
    const queryParams = new URLSearchParams();

    if (resampleSize) {
      queryParams.append("resample_size", resampleSize);
    }

    if (startDatetime) {
      queryParams.append("start_datetime", startDatetime);
    }

    if (endDatetime) {
      queryParams.append("end_datetime", endDatetime);
    }

    const path = "/financial/v1/charts/" + id + "?" + queryParams.toString();

    return this.fetchGetRequestWithToken(path)
      .then((data) => {
        const chartData = {
          id: data.id,
          name: data.name,
          interval: data.interval,
        };
        const bars = data.bars.map((bar) => ({
          time: Utils.convertDatetimeToTimestamp(bar.datetime),
          open: bar.open,
          high: bar.high,
          low: bar.low,
          close: bar.close,
          volume: bar.volume,
        }));
        return { chartData, bars };
      })
      .catch((e) => {
        if (attempt > 3) {
          console.log(e);
          throw e;
        }

        const daysBack = attempt === 1 ? 365 : 182 ;

        const startDatetime = Utils.getTimeInPast(daysBack);

        return this.getChart(id, {startDatetime}, attempt + 1);
      });
  }
  static getBinanceChart(id, interval) {
    const [symbol, intervalString] = id.split("_");
    return client
      .candles({
        symbol,
        interval: intervalString,
        limit: 1000,
      })
      .then((data) => {
        const chartData = {
          id: id,
          name: symbol,
          interval,
          intervalString,
          type: "Binance",
        };
        const bars = data.map((bar) => ({
          time: bar.openTime,
          open: +bar.open,
          high: +bar.high,
          low: +bar.low,
          close: +bar.close,
          volume: +bar.volume,
        }));
        return { chartData, bars };
      });
  }

  static getModels() {
    let path = "/financial/v1/models";

    return this.fetchGetRequestWithToken(path);
  }

  static createModel(data) {
    let path = "/financial/v1/models";

    return this.fetchPostRequestWithToken(path, data);
  }

  static createColdModel(data) {
    let path = "/financial/v1/models/cold-training";

    return this.fetchPostRequestWithToken(path, data);
  }

  static trainModel(id, data) {
    let path = "/financial/v1/models/" + id;

    return this.fetchPutRequestWithToken(path, { data: data });
  }

  static coldTrainModel(id, data) {
    let path = "/financial/v1/models/" + id + "/cold-training";

    return this.fetchPutRequestWithToken(path, data);
  }

  static editModel(id, data) {
    let path = "/financial/v1/models/" + id;

    return this.fetchPatchRequestWithToken(path, data);
  }

  static removeModel(id) {
    let path = "/financial/v1/models/" + id;

    return this.fetchDeleteRequestWithToken(path);
  }

  static replaceModel(id, data) {
    const path = "/financial/v1/models/" + id + "/replace";

    return this.fetchPutRequestWithToken(path, data);
  }

  static detectPatternsWithoutData({
    modelId,
    chartId,
    use_model_confidence_threshold,
    max_number,
    proposal_time_range,
  }) {
    let path = `/financial/v1/detection/models/${modelId}/charts/${chartId}?use_model_confidence_threshold=${use_model_confidence_threshold}&max_number=${max_number}`;

    if (proposal_time_range && proposal_time_range.length !== 0) {
      path =
        path +
        `&proposal_time_range=${proposal_time_range
          .map((el) => el.toTimeString().split(" ")[0])
          .join(",")}`;
    }

    return this.fetchGetRequestWithToken(path);
  }
  static detectPatternsWithData(payload) {
    let path = `/financial/v1/detection/models/${payload.modelId}`;
    return this.fetchPostRequestWithToken(path, payload);
  }

  static getModel(id) {
    let path = "/financial/v1/models/" + id;

    return this.fetchGetRequestWithToken(path);
  }

  static getChartCollections() {
    let path = "/financial/v1/chart-collections";

    return this.fetchGetRequestWithToken(path);
  }

  static createChartCollection(data) {
    let path = "/financial/v1/chart-collections/create";

    return this.fetchPostRequestWithToken(path, data);
  }

  static editChartCollection(id, data) {
    let path = `/financial/v1/chart-collections/${id}`;

    return this.fetchPatchRequestWithToken(path, data);
  }

  static deleleteChartCollection(id) {
    let path = `/financial/v1/chart-collections/${id}`;

    return this.fetchDeleteRequestWithToken(path);
  }

  static getChartCollection(id) {
    let path = "/financial/v1/chart-collections/" + id;

    return this.fetchGetRequestWithToken(path);
  }

  static detectPatternsOnCollection(modelId, collectionId) {
    let path =
      "/financial/v1/detection/models/" +
      modelId +
      "/chart-collections/" +
      collectionId;

    return this.fetchGetRequestWithToken(path);
  }

  static uploadNewChart(data) {
    let path = "/financial/v1/charts/upload-csv";

    return this.fetchFileUploadWithToken(path, data);
  }

  static deleteChart(id) {
    let path = "/financial/v1/charts/" + id;

    return this.fetchDeleteRequestWithToken(path);
  }

  static viewChartDetails(id) {
    let path = "/financial/v1/charts/" + id;

    return this.fetchGetRequestWithToken(path);
  }

  static viewChartCollectionDetails(id) {
    let path = `/financial/v1/chart-collections/${id}`;
    return this.fetchGetRequestWithToken(path);
  }

  static getIndicatorList() {
    const path = "/financial/v1/indicators";

    return this.fetchGetRequestWithToken(path);
  }

  static getIndicators({ indicators, chartId }) {
    const path = `/financial/v1/indicators/${indicators.join(
      ","
    )}/charts/${chartId}`;
    return this.fetchGetRequestWithToken(path);
  }

  static getSignal(signalId) {
    const path = `/signals/${signalId}`;

    return this.fetchGetRequestWithToken(path, HEAT_MAP_BASE);
  }

  static getSignals(signalsFilters) {
    const filterToQueryParamMap = {
      signalType: "signal_type",
      status: "status",
      entryDirection: "entry_direction",
      modelIds: "model_ids",
      intervals: "intervals",
      ticker: "ticker",
      minSignalValue: "min_signal_value",
      maxSignalValue: "max_signal_value",
      firstBar: "first_bar",
      lastBar: "last_bar",
      signalsOnChartCount: "signals_on_chart_count",
      showMissingSignals: "show_missing_signals",
    };

    const queryParams = new URLSearchParams();

    for (const [filterName, queryParamName] of Object.entries(
      filterToQueryParamMap
    )) {
      const filterValue = signalsFilters[filterName];

      if (filterValue) {
        if (Array.isArray(filterValue)) {
          for (let v of filterValue) {
            queryParams.append(queryParamName, v);
          }
        } else if (typeof filterValue === "object") {
          if (Object.keys(filterValue).length > 0) {
            queryParams.append(queryParamName, JSON.stringify(filterValue));
          }
        } else {
          queryParams.append(queryParamName, filterValue);
        }
      }
    }

    const path = "/signals?" + queryParams.toString();

    return this.fetchGetRequestWithToken(path, HEAT_MAP_BASE);
  }

  static getHeatMapPatterns(interval, modelId) {
    const path = `/signals/patterns/intervals/${interval}/models/${modelId}`;

    return this.fetchGetRequestWithToken(path, HEAT_MAP_BASE);
  }

  static getHeatMapIndicators(interval) {
    const path = `/signals/indicators/intervals/${interval}`;

    return this.fetchGetRequestWithToken(path, HEAT_MAP_BASE);
  }

  static getHeatMapPresets() {
    const path = "/presets?page_size=50";

    return this.fetchGetRequestWithToken(path, HEAT_MAP_BASE);
  }

  static getHeatMapPreset(preset_id) {
    const path = `/presets/${preset_id}`;

    return this.fetchGetRequestWithToken(path, HEAT_MAP_BASE);
  }

  static createHeatmapPreset(presetData) {
    const path = "/presets";

    return this.fetchPostRequestWithToken(path, presetData, HEAT_MAP_BASE);
  }

  static updateHeatMapPreset(presetId, presetData) {
    const path = `/presets/${presetId}`;

    return this.fetchPutRequestWithToken(path, presetData, HEAT_MAP_BASE);
  }

  static deleteHeatMapPreset(presetId) {
    const path = `/presets/${presetId}`;

    return this.fetchDeleteRequestWithToken(path, HEAT_MAP_BASE);
  }

  static getHeatMapModels() {
    const path = "/financial/v1/models?deployed=true";

    return this.fetchGetRequestWithToken(path);
  }

  static getTickers() {
    return this.fetchGetRequestWithToken("/tickers", HEAT_MAP_BASE);
  }

  static deployModel(modelId) {
    const path = "/financial/v1/models/" + modelId;

    return this.fetchPatchRequestWithToken(path, { deployed: true });
  }

  static undeployModel(modelId) {
    const path = "/financial/v1/models/" + modelId;

    return this.fetchPatchRequestWithToken(path, { deployed: false });
  }
}
