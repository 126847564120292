import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllCharts,
  setLayoutError,
} from "../../../redux/slices/chartLayout";

import { layoutHelper } from "./AutoLayoutFunctions/layoutHelper";

export const useAutoLayoutGenerator = ({ mostRecentLayout }) => {
  const [autoLayout, setAutoLayout] = useState([]);
  const dispatch = useDispatch();

  const chartList = useSelector(selectAllCharts);

  const chartUpdate = useSelector(
    ({ chartLayout }) => chartLayout.updateRequired
  );
  useEffect(() => {
    if (!chartUpdate) return;
    if (!chartList.length) {
      setAutoLayout([]);
      return;
    }

    const { newLayout, error } = layoutHelper(
      chartList,
      mostRecentLayout.current
    );
    setAutoLayout(newLayout);
    if (error) dispatch(setLayoutError(true));
  }, [dispatch, chartList, chartUpdate, mostRecentLayout]);
  return autoLayout;
};
