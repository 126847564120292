import styled from "styled-components";

export const HelpContainer = styled.div`
  font-size: 1rem;
  height: 75vh;
  width: 75vw;
  max-width: 1300px;
`;
export const HelpTitle = styled.div`
  padding: 10px 15px;
  margin: 10px 10px 25px 10px;
  background-color: #68ae1e;
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  box-sizing: border-box;
`;
export const HelpRow = styled.div`
  padding: 5px 50px;
  margin: 5px;
  background-color: #1476b7;
  border-radius: 5px;
  text-align: start;
  width: 95%;
  box-sizing: border-box;
`;
export const PageContainer = styled.div`
  height: calc(75vh - 50px);
  width: calc(75vw - 25px);
  max-width: 1275px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ImageContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HelpImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
`;
