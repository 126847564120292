import React from "react";
import { TextField } from "@material-ui/core";
import { Container } from "./SaveCustomCollectionStyles";


export default function SaveCustomCollection({ name, setName }) {
  return (
    <Container>
      <TextField
        label="Custom collection name"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Container>
  );
}
