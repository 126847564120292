import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  align-self: flex-start;
  font-size: 12px;
`;

export const SelectedChartsContainer = styled.div`
  height: 400px;
  overflow-y: scroll;
`;
