export default {
  primary: "rgb(255,255,255)",
  primaryBackground: "rgb(19, 23, 34)",
  containerBackground1: "rgb(38,51,64)",
  containerBackground2: "rgb(52, 61, 70)",
  containerBackground3: "rgb(123,127,131)",
  containerBackgroundTranslucent: "rgba(179, 178, 178, 0.233)",
  selected: "rgb(104,174,30)",
  buttonHover: "rgb(86,91,98)",
  coldStartPatternColor: "rgb(91, 185, 248)",
  error: "#FF6060",
};
