import React from "react";
import * as dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";

const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const filterCharts = (charts, interval, chartName) => {
    const lowerSearchValue = chartName.toLowerCase();

     return charts.filter((chart) => {
      if (lowerSearchValue && interval) {
        return (
          chart.name.toLowerCase().includes(lowerSearchValue) &&
          chart.interval === interval
        );
      }

      if (interval) {
        return chart.interval === interval;
      }

      if (lowerSearchValue) {
        return chart.name.toLowerCase().includes(lowerSearchValue);
      }

      return true;
    });
}

export const minutesToStrRepr = (minutes) => {
  if ((minutes % 1440) === 0) {
    return `${minutes / 1440}D`;
  }
  
  if ((minutes % 60) === 0) {
  	return `${minutes / 60}H`;
  }
  
  return `${minutes}M`;
}

export class Utils {
  static getTimeInPast(daysBack) {
    const utcNow = dayjs.utc();
    const timeInPast = utcNow.subtract(daysBack, "day");
    return timeInPast.format(DATETIME_FORMAT);
  }

  static formatTimeInterval(time) {
    if (!time) return null;

    let duration = dayjs.duration(time);
    let milliseconds = duration.milliseconds();
    let seconds = duration.seconds();
    let minutes = duration.minutes();
    let hours = duration.hours();

    let label = hours > 0 ? hours + "h " : "";
    label += minutes > 0 ? minutes + "m " : hours > 0 ? "0m " : "";
    label +=
      seconds > 0 || milliseconds > 0 ? `${seconds}.${milliseconds}s` : "0s";

    return label;
  }

  static convertDatetimeToTimestamp(datetime) {
    return dayjs.utc(datetime, DATETIME_FORMAT).valueOf();
  }

  static convertTimestampToDatetime(timestamp) {
    return dayjs.utc(timestamp).format(DATETIME_FORMAT);
  }

  static getResolutionForInterval(interval) {
    let duration = dayjs.duration(interval * 60000);
    let days = duration.asDays();
    let minutes = duration.asMinutes();
    return days > 1 ? Math.floor(days) + "D" : minutes + "";
  }
  static convertOptions(options) {
    if (!options) return [];

    return _.map(options, (option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }
  static formatHeatmapInterval(interval) {
    return dayjs.duration(interval * 60 * 1000).humanize();
  }
  static sortAlphabetical(array) {
    const newArray = array.slice();
    newArray.sort((a, b) => {
      let textA = a.name.toUpperCase();
      let textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return newArray;
  }
  static sortMarketCap(array) {
    const newArray = array.slice();
    newArray.sort((a, b) => {
      let A = a.marketCap ? a.marketCap : 0;
      let B = b.marketCap ? b.marketCap : 0;
      return B - A;
    });
    return newArray;
  }

  static formatCurrency(number) {
    let array = number.toString().split("");
    for (let i = array.length - 3; i > 0; i -= 3) {
      array.splice(i, 0, ",");
    }
    return "$" + array.join("");
  }

  static restrictList = ({ list, max }) => {
    return list.length > max ? list.slice(0, max) : list;
  };

  static maxMin = (a, b) => {
    if (a > b) return [a, b];
    return [b, a];
  };
}
