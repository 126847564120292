import React from "react";

import { useDragger } from "./useDragger";
import { StyledDragger } from "./DraggerStyles";

export default function Dragger() {
  const handleMouseDown = useDragger();

  return <StyledDragger onMouseDown={(e) => handleMouseDown(e)} />;
}
