import { useSelector, useDispatch } from "react-redux";
import { updateChartCollectionToDetectOn } from "../../../../../redux/slices/detectedPatterns";

const useChartCollectionsToDetect = () => {
  const dispatch = useDispatch();

  const customChartCollections = useSelector(
    ({ chartCollections }) => chartCollections.customCollections
  );

  const chartCollectionToDetectOn = useSelector(
    ({ detectedPatterns }) => detectedPatterns.chartCollectionToDetect
  );

  if (!chartCollectionToDetectOn && customChartCollections.length > 0) {
    dispatch(updateChartCollectionToDetectOn(customChartCollections[0]));
  }

  const onChange = (event) => {
    dispatch(updateChartCollectionToDetectOn(event.target.value));
  };

  const selectedChartCollection = customChartCollections.find((c) => c.id === chartCollectionToDetectOn?.id)

  return [customChartCollections, selectedChartCollection, onChange]

};

export default useChartCollectionsToDetect;
