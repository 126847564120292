import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import TabSelect from "./TabSelect/TabSelect";
import ModelsTab from "./ModelsTab/ModelsTab";
import ChartsTab from "./ChartsTab/ChartsTab";
import IndicatorsTab from "./IndicatorsTab/IndicatorsTab";

import { StyledLeftPanel } from "./LeftPanelStyle";
import { getChartCollections } from "../../redux/slices/chartCollections";

export default function LeftPanel() {
  const [selectedTab, setSelectedTab] = useState(0);

  // Select from redux store
  const mode = useSelector(({ display }) => display.mode);
  const detectingPatterns = mode === "detectPatterns";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getChartCollections(true));
  }, [dispatch]);

  useEffect(() => setSelectedTab(0), [mode]);

  return (
    <StyledLeftPanel>
      <TabSelect
        selected={selectedTab}
        setSelected={setSelectedTab}
        detectingPatterns={detectingPatterns}
      />
      <ModelsTab selected={selectedTab === 0} />
      <ChartsTab selected={selectedTab === 1} />
      <IndicatorsTab selected={selectedTab === 2} />
    </StyledLeftPanel>
  );
}
