import styled from "styled-components";

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 500px;
  padding: 0 2px;
  width: 800px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #131622;
  border: solid #333 1px;
  border-radius: 10px;
  flex: 1;
  margin: 4px;
  padding: 10px 15px;
`;

export const Title = styled.p`
  align-self: flex-start;
  font-size: 12px;
`;

export const ListItem = styled.div`
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 2px;
  padding: 4px 10px;
  text-transform: capitalize;
  background-color: ${(props) =>
    props.selected ? props.theme.selected : props.theme.containerBackground2};
  &:hover {
    background-color: ${(props) =>
      props.selected ? props.theme.selected : props.theme.buttonHover};
  }
`;
