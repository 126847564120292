import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import render from "../../utils/heatmap/drawHeatMap/functions/render";
import setTileColors from "../../utils/heatmap/setTileColors";
import { setWarningMessage } from "../../redux/slices/heatMap";
import { setZoomOut } from "../../redux/slices/heatMap";

const Svg = styled.svg`
  font: 12px sans-serif;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: ${({ inFront }) => (inFront ? "2" : "1")};
`;

export default function HeatMapSvg({ index, data }) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Select variables from redux store
  const inFront = useSelector(({ heatMap }) => heatMap.inFront);
  const triggerRerender = useSelector(
    ({ heatMap }) => heatMap.triggerRerender[`heatMap${index}`]
  );

  // useEffect hooks
  useEffect(() => {
    if (data && data.children && data.children.length) {
      dispatch(setWarningMessage(null));
      const dataCopy = { ...data };
      setTileColors(dataCopy);
      const { zoomOut } = render(dataCopy, index);
      dispatch(setZoomOut(() => zoomOut(dataCopy)));
      document
        .querySelector(`#heat-map-${index}`)
        .classList.remove("transparent");
    } else {
      document.querySelector(`#heat-map-${index}`).classList.add("transparent");
      if (data) {
        dispatch(
          setWarningMessage(["No signals found", "Try changing your filters"])
        );
      }
    }
  }, [data, index, triggerRerender, dispatch]);

  return <Svg id={"heat-map-" + index} inFront={inFront === index} />;
}
