import styled from "styled-components";

export const StyledDragger = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1px;
  width: 20px;
  background-color: transparent;
  cursor: col-resize;
  z-index: 1201;
  border-right: 1px solid grey;
`;
