import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowLines } from "../../../../redux/slices/grid";
import { Container } from "./ChartSwitchesStyles";
import ChartSwitch from "./ChartSwitch";

export default function ChartSwitches() {
  // Retrieve redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const showLines = useSelector(({ grid }) => grid.showLines);

  // Hander functions
  const onShowLinesToggle = useCallback(
    () => dispatch(toggleShowLines()),
    [dispatch]
  );

  return (
    <Container>
      <ChartSwitch
        checked={showLines}
        onChange={onShowLinesToggle}
        label="Show pattern lines"
      />
    </Container>
  );
}
