import React from "react";
import { useSelector } from "react-redux";

import { useColdStartMessage } from "../../../hooks/useColdStartMessage";
import { StyledMessage, MessageContainer } from "./MessageStyle";

export default function Message() {
  const error = useSelector(({ coldStart }) => coldStart.error);

  const message = useColdStartMessage();

  return (
    <MessageContainer>
      {message && <StyledMessage error={error}>{message}</StyledMessage>}
    </MessageContainer>
  );
}
