import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const StyledCloseButton = styled(IconButton)`
  color: rgb(58, 58, 58) !important;
  padding: 0;
`;

export const ButtonContainer = styled.div`
  justify-content: flex-end;
  width: 100px;
`;
