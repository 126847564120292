import store from "../../../redux/store";
import { minutesToStrRepr } from "../../../utils/utils";
import _ from "lodash";

const createTreeData = (data, drillDownBy, tickerFields, modelIdToNameMap) => {
  let maxPosSignal = 0;
  let minPosSignal = 1;
  let maxNegSignal = 0;
  let minNegSignal = -1;

  // Construct head of tree data structure
  let treeData = {
    name: "TOTAL",
    children: [],
  };

  // Iterate through data and construct tree data according to "drillDownBy" layers
  for (let signal of data) {
    // Initialise pointer at head of tree
    let node = treeData;
    for (let category of drillDownBy) {
      let categoryValue;
      let categoryLabel;

      if (category === "intervals") {
        categoryValue = signal.interval;
        categoryLabel = minutesToStrRepr(categoryValue);
      } else if (category === "modelIds") {
        categoryValue = signal.model_id;
        categoryLabel = modelIdToNameMap[categoryValue]?.name;
      } else if (tickerFields.includes(category)) {
        categoryValue = signal.ticker[category];
        categoryLabel = categoryValue;
      } else {
        throw new Error(`Unknow category: ${category}`);
      }

      // Find child node which corresponds to the data
      let categoryNode = node.children.find((el) =>
        _.isEqual(el.name, categoryValue)
      );

      // If such node does not exist, create the node and push to "children" array of parent node
      if (!categoryNode) {
        categoryNode = {
          name: categoryValue,
          nameLabel: categoryLabel,
          children: [],
        };
        node.children.push(categoryNode);
      }
      // Set pointer to parent node to current node
      categoryNode.parent = node;

      // Set pointer to current node to child node and repeat
      node = categoryNode;
    }

    // Add patterns data to current leaf nodes
    // Adjust auxiliary data
    if (signal.signal_value > 0) {
      if (signal.signal_value > maxPosSignal)
        maxPosSignal = signal.signal_value;
      if (signal.signal_value < minPosSignal)
        minPosSignal = signal.signal_value;
    } else {
      if (signal.signal_value < maxNegSignal)
        maxNegSignal = signal.signal_value;
      if (signal.signal_value > minNegSignal)
        minNegSignal = signal.signal_value;
    }

    // Then add pattern data
    const patternData = {
      value: 1,
      ...signal,
    };

    store.getState().heatMap.selectedSignals.forEach((selectedChart) => {
      if (selectedChart.id === patternData.chart_id) {
        patternData.color = selectedChart.color;
      }
    });

    node.children.push(patternData);
  }

  return {
    ...treeData,
    maxPosSignal,
    minPosSignal,
    maxNegSignal,
    minNegSignal,
  };
};

export default createTreeData;
