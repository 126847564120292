import React, { useState } from "react";
import DrillDownByLayers from "./DrillDownByLayers/DrillDownByLayers";
import Container from "./styledComponents/Container";
import { Title, TitleContainer } from "../HeatMapControlStyles";
import AddLayerButton from "./Buttons/AddLayerButton";
import DrillDownContainer from "./styledComponents/DrillDownContainer";
import DepthSlider from "./DepthSlider/DepthSlider";
// import CustomLayersDropzone from "./CustomLayersDropzone/CustomLayersDropzone";
import ClearLayersButton from "./Buttons/ClearLayersButton";
import ButtonsContainer from "./styledComponents/ButtonsContainer";

export default function DrillDownBy() {
  // useState hooks
  const [openFilter, setOpenFilter] = useState(null);
  const [transitioningHeight, setTransitioningHeight] = useState(false);

  return (
    <Container>
      <TitleContainer>
        <Title>Layers:</Title>
        {
          //<CustomLayersDropzone />
        }
      </TitleContainer>
      <DrillDownContainer>
        <DepthSlider
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          transitioningHeight={transitioningHeight}
        />
        <DrillDownByLayers
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          setTransitioningHeight={setTransitioningHeight}
        />
      </DrillDownContainer>
      <ButtonsContainer>
        <AddLayerButton />
        <ClearLayersButton />
      </ButtonsContainer>
    </Container>
  );
}
