import "./App.css";
import "./context-menu.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import "react-notifications-component/dist/theme.css";

import React from "react";
import styled from "styled-components";
import ReactNotification from "react-notifications-component";

import useAuth from "./auth/hooks/useAuth";
import MainPage from "./components/MainPage/MainPage";
import AuthMain from "./components/Auth/AuthMain";
import CustomSpinner from "./components/UI/Spinner/CustomSpinner";

const AuthContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const AuthText = styled.h1`
  color: rgb(73, 73, 73);
  font-size: 2rem;
  margin-bottom: 1rem;
`;

function App() {
  const isAuth = useAuth();

  return (
    <div>
      <ReactNotification />

      {isAuth && <MainPage />}
      {isAuth === false && (
        <div>
          <AuthMain />
        </div>
      )}
      {isAuth === null && (
        <AuthContainer>
          <AuthText>Authenticating. Please wait...</AuthText>
          <CustomSpinner />
        </AuthContainer>
      )}
    </div>
  );
}

export default App;
