import React from "react";
import { useSelector } from "react-redux";
import { LineChart, Line, XAxis } from "recharts";

import { selectPatternById } from "../../../../redux/slices/coldStart";

import { StyledPattern } from "./PatternStyle";
import DeletePattern from "./DeletePattern";

export default function Pattern({ id, selected, height, onClick, onDelete }) {
  const pattern = useSelector((state) => selectPatternById(state, id));

  return (
    <StyledPattern onClick={() => onClick(selected, id)} selected={selected}>
      <DeletePattern id={id} onDelete={onDelete} />

      <LineChart width={396} height={height} data={pattern.shape}>
        <XAxis dataKey="x" type="number" hide={true} />
        <Line type="monotone" dataKey="baseline" dot={{ r: 0 }} />
        <Line
          type="monotone"
          dataKey="y"
          stroke="rgb(24,235,254)"
          dot={false}
          animationDuration={750}
        />
      </LineChart>
    </StyledPattern>
  );
}
