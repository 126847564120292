import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../redux/slices/coldStart";

const BASE_LINE_POINTS = 1;

const validateShape = (shape) => {
  //check we have at least 3 points
  const length = shape.length - BASE_LINE_POINTS;
  if (length > 0 && length < 3) {
    return "Draw at least 3 points";
  }
};

export const useColdStartMessage = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const currentShape = useSelector(({ coldStart }) => coldStart.currentShape);

  useEffect(() => {
    //Check for errors
    const validationError = validateShape(currentShape);
    if (validationError) {
      dispatch(setError(true));
      setMessage(validationError);
      return;
    }

    //all validations passed. Clear errors and set success message.
    dispatch(setError(false));
    setMessage("");
  }, [currentShape, dispatch]);

  return message;
};
