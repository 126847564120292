import React from "react";

import {
  HelpRow,
  HelpTitle,
  HelpImage,
  ImageContainer,
  PageContainer,
} from "./HelpStyles";
import Step5b from "../../../../assets/TrainingHelp/Step5b.jpg";

export default function HelpPage6() {
  return (
    <PageContainer>
      <HelpTitle>Step 5: Retrain Part 2</HelpTitle>
      <HelpRow>Click the "Retrain Model" button</HelpRow>
      <HelpRow>
        The model will incorporate the new data to increase its accuracy
      </HelpRow>
      <ImageContainer>
        <HelpImage src={Step5b} alt="test model" />
      </ImageContainer>
    </PageContainer>
  );
}
