import styled from "styled-components";

export const StyledLeftPanel = styled.div`
  background-color: ${(props) => props.theme.containerBackground1};
  box-sizing: border-box;
  color: ${(props) => props.theme.primary};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 50px);
  padding: 15px;
  width: 380px;
`;

export const ListItemContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const ListTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-right: 30px;
  padding-bottom: 8px;
`;

export const ListItem = styled.div`
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  flex: 1;
  font-size: 14px;
  justify-content: space-between;
  margin: 0 10px 2px 0;
  padding: 4px 10px;
  background-color: ${(props) =>
    props.selected ? props.theme.selected : props.theme.containerBackground2};
  &:hover {
    background-color: ${(props) =>
      props.selected ? props.theme.selected : props.theme.buttonHover};
  }
`;

export const MenuContainer = styled.div`
  display: ${({ selected }) => (selected ? "flex" : "none")};
  flex-direction: column;
  height: 100%;
`;
export const Row = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
`;
export const DPatternsCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
`;
export const PatternsCountContainer = styled.div`
  width: 100%;
  padding: 10px 10px;
  line-height: 2rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.containerBackground2};
  border-radius: 10px;
  margin: 10px 0;
`;

export const Title = styled.p`
  font-size: 12px;
  margin: 15px 0;
`;
