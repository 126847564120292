import React from 'react';
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import CustomButton from '../../UI/Button/CustomButton';

function Warning (props) {

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>
        {props.warning}
      </DialogTitle>
      <DialogActions>
        <CustomButton
          variant="outlined"
          onClick={props.onClose}>
          Ok
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default Warning;
