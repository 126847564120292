import { useMemo } from "react";

export const useGetPatternData = (shapes) => {
  return useMemo(() => {
    const [rangeShape, ...lines] = shapes;
    const start = rangeShape.points[0].time * 1000;
    const end = rangeShape.points[1].time * 1000;

    return { patternRange: { start, end }, patterns: lines };
  }, [shapes]);
};
