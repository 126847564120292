import React from "react";
import { useDispatch } from "react-redux";

import { IconButton, Tooltip } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import {
  setSelectedShape,
  saveCurrentShape,
  resetShape,
} from "../../../../../redux/slices/coldStart";

export default function SaveButton({ disabled }) {
  const dispatch = useDispatch();

  const onSave = () => {
    //save shape
    dispatch(saveCurrentShape());

    dispatch(resetShape());

    //deselect current shape
    dispatch(setSelectedShape(null));
  };

  return (
    <Tooltip title="Save and clear pattern">
      <span>
        <IconButton disabled={disabled} onClick={onSave}>
          <SaveIcon
            color={disabled ? "disabled" : "primary"}
            fontSize="large"
          />
        </IconButton>
      </span>
    </Tooltip>
  );
}
