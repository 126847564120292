import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllSDPatterns } from "../../redux/slices/selectedDetectedPatterns";

export const useSDPatternsByChart = (chartId) => {
  const allSDPatterns = useSelector(selectAllSDPatterns);
  return useMemo(
    () => allSDPatterns.filter((pattern) => pattern.chartId === chartId),
    [chartId, allSDPatterns]
  );
};
