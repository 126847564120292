import React from "react";
import { useDispatch } from "react-redux";

import { setSelectedInterval } from "../../../../redux/slices/leftPanel";

import { StyledIntervalButton } from "./IntervalSelectStyle";
import { minutesToStrRepr } from "../../../../utils/utils";

export default function IntervalButton({ selected, interval }) {
  const dispatch = useDispatch();

  return (
    <StyledIntervalButton
      selected={selected}
      onClick={() => {
        dispatch(setSelectedInterval(interval));
      }}
    >
      {minutesToStrRepr(interval)}
    </StyledIntervalButton>
  );
}
