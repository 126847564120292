import _ from "lodash";

const findSubsetTreeData = (data, zoomInTileData) => {
  // If there is no valid data, return undefined
  if (!data || !zoomInTileData) return null;

  const { maxPosSignal, minPosSignal, maxNegSignal, minNegSignal } = data;
  const { x0, x1, y0, y1, path } = zoomInTileData;
  const rootNode = findNode(data, path);

  const subsetTreeData = {
    ...rootNode,
    path,
    maxPosSignal,
    minPosSignal,
    maxNegSignal,
    minNegSignal,
    x0,
    x1,
    y0,
    y1,
  };

  return subsetTreeData;
};

export default findSubsetTreeData;

const findNode = (treeData, path) => {
  if (!path.length) return treeData;
  const nextNode = treeData.children.find((child) =>
    _.isEqual(child.name, path[0])
  );
  if (nextNode) return findNode(nextNode, path.slice(1));
  return null;
};
