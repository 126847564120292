import React from "react";
import { useDispatch } from "react-redux";
import { StyledGridControls, CloseIconContainer } from "./GridControlsStyles";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { setChartDisplay } from "../../../redux/slices/display";
import ChartSwitches from "./ChartSwitches/ChartSwitches";

export default function GridControls() {
  // dispatch function from redux store
  const dispatch = useDispatch();

  // Handler functions
  const onCloseClick = () => dispatch(setChartDisplay());

  return (
    <StyledGridControls>
      <CloseIconContainer>
        <BackspaceIcon onClick={onCloseClick} />
      </CloseIconContainer>
      <ChartSwitches />
    </StyledGridControls>
  );
}
