import { useGridLink } from "./useGridLink";
import { useNavigate } from "./useNavigate";

export const useNavigationController = ({ chart, isFullScreen }) => {
  //
  // ─── NAVIGATION BUTTONS ─────────────────────────────────────────────────────────
  //
  const [onPrevPattern, onNextPattern] = useNavigate({ chart });
  // ────────────────────────────────────────────────────────────────────────────────

  //
  // ─── NAVIGATION LINK ────────────────────────────────────────────────────────────
  //
  useGridLink({ chart, isFullScreen });
  // ────────────────────────────────────────────────────────────────────────────────

  return [onPrevPattern, onNextPattern];
};
