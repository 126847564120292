import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditModel from "../../Modals/EditModel";
import DeployModel from "../../Modals/DeployModel/DeployModel";
import WarningModal from "../../Modals/Warning";
import DeleteModelModal from "../../Modals/DeleteModel";
import SaveModelModal from "../../Modals/SaveModel";
import {
  setIsWarningModalOpen,
  setModalWarning,
  setIsSaveModelModalOpen,
  setIsEditModalOpen,
  setIsDeleteModelModalOpen,
  setSelectedModel,
  setIsDeployModalOpen,
} from "../../../redux/slices/toolbar";
export default function ToolbarModal() {
  // Retrieve redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const modalWarning = useSelector(({ toolbar }) => toolbar.modalWarning);
  const {
    isWarningModalOpen,
    isSaveModelModalOpen,
    isEditModalOpen,
    isDeleteModelModalOpen,
    isDeployModelModalOpen,
    actionModel,
    selectedModel,
  } = useSelector(({ toolbar }) => toolbar);

  // Close Modal Handlers
  const handleCloseEditModal = () => {
    dispatch(setIsEditModalOpen(false));
  };
  const handleCloseWarningModal = () => {
    dispatch(setIsWarningModalOpen(false));
    dispatch(setModalWarning(""));
  };
  const handleCloseDeleteModelModal = () => {
    dispatch(setIsDeleteModelModalOpen(false));
  };
  const handleCloseSaveModelModal = () => {
    dispatch(setIsSaveModelModalOpen(false));
  };
  const handleCloseDeployModelModal = () => {
    dispatch(setIsDeployModalOpen(false));
  };

  return (
    <Fragment>
      {isWarningModalOpen && (
        <WarningModal
          onClose={handleCloseWarningModal}
          warning={modalWarning}
        />
      )}
      {isSaveModelModalOpen && (
        <SaveModelModal onClose={handleCloseSaveModelModal} />
      )}
      {isEditModalOpen && (
        <EditModel
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          model={actionModel}
          onClose={handleCloseEditModal}
        />
      )}
      {isDeleteModelModalOpen && (
        <DeleteModelModal
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
          model={actionModel}
          onClose={handleCloseDeleteModelModal}
        />
      )}
      {isDeployModelModalOpen && (
        <DeployModel
          model={actionModel}
          onClose={handleCloseDeployModelModal}
        />
      )}
    </Fragment>
  );
}
