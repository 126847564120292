import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
`;

export const ConfigurationsContainer = styled.div`
  align-self: center;
  background-color: #121212;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 300px;
  width: 100%;
  padding: 20px;
  overflow-y: scroll;
`;

export const Title = styled.h3`
  font-weight: 200;
  margin-bottom: 30px;
  margin-left: 30px;
`;

export const ConfigurationCell = styled.div`
  align-items: center;
  box-sizing: border-box;
  background-color: rgb(38, 51, 64);
  border-radius: 5px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 70px;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 30px;

  transition: all ease-in-out 0.2s;
  width: 49%;

  &:hover {
    background-color: rgb(48, 61, 74);
    opacity: 0.9;
  }
`;

export const ConfigurationText = styled.p`
  font-size: 20px;
  font-weight: 200;
`;

export const ConfigurationIndex = styled.p`
  font-size: 14px;
  font-weight: 200;
`;
