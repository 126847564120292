const findPathToNode = (relevantPatternData) => {
  const result = [];
  let pointer = relevantPatternData;
  while (pointer.parent) {
    result.unshift(pointer.data.name);
    pointer = pointer.parent;
  }
  return result;
};

export default findPathToNode;
