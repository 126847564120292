import { store } from "react-notifications-component";
import "animate.css";

export const addNotification = (messages) =>
  store.addNotification({
    title: messages[0],
    message: messages[1],
    type: "default",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      onScreen: true,
    },
  });
