import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SwitchContainer, Option } from "../FilterSwitchesStyles";
import { changeFilter, SignalType } from "../../../../../../redux/slices/heatMap";

export default function SignalTypeSwitch() {
  const signalType = useSelector(({ heatMap }) => heatMap.filters.signalType);

  const dispatch = useDispatch();

  const switchSignalTypeHandler = (signalTypeValue) => {
    dispatch(changeFilter({ signalType: signalTypeValue }));
  };

  return (
    <SwitchContainer side={"left"}>
      <Option
        side={"left"}
        selected={signalType === SignalType.PATTERN}
        onClick={() => switchSignalTypeHandler(SignalType.PATTERN)}
        selectedColor={"#dbd800"}
      >
        <p>patterns</p>
      </Option>
      <Option
        selected={signalType === SignalType.ALL}
        selectedColor={"#DCDCDC"}
        onClick={() => switchSignalTypeHandler(SignalType.ALL)}
      >
        <p>all</p>
      </Option>
      <Option
        side={"right"}
        selected={signalType === SignalType.INDICATOR}
        onClick={() => switchSignalTypeHandler(SignalType.INDICATOR)}
        selectedColor={"#de4dff"}
      >
        <p>indicators</p>
      </Option>
    </SwitchContainer>
  );
}
