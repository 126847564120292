import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../../awsConfig/awsConfig";

import { onAuthorizeSuccess, onAuthorizeFail } from "../../redux/slices/login";
import bloombergAuth from "../../auth/utils/bloombergAuth";

const useAuth = (loginAttempts) => {
  const dispatch = useDispatch();
  Amplify.configure(awsconfig);

  useEffect(() => {
    async function authenticate() {
      try {
        await Auth.currentSession();
        await bloombergAuth(Auth);
        dispatch(onAuthorizeSuccess());
      } catch (error) {
        console.log(error);
        dispatch(onAuthorizeFail());
      }
    }
    authenticate();
  }, [dispatch, loginAttempts]);

  const isAuth = useSelector(({ login }) => login.isAuthorised);

  return isAuth;
};

export default useAuth;
