import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockIcon from "@material-ui/icons/Lock";

import FormErrors from "./FormErrors";
import PasswordCheck from "./PasswordCheck";

import {
  AuthButton,
  AuthForm,
  AuthInput,
  InputField,
  Field,
  styleLoginIcon,
} from "../authStyle";

const StyledVpnKeyIcon = styleLoginIcon(VpnKeyIcon);
const StyledMailOutlineIcon = styleLoginIcon(MailOutlineIcon);
const StyledLockIcon = styleLoginIcon(LockIcon);

export default function ForgotPasswordVerify({ history, setHeight }) {
  const [verificationCode, setVerificationCode] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(() => {
    setHeight(700);
  }, [setHeight]);

  const passwordVerificationHandler = async (e) => {
    e.preventDefault();
    setErrors(null);
    // Amplify/Cognito
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      history.push("/changePasswordConfirm");
    } catch (error) {
      setErrors(error.message ? error : { message: error });
    }
  };

  const onVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };
  const onEmailChange = (e) => {
    setErrors(null);
    setEmail(e.target.value);
  };
  const onPasswordChange = (e) => {
    setErrors(null);
    setNewPassword(e.target.value);
  };

  return (
    <>
      {errors ? (
        <FormErrors formErrors={errors} />
      ) : (
        <PasswordCheck
          password={newPassword}
          onSetSubmitEnabled={setSubmitEnabled}
        />
      )}

      <AuthForm onSubmit={passwordVerificationHandler}>
        <InputField>
          <StyledVpnKeyIcon fontSize="large" />
          <AuthInput
            type="text"
            id="verificationcode"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={onVerificationCodeChange}
            required={true}
          />
        </InputField>
        <InputField>
          <StyledMailOutlineIcon fontSize="large" />
          <AuthInput
            type="email"
            id="email"
            placeholder="Enter email"
            value={email}
            onChange={onEmailChange}
            required={true}
          />
        </InputField>
        <InputField>
          <StyledLockIcon fontSize="large" />
          <AuthInput
            type="password"
            id="newpassword"
            placeholder="New password"
            value={newPassword}
            onChange={onPasswordChange}
            required={true}
          />
        </InputField>
        <Field>
          <AuthButton disabled={!(submitEnabled && verificationCode.length)}>
            Submit
          </AuthButton>
        </Field>
      </AuthForm>
    </>
  );
}
