import React from "react";

import {
  HelpRow,
  HelpTitle,
  HelpImage,
  ImageContainer,
  PageContainer,
} from "./HelpStyles";
import Step6 from "../../../../assets/TrainingHelp/Step6.jpg";

export default function HelpPage7() {
  return (
    <PageContainer>
      <HelpTitle>Step 6: Save Model</HelpTitle>
      <HelpRow>
        When you have finished retraining the model, click "Save Model"
      </HelpRow>
      <HelpRow>Choose a name for your model</HelpRow>
      <HelpRow>It will now be available in your list of models.</HelpRow>
      <ImageContainer>
        <HelpImage src={Step6} alt="test model" />
      </ImageContainer>
    </PageContainer>
  );
}
