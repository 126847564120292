import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPatternNavigation } from "../../../../../redux/slices/navigation";

const getNewRange = (patternLink, currentRange) => {
  const [start, end] = patternLink;

  const currentDistance = currentRange.to - currentRange.from;
  const newDistance = end - start;

  if (newDistance > currentDistance) {
    //zoom out to fit in entire pattern
    return { from: start, to: end };
  } else {
    //we want to keep the same scale, but center the view on the new pattern
    const patternMid = (start + end) / 2;
    return {
      from: patternMid - currentDistance / 2,
      to: patternMid + currentDistance / 2,
    };
  }
};

export const useGridLink = ({ chart, isFullScreen }) => {
  const dispatch = useDispatch();

  const patternLink = useSelector(({ navigation }) => navigation.pattern);

  //Respond to grid link: navigate to specific pattern
  useEffect(() => {
    if (!chart.ready || !chart.barsReady || !patternLink || !isFullScreen)
      return;
    const currentRange = chart.getRange();
    const newRange = getNewRange(patternLink, currentRange);
    chart.setRange(newRange);
    dispatch(setPatternNavigation(null));
  }, [chart, chart.barsReady, patternLink, dispatch, isFullScreen]);
};
