import styled from "styled-components";

export const StyledHeader = styled.div`
  box-sizing: border-box;
  height: 50px;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CloseIconContainer = styled.div`
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 50px;
  position: absolute;
  left: 35px;
  top: 0;
  width: 50px;
`;

export const TestButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  left: 0;
  margin-left: 50px;
  position: absolute;
`;
