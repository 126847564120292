import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(52, 61, 70);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-around;
  margin-top: 20px;
`;
