import React from "react";

import { Select, Input } from "@material-ui/core";

import {
  StyledFormControl,
  StyledFormHelperText,
  StyledMenuItem,
} from "./DropDownStyle";

export default function DropDown({
  label,
  value,
  options,
  multiple,
  onChange,
}) {
  return (
    <StyledFormControl>
      <StyledFormHelperText>{label}</StyledFormHelperText>
      <Select
        multiple={multiple}
        value={value}
        input={<Input />}
        onChange={onChange}
        disabled={!options.length}
      >
        {options.map((option) => (
          <StyledMenuItem key={option.id} value={option}>
            {option.name}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}
