import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

// _____ PARAMETERS __________________________________

export const MAX_NUMBER_CHARTS = 9;

// ___________________________________________________

const chartLayoutAdapter = createEntityAdapter();

const initialState = chartLayoutAdapter.getInitialState({
  updateRequired: true,
  layoutError: false,
  fullScreenChart: null,
  resizing: false,
});

const chartLayoutSlice = createSlice({
  name: "chartLayout",
  initialState,
  reducers: {
    addLayoutChartReducer: (state, action) => {
      chartLayoutAdapter.upsertOne(state, {
        id: action.payload,
        locked: false,
      });
      state.fullScreenChart = null;
      state.updateRequired = true;
    },
    removeLayoutChart: (state, action) => {
      chartLayoutAdapter.removeOne(state, action.payload);
      if (action.payload === state.fullScreenChart)
        state.fullScreenChart = null;
      state.updateRequired = true;
    },
    resetChartLayout: (state) => {
      chartLayoutAdapter.setAll(state, []);
      state.fullScreenChart = null;
      state.updateRequired = true;
    },
    toggleLock: (state, action) => {
      state.entities[action.payload].locked =
        !state.entities[action.payload].locked;
      if (state.entities[action.payload].locked) {
        state.updateRequired = false;
      } else {
        state.updateRequired = true;
      }
    },
    lockAndUpdate: (state, action) => {
      state.entities[action.payload].locked = true;
      state.updateRequired = [true]; //force an update with new array
    },
    setLayoutError: (state, action) => {
      state.layoutError = action.payload;
    },
    setResizing: (state, action) => {
      state.resizing = action.payload;
    },
    setFullScreenChart: (state, action) => {
      state.fullScreenChart = action.payload;
    },
  },
});

const { addLayoutChartReducer } = chartLayoutSlice.actions;

export const {
  removeLayoutChart,
  resetChartLayout,
  toggleLock,
  lockAndUpdate,
  setLayoutError,
  setResizing,
  setFullScreenChart,
} = chartLayoutSlice.actions;

export const { selectAll: selectAllCharts, selectIds: selectChartIds } =
  chartLayoutAdapter.getSelectors((state) => state.chartLayout);

export const addLayoutChart = (id) => (dispatch, getState) => {
  const ids = selectChartIds(getState());
  if (ids.includes(id)) return true;

  const allCharts = selectAllCharts(getState());
  if (allCharts.length === MAX_NUMBER_CHARTS) {
    dispatch(setLayoutError(true));
    return false;
  }

  dispatch(addLayoutChartReducer(id));
  return true;
};

export default chartLayoutSlice.reducer;
