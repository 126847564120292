import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "../../../../UI/Button/CustomButtonLeftPanelGreen";

export default function AddCustomCollectionButton({ onClick }) {
  return (
    <Button onClick={onClick}>
      <>
        <AddIcon />
        <div style={{ marginLeft: 5 }}>Add custom security list</div>
      </>
    </Button>
  );
}
