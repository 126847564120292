import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

function DropdownItem({ onClick, leftIcon, rightIcon, children, backButton }) {
  return backButton ? (
    <div className="back-button" onClick={onClick}>
      <ArrowBackIosIcon />
    </div>
  ) : (
    <div className="menu-item" onClick={onClick}>
      <span className="icon-button">{leftIcon}</span>
      {children}
      <span className="icon-right">{rightIcon}</span>
    </div>
  );
}

export default DropdownItem;
