import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../UI/Button/CustomButtonLeftPanelBlue";
import { setIsSaveModelModalOpen } from "../../../../redux/slices/toolbar";
import {
  setIsWarningModalOpen,
  setModalWarning,
} from "../../../../redux/slices/toolbar";
import SaveIcon from "@material-ui/icons/Save";

export default function SaveModelButton() {
  // Retrieve dispatch from redux store
  const dispatch = useDispatch();

  // Select from redux store
  const training = useSelector(({ models }) => models.training);
  const detectingPatterns = useSelector(
    ({ detectedPatterns }) => detectedPatterns.fetching
  );
  const tempModel = useSelector(({ models }) => models.temporaryModel);
  const modelToRetrain = useSelector(({ models }) => models.retrainModel);

  // Handler functions
  const onSaveModel = () => {
    if (!tempModel) {
      let errMsg = "Please create a new model to save";

      if (modelToRetrain) {
        errMsg = "Please select patterns and retrain model first";
      }

      dispatch(setModalWarning(errMsg));
      dispatch(setIsWarningModalOpen(true));
    } else {
      dispatch(setIsSaveModelModalOpen(true));
    }
  };

  return (
    <Button disabled={training || detectingPatterns} onClick={onSaveModel}>
      <>
        <SaveIcon />
        <div style={{ marginLeft: 10 }}>Save Model</div>
      </>
    </Button>
  );
}
