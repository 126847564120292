import styled from "styled-components";

export const StyledMessage = styled.div`
  color: ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.error};
  font-size: 14px;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 10px;
`;
export const MessageContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
`;
