import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px 0 20px;
`;

export const SwitchContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0px 2px 5px black;
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 5px 0;
  margin-left: ${({ side }) => (side === "right" ? "5px" : 0)};
  margin-right: ${({ side }) => (side === "left" ? "5px" : 0)};
`;

export const Option = styled.div`
  align-items: center;
  background-color: ${({ selectedColor, selected }) =>
    (selected && selectedColor) || "rgb(19, 23, 34)"};
  border-right: ${({ side }) => (side === "left" ? "1px solid black" : "")};
  border-left: ${({ side }) => (side === "right" ? "1px solid black" : "")};
  border-top-left-radius: ${({ side }) => (side === "left" ? "10px" : 0)};
  border-bottom-left-radius: ${({ side }) => (side === "left" ? "10px" : 0)};
  border-top-right-radius: ${({ side }) => (side === "right" ? "10px" : 0)};
  border-bottom-right-radius: ${({ side }) => (side === "right" ? "10px" : 0)};
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 20px;
  justify-content: center;

  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;

  p {
    color: ${({ selected }) => (selected ? "black" : "white")};
    font-size: 12px;
    margin: 0;
  }
`;
