import React from "react";

import {
  HelpRow,
  HelpTitle,
  HelpImage,
  ImageContainer,
  PageContainer,
} from "./HelpStyles";
import Step2 from "../../../../assets/TrainingHelp/Step2.jpg";

export default function HelpPage3() {
  return (
    <PageContainer>
      <HelpTitle>Step 2: Train Model</HelpTitle>
      <HelpRow>Click the "Train Model" button</HelpRow>
      <HelpRow>
        The model will be trained, then deployed on any charts that are
        currently open
      </HelpRow>
      <ImageContainer>
        <HelpImage src={Step2} alt="train model" />
      </ImageContainer>
    </PageContainer>
  );
}
