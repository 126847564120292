import React from "react";

import {
  HelpRow,
  HelpTitle,
  HelpImage,
  ImageContainer,
  PageContainer,
} from "./HelpStyles";
import Step3 from "../../../../assets/TrainingHelp/Step3.jpg";

export default function HelpPage4() {
  return (
    <PageContainer>
      <HelpTitle>Step 3: Adjust Confidence</HelpTitle>
      <HelpRow>Use the slider to adjust the confidence range</HelpRow>
      <HelpRow>
        A higher confidence will reduce the number of patterns found
      </HelpRow>
      <ImageContainer>
        <HelpImage src={Step3} alt="confidence slider" />
      </ImageContainer>
    </PageContainer>
  );
}
