import React, { Fragment } from "react";
import { IndicatorArrow } from "../HeatMapPreviewChartStyles";
import { useGetIndicators } from "../hooks/useGetIndicators";
import { convertShapePoints } from "../../../../../utils/heatmap/signals";

export const Indicator = ({
  signalData,
  filteredBars,
  chartWidth,
  chartHeight,
}) => {
  const indicatorShapes = signalData.shapes
    .filter((shape) => shape.shape_type.includes("arrow"))
    .map((shape) => convertShapePoints(shape));

  const indicators = useGetIndicators(
    filteredBars,
    indicatorShapes,
    chartHeight,
    chartWidth
  );

  return (
    <Fragment>
      {indicators.map((indicator, index) => (
        <IndicatorArrow
          key={index}
          x={indicator.x}
          y={indicator.y}
          direction={indicator.direction}
        />
      ))}
    </Fragment>
  );
};
