import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";

import CustomButton from "../../UI/Button/CustomButton";
import CustomSpinner from "../../UI/Spinner/CustomSpinner";
import { removeModel } from "../../../redux/slices/models";

function Delete(props) {
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [waiting, setWaiting] = useState(false);

  const handleDelete = () => {
    setWaiting(true);

    const onError = (erroMsg) => {
      setSuccess(false);
      setWaiting(false);
      setError(erroMsg);
    };

    const onSuccess = () => {
      setSuccess(true);
      setWaiting(false);
      setError("");
    };

    dispatch(removeModel(props.model.id, onError, onSuccess));
  };

  return (
    <Dialog open={true} onClose={props.onClose} maxWidth={false}>
      <DialogTitle>Delete Model</DialogTitle>
      <div className="delete-container">
        Are you sure you want to delete the following model?
        <div className="delete-chart">{props.model.name}</div>
        {success && (
          <div className="delete-success">Model deleted successfully!</div>
        )}
        {error && (
          <div className="delete-error">
            {error}
          </div>
        )}
        {waiting && <CustomSpinner />}
      </div>
      {!success && (
        <DialogActions>
          <CustomButton
            variant="outlined"
            disabled={waiting}
            onClick={handleDelete}
          >
            Yes
          </CustomButton>
          <CustomButton
            variant="outlined"
            disabled={waiting}
            onClick={props.onClose}
          >
            No
          </CustomButton>
        </DialogActions>
      )}
      {success && (
        <DialogActions>
          <CustomButton variant="outlined" onClick={props.onClose}>
            OK
          </CustomButton>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default Delete;
