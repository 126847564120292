import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { StyledLinkButton } from "./FavouriteStarStyles";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import {
  toggleFavourite,
  selectAllFavourites,
} from "../../../../../redux/slices/heatMapFavourites";

export default function FavouriteStar({ chartData, pattern }) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const favourites = useSelector(selectAllFavourites);

  const favourited = favourites.reduce(
    (acc, el) => (pattern.id === el.id ? true : acc),
    false
  );

  return (
    chartData && (
      <StyledLinkButton>
        {favourited ? (
          <StarIcon
            onClick={() => dispatch(toggleFavourite(pattern))}
            style={{ color: "cyan", cursor: "pointer" }}
          />
        ) : (
          <StarBorderIcon
            onClick={() => dispatch(toggleFavourite(pattern))}
            style={{ color: "white", cursor: "pointer" }}
          />
        )}
      </StyledLinkButton>
    )
  );
}
