import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectChartDataById } from "../../../../../redux/slices/chartData";

export const useUpdateBars = ({ chart, chartId }) => {
  const data = useSelector((state) => selectChartDataById(state, chartId));
  useEffect(() => {
    const bars = data.bars;
    const chartId = data.chartData.id;
    if (!chart.ready || !bars || !bars.length) return;

    chart.interval = data.chartData.interval;
    chart.updatePreviewBars(data.previewBars);
    chart.defaultRangeFull(bars);
    chart.updatePriceRange(bars);
    chart.updateBars(chartId);
  }, [chart, data]);
};
