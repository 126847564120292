import { createSlice } from "@reduxjs/toolkit";
import retrieveBookmarks from "../../utils/heatmap/retrieveBookmarks";
import LocalStorage from "../../utils/localStorage";
import {
  populateHeatMap,
  resetApiLoadingProgress,
  incrementApiLoadingProcess,
} from "./heatMap";

import { Api } from "../../api/api";

const heatMapConfigsSlice = createSlice({
  name: "heatMapConfigs",
  initialState: {
    configs: [],
    selectedConfig: null,
    bookmarks: retrieveBookmarks(),
    isFetching: false
  },
  reducers: {
    setHeatMapConfigs: (state, action) => {
      state.configs = action.payload;
    },
    setHeatMapSelectedConfig: (state, action) => {
      state.selectedConfig = action.payload;
    },
    setBookmarks: (state, action) => {
      state.bookmarks = action.payload;
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
  },
});

const { setBookmarks, setIsFetching } = heatMapConfigsSlice.actions;

export const { setHeatMapConfigs, setHeatMapSelectedConfig } =
  heatMapConfigsSlice.actions;

export default heatMapConfigsSlice.reducer;

export const fetchConfigs = () => async (dispatch) => {
  dispatch(setIsFetching(true));
  const configs = await Api.getHeatMapPresets();
  dispatch(setHeatMapConfigs(configs.items));
  dispatch(setIsFetching(false));
};

export const selectConfig = (configId) => async (dispatch) => {
  resetApiLoadingProgress(dispatch);

  const selectedConfig = await Api.getHeatMapPreset(configId);
  incrementApiLoadingProcess(dispatch, 10);
  dispatch(setHeatMapSelectedConfig(selectedConfig));
  dispatch(populateHeatMap(selectedConfig));
};

export const createConfig =
  (configName, isPreset, onError, onSuccess) => async (dispatch, getState) => {
    const heatMapState = getState().heatMap;

    const presetData = {
      name: configName,
      preset: isPreset,
      drill_down_by: heatMapState.drillDownBy,
      depth_constraint: heatMapState.depthConstraint,
      filters: {
        signal_type: heatMapState.filters.signalType,
        status: heatMapState.filters.status,
        entry_direction: heatMapState.filters.entryDirection,
        model_ids: heatMapState.filters.modelIds,
        intervals: heatMapState.filters.intervals,
        ticker: JSON.stringify(heatMapState.filters.ticker),
        min_signal_value: heatMapState.filters.minSignalValue,
        max_signal_value: heatMapState.filters.maxSignalValue,
        first_bar: heatMapState.filters.firstBar,
        last_bar: heatMapState.filters.lastBar,
        signals_on_chart_count: heatMapState.filters.signalsOnChartCount,
        show_missing_signals: heatMapState.filters.showMissingSignals,
      },
    };
    try {
      await Api.createHeatmapPreset(presetData);
      dispatch(fetchConfigs());
      onSuccess();
    } catch (error) {
      onError(error.message);
    }
  };

export const updateConfig =
  (configToUpdate, name, isPreset, onSuccess, onError) => async (dispatch, getState) => {
    const heatMapState = getState().heatMap;

    if (!selectConfig) {
      return;
    }

    const presetData = {
      name: name,
      preset: isPreset,
      drill_down_by: heatMapState.drillDownBy,
      depth_constraint: heatMapState.depthConstraint,
      filters: {
        signal_type: heatMapState.filters.signalType,
        status: heatMapState.filters.status,
        entry_direction: heatMapState.filters.entryDirection,
        model_ids: heatMapState.filters.modelIds,
        intervals: heatMapState.filters.intervals,
        ticker: JSON.stringify(heatMapState.filters.ticker),
        min_signal_value: heatMapState.filters.minSignalValue,
        max_signal_value: heatMapState.filters.maxSignalValue,
        first_bar: heatMapState.filters.firstBar,
        last_bar: heatMapState.filters.lastBar,
        signals_on_chart_count: heatMapState.filters.signalsOnChartCount,
        show_missing_signals: heatMapState.filters.showMissingSignals,
      },
    };
    try {
      await Api.updateHeatMapPreset(configToUpdate.id, presetData);
      dispatch(fetchConfigs());
      onSuccess();
    } catch (error) {
      onError(error.message);
    }
  };

export const deleteConfig =
  (configToDelete, onSuccess, onError) => async (dispatch) => {
    try {
      await Api.deleteHeatMapPreset(configToDelete.id);
      onSuccess();
      dispatch(fetchConfigs());
    } catch (error) {
      onError(error.message);
    }
  };

export const addOrUpdateBookmark =
  (configIndex, bookmarkIndex) => (dispatch, getState) => {
    const bookmarksCopy = [...getState().heatMapConfigs.bookmarks];
    bookmarksCopy[bookmarkIndex] = configIndex;
    dispatch(setBookmarks(bookmarksCopy));
    LocalStorage.setBookmarks(bookmarksCopy);
  };

export const deleteBookmark = (bookmarkIndex) => (dispatch, getState) => {
  const bookmarksCopy = [...getState().heatMapConfigs.bookmarks];
  bookmarksCopy[bookmarkIndex] = null;
  dispatch(setBookmarks(bookmarksCopy));
  LocalStorage.setBookmarks(bookmarksCopy);
};
