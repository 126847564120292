import React, { useEffect } from "react";

import { StyledLink, WelcomeBox } from "../authStyle";

export default function Welcome({ setHeight }) {
  useEffect(() => {
    setHeight(440);
  }, [setHeight]);

  return (
    <>
      <WelcomeBox>
        <p>You have successfully registered a new account.</p>
        <p>
          We've sent you a email. Please click on the confirmation link to
          verify your account.
        </p>
      </WelcomeBox>
      <StyledLink to="/">Sign in</StyledLink>
    </>
  );
}
