import React from "react";

import {
  HelpRow,
  HelpTitle,
  HelpImage,
  ImageContainer,
  PageContainer,
} from "./HelpStyles";
import Step5a from "../../../../assets/TrainingHelp/Step5a.jpg";

export default function HelpPage5() {
  return (
    <PageContainer>
      <HelpTitle>Step 4: Retrain Part 1</HelpTitle>
      <HelpRow>
        Select at least 3 patterns which have been correctly detected
      </HelpRow>
      <ImageContainer>
        <HelpImage src={Step5a} alt="test model" />
      </ImageContainer>
    </PageContainer>
  );
}
