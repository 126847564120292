import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";

import CustomButton from "../../UI/Button/CustomButton";
import CustomSpinner from "../../UI/Spinner/CustomSpinner";
import { editModel } from "../../../redux/slices/models";

function EditModel(props) {
  const dispatch = useDispatch();

  const [name, setName] = useState(props.model.name);
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setName(e.target.value);
    setError(false);
  };

  const handleSave = async () => {
    if (name.length === 0) setError(true);
    else {
      setWaiting(true);
      const response = await dispatch(editModel(props.model.id, name));
      setWaiting(false);
      if (response) {
        setSuccess(true);
        if (props.selectedModel.id === props.model.id)
          props.setSelectedModel({ ...props.selectedModel, name });
      } else setApiError(true);
    }
  };

  return (
    <Dialog
      classes={{ paper: "edit-model-modal " }}
      open={true}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit model</DialogTitle>
      <DialogContent classes={{ root: "edit-model-modal-content" }}>
        <TextField
          classes={{ root: "custom-input" }}
          error={error}
          helperText={error ? "Please, fill out this field" : ""}
          label="Model name"
          value={name}
          onChange={handleChange}
        />
      </DialogContent>
      <div className="delete-container">
        {success && (
          <div className="delete-success">Model name changed successfully!</div>
        )}
        {apiError && (
          <div className="delete-error">
            Error processing your request. Please try again.
          </div>
        )}
        {waiting && <CustomSpinner />}
      </div>
      {!success && (
        <DialogActions>
          <CustomButton variant="outlined" onClick={props.onClose}>
            Cancel
          </CustomButton>
          <CustomButton variant="outlined" onClick={handleSave}>
            Save
          </CustomButton>
        </DialogActions>
      )}
      {success && (
        <DialogActions>
          <CustomButton variant="outlined" onClick={props.onClose}>
            OK
          </CustomButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
export { EditModel as default };
