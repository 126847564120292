import React from "react";

import {
  PageContainer,
  HelpRow,
  HelpTitle,
  HelpImage,
  ImageContainer,
} from "./HelpStyles";
import Step1Option1 from "../../../../assets/TrainingHelp/Step1Option1.jpg";

export default function HelpPage2() {
  return (
    <PageContainer>
      <HelpTitle>Step 1: Draw your own Pattern</HelpTitle>
      <HelpRow>Draw and save at least one pattern</HelpRow>
      <HelpRow>
        Save multiple variations of your pattern to increase model accuracy
      </HelpRow>
      <ImageContainer>
        <HelpImage src={Step1Option1} alt="draw pattern" />
      </ImageContainer>
    </PageContainer>
  );
}
