let subscription = {};

const getBar = (data) => {
  return {
    time: data["t"],
    open: data["o"],
    high: data["h"],
    low: data["l"],
    close: data["c"],
    volume: data["v"],
  }
}

export function subscribeOnStream (symbolInfo, onRealtimeCallback, subscribeUID) {
  const name = symbolInfo.name.replace('Binance', '');
  const symbol = name.split('_')[0].toLowerCase();
  const interval = name.split('_')[1].toLowerCase();
  subscription[subscribeUID] = new WebSocket(`wss://stream.binance.com:9443/ws/${symbol}@kline_${interval}`);
  subscription[subscribeUID].addEventListener('message', (event)=>{
    let data = JSON.parse(event.data).k
    let bar = getBar(data)
    if (onRealtimeCallback) onRealtimeCallback(bar);
  });
}

export function unsubscribeFromStream (subscriberUID) {
  if (subscription[subscriberUID]) {
    subscription[subscriberUID].close(1000, "Work complete");
    delete subscription[subscriberUID];
  }
}
