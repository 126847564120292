const findFilteredBars = (bars, pattern) => {
  const rect = pattern.shapes.find((shape) => shape.shape_type === "rectangle");
  const patternRange = [rect.points[0].time * 1000, rect.points[1].time * 1000];
  const filteredBars = bars.filter(
    ({ time }) => time >= patternRange[0] && time <= patternRange[1]
  );

  return filteredBars;
};

export default findFilteredBars;
