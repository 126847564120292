import React from "react";
import { useSelector } from "react-redux";

export default function DetectedPatternsCount() {
  const count = useSelector(
    ({ detectedPatterns }) => detectedPatterns.numberOfNewDetectedPatterns
  );
  const mode = useSelector(({ display }) => display.mode);

  return (
    <div>{`${count} ${mode !== "detectPatterns" ? "new" : ""} pattern${
      count === 1 ? "" : "s"
    } detected`}</div>
  );
}
