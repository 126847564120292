import { useRef } from "react";
import { ColdStartUtils } from "../ColdStartUtils";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentShape } from "../../../redux/slices/coldStart";

//base end ensures that the axes spread to 100

export const useMouseInteractions = ({
  height,
  width,
  containerLeft,
  containerTop,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(({ coldStart }) => coldStart.currentShape);
  const selectedDot = useRef(null);

  const convertCoordsScreenToChart = (e) => {
    const { clientX, clientY } = e;
    const relativeX = clientX - containerLeft;
    const relativeY = clientY - containerTop;
    const normalizedX = ColdStartUtils.normalizeXCoords(relativeX, width);
    const normalizedY = ColdStartUtils.normalizeYCoords(relativeY, height);

    return { x: normalizedX, y: normalizedY };
  };

  const onDotClick = (index) => {
    selectedDot.current = index;
    document.addEventListener("mousemove", throttledMouseMove, true);
    document.addEventListener("mouseup", handleMouseUp, true);
  };
  const handleMouseUp = () => {
    selectedDot.current = null;
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", throttledMouseMove, true);
  };

  const handleMouseMove = (e) => {
    const coordinates = convertCoordsScreenToChart(e);
    const updatedArray = ColdStartUtils.arrayReplaceCoords({
      array: data,
      index: selectedDot.current,
      coordinates,
    });
    dispatch(setCurrentShape(updatedArray));
  };

  const throttledMouseMove = _.throttle(handleMouseMove, 16, {
    trailing: false,
  });

  const onBackgroundClick = (e) => {
    //left click => add
    if (e.button === 0 && selectedDot.current === null) {
      const coordinates = convertCoordsScreenToChart(e);
      const updatedArray = ColdStartUtils.arrayAddCoords({
        array: data,
        coordinates,
      });
      dispatch(setCurrentShape(updatedArray));
    }
    //right click => delete
    else if (e.button === 2 && selectedDot.current !== null) {
      const updatedArray = ColdStartUtils.arraydeleteCoords({
        array: data,
        index: selectedDot.current,
      });
      dispatch(setCurrentShape(updatedArray));
      handleMouseUp(); //cancel the move function
    }
  };

  return { onDotClick, onBackgroundClick };
};
