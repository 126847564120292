import React from "react";

import { ListItemContainer, ListItem } from "../../LeftPanelStyle";

export default function IndicatorItem({ indicator, onSelect, selected }) {
  return (
    <ListItemContainer>
      <div style={{ width: "100%" }}>
        <ListItem selected={selected} onClick={() => onSelect(indicator)}>
          {indicator}
        </ListItem>
      </div>
    </ListItemContainer>
  );
}
