import * as d3 from "d3";

let render = require("../render");

// Add text for each level of tree
export function addText(numberOfText, leftPanelExpanded) {
  for (let i = 0; i < numberOfText; i++) {
    render.toolTip
      .append("text")
      .attr("font-size", "12px")
      .attr("x", 4)
      .attr("y", 15 + 15 * i)
      .attr("class", "tool-tip-text");
  }

  render.nodes
    .filter((d) => !d.children)
    .on("mousemove", function (d) {
      // Extract relevant info for that leaf node from tree data
      const nodeInfo = extractInfo(d, numberOfText);
      // Add extracted info to tool tip text
      render.toolTip
        .selectAll("text")
        .data(nodeInfo)
        .text((infoLayer) => infoLayer);

      setToolTipPosition(numberOfText, leftPanelExpanded);
      render.toolTip.classed("hidden", false);
    })
    .on("mouseout", function () {
      render.toolTip.classed("hidden", true);
    });
}

const FilterNameToSignalFieldMap = {
  intervals: "interval",
  modelIds: "model_id",
};

function setToolTipPosition(numberOfText, leftPanelExpanded) {
  // Calculate required tool tip width and height
  const toolTipWidth = Array.from(document.querySelectorAll(".tool-tip-text"))
    .map((text) => text.getBBox().width)
    .reduce((a, b) => (a > b ? a : b));
  const toolTipHeight = 15 * numberOfText;

  const margin = leftPanelExpanded ? 350 : 0;

  // Calculate position of tool tip on page
  const translateX =
    d3.event.pageX - margin < render.width - toolTipWidth
      ? d3.event.pageX - margin
      : d3.event.pageX - margin - 8 - toolTipWidth;

  const translateY =
    d3.event.pageY < render.height - toolTipHeight
      ? d3.event.pageY - 40
      : d3.event.pageY - 80 - toolTipHeight;

  // Set tool tip attributes
  render.toolTip
    .attr("transform", `translate(${translateX} ${translateY})`)
    .selectAll("rect")
    .attr("width", toolTipWidth + 8)
    .attr("height", toolTipHeight + 8);
}

function extractInfo(d) {
  const nodeInfo = [];
  nodeInfo.push(
    !d.data.signal_value
      ? "No Signal"
      : `${
          d.data.aggregate && !d.data.oneSignal ? "Mean " : ""
        }Signal: ${Number(d.data.signal_value).toFixed(4)}`
  );
  let pointer = d;
  const categories = d.data.aggregate
    ? render.drillDownBy.slice(0, render.treeHeight - 1).reverse()
    : Object.keys(render.filtersToLabelMap);

  // Iterate through categories and populate nodeInfo
  for (let category of categories) {
    if (d.data.aggregate) {
      if (pointer) {
        // Traverse from node up towards head of tree data
        pointer = pointer.parent || pointer.data.parent;
        if (pointer && pointer.data) {
          nodeInfo.push(
            `${render.filtersToLabelMap[category]}: ${
              pointer.data ? pointer.data.signal_type : pointer.signal_type
            }`
          );
        }
      }
    } else {
      const categoryLabel = render.filtersToLabelMap[category];
      let categoryValue;

      if (render.tickerFields.includes(category)) {
        categoryValue = d.data.ticker[category];
      } else if (category === "modelIds") {
        categoryValue =
          render.modelIdToNameMap[d.data[FilterNameToSignalFieldMap[category]]]
            ?.name || "No signal";
      } else {
        categoryValue = d.data[FilterNameToSignalFieldMap[category]];
      }

      nodeInfo.push(`${categoryLabel}: ${categoryValue}`);
    }
  }
  return nodeInfo;
}
