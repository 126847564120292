import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Spacer } from "./BookmarksStyles";
import Bookmark from "@material-ui/icons/Bookmark";
import BookmarkButton from "./BookmarkButton/BookmarkButton";
import Button from "../../../UI/Button/CustomButtonWhite";
import SetBookmarksModal from "./SetBookmarksModal/SetBookmarksModal";

export default function Bookmarks() {
  // useState hooks
  const [bookmarksModalOpen, setBookmarksModalOpen] = useState(false);

  // Select from redux store
  const bookmarks = useSelector(
    ({ heatMapConfigs }) => heatMapConfigs.bookmarks
  );

  return (
    <Container>
      {bookmarksModalOpen && (
        <SetBookmarksModal
          bookmarks={bookmarks}
          onClose={() => setBookmarksModalOpen(false)}
        />
      )}

      <Bookmark fontSize="small" />
      <Spacer />
      {bookmarks.map((bookmark, index) => (
        <BookmarkButton key={index} index={index} bookmarkIndex={bookmark} />
      ))}
      <Spacer />
      <Button onClick={() => setBookmarksModalOpen(true)}>Set Bookmarks</Button>
    </Container>
  );
}
