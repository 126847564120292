import { Auth } from "aws-amplify";

const isUserAdmin = async () => {
  const authObj = await Auth.currentSession();
  const groups = authObj?.idToken?.payload["cognito:groups"]

  let isAdmin = false;

  if (groups && groups.includes("admin")) {
    isAdmin = true;
  }

  return isAdmin;
} 

export default isUserAdmin;