import "./DeleteChart.css";
import React, { useState } from "react";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";

import CustomButton from "../../../../UI/Button/CustomButton";
import CustomSpinner from "../../../../UI/Spinner/CustomSpinner";
// import { deleteChart } from "../../../../../redux/slices/leftPanel";

export default function Delete({ chartToDelete, setChartToDelete }) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const handleDelete = async () => {
    // setWaiting(true);
    // const response = await dispatch(deleteChart(chartToDelete.id));
    // setWaiting(false);
    // if (response) setSuccess(true);
    // else setError(true);
  };

  const onClose = () => {
    setChartToDelete(false);
    setError(false);
    setWaiting(false);
    setSuccess(false);
  };

  return (
    <Dialog open={!!chartToDelete} onClose={onClose} maxWidth={false}>
      <DialogTitle>Delete Chart</DialogTitle>
      <div className="delete-container">
        Are you sure you want to delete the following chart?
        <div className="delete-chart">{chartToDelete.name}</div>
        {success && (
          <div className="delete-success">Chart deleted successfully!</div>
        )}
        {error && (
          <div className="delete-error">
            Error processing your request. Please try again.
          </div>
        )}
        {waiting && <CustomSpinner />}
      </div>
      {!success && (
        <DialogActions>
          <CustomButton
            variant="outlined"
            disabled={waiting}
            onClick={handleDelete}
          >
            Yes
          </CustomButton>
          <CustomButton variant="outlined" disabled={waiting} onClick={onClose}>
            No
          </CustomButton>
        </DialogActions>
      )}
      {success && (
        <DialogActions>
          <CustomButton variant="outlined" onClick={onClose}>
            OK
          </CustomButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
