import store from "../redux/store";
import _ from "lodash";
import { subscribeOnStream, unsubscribeFromStream } from "../socket/socket";

export class ChartUtils {
  static onReady = (cb) => {
    let config = {};
    setTimeout(() => cb(config), 0);
  };

  static searchSymbols = (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {};

  static resolveSymbol = (resolution) => (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    let symbol_stub = {
      name: symbolName,
      type: "stock",
      session: "24x7",
      timezone: "Etc/UTC",
      ticker: symbolName,
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      intraday_multipliers: ["1", "60"],
      data_status: "streaming",
    };
    setTimeout(() => {
      onSymbolResolvedCallback(symbol_stub);
    }, 0);
  };

  static getBars = (option, context) => (
    symbolInfo,
    resolution,
    from,
    to,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest
  ) => {
    let bars;
    const chartId = symbolInfo.name.replace("Binance", "");
    const data =
      context === "heatMap"
        ? store.getState().heatMapChart.data
        : store.getState().chartData.entities[chartId];
    if (chartId === "preload") bars = [];
    else if (option === "preview") {
      const barsToClone = data.previewBars || data.bars;
      bars = _.cloneDeep(barsToClone);
    } else if (option === "tile") {
      bars = _.cloneDeep(data.bars);
    }
    if (firstDataRequest) {
      setTimeout(() => {
        onHistoryCallback(bars || [], { noData: !bars || bars.length === 0 });
      }, 0);
    } else {
      setTimeout(() => {
        onHistoryCallback([], { noData: true });
      }, 0);
    }
  };

  static subscribeBars = (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    if (symbolInfo.name.includes("Binance")) {
      subscribeOnStream(symbolInfo, onRealtimeCallback, subscribeUID);
    }
  };

  static unsubscribeBars = (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  };
}
