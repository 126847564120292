import { useEffect } from "react";

export const useUpdateBars = ({ chart, data }) => {
  useEffect(() => {
    if (!chart || !data) return;
    const bars = data.bars;
    const chartId = data.chartData.id;
    const chartType = data.chartData.type;
    if (!chart.ready || !bars || !bars.length) return;
    const symbol = chartType === "Binance" ? `${chartId}Binance` : chartId;
    chart.interval = data.chartData.interval;
    chart.defaultRangeLast250(bars);
    chart.updateBars(symbol);
  }, [chart, data]);
};
