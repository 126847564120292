import React from "react";

import leftChevron from "../../../../../../assets/left-chevron.svg";
import rightChevron from "../../../../../../assets/right-chevron.svg";

import { NavIcon, NavButton } from "./PatternNavButtonsStyles";

export default function PatternNavButtons({
  isMinimized,
  onNextPattern,
  onPrevPattern,
  isFullScreen,
}) {
  return (
    <>
      {!isMinimized && onNextPattern && (
        <>
          <NavButton
            direction="left"
            isFullScreen={isFullScreen}
            onClick={onPrevPattern}
          >
            <NavIcon src={leftChevron} alt="leftChevron" />
          </NavButton>
          <NavButton direction="right" onClick={onNextPattern}>
            <NavIcon src={rightChevron} alt="rightChevron" />
          </NavButton>
        </>
      )}
    </>
  );
}
