import styled from "styled-components";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: scroll;
`;

export const EmptyListText = styled.p`
  font-size: 12px;
  margin: 0 auto;
`;
