import { Fragment } from "react";
import React from "react";
import { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { RowContainer } from "./ModelSelect/ModelSelectStyles";
import { Container, FormHelperText } from "@material-ui/core";
import { Title } from "./ModelSelect/ModelSelectStyles";

const PatternsTimeRange = (props) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeRangeIsValid, setTimeRangeIsValid] = useState(true);

  const setTimeRangeValue = (value, setterFunc, secondTimeToCompare) => {
    let isValid = false;

    if ((value && secondTimeToCompare) || (!value && !secondTimeToCompare)) {
      isValid = true;
    }

    setTimeRangeIsValid(isValid);

    let valueToset = value;
    let timeRange = [];

    if (value) {
      const coeff = 1000 * 60;
      valueToset = new Date(Math.round(value.getTime() / coeff) * coeff);
    }

    if (isValid && valueToset) {
      timeRange = [valueToset, secondTimeToCompare];
    }

    setterFunc(valueToset);
    props.setPatternsTimeRange(timeRange);
    props.setTimeRangeIsValid(isValid);
  };

  return (
    <Fragment>
      <RowContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Container>
            <Title>Start pattern time</Title>
            <TimePicker
              type="time"
              id="start-time"
              clearable
              ampm={false}
              value={startTime}
              onChange={(v) => {
                setTimeRangeValue(v, setStartTime, endTime);
              }}
            />
          </Container>
          <Container>
            <Title>End pattern time</Title>
            <TimePicker
              type="time"
              id="end-time"
              clearable
              ampm={false}
              value={endTime}
              onChange={(v) => {
                setTimeRangeValue(v, setEndTime, startTime);
              }}
            />
          </Container>
        </MuiPickersUtilsProvider>
      </RowContainer>
      {!timeRangeIsValid && (
        <FormHelperText error={true}>
          Both start and end patterns time should be specified.
        </FormHelperText>
      )}
    </Fragment>
  );
};

export default PatternsTimeRange;