import styled from "styled-components";

export const DetectedPatternContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  align-content: flex-start;
  justify-content: center;
`;
