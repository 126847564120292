import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 10px;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const HeatMapConstraint = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const NoHeatMapContainer = styled.div`
  color: white;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 45%;
  transform: translate(-50%, -50%);
`;

export const HeatMapContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
