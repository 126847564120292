import styled from "styled-components";

export const TileChartContainer = styled.div`
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height 100%;
  margin-bottom: 10px;
  overflow: hidden;
`;
export const StyledTileChart = styled.div`
  box-sizing: border-box;
  padding: 5px;
  padding-top: 10px;
  margin-top: 0;
  flex-grow: 1;
  width: 100%;
  position: relative;
  z-index: 1;
`;
