import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomSlider from "../../../UI/Slider/CustomSlider";
import { changeFilter } from "../../../../redux/slices/heatMap";

export default function BarRangeSlider() {
  const barRange = useSelector(({ heatMap }) => heatMap.filters.lastBar);

  const dispatch = useDispatch();

  const handleSliderChange = (event, newValue) => {
    dispatch(changeFilter({ lastBar: newValue }));
  };

  return (
    <CustomSlider
      value={barRange}
      onChange={handleSliderChange}
      valueLabelDisplay="on"
      aria-labelledby="range-slider"
      step={5}
      min={0}
      max={600}
    />
  );
}
