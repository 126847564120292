import React from "react";

import {
  HelpRow,
  HelpTitle,
  HelpImage,
  ImageContainer,
  PageContainer,
} from "./HelpStyles";
import Step1Option2 from "../../../../assets/TrainingHelp/Step1Option2.jpg";

export default function HelpPage2() {
  return (
    <PageContainer>
      <HelpTitle>Step 1: Select patterns from chart data</HelpTitle>
      <HelpRow>
        Select the rectangle tool from the TradingView menu on the left of the
        chart
      </HelpRow>
      <HelpRow>
        The rectangle tool is often hidden behind the brush tool - click the
        arrow next to the brush to reveal the rectangle tool
      </HelpRow>
      <HelpRow>
        Save multiple variations of your pattern to increase model accuracy
      </HelpRow>
      <ImageContainer>
        <HelpImage src={Step1Option2} alt="select pattern" />
      </ImageContainer>
    </PageContainer>
  );
}
