import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useHighlightedRange = ({ chart }) => {
  const highlightedRange = useSelector(
    ({ previewChart }) => previewChart.range
  );

  useEffect(() => {
    if (!chart.ready) return;
    chart.updateVisibleRange(highlightedRange);
    chart.drawRangeIndicator();
  }, [chart, highlightedRange]);
};
