const findLineSegmentCombinations = (lineSegments, numberOfSegments) => {
  if (numberOfSegments === 1)
    return lineSegments.map((lineSegment) => [lineSegment]);

  const result = [];

  for (let lineSegment of lineSegments) {
    const nextLineSegments = lineSegments.filter(
      ([first]) => first.time >= lineSegment[1].time
    );

    const nextCombinations = findLineSegmentCombinations(
      nextLineSegments,
      numberOfSegments - 1
    );

    nextCombinations.forEach((combination) =>
      result.push([lineSegment, ...combination])
    );
  }

  return result.filter(
    (combination) => combination.length === numberOfSegments
  );
};

export default findLineSegmentCombinations;
