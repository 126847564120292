import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomSlider from "../../../UI/Slider/CustomSlider";
import { changeFilter } from "../../../../redux/slices/heatMap";

export default function SignalsOnChartRangeSlider() {
  const signalsOnChartCountRange = useSelector(({heatMap}) => heatMap.filters.signalsOnChartCount);
  
  const dispatch = useDispatch();

  const handleSliderChange = (event, newValue) => {
    dispatch(changeFilter({signalsOnChartCount: newValue}));
  };

  return (
    <CustomSlider
      value={signalsOnChartCountRange || 0}
      onChange={handleSliderChange}
      valueLabelDisplay="on"
      aria-labelledby="range-slider"
      step={1}
      min={0}
      max={4}
    />
  );
}
