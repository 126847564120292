import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(38, 51, 64);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  cursor: grab;
  height: 60px;
  right: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  z-index: 1000;
`;

export const Dot = styled.div`
  background-color: white;
  border-radius: 50%;
  height: 6px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: ${({ y }) => `translate(-55%, ${-50 + y}%)`};
  width: 6px;
`;
