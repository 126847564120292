import React from "react";
import { LineChart, Line, ReferenceDot, XAxis } from "recharts";

import { useGetDimensions } from "../hooks/useGetDimensions";
import { useMouseInteractions } from "../hooks/useMouseInteractions";
import { StyledDrawingArea } from "./DrawingAreaStyles";
import { useSelector } from "react-redux";

export default function DrawingArea() {
  const data = useSelector(({ coldStart }) => coldStart.currentShape);
  const { height, width, containerTop, containerLeft } = useGetDimensions();

  const { onDotClick, onBackgroundClick } = useMouseInteractions({
    height,
    width,
    containerTop,
    containerLeft,
  });
  return (
    <StyledDrawingArea
      id="coldStartContainer"
      onMouseDown={(e) => onBackgroundClick(e)}
      onContextMenu={(e) => e.preventDefault()}
    >
      <LineChart width={width} height={height} data={data}>
        <XAxis dataKey="x" type="number" hide={true} />
        <Line type="monotone" dataKey="baseline" dot={{ r: 0 }} />
        {data.length > 1 && (
          <Line
            type="monotone"
            dataKey="y"
            stroke="rgb(24,235,254)"
            dot={false}
            animationDuration={750}
          />
        )}
        {data.map((dot, index) => (
          <ReferenceDot
            key={index}
            x={dot.x}
            y={dot.y}
            r={10}
            fill="white"
            stroke="none"
            onMouseDown={() => onDotClick(index)}
          />
        ))}
      </LineChart>
    </StyledDrawingArea>
  );
}
