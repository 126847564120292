import LayoutGenerator from "./LayoutGenerator";

export const layoutHelper = (chartList, previousLayout) => {
  const layoutGenerator = new LayoutGenerator(chartList, previousLayout);

  const newLayout = layoutGenerator.populateGridWithLockedCharts();

  const numOfUnlockedCharts = layoutGenerator.setUnlockedCharts();
  if (!numOfUnlockedCharts) return { newLayout };

  //we want to start with the charts as large as possible. This makes it much faster to find an optimal layout.
  //we will set all the charts to be the same size, and find the largest size shape where all the charts can fit.
  const error = layoutGenerator.findLargestUniformSize();

  if (error) return { error: true, newLayout }; //if we can't fit in all charts even at size 1x1, return error,

  //Optimize by finding a more efficient starting position with some charts larger than others
  layoutGenerator.optimiseStartingPosition();

  //recursive mutations
  for (let i = 0; i < numOfUnlockedCharts; i++) {
    layoutGenerator.mutate(layoutGenerator.startingCharts, i);
  }
  if (!layoutGenerator.finalResult) return { error: true, newLayout };

  const convertedResults = layoutGenerator.convertResults();
  // console.log(layoutGenerator.counter);
  return { newLayout: [...newLayout, ...convertedResults] };
};
