import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllSPatterns } from "../../redux/slices/selectedPatterns";

export const useSPatternsByChart = (chartId) => {
  const allSPatterns = useSelector(selectAllSPatterns);
  return useMemo(
    () => allSPatterns.filter((pattern) => pattern.chartId === chartId),
    [chartId, allSPatterns]
  );
};
