import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  height: 250px;
`;

export const BottomContainer = styled.div`
  background-color: rgb(52, 61, 70);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  justify-content: center;
  margin-top: 20px;
`;
