import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { updateDPattern } from "./detectedPatterns";

const selectedDetectedPatternsAdapter = createEntityAdapter();

const selectedDetectedPatternsSlice = createSlice({
  name: "selectedDetectedPatterns",
  initialState: selectedDetectedPatternsAdapter.getInitialState(),
  reducers: {
    addSDPattern: (state, action) => {
      selectedDetectedPatternsAdapter.upsertOne(state, action.payload);
    },
    updateSDPattern: (state, action) => {
      selectedDetectedPatternsAdapter.updateOne(state, action.payload);
    },
    removeSDPattern: (state, action) => {
      selectedDetectedPatternsAdapter.removeOne(state, action.payload);
    },
    removeManySDPatterns: (state, action) => {
      selectedDetectedPatternsAdapter.removeMany(state, action.payload);
    },
    removeAllSDPatterns: (state) => {
      selectedDetectedPatternsAdapter.setAll(state, []);
    },
  },
});

export const {
  addSDPattern,
  updateSDPattern,
  removeSDPattern,
  removeManySDPatterns,
  removeAllSDPatterns,
} = selectedDetectedPatternsSlice.actions;

export default selectedDetectedPatternsSlice.reducer;

export const {
  selectAll: selectAllSDPatterns,
  selectIds: selectSDPatternIds,
  selectTotal: selectTotalSDPatterns,
} = selectedDetectedPatternsAdapter.getSelectors(
  (state) => state.selectedDetectedPatterns
);
export const selectSDChartList = createSelector(
  [selectAllSDPatterns],
  (patterns) =>
    patterns.map(({ chartName, interval }) => ({
      chartName,
      interval,
    }))
);

export const clearSelectedDetectedPatternsSingleChart = (chartId) => async (
  dispatch,
  getState
) => {
  if (!chartId) chartId = getState().chartLayout.fullScreenChart;
  const allPaterns = Object.values(
    getState().selectedDetectedPatterns.entities
  );
  const chartPatterns = allPaterns.filter(
    (pattern) => pattern.chartId === chartId
  );
  dispatch(removeManySDPatterns(chartPatterns.map((pattern) => pattern.id)));
};

export const deselectPattern = (id) => (dispatch) => {
  dispatch(updateDPattern({ id, changes: { selected: false } }));
  dispatch(removeSDPattern(id));
};
export const editSDPattern = ({ id, points }) => (dispatch) => {
  dispatch(
    updateSDPattern({
      id,
      changes: {
        shapes: [
          {
            points: points,
            shape_type: "rectangle",
          },
        ],
      },
    })
  );
};
