import { withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";

const styles = {
  root: {
    color: "white !important",
  },
};

export default withStyles(styles)(Checkbox);
