import { useRef } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { setDrawerWidth } from "../../../../redux/slices/grid";

export const useDragger = () => {
  const dispatch = useDispatch();
  const anchor = useRef();

  const handleMouseDown = () => {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
    anchor.current = document.getElementById("s-p-grid").offsetLeft;
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", throttledMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", throttledMouseMove, true);
  };

  const handleMouseMove = (e) => {
    const newWidth = e.clientX - anchor.current;
    dispatch(setDrawerWidth(newWidth));
  };

  const throttledMouseMove = _.throttle(handleMouseMove, 16);

  return handleMouseDown;
};
