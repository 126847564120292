import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { IconButton } from "@material-ui/core";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { StyledLinkButton } from "./LinkButtonStyles";
import { showHeatMapChartWithSignal } from "../../../../../redux/slices/heatMapChart";

export default function LinkButton({ signal, chartData, filteredBars }) {
  const dispatch = useDispatch();

  const onLinkClick = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch(showHeatMapChartWithSignal(signal, filteredBars));
    },

    [dispatch, signal, filteredBars]
  );

  return (
    <>
      {chartData && (
        <StyledLinkButton>
          <IconButton onClick={onLinkClick}>
            <FindInPageIcon color="primary" />
          </IconButton>
        </StyledLinkButton>
      )}
    </>
  );
}
