import React from "react";

import { PreviewContainer, StyledPreview } from "./PreviewStyles";
import { useSetupChart } from "./hooks/useSetupChart";
import { useVisibleRangeController } from "./hooks/useVisibleRangeController";
import { useHighlightedRange } from "./hooks/useHighlightedRange";
import { useClickHandler } from "./hooks/useClickHandler";
import { usePatternDrawings } from "./hooks/usePatternDrawings";

export default function Preview({ fullScreenChart, containerId }) {
  const { chart, newVisRange, newSelection } = useSetupChart({
    chartId: fullScreenChart,
    containerId,
  });

  useVisibleRangeController({ chart, newVisRange });
  useHighlightedRange({ chart });
  useClickHandler({
    chart,
    newSelection,
  });
  usePatternDrawings({ chart, newVisRange, chartId: fullScreenChart });

  return (
    <PreviewContainer>
      <StyledPreview id={containerId} />
    </PreviewContainer>
  );
}
