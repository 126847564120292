import styled from "styled-components";

export const Container = styled.div`
  background-color: rgb(38, 51, 64);
  border-radius: 10px;
  margin: 50px auto;
  max-width: 1300px;
  min-height: 500px;
  min-width: 1100px;
  padding: 30px;
`;

export const Title = styled.h2`
  color: white;
`;

export const Subtitle = styled.h4`
  color: white;
  font-weight: 400;
`;

export const SettingsContainer = styled.div`
  border: white 1px solid;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;
  width: 100%;
`;

export const SettingsTitle = styled.h3`
  color: white;
  font-weight: 200;
  margin: 30px 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ left }) => (left ? "flex-end" : "flex-start")};
  padding: 10px;
  width: 50%;
`;
