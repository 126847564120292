import styled from "styled-components";

export const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 130px;
`;

export const Container = styled.div`
  margin: 50px 0;
`;

export const Label = styled.p`
  align-self: center;
  font-size: 12px;
  margin: 0;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const SliderContainer = styled.div`
  margin-top: 50px;
  padding: 0 30px 0 20px;
  width: 200px;
`;

export const RowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
`;
