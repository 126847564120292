import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../UI/Button/CustomButtonLeftPanelBlue";
import SchoolIcon from "@material-ui/icons/School";

import { setGridDisplay } from "../../../../redux/slices/display";
import { coldTrainModel, trainModel } from "../../../../redux/slices/models";
import {
  detectPatternsForUnsavedModel,
  detectPatterns,
  MAX_PATTERNS_NUMBER,
} from "../../../../redux/slices/detectedPatterns";
import { setTrained } from "../../../../redux/slices/toolbar";
import {
  clearColdStartPatterns,
  saveCurrentShape,
} from "../../../../redux/slices/coldStart";
import getSelectedBars from "./utils/getSelectedBars";
import getNodalRepresentation from "./utils/getNodalRepresentation";
import drawNodalRepresentation from "./utils/drawNodalRepresentation";
import useChartCollectionsToDetect from "../ChartsToDetectOnSelect/hooks/useChartCollectionsToDetect"

export default function TrainButton({
  selectedCount,
  patternsTimeRange,
  patternsTimeRangeIsValid,
  drawnCount,
  trained,
  disabled,
}) {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [onTrainRunning, setOnTrainRunning] = useState(false);
  const [onDetectRunning, setOnDetectRunning] = useState(false);

  const modelToRetrain = useSelector(({ models }) => models.retrainModel);

  const [, selectedCollection, ] = useChartCollectionsToDetect();

  // Select from redux store
  const training = useSelector(({ models }) => models.training);
  const { fetching: detectingPatterns, chartCollectionToDetect } = useSelector(
    ({ detectedPatterns }) => detectedPatterns
  );

  useEffect(() => {
    const detect = async () => {
      dispatch(setGridDisplay());
      setOnDetectRunning(true);

      await dispatch(
        detectPatterns({
          model: modelToRetrain,
          defaultConfidence: false,
          maxPatterns: MAX_PATTERNS_NUMBER,
          detectionProposals: patternsTimeRange,
        })
      );

      dispatch(setTrained(true));
      dispatch(clearColdStartPatterns());
      setOnDetectRunning(false);
    };

    if (modelToRetrain && patternsTimeRangeIsValid) {
      detect();
    }
  }, [
    modelToRetrain,
    dispatch,
    patternsTimeRange,
    patternsTimeRangeIsValid,
    selectedCollection,
  ]);

  // Label button if model trained or not
  useEffect(() => {
    if (onDetectRunning) {
      setText("Detecting");
    } else if (onTrainRunning) {
      setText("Training");
    } else {
      setText(trained ? "Retrain Model" : "Train Model");
    }
  }, [trained, onTrainRunning, onDetectRunning]);

  const onTrain = async () => {
    dispatch(setGridDisplay());
    setOnTrainRunning(true);

    if (drawnCount) {
      await dispatch(coldTrainModel(drawnCount));
    } else if (selectedCount === 1) {
      // If only one selected pattern, convert to nodal representation, draw on coldStart and train from coldStart
      const bars = getSelectedBars();
      const nodalRepresentation = await getNodalRepresentation(bars, 12);
      drawNodalRepresentation(nodalRepresentation, dispatch);
      dispatch(saveCurrentShape());
      await dispatch(coldTrainModel(drawnCount));
    } else {
      await dispatch(trainModel(selectedCount));
    }

    setOnTrainRunning(false);

    setOnDetectRunning(true);
    await dispatch(detectPatternsForUnsavedModel(patternsTimeRange));

    dispatch(setTrained(true));
    dispatch(clearColdStartPatterns());

    setOnDetectRunning(false);
  };

  return (
    <Button
      loading={training || detectingPatterns}
      disabled={
        disabled || training || detectingPatterns || !chartCollectionToDetect
      }
      onClick={onTrain}
    >
      <>
        <SchoolIcon />
        <div style={{ marginLeft: 10 }}>{text}</div>
      </>
    </Button>
  );
}
