import React, { useState } from "react";
import { useSelector } from "react-redux";

import { StyledOverlay } from "./ToolbarStyles";

export default function Overlay() {
  const [active, setActive] = useState(false);
  const resizing = useSelector(({ chartLayout }) => chartLayout.resizing);
  //Overlay prevents mouse from interacting with other elements while dragging
  return (
    <StyledOverlay
      active={active || resizing}
      onMouseDown={() => {
        setActive(true);
      }}
      onMouseUp={() => {
        setActive(false);
      }}
    />
  );
}
