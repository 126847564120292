import React from "react";
import DropDown from "../../DropDown/DropDown";
import { Container, Title } from "./ChartsToDetectOnStyles";
import useChartCollectionsToDetect from "./hooks/useChartCollectionsToDetect";

export default function ChartsToDetectOnSelect() {
  const [customCollections, selectedCollection, onCollectionSelect] = useChartCollectionsToDetect();
  return (
    <Container>
      <Title>Detect on:</Title>
      <DropDown
        value={selectedCollection}
        multiple={false}
        options={customCollections}
        onChange={onCollectionSelect}
      />
    </Container>
  );
}
