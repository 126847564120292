import React from "react";
import DropDown from "../../DropDown/DropDown";
import useModelNames from "./hooks/useModelNames";
import { Container, Title } from "./ModelSelectStyles";

export default function ModelSelect() {
  // Custom hooks
  const [models, selectedModel, onChangeModel] = useModelNames();

  return (
    <Container>
      <Title>Select model:</Title>
      <DropDown
        value={selectedModel}
        multiple={false}
        options={models}
        onChange={onChangeModel}
      />
    </Container>
  );
}
