import styled from "styled-components";

export const Circle = styled.div.attrs(({ top, dragging }) => ({
  style: {
    top,
    boxShadow: dragging ? "0 0 10px 5px #1c84ff" : "",
  },
}))`
  background-color: #1c84ff;
  border-radius: 50%;
  cursor: grab;
  height: 13px;
  left: 8px;
  position: absolute;

  transition: top
    ${({ transitioningHeight }) => (transitioningHeight ? "0.2s" : "0s")} linear;
  transform: translate(-50%, -50%);
  width: 13px;
  z-index: 2;

  &:hover {
    box-shadow: 0 0 10px 0 #1c84ff;
  }
`;

export const Label = styled.div`
  align-items: center;
  background-color: #1c84ff;
  border-radius: 2px;
  display: flex;
  height: 20px;
  font-size: 12px;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: -20px;
  transform: translate(-50%, -50%);
  width: 15px;
  z-index: 3;
`;
