import { useEffect, useState } from "react";
import { ChartUtils } from "../../../../../utils/chartUtils";
import WidgetClass from "../../Classes/WidgetClass";
import { useUpdateBars } from "./useUpdateBars";

const DISABLED_FEATURES = [
  "header_widget",
  "legend_widget",
  "timeframes_toolbar",
  "chart_scroll",
  "chart_zoom",
  "left_toolbar",
  "control_bar",
];
const VISIBLE_RANGE_OVERRIDES = {
  "linetooldaterange.textcolor": "transparent",
  "linetooldaterange.labelBackgroundColor": "transparent",
};
const WIDGET_OPTIONS = {
  symbol: "preload",
  datafeed: {
    onReady: ChartUtils.onReady,
    searchSymbols: ChartUtils.searchSymbols,
    resolveSymbol: ChartUtils.resolveSymbol(60),
    getBars: ChartUtils.getBars("preview"),
    subscribeBars: ChartUtils.subscribeBars,
    unsubscribeBars: ChartUtils.unsubscribeBars,
  },

  interval: 60,
  library_path: "/charting_library/",
  autosize: true,
  disabled_features: DISABLED_FEATURES,
  overrides: VISIBLE_RANGE_OVERRIDES,
  theme: "Dark",
};

export const useSetupChart = ({ chartId, containerId }) => {
  const [chart, setChart] = useState({});

  //Triggers to allow us to respond to chart events
  const [newVisRange, setNewVisRange] = useState(false);
  const [newSelection, setNewSelection] = useState(false);

  //set up widget
  useEffect(() => {
    const widgetOptions = {
      ...WIDGET_OPTIONS,
      container_id: containerId,
    };
    const widgetApp = new window.TradingView.widget(widgetOptions);

    widgetApp.onChartReady(() => {
      const newChart = new WidgetClass({
        instance: widgetApp,
      });

      newChart.onMouseMove(({ time }) => {
        newChart.mousePosition = time;
      });

      newChart.onMouseClick(() => setNewSelection([true])); //array used to ensure equality checks fail

      newChart.onVisibleRangeChange(() => {
        setNewVisRange([true]);
      });

      setChart(newChart);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateBars({ chart, chartId });

  return {
    chart,
    newVisRange,
    newSelection,
  };
};
