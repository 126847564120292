import styled from "styled-components";

export const StyledOverlay = styled.div`
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 3;
  height: ${(props) => (props.active ? "200%" : "20px")};
  top: ${(props) => (props.active ? "-50%" : "0")};
`;
export const StyledToolbar = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  height: 20px;
  width: 100%;
  background-color: rgb(206, 205, 205);
  border: 1px solid rgb(59, 59, 59);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const TileToolbarTitle = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  text-align: ${(props) => (props.textLeft ? "left" : "center")};
  width: calc(100% - ${(props) => (props.textLeft ? "42px" : "100px")});
  color: rgb(58, 58, 58);
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
