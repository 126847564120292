import React from "react";
import EditIcon from "@material-ui/icons/Edit";
// import UploadModal from "../../../../Modals/Upload/Upload";

import { ListItemContainer, ListItem } from "../../../LeftPanelStyle";

function CollectionItem({
  collection,
  onSelect,
  selected,
  onEditCustomCollectionClick,
}) {
  return (
    <ListItemContainer>
      <ListItem selected={selected} onClick={() => onSelect(collection)}>
        {collection.name}
      </ListItem>
      {collection.custom && (
        <EditIcon
          style={{ cursor: "pointer" }}
          onClick={() => onEditCustomCollectionClick(collection)}
          fontSize="small"
        />
      )}
    </ListItemContainer>
  );
}

export default CollectionItem;
