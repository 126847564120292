import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrillDownBy } from "../../../redux/slices/heatMap";

const useDrillDownConstraint = () => {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Select from redux store
  const drillDownConstraint = useSelector(
    ({ settings }) => settings.drillDownConstraint
  );

  const reduxDrillDownBy = useSelector(({ heatMap }) => heatMap.drillDownBy);

  useEffect(() => {
    if (reduxDrillDownBy.length > drillDownConstraint) {
      dispatch(setDrillDownBy(reduxDrillDownBy.slice(0, drillDownConstraint)));
    }
  }, [reduxDrillDownBy, dispatch, drillDownConstraint]);
};

export default useDrillDownConstraint;
